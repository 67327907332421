import React, { useContext, useEffect, useState } from 'react'
import LeftArrow from '../../static/svg/LeftArrow'
import { useHistory } from 'react-router-dom';
import { Empty, Input, notification } from 'antd';
import LeadCardSkeleton from '../LeadsV2/skeletons/LeadCardSkeleton';
import { getCandidateListV2 } from '../../service/LeadsPageV2Service';
import { AppContext } from '../../App';
import LeadCard from '../LeadsV2/components/LeadCard';
import { trackEvent } from '../../service/engagementMonitoringService';

const { Search } = Input;

function SearchPageV2() {
  const history = useHistory();
  const [leadCardLoading, setLeadCardLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState(null);
  const [leadCardList, setLeadCardList] = useState([]);
  const [noLeadsFound, setNoLeadsFound] = useState(false);
  const {mitraReducer} = useContext(AppContext);
  const filters = {
    "clients": [],
    "locations" :[],
    "callStatus": [],
    "teams": [],
    "teamMembers": [],
    "lastMilestone": [],
    "searchText": "",
    "sortBy": "most recent",
    "typeOfLeads": "referred",
    "limit": 50,
    "offset": 0
};

  const onSearch = (e) => {
    filters.searchText = e;

    setSearchTerm(e);

    if(!e.length) {
      notification['error']({
        message: 'Please enter search text to continue..'
      });

      return;
    }
    setLeadCardLoading(true);

   getCandidateListV2(filters,mitraReducer?.mitraInfo?.id)
   .then(res=> {
    if(res?.data?.data?.length) {
      setLeadCardList([...res?.data?.data]);
    } else {
      setLeadCardList([]);
      setNoLeadsFound(true);
    }

    setLeadCardLoading(false);
   }).catch(err => {
    console.log(err);
   })
  }

  useEffect(() => {
    if (history.location?.state?.candidatePhoneNumber || localStorage.getItem('currentPhoneNumber')) {
      const phoneNumber = history.location?.state?.candidatePhoneNumber || localStorage.getItem('currentPhoneNumber');
      onSearch(phoneNumber);
    }

    trackEvent('vp_search_page_visited', {
      phoneNumber: mitraReducer?.mitraInfo?.phoneNumber
    });
  }, [])
  
  return (
    <div className='vahan-pro-component-container'>

      <div className='d-flex align-items-start flex-gap-16' style={{padding: '32px 24px'}}>
        <div onClick={()=>window.history.back()} className='cursor-pointer'>
          <LeftArrow height={20} width={20}/>
        </div>
        <span className='inter-semibold-16-24 text-align-start'>Search by Candidate Name/Phone Number</span>
      </div>

      <div style={{padding: '0px 24px'}}>
        <Search 
        placeholder="Search by Candidate Name/Phone Number" 
        onSearch={onSearch} 
        allowClear={false}
        enterButton/>
      </div>

      <div className="leads-card-container">

        {
          leadCardLoading ? <LeadCardSkeleton />:
          !leadCardList?.length && noLeadsFound ? (
              <div className='d-flex align-items-center justify-content-center w-100 flex-column'>
                <Empty description=''/>
                <span className='inter-medium-14-20'>No Candidates Found</span>
              </div>
          ): leadCardList?.length ? (leadCardList.map((item, index)=>(
            <LeadCard
            key={index} 
            leadInfo={item}
            filters={filters}
            mitraReducer={mitraReducer}
            getLeadsDashboardConfiguration={()=>onSearch(searchTerm)}
            />
          ))): null
        }
      </div>

    </div>
  )
}

export default SearchPageV2