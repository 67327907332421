import React, { useContext, useEffect, useState } from 'react';
import '../styles/HelpAutomation.scss'
import HelpGuide from './HelpGuide';
import { Button } from 'antd';
import ArrowSquareRight from '../../../static/svg/ArrowSquareRight';
import RaisedIssueListContainer from './RaisedIssueListContainer';
import SupportChatBot from './SupportChatBot';
import { trackEvent } from '../../../service/engagementMonitoringService';
import { AppContext } from '../../../App';

function HelpAutomation() {
    const [showChatbot, setShowChatbox] = useState(false);
    const {mitraReducer} = useContext(AppContext);

    useEffect(() => {
      trackEvent('vp_visited_suport_page', {
        phoneNumner: mitraReducer?.mitraInfo?.phoneNumber
      });
    }, [])
    
  return (
    <>
        <div className='vahan-pro-component-container'>
            <div className="help-automation-header-container d-flex align-items-center justify-content-between">
                <div className="hahc-left d-flex align-items-start flex-column">
                    <span className='unbounded-semibold-24-28 plain-black'>
                        Help
                    </span>
                    <span className='color-838383 inter-regular-14-20 text-align-start'>Find solutions to issues from our video library and FAQs</span>
                </div>
                <img src='/image/help-header-icon.webp' className='visibility-desktop help-header-icon' />
            </div>

            <HelpGuide />

            <div className='issue-not-resolved-container'>
                <span className='unbounded-regular-16'>Issue Not Resolved?</span>
                <Button className='primary-btn-styles-v2 d-flex align-items-center flex-gap-4' onClick={()=>setShowChatbox(true)}>
                    <span className='plain-white inter-semibold-12-18'>
                        Try 
                        {mitraReducer?.customBrandDetails?.brandName ? ' ':' VahanPro '} 
                        Support
                    </span>
                    <ArrowSquareRight />
                </Button>
            </div>

            <RaisedIssueListContainer />
        </div>

        {
            showChatbot ? <SupportChatBot setShowChatbox={setShowChatbox} conversationId={null} source={'help_section'}/>: null
        }
        
    </>
  )
}

export default HelpAutomation