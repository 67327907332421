import * as React from "react";
const RupeeSymbol = ({fillColor='none',height=16,width=16,strokeColor="#838383"}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill={fillColor}
  >
    <g clipPath="url(#clip0_599_11573)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5 7.99951C14.5 11.5894 11.5899 14.4995 8 14.4995C4.41015 14.4995 1.5 11.5894 1.5 7.99951C1.5 4.40966 4.41015 1.49951 8 1.49951C11.5899 1.49951 14.5 4.40966 14.5 7.99951ZM15.5 7.99951C15.5 12.1416 12.1421 15.4995 8 15.4995C3.85786 15.4995 0.5 12.1416 0.5 7.99951C0.5 3.85738 3.85786 0.499512 8 0.499512C12.1421 0.499512 15.5 3.85738 15.5 7.99951ZM10.5471 5.54336C10.5471 5.64444 10.4652 5.7264 10.3641 5.7264H9.25152C9.34261 5.87517 9.41573 6.03846 9.4689 6.21449H10.3641C10.4652 6.21449 10.5471 6.29645 10.5471 6.39753V7.00765C10.5471 7.10873 10.4652 7.19068 10.3641 7.19068H9.55858C9.44915 8.3272 8.61565 9.10594 7.44881 9.14163L9.75021 11.266C9.87265 11.379 9.79269 11.5835 9.62607 11.5835H8.36675C8.32074 11.5835 8.27642 11.5662 8.24261 11.535L5.72507 9.21113C5.68753 9.17647 5.66618 9.12771 5.66618 9.07662V8.26803C5.66618 8.16695 5.74813 8.085 5.84921 8.085H7.13047C7.76798 8.085 8.17591 7.7411 8.27073 7.19068H5.84921C5.74813 7.19068 5.66618 7.10873 5.66618 7.00765V6.39753C5.66618 6.29645 5.74813 6.21449 5.84921 6.21449H8.07997C7.88612 5.95071 7.56652 5.79882 7.14999 5.79882H5.84921C5.74813 5.79882 5.66618 5.71686 5.66618 5.61578V4.93324C5.66618 4.83216 5.74813 4.7502 5.84921 4.7502H10.3641C10.4652 4.7502 10.5471 4.83216 10.5471 4.93324V5.54336Z"
        fill={strokeColor}
      />
    </g>
    <defs>
      <clipPath id="clip0_599_11573">
        <rect width={16} height={16} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default RupeeSymbol;
