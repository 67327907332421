import axios from 'axios';
import crypto from 'crypto';
import moment from 'moment';

const PUBLIC_KEY =  process.env.REACT_APP_AUTHORIZATION_TOKEN_PUBLIC_KEY.replace(/\\n/g, '\n');

// Add a request interceptor 
var axiosInstance = axios.create();

let  cancelTokenSources  = {};

// Function to cancel any previous request of the same type
const  cancelPreviousRequest  = (key) => {
    if (cancelTokenSources[key]) {
        cancelTokenSources[key].cancel(`Operation canceled due to new request. Cancelled API: ${key}`);
        delete  cancelTokenSources[key];
    }
};

const encryptData = (data, publicKey) => {
    const buffer = Buffer.from(data, 'utf8');
    const encrypted = crypto.publicEncrypt({
        key: publicKey,
        padding: crypto.constants.RSA_PKCS1_PADDING,
    }, buffer);
    return encrypted.toString('base64');
}

const createAuthHeader = (mitraId) => {
    const timestamp = moment.utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'); // in milliseconds cz APIs were found to have same seconds as well
    const tokenData = `${mitraId}+${timestamp}`;
    return encryptData(tokenData, PUBLIC_KEY);
}

axiosInstance.interceptors.request.use(function async(config) {
    config.url = window.location.href.includes('gigshare') ? config.url.replace(process.env.REACT_APP_BACKEND_URL, process.env.REACT_APP_GIGSHARE_BACKEND_URL) : config.url; 
    const url = config.url;
    config.headers['Cache-Control'] = 'no-cache, no-store, must-revalidate';
    config.headers['Pragma'] = 'no-cache';
    config.headers['Expires'] = '0';
    
    if(!url.includes('/swiggy/'))  { // disabling it for swiggy APIs
        cancelPreviousRequest(url);
        const source = axios.CancelToken.source();

        config.cancelToken = source.token;
        cancelTokenSources[url] = source;
    }

    const mitraId = localStorage.getItem('mitraId');
    if (mitraId) {
        const encryptedAuthToken = createAuthHeader(mitraId);
        config.headers['auth-token'] = `${encryptedAuthToken}`;
    }

    config.headers['X-Cancel-Token']=`${Date.now()}-${Math.random().toString(36).substring(2)}`;

    return config
}, function (error) {
   // Do something with request error 
   return Promise.reject(error);
});

export default axiosInstance;
