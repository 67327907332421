import axiosInstance from "./interceptorService";


export const getLeadsPageConfiguration = async ({mitraId}) => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/leads/${mitraId}/getViews?type=leads_page_view&xyz=1`;
    return axiosInstance.get(url, { withCredentials: true });
};

export const saveEditViews = async ({mitraId, data}) => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/leads/${mitraId}/saveViews`;
    return axiosInstance.post(url, data, { withCredentials: true });
}

export const getFilters = async (mitraId, data) => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/leads/${mitraId}/getFiltersData`;
    return axiosInstance.post(url, data,{ withCredentials: true });
};

export const getCandidateListV2 = async (data, mitraId) => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/leads/${mitraId}/getLeadsPageCandidates`;
    return axiosInstance.post(url, data,{ withCredentials: true });
};

export const fetchApplicationDetails = async (data) => {
    const applicationId = data.applicationId;
    const leadPoolId = data.leadPoolId;
    let url = `${process.env.REACT_APP_BACKEND_URL}/leads/${data.mitraId}/getApplicationPopupData?`;

    // Either one of two, never both
    if(applicationId) {
        url = url + 'applicationId=' + applicationId;
    }

    if(leadPoolId) {
        url = url + 'leadPoolId=' + leadPoolId;
    }
    return axiosInstance.get(url, { withCredentials: true });
};

export const reAssignLeadsV2 = async (data, mitraId) => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/leads/${mitraId}/reassignLeads`;
    return axiosInstance.post(url, data,{ withCredentials: true });
};

export const deleteLeadsPageView = async (data, mitraId) => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/leads/${mitraId}/deleteView`;
    return axiosInstance.post(url, data,{ withCredentials: true });
};

export const downloadNewLeadsPageReport = async (data, mitraId) => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/leads/${mitraId}/download/candidateList`;
    return axiosInstance.post(url, data,{ withCredentials: true });
};