import { Button, DatePicker, Input, Modal, notification, Select } from 'antd'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import CrossIcon from '../../../static/svg/CrossIcon'
import PlusIcon from '../../../static/svg/PlusIcon'
import Dustbin from '../../../static/svg/DustbinIcon';
import './AddViewModalStyles.css';
import LeftArrow from '../../../static/svg/LeftArrow';
import UpArrow from '../../../static/svg/UpArrow';
import DownArrow from '../../../static/svg/DownArrow';
import CustomSearchDropdown from '../../SamvaadiniV2/components/CustomSearchDropdown';
import { getLocationForFilter } from '../../../service/commonService';
import { getOverallTeamCount } from '../../../service/accountService';
import PopoverDropdown from '../../SamvaadiniV2/components/PopoverDropdown';
import Lottie from 'react-lottie';
import LoadingAnimation from '../../../static/lottie/blue-circle-loader.json';
import BlueTickAnimation from '../../../static/lottie/blue-circle-tick.json';
import CONSTANTS from '../../../constants/constants';
import { AppContext } from '../../../App';
import { deleteLeadsPageView, getFilters, saveEditViews } from '../../../service/LeadsPageV2Service';
import moment from 'moment';
import { trackEvent } from '../../../service/engagementMonitoringService';
import BookmarkIcon from '../../../static/svg/BookmarkIcon';

const {Option} = Select;

const LAYOUTS = {
    'ADD_VIEW': 'ADD_VIEW',
    'FILTERS': 'FILTERS',
    'WELCOME_LAYOUT': 'WELCOME_LAYOUT',
    'LOTTIE_VIEW': 'LOTTIE_VIEW'
}

export const Dropdown = ({val,sectionIndex, dropdownOptions, onSelect}) => {
    const [showDurationFilter, setShowDurationFilter] = useState(false);

    const onOptionSelect = (id) => {
        const selected = dropdownOptions.find((option) => option.id === id);

        onSelect(selected, sectionIndex);
    }
    return (
    <>
        {/* <PopoverDropdown
          content={dropdownOptions}
          visible={showDurationFilter}
          setVisible={setShowDurationFilter}
          onSelect={(item) => onSelect(item, sectionIndex)}
        >
          <div className="custom-dropdown-box">
            <span className="inter-medium-14-20 color-323232" style={{ flex: 4 }}>
              {val}
            </span>
            <div style={{ flex: 1 }}>{showDurationFilter ? <UpArrow /> : <DownArrow />}</div>
          </div>
        </PopoverDropdown> */}

        <Select
            className="mobile-sortby-dd-filter"
            style={{border: 0}}
            value={val}
            onChange={onOptionSelect}
        >
        {
            dropdownOptions.map((item, index) => (
            <Option key={index} value={item?.id}>{item?.name}</Option>
            ))
        }
        </Select>
    </>
    )
}

function AddViewModal({openAddViewModal, setOpenAddViewModal, selectedViewDetails=null, getLeadsDashboardConfiguration}) {
    const [totalSelectedFilterCount, setTotalSelectedFilterCount] = useState(0);
    const [animation, setAnimation] = useState(LoadingAnimation);
    const {mitraReducer} = useContext(AppContext);

    const [modifiedViewName, setModifiedViewName] = useState(null);

    const [durationFilterItems, setDurationFilterItems] = useState([
        { id: 3, name: 'Last day', isActive: true },
        { id: 0, name: 'Last week', isActive: false },
        { id: 1, name: 'Last 10 days',isActive: false }
    ]);

    const [sortByFilterItems, setSortByFilterItems] = useState([
        { id: 3, name: 'Most Recent', isActive: true },
        { id: 0, name: 'Oldest', isActive: false },
    ]);
    
    const [defaultView, setDefaultView] = useState({
        "key": "leads_page_view",
        "value": null,
        "subViews": [],
        "label": null,
        "isActive": true
    });

    const defaultSubview = {
        "key": null,
        "header": null,
        "isSaved": true,
        "isActive": false,
        "filterOptions": [],
        "duration": 'Last day',
        "sortBy": 'Most Recent',
        "startDate": moment(),
        "endDate": moment()
    };

    const [defaultFilterOptions, setDefaultFilterOptions] = useState([]);

    const [sections, setSections] = useState([]);

    const [currentLayout, setCurrentLayout] = useState();

    // variable to determine filters for a particular section based on its index 
    const [currentSection, setCurrentSection] = useState();

    const addSection = () => {
        const newSections = [...sections];

        newSections.push({
            ...defaultSubview,
            filterOptions: JSON.parse(JSON.stringify([...defaultFilterOptions]))
        });

        setSections([...newSections]);
    }

    const removeSection = (index) => {
        const newSections = [...sections];

        newSections.splice(index, 1);
        setSections([...newSections]);
    }

    const onSectionFilterClick = (index) => {
        setCurrentSection(index);
        setCurrentLayout(LAYOUTS.FILTERS);

        // as per current section's index, evaluate the total selected filters

        let totalSelectedFilters = 0;

        const filterOptions = sections[index].filterOptions;

        for (const filterOption of filterOptions) {
            const filterValues = (Array.isArray(filterOption.filterValues) && filterOption.filterValues) || [];
            const selectedOptions = filterOption.selectedOptions || [];

            for(const filterValue of filterValues) {
                if(filterValue.isSelected) {
                    totalSelectedFilters++;
                }
            }

            for(const selectedOption of selectedOptions) {
                if(selectedOption.isSelected) {
                    totalSelectedFilters++;
                }
            }
        }

        setTotalSelectedFilterCount(totalSelectedFilters);
        
    }

    const onSearchCities = (label, sectionIndex, filterOptionIndex) => {
        const modifiedSections = JSON.parse(JSON.stringify([...sections]));
  
        if(!label) {
            // set dropdown values to empty arr
            modifiedSections[sectionIndex].filterOptions[filterOptionIndex].dropdownOptions = [];

            setSections([...modifiedSections]);
            return
        }
  
        let data = {
            search: label
        }
        getLocationForFilter(data).then(response => {
            if(response?.data?.locationList.length) {
                const locationData = [];
  
                for(let i=0;i<response?.data?.locationList?.length; i++) {
                    locationData.push({label:response?.data?.locationList[i], value: response?.data?.locationList[i]});
                }
  
                //set dropdown values in the filter obj
                modifiedSections[sectionIndex].filterOptions[filterOptionIndex].dropdownOptions = [...locationData];
                setSections([...modifiedSections]);
            } else {
                // set dropdown values to empty arr
                modifiedSections[sectionIndex].filterOptions[filterOptionIndex].dropdownOptions = [];
                setSections([...modifiedSections]);
            }
        }).catch(err=> {
            // set dropdown values to empty arr
            modifiedSections[sectionIndex].filterOptions[filterOptionIndex].dropdownOptions = [];
            setSections([...modifiedSections]);
        })
      }
  
      const onSearchTeamMembers = (label, sectionIndex, filterOptionIndex) => {
        // const newFilterSections = [...filterSections];
        const modifiedSections = JSON.parse(JSON.stringify([...sections]));
  
        if(!label) {
            // set dropdown values to empty arr
            modifiedSections[sectionIndex].filterOptions[filterOptionIndex].dropdownOptions = [];
            setSections([...modifiedSections]);
            return
        }
  
        let data = {
            search: label
        }
  
        getOverallTeamCount(data).then((teamListRes) => {
          if(teamListRes?.data?.teamList?.length) {
              const teamList = [];
  
                for(let i=0;i<teamListRes?.data?.teamList?.length; i++) {
                    teamList.push({label:teamListRes?.data?.teamList[i].name, value: teamListRes?.data?.teamList[i].name, data: teamListRes?.data?.teamList[i]});
                }
  
                //set dropdown values in the filter obj
                modifiedSections[sectionIndex].filterOptions[filterOptionIndex].dropdownOptions = [...teamList];
                setSections([...modifiedSections]);
          } else {
            // set dropdown values to empty arr
            modifiedSections[sectionIndex].filterOptions[filterOptionIndex].dropdownOptions = [];
            setSections([...modifiedSections]);
          }
        }).catch(err=> {
            // set dropdown values to empty arr
            modifiedSections[sectionIndex].filterOptions[filterOptionIndex].dropdownOptions = [];
            setSections([...modifiedSections]);
        })
      }

    const onSearchText = (searchText, header, sectionIndex, filterOptionIndex) => {
        //basis search type, calling appropriate API
      if (header?.toLowerCase() === 'locations') {
        onSearchCities(searchText, sectionIndex, filterOptionIndex);
      } else if(header?.toLowerCase() === 'telecaller' || header?.toLowerCase() === 'team member') {
        onSearchTeamMembers(searchText, sectionIndex, filterOptionIndex);
      }
    };

    const onSelectOption = (data, header, sectionIndex, filterOptionIndex) => {
        const modifiedSections = JSON.parse(JSON.stringify([...sections]));

        data.map((items)=>{
            items.isSelected = true
          });

        if(header.toLowerCase() === 'locations') {
            const popularCitiesToDataMap = {};
            for (let i = 0; i < modifiedSections[sectionIndex].filterOptions[filterOptionIndex]?.filterValues.length; i++) {
                // {'Bangalore': 1} || Here 1 is the index of the particular city in the filterValues array
                // Further using index we will tweak the flag to avoid iteration
                popularCitiesToDataMap[modifiedSections[sectionIndex].filterOptions[filterOptionIndex]?.filterValues[i]?.label] = i;
            }

            let finalNewSelectedData = modifiedSections[sectionIndex].filterOptions[filterOptionIndex].selectedOption ? [...modifiedSections[sectionIndex].filterOptions[filterOptionIndex].selectedOption]:[];
            const selectedDataLength = data.length-1; // using the last index as that is the chosen value

            if(data[selectedDataLength].label in popularCitiesToDataMap) {
                if(!modifiedSections[sectionIndex].filterOptions[filterOptionIndex].filterValues[popularCitiesToDataMap[data[selectedDataLength].label]].isSelected) {
                    modifiedSections[sectionIndex].filterOptions[filterOptionIndex].selectedFilterCount += 1;
                    setTotalSelectedFilterCount((prevVal)=> prevVal + 1);
                }
                modifiedSections[sectionIndex].filterOptions[filterOptionIndex].filterValues[popularCitiesToDataMap[data[selectedDataLength].label]].isSelected = true;
            } else {
                finalNewSelectedData.push(data[selectedDataLength]);
                modifiedSections[sectionIndex].filterOptions[filterOptionIndex].selectedFilterCount += 1;
                setTotalSelectedFilterCount((prevVal)=> prevVal + 1);
            }

            modifiedSections[sectionIndex].filterOptions[filterOptionIndex].selectedOption = [...finalNewSelectedData];
            // setFilterSections([...modifiedSections]); 
            setSections([...modifiedSections]);
        } else {
            setTotalSelectedFilterCount((prevVal)=> prevVal + 1);

            modifiedSections[sectionIndex].filterOptions[filterOptionIndex].selectedOption = [...data];
            modifiedSections[sectionIndex].filterOptions[filterOptionIndex].selectedFilterCount +=1;
            
            // setFilterSections([...newFilterSections]); 
            setSections([...modifiedSections]);
        }
    };

    const onFilterClick = (filterItem, sectionIndex, filterOptionIndex, filterValueIndex) => {
        const modifiedSections = JSON.parse(JSON.stringify([...sections]));

        if(filterItem.isSelected) {
            // while unchecking the chosen filter, decrease the count of selected filter
            modifiedSections[sectionIndex].filterOptions[filterOptionIndex].selectedFilterCount -=1;
            modifiedSections[sectionIndex].filterOptions[filterOptionIndex].filterValues[filterValueIndex].isSelected = false;
            setTotalSelectedFilterCount((prevVal)=> prevVal - 1);
          } else {
            modifiedSections[sectionIndex].filterOptions[filterOptionIndex].selectedFilterCount +=1;
            modifiedSections[sectionIndex].filterOptions[filterOptionIndex].filterValues[filterValueIndex].isSelected = true;
            setTotalSelectedFilterCount((prevVal)=> prevVal + 1);
          }

        setSections([...modifiedSections]);
    };

    const onRemoveNewOption = (filterItem, sectionIndex, filterOptionIndex, filterValueIndex) => {
        
        const modifiedSections = JSON.parse(JSON.stringify([...sections]));
        setTotalSelectedFilterCount((prevVal)=> prevVal - 1);
       
        const newSelectedValues = modifiedSections[sectionIndex].filterOptions[filterOptionIndex].selectedOption.filter(item=> item.label!=filterItem.label);
        modifiedSections[sectionIndex].filterOptions[filterOptionIndex].selectedFilterCount -=1;

        modifiedSections[sectionIndex].filterOptions[filterOptionIndex].selectedOption = [...newSelectedValues];

        setSections([...modifiedSections]);
    };

    const addNewView = () => {
        const finalViewInformation = {...defaultView};

        finalViewInformation.subViews = [...sections];

        // Now to check that finalViewInformation should have view name, name of sections and atleast one filter selected
        if(!modifiedViewName?.trim()) {
            notification['error']({
                message: 'Add a name to the view.'
            });
            return;
        }

        for (let sectionIndex = 0; sectionIndex < sections.length; sectionIndex++) {
            const section = sections[sectionIndex];
            let selectedFilterCount = 0;
        
            // Check if section header is missing
            if (!section.header?.trim()) {
                notification.error({
                    message: `Add section name to Section ${sectionIndex + 1}.`,
                });
                return;
            }
        
            // Iterate over each filter option in the section
            for (const filterOption of section.filterOptions || []) {
        
                // Count selected filter values
                if(Array.isArray(filterOption.filterValues)) {
                    for (const filterValue of filterOption.filterValues || []) {
                        if (filterValue.isSelected) {
                            selectedFilterCount++;
                        }
                    }
                }
        
                // Count selected options from dropdown
                for (const selectedOption of filterOption.selectedOption || []) {
                    if (selectedOption.isSelected) {
                        selectedFilterCount++;
                    }
                }
            }
        
            // Ensure at least one filter is selected
            if (!selectedFilterCount) {
                notification.error({
                    message: `Add default filters to Section ${sectionIndex + 1}.`,
                });
                return;
            }
        }

        // API Call to save the newly created view
        // on success show the lottie 
        const payload = {
            mitraId: mitraReducer?.mitraInfo?.id,
            data: {
              "type": CONSTANTS.CONFIG_TYPE.LEADS_PAGE,
              "view": selectedViewDetails ? finalViewInformation?.label?.toLowerCase().trim().replaceAll(' ', '_'): modifiedViewName?.toLowerCase().trim().replaceAll(' ', '_'),
              "subViews": finalViewInformation?.subViews,
              "label": selectedViewDetails ? finalViewInformation?.label.trim(): modifiedViewName?.trim(),
              "functionalityType": selectedViewDetails ? CONSTANTS.VIEW_SUBMISSION_TYPE.UPDATE : CONSTANTS.VIEW_SUBMISSION_TYPE.CREATE
            }
        };

        if(selectedViewDetails && modifiedViewName?.toLowerCase().trim() !== finalViewInformation?.label?.toLowerCase().trim()) {
            // if its of edit type then check if modified view name is different than previous, if yes then add these 2 properties
            payload.data.modifiedView = modifiedViewName?.toLowerCase().trim().replaceAll(' ', '_');
            payload.data.modifiedLabel = modifiedViewName.trim()
        }


        saveEditViews(payload)
        .then(res=> {
            if(!res?.data?.status) {
            notification['error'] ({
                message: res?.data?.message
            })
            } else {
            setAnimation(BlueTickAnimation);
            notification['success'] ({
                message: res?.data?.message
            });

            trackEvent('vp_new_view_added', {
                phoneNumber: mitraReducer?.mitraInfo?.phoneNumber
            })
            }
        }).catch(err=> {
            console.log(err);
        })
        
        setCurrentLayout(LAYOUTS.LOTTIE_VIEW);
         
    }

    const destroyModalContents = () => {
        setSections([
            {
                ...defaultSubview,
                filterOptions: JSON.parse(JSON.stringify([...defaultFilterOptions]))
            }
        ]);
        setCurrentLayout(LAYOUTS.WELCOME_LAYOUT);
    
        // variable to determine filters for a particular section based on its index 
        setCurrentSection();
        setOpenAddViewModal(false);
        getLeadsDashboardConfiguration();
    }

    const onDurationFilterSelection = (item, sectionIndex) => {

        const modifiedSections = JSON.parse(JSON.stringify([...sections]));
        
        const modifiedDurationFilterItems = [...durationFilterItems]
  
        for (let i = 0; i < modifiedDurationFilterItems.length; i++) {
          if(modifiedDurationFilterItems[i].id === item.id) {
            modifiedDurationFilterItems[i].isActive = true;
            modifiedSections[sectionIndex].duration = modifiedDurationFilterItems[i].name;
          } else {
            modifiedDurationFilterItems[i].isActive = false;
          }
        }
        setSections([...modifiedSections]);
        setDurationFilterItems([...modifiedDurationFilterItems]);
    }

    const setSectionHeader = (val, sectionIndex) => {
        const modifiedSections = JSON.parse(JSON.stringify([...sections]));
        modifiedSections[sectionIndex].header = val;
        setSections([...modifiedSections]);
    }

    const onSortBySelection = (item, sectionIndex) => {

        const modifiedSections = JSON.parse(JSON.stringify([...sections]));
        
        const modifiedDurationFilterItems = [...sortByFilterItems]
  
        for (let i = 0; i < modifiedDurationFilterItems.length; i++) {
          if(modifiedDurationFilterItems[i].id === item.id) {
            modifiedDurationFilterItems[i].isActive = true;
            modifiedSections[sectionIndex].sortBy = modifiedDurationFilterItems[i].name;
          } else {
            modifiedDurationFilterItems[i].isActive = false;
          }
        }
        setSections([...modifiedSections]);
        setSortByFilterItems([...modifiedDurationFilterItems]);
    }

    const setPreExistingViewDetails = () => {

        setModifiedViewName(selectedViewDetails?.label);
        
        setDefaultView({
            "key": CONSTANTS.CONFIG_TYPE.LEADS_PAGE,
            "value": selectedViewDetails?.value,
            "subViews": [],
            "label": selectedViewDetails?.label,
            "isActive": selectedViewDetails?.isActive
        });

        setSections([...selectedViewDetails?.subViews]);
        setDefaultFilterOptions([...selectedViewDetails?.subViews[0].filterOptions]);
    }

    const getFiltersInformation = () => {
        const payload = {
            requiredFields:['clients', 'locations', 'milestoneList', 'callStatus', 'teams','teamMembers', 'smartFilters']
        };

        getFilters(mitraReducer?.mitraInfo?.id, payload)
        .then(res=> {

            if(res?.data?.data?.length) {
                setDefaultFilterOptions([...res.data.data]);
                setSections([
                    {
                        ...defaultSubview,
                        filterOptions: JSON.parse(JSON.stringify([...res.data.data]))
                    }
                ]);
            } else {
                setDefaultFilterOptions([]);
            }
        }).catch(err=> {
            console.log(err);
            notification['error']({
                message: 'Error fetching filters, kindly reload this page.'
            })
        })
    }

    useEffect(() => {
        if(selectedViewDetails) {
            setPreExistingViewDetails();
            setCurrentLayout(LAYOUTS.ADD_VIEW);
        } else {
            setCurrentLayout(LAYOUTS.WELCOME_LAYOUT);
            getFiltersInformation();
        }
      return () => {
        setTotalSelectedFilterCount(0);
      }
    }, [selectedViewDetails]);

    useEffect(() => {
        if (mitraReducer && mitraReducer.allowedFeatures) {
            const viewAccessRestricted = mitraReducer?.allowedFeatures[CONSTANTS.VIEW_DATA_ACCESS]?.isEnable
            if (viewAccessRestricted) {
                let temp_var = moment().subtract(mitraReducer?.allowedFeatures[CONSTANTS.VIEW_DATA_ACCESS]?.numberOfDays, 'days')
                setDisableDatesBefore(moment().subtract(mitraReducer?.allowedFeatures[CONSTANTS.VIEW_DATA_ACCESS]?.numberOfDays, 'days'))
            }
          }
    }, [mitraReducer?.allowedFeatures])
    

    const onClickFilterHeader = (filter) => {
        const modifiedFilterSections = [...sections];
  
        for (let i = 0; i < modifiedFilterSections[currentSection]?.filterOptions.length; i++) {
          if(modifiedFilterSections[currentSection]?.filterOptions[i].label === filter.label) {
            modifiedFilterSections[currentSection].filterOptions[i].isExpanded = true;
          } else {
            modifiedFilterSections[currentSection].filterOptions[i].isExpanded = false;
          }
        }
  
        setSections([...modifiedFilterSections]);
      }

    const filterContent = () => (
        sections?.[currentSection]?.filterOptions.map((filter, idx) => (
            <div className="single-filter-card new-theme-shadow-light" key={idx} style={{marginBottom: '24px'}}>
            <div className='single-filter-card-header'>
                <div className='d-flex align-items-center justify-content-between mobile-extra-width' onClick={()=>onClickFilterHeader(filter)}>
                    <div className='d-flex align-items-center'>
                        <span className='inter-regular-16-24 color-252526 d-flex'>{filter.label}</span>
                        {
                        filter.selectedFilterCount ? <div className='selected-subfilter-count'>{filter.selectedFilterCount}</div>: null
                        }
                    </div>
                    <div className='visibility-mobile'>
                        {
                        filter.isExpanded ? <UpArrow  height={18} width={18}/>: <DownArrow height={18} width={18}/>
                        }
                    </div>
                </div>

                {
                filter.showSearchBox && (
                    // The following condition is to handle conditions to show the section for mobile and desktop
                    (
                    filter.isExpanded && window.innerWidth < 800) ||
                    window.innerWidth > 800
                ) ? (
                    <CustomSearchDropdown 
                    placeholder={filter?.searchPlaceholder}
                    onSearch={(value)=>onSearchText(value, filter.label, currentSection, idx)}
                    dropdownOptions={filter.dropdownOptions}
                    onSelect={(data)=>onSelectOption([...data], filter.label, currentSection, idx)}
                    value={filter?.selectedOption}
                    checkboxGroupVisible={false}
                    inputBoxCustomClass='filter-search-box'
                    searchIconSize={16}
                    showSelection={false}
                    />
                ): null
                }
            </div>

            {
                ((Array.isArray(filter?.filterValues) && filter?.filterValues?.length) || filter?.selectedOption?.length)   && (
                    // The following condition is to handle conditions to show the section for mobile and desktop
                    (
                    filter.isExpanded && window.innerWidth < 800) ||
                    window.innerWidth > 800
                ) ? (
                    <div className="single-filter-card-content">

                    {
                        filter?.filterValues?.map((filterItem, filterItemIdx) =>(
                        <div 
                        onClick={()=> onFilterClick(filterItem, currentSection, idx, filterItemIdx)}
                        className={`new-single-filter-item ${filterItem?.isSelected ? 'new-single-filter-item-selected':''}`}
                        key={`${idx} + ' ' + ${filterItemIdx}`}>
                            {
                              filterItem.logo ? <img src={filterItem.logo} style={{height:'32px', width:'32px', borderRadius: '4px', marginRight: '8px', objectFit: 'contain'}} />: null
                            }
                            <span className='inter-regular-14-20 color-1D5FFF'>{filterItem.label}</span>
                            {
                            filterItem?.isSelected ? <CrossIcon strokeColor='#1D5FFF' height={12} width={12}/>: null
                            }
                        </div>
                        ))
                    }

                    {
                        filter?.selectedOption?.map((filterItem, filterItemIdx) =>(
                        <div 
                        onClick={()=>onRemoveNewOption(filterItem, currentSection, idx, filterItemIdx)}
                        className={`new-single-filter-item ${filterItem?.isSelected ? 'new-single-filter-item-selected':''}`}
                        key={`${idx} + ' ' + ${filterItemIdx}`}>
                            <span className='inter-regular-14-20 color-1D5FFF'>{filterItem.label}</span>
                            {
                            filterItem?.isSelected ? <CrossIcon strokeColor='#1D5FFF' height={12} width={12}/>: null
                            }
                        </div>
                        ))
                    }
                    </div>
                ): null
            }
            
            </div>
        ))
        
    );

    const { RangePicker } = DatePicker;
    const dateFormat = 'DD MMM YYYY';
    const [customStartDate, setCustomStartDate] = useState(moment());
    const [customEndDate, setCustomEndDate] = useState(moment());
    const [disableDatesBefore, setDisableDatesBefore] = useState(moment());

    const fetchDisabledDates = (current) => {
        //Disable all dates before allowed number of days mentioned in View Data Access 
        return current && current <= disableDatesBefore.endOf("day");
      }

    const changeDateRange = (dateRange, sectionIndex) => {
        if (dateRange[0] && dateRange[1]) {
          const startDate = dateRange[0].format("YYYY-MM-DD");
          const endDate = dateRange[1].format("YYYY-MM-DD");

            const modifiedSections = JSON.parse(JSON.stringify([...sections]));
            modifiedSections[sectionIndex].startDate = startDate;
            modifiedSections[sectionIndex].endDate = endDate;
            setSections([...modifiedSections]);
        }
    };

    const changeDate = (date, category, sectionIndex) => {
        if(category === 'startDate') {
            const startDate = date.format("YYYY-MM-DD");

            const modifiedSections = JSON.parse(JSON.stringify([...sections]));
            modifiedSections[sectionIndex].startDate = startDate;
            setSections([...modifiedSections]);
        } else {
            const endDate = date.format("YYYY-MM-DD");
            const modifiedSections = JSON.parse(JSON.stringify([...sections]));
            modifiedSections[sectionIndex].endDate = endDate;
            setSections([...modifiedSections]);
        }
      }

    const customDateRangePicker = (sectionIndex) => (
        <div className='lp-datepicker-customised'>
            <RangePicker
                format={dateFormat}
                onChange={(range)=>changeDateRange(range, sectionIndex)}
                ranges={{
                    Today: [moment().startOf('day'), moment().endOf('day')],
                    "Last 1 Day": [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],
                    "Last Week": [moment().subtract(1, 'weeks').startOf('week'), moment().subtract(1, 'weeks').endOf('week')],
                    "Last 10 Days": [moment().subtract(10, 'days').startOf('day'),moment().endOf('day')],
                    'This Month': [moment().startOf('month'), moment().endOf('month')],
                }}
                numberOfMonths={1}
                value={[moment(sections[sectionIndex].startDate || customStartDate), moment(sections[sectionIndex].endDate || customEndDate)]}
                allowClear={false}
                className="range-picker-leads custom-date-lp"
                style={{marginTop: '0px !important'}}
                disabledDate={fetchDisabledDates}
                getPopupContainer={trigger => trigger.parentNode}
            >
            Range Picker
            </RangePicker>
        </div>
      )

    const deleteView = ()=> {
        const payload = {
            viewId: selectedViewDetails?.id
        };

        deleteLeadsPageView(payload, mitraReducer?.mitraInfo?.id)
        .then(res=> {
            if(res?.data?.status) {
                notification['success']({
                    message: 'View deleted successfully.'
                });

                getLeadsDashboardConfiguration();
                setOpenAddViewModal(false);
            } else {
                notification['error']({
                    message: 'Error deleting the view.'
                });
            }
        }).catch(err=>{
            notification['error']({
                message: 'Something went wrong, please try again later.'
            });
        })
    }

    const addViewLayout = () => (
        <>
            <div className="leads-filter-header">
                <span className='unbounded-semibold-20-24 plain-black'>{selectedViewDetails ? 'Edit View': 'Add View'}</span>
                <div style={{cursor: 'pointer'}} onClick={()=>destroyModalContents() }>
                    <CrossIcon height={18} width={18}/>
                </div>
            </div>

            <div className="new-call-note-main-container custom-scroll-bar">
                <div className='section-addition-main-container new-theme-shadow-light'>
                <span className='inter-regular-16-24 color-252526 d-flex'>View Name<span style={{top: '-5px',color: '#FF4545'}}>*</span></span>
                <Input 
                className='samvaadini-search-candidate-new lr-input mt-24' 
                value={modifiedViewName}
                onChange={(e)=>setModifiedViewName(e.target.value)}
                placeholder="Enter view name"/>
                </div>

                {/* Layout for sections */}
                {
                    sections.map((section, index) => (
                        <div key={index} className='section-addition-main-container new-theme-shadow-light'>
                            {/* header section */}
                            <div className='d-flex align-items-center justify-content-between'>
                                <span className='inter-regular-16-24 color-252526'>Section {index+1}</span>

                                {
                                    sections.length > 1 ? (
                                        <div className='d-flex align-items-center flex-gap-4 cursor-pointer' onClick={()=>removeSection(index)}>
                                            <Dustbin height={16} width={16} strokeColor='#FF4545'/>
                                            <span className='inter-semibold-12-18 color-FF4545'>Remove</span>
                                        </div>
                                    ): null
                                }
                                
                            </div>

                            {/* content section */}
                            <div className='sa-content-section'>
                                <span className='inter-regular-14-20 color-838383 d-flex'>Section Name<span style={{top: '-5px',color: '#FF4545'}}>*</span></span>
                                <Input 
                                className='samvaadini-search-candidate-new lr-input mt-12'
                                value={section?.header} 
                                onChange={(e)=>setSectionHeader(e.target.value, index)}
                                placeholder="Enter section name"/>
                            </div>

                            <div className='av-dropdowns-container'>
                                <div className='av-dropdown-items visibility-desktop'>
                                    <span className='inter-regular-14-20 color-838383'>Show leads from</span>
                                    {/* <Dropdown val={section?.duration} sectionIndex={index} dropdownOptions={durationFilterItems} onSelect={onDurationFilterSelection}/> */}
                                    {customDateRangePicker(index)}
                                </div>

                                {/* <RangePicker
                                format={dateFormat}
                                onChange={(range)=>changeDateRange(range, sectionIndex)}
                                ranges={{
                                    Today: [moment().startOf('day'), moment().endOf('day')],
                                    "Last 1 Day": [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],
                                    "Last Week": [moment().subtract(1, 'weeks').startOf('week'), moment().subtract(1, 'weeks').endOf('week')],
                                    "Last 10 Days": [moment().subtract(10, 'days').startOf('day'),moment().endOf('day')],
                                    'This Month': [moment().startOf('month'), moment().endOf('month')],
                                }}
                                numberOfMonths={1}
                                value={[moment(sections[sectionIndex].startDate || customStartDate), moment(sections[sectionIndex].endDate || customEndDate)]}
                                allowClear={false}
                                className="range-picker-leads custom-date-lp"
                                style={{marginTop: '0px !important'}}
                                disabledDate={fetchDisabledDates}
                                getPopupContainer={trigger => trigger.parentNode}
                            ></RangePicker> */}

                                <div className='av-dropdown-items visibility-mobile'>
                                    <span className='inter-regular-14-20 color-838383'>Show leads</span>
                                    {/* <Dropdown val={section?.duration} sectionIndex={index} dropdownOptions={durationFilterItems} onSelect={onDurationFilterSelection}/> */}
                                    <div className='lp-mobile-filter-top-section' style={{backgroundColor: 'none',padding: '0px'}}>
                                        <div className='d-flex align-items-center flex-gap-12 w-100'>
                                        <span className="inter-regular-14-20 inter-regular-14-20 color-838383">From</span>
                                        <DatePicker 
                                        format={dateFormat}
                                        value={moment(sections[index].startDate || customStartDate)}
                                        onChange={(date)=>changeDate(date, 'startDate', index)}
                                        disabledDate={fetchDisabledDates}
                                        allowClear={false}
                                        getPopupContainer={(trigger) => trigger.parentNode}
                                        style={{ width: '100%' }}
                                        className='custom-date-lp'
                                        />
                                        </div>

                                        <div className='d-flex align-items-center flex-gap-12 w-100'>
                                        <span className="inter-regular-14-20 inter-regular-14-20 color-838383">To</span>
                                        <DatePicker 
                                        format={dateFormat}
                                        value={moment(sections[index].endDate || customEndDate)}
                                        onChange={(date)=>changeDate(date, 'endDate', index)}
                                        disabledDate={(current) =>
                                            current && current < moment(sections[index].startDate || customStartDate) // Ensure end date is after start date
                                        }
                                        allowClear={false}
                                        getPopupContainer={(trigger) => trigger.parentNode}
                                        style={{ width: '100%' }}
                                        className='custom-date-lp'
                                        />
                                        </div>
                                    </div>
                                </div>

                                <div className='av-dropdown-items'>
                                    <span className='inter-regular-14-20 color-838383'>Sort by</span>
                                    <Dropdown val={section.sortBy} sectionIndex={index} dropdownOptions={sortByFilterItems} onSelect={onSortBySelection}/>
                                </div>
                            </div>

                            <div className='d-flex flex-column align-items-start mt-24'>
                                <span className='inter-regular-14-20 color-838383'>Filtered by</span>

                                <div className='d-flex align-items-center flex-wrap flex-gap-8 mt-8'>
                                    {
                                        // Layout to show selected filters
                                        section?.filterOptions?.map((filterOption, filterOptionIndex) => (
                                            <Fragment key={filterOptionIndex + '=' + index}>
                                                {
                                                    Array.isArray(filterOption?.filterValues) ? filterOption?.filterValues?.map((filterItem, filterValueIndex) => (
                                                        filterItem?.isSelected ? (
                                                            <div className="leads-filter-item" key={`${index}-${filterOptionIndex}-${filterValueIndex}`}>
                                                            <span className="inter-regular-12-16 color-1D5FFF">{filterItem.label}</span>
                                                            <div style={{cursor: 'pointer'}} onClick={()=> onFilterClick(filterItem, index, filterOptionIndex, filterValueIndex)}>
                                                                <CrossIcon width={12} height={12} strokeColor="#1D5FFF" />
                                                            </div>
                                                            </div>
                                                            ): null
                                                    )): null
                                                }
                                                {
                                                    filterOption?.selectedOption?.map((filterItem, filterValueIndex) => (
                                                        filterItem?.isSelected ? (
                                                            <div className="leads-filter-item" key={`${index}-${filterOptionIndex}-${filterValueIndex}`}>
                                                            <span className="inter-regular-12-16 color-1D5FFF">{filterItem.label}</span>
                                                            <div style={{cursor: 'pointer'}} onClick={()=> onRemoveNewOption(filterItem, index, filterOptionIndex, filterValueIndex)}>
                                                                <CrossIcon width={12} height={12} strokeColor="#1D5FFF" />
                                                            </div>
                                                            </div>
                                                            ): null
                                                    ))
                                                }
                                            </Fragment>
                                        ))
                                    }
                                    <Button 
                                    onClick={()=>onSectionFilterClick(index)}
                                    className='secondary-btn-styles-v2 width-fit-content' 
                                    style={{padding: '4px 8px',borderRadius: '4px'}}>
                                        <PlusIcon height={18} width={18}/>
                                        <span className='inter-semibold-12-18 color-1D5FFF'>Add Filter</span>
                                    </Button>
                                </div>
                                
                            </div>
                        </div>
                    ))
                }

                {
                    sections.length >= 5 ? null: (
                        <div className='d-flex flex-column align-items-start mt-24'>
                            <Button className='secondary-btn-styles-v2 width-fit-content' style={{padding: '4px 8px',borderRadius: '4px'}} onClick={addSection}>
                                <PlusIcon height={18} width={18}/>
                                <span className='inter-semibold-14-16 color-1D5FFF'>Add Section</span>
                            </Button>
                        </div>
                    )
                }
            </div>

            <div className="leads-filter-footer justify-content-between">
                {
                    selectedViewDetails ? 
                    <div className='cursor-pointer' onClick={()=> deleteView()}>
                        <span style={{color: 'red',textDecoration: 'underline', textUnderlineOffset: '6px'}}>Delete View</span>
                    </div>
                    : ''
                }
                <Button className='primary-btn-styles-v2 leads-filter-footer-button' style={{marginLeft: 'auto'}} onClick={()=>addNewView()}> 
                    {
                        selectedViewDetails ? (
                            <BookmarkIcon strokeColor={'#FFF'}/>
                        ): (
                        <PlusIcon height={20} width={20} strokeColor='#FFF'/>
                    )
                    }
                    
                    <span className='btn-text-semibold-14-16 plain-white'>{selectedViewDetails ? 'Save': 'Add View'}</span>
                </Button>
            </div>
        </>
    )

    const filtersLayout = () => (
        <>
            <div className="leads-filter-header">
                <div className='d-flex align-items-center flex-gap-4'>
                    <div onClick={()=> setCurrentLayout(LAYOUTS.ADD_VIEW)} className='cursor-pointer'>
                        <LeftArrow  strokeWidth={2}/>
                    </div>

                    <div className='d-flex align-items-center'>
                        <span className='unbounded-semibold-20-24 plain-black'>Section {currentSection+1} Filters</span>
                        {totalSelectedFilterCount ? <div className='selected-subfilter-count'>{totalSelectedFilterCount}</div>: null}
                    </div>
                </div>
                <div style={{cursor: 'pointer'}} onClick={()=>destroyModalContents() }>
                <CrossIcon height={18} width={18}/>
                </div>
            </div>

            <div className="new-call-note-main-container custom-scroll-bar">
                {filterContent()}
            </div>

            <div className="leads-filter-footer">
                <Button className='primary-btn-styles-v2 leads-filter-footer-button' onClick={()=>setCurrentLayout(LAYOUTS.ADD_VIEW)}> 
                    <PlusIcon height={20} width={20} strokeColor='#FFF'/>
                    <span className='btn-text-semibold-14-16 plain-white'>Add</span>
                </Button>
            </div>
        </>
    );

    const welcomeLayout = () => (
        <>
            <div className="leads-filter-header">
                <div className='d-flex align-items-center flex-gap-4'>
                    <span className='unbounded-semibold-20-24 plain-black'>Add View</span>
                </div>
                <div style={{cursor: 'pointer'}} onClick={()=>destroyModalContents()}>
                <CrossIcon height={18} width={18}/>
                </div>
            </div>

            <div className="new-call-note-main-container custom-scroll-bar d-flex align-items-center justify-content-center">
                <div className='d-flex align-items-center av-initial-layout'>
                    <img src="/image/add-view.webp" style={{width: window.innerWidth < 800 ? '200px':'168px', objectFit: 'contain'}} />
                    <span className={`${window.innerWidth < 800 ? 'inter-regular-16-24 text-align-center':'inter-regular-20-24 text-align-start'} color-252526`}>Now you can customize the leads page by adding a view and saving filters!</span>
                </div>
            </div>

            <div className="leads-filter-footer">
                <Button className='primary-btn-styles-v2 leads-filter-footer-button' onClick={()=>setCurrentLayout(LAYOUTS.ADD_VIEW)}> 
                    <span className='btn-text-semibold-14-16 plain-white'>Continue</span>
                </Button>
            </div>
        </>
    );

    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: animation,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
    };

    const viewCreationAcknowledgementLayout = () => (
        <>
            <div className="leads-filter-header">
                <span className='unbounded-semibold-20-24 plain-black'>Add View</span>
                <div style={{cursor: 'pointer'}} onClick={()=>destroyModalContents() }>
                    <CrossIcon height={18} width={18}/>
                </div>
            </div>

            <div className='new-call-note-main-container align-items-center justify-content-center d-flex flex-column mobile-full-height'>
                <Lottie 
                options={defaultOptions}
                height={120}
                width={120}
                speed={1}
                />
                <span className='inter-regular-16-24 color-252526'>
                    {
                        animation === LoadingAnimation ? 'Creating new view and adding filters': `View has been ${selectedViewDetails ? 'edited': 'created'}`
                    }
                </span>
            </div>
        </>
    );

  return (
    <Modal
    title={null}
    visible={openAddViewModal}
    className="leads-filter-modal"
    closable={false}
    maskClosable={false}
    destroyOnClose={true}
    bodyStyle={{borderRadius: '8px',padding: '0px'}}
    footer={null}
    >
        {
            currentLayout === LAYOUTS.ADD_VIEW ? addViewLayout(): null
        }

        {
            currentLayout === LAYOUTS.FILTERS ? filtersLayout(): null
        }

        {
            currentLayout === LAYOUTS.WELCOME_LAYOUT ? welcomeLayout(): null
        }

        {
            currentLayout === LAYOUTS.LOTTIE_VIEW ? viewCreationAcknowledgementLayout(): null
        }
    </Modal>
  )
}

export default AddViewModal