import CrossIcon from '../../static/svg/CrossIcon';
import DesktopSidebar from '../DesktopSidebar/DesktopSidebar';
import './MobileSidebar.css';
import React from 'react'

function MobileSidebar({close}) {
  return (
    <div className="background-overlay-container" onClick={()=>close()}>
        <div className="mobile-menu-main-container">
            <div className='mobile-menu-top-branding'>
                <div onClick={()=>close()}>
                    <CrossIcon height={18} width={18}/>
                </div>
                <img alt="Logo" src="/image/vahan-pro.png" style={{width: '150px',objectFit: 'contain'}}/>
            </div>
            <DesktopSidebar />
        </div>
    </div>
  )
}

export default MobileSidebar