import * as React from "react";
const ClockIcon = ({fillColor='none',height=16,width=16,strokeColor="#838383"}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill={fillColor}
  >
    <path
      d="M8.65 4.66671C8.65 4.30772 8.35899 4.01671 8 4.01671C7.64102 4.01671 7.35 4.30772 7.35 4.66671H8.65ZM8 8.00004H7.35C7.35 8.35903 7.64102 8.65004 8 8.65004V8.00004ZM11.3333 8.65004C11.6923 8.65004 11.9833 8.35903 11.9833 8.00004C11.9833 7.64106 11.6923 7.35004 11.3333 7.35004V8.65004ZM13.5846 3.5454C13.8596 3.77615 14.2696 3.74028 14.5003 3.46529C14.7311 3.19029 14.6952 2.7803 14.4202 2.54955L13.5846 3.5454ZM12.3774 0.835445C12.1024 0.604694 11.6925 0.640564 11.4617 0.915562C11.231 1.19056 11.2668 1.60055 11.5418 1.8313L12.3774 0.835445ZM4.4606 1.83138C4.7356 1.60063 4.77147 1.19064 4.54071 0.915643C4.30996 0.640645 3.89997 0.604775 3.62497 0.835527L4.4606 1.83138ZM1.58219 2.54963C1.30719 2.78038 1.27132 3.19037 1.50207 3.46537C1.73282 3.74037 2.14281 3.77624 2.41781 3.54548L1.58219 2.54963ZM7.35 4.66671V8.00004H8.65V4.66671H7.35ZM8 8.65004H11.3333V7.35004H8V8.65004ZM8 12.6834C5.41347 12.6834 3.31667 10.5866 3.31667 8.00004H2.01667C2.01667 11.3045 4.6955 13.9834 8 13.9834V12.6834ZM3.31667 8.00004C3.31667 5.41351 5.41347 3.31671 8 3.31671V2.01671C4.6955 2.01671 2.01667 4.69554 2.01667 8.00004H3.31667ZM8 3.31671C10.5865 3.31671 12.6833 5.41351 12.6833 8.00004H13.9833C13.9833 4.69554 11.3045 2.01671 8 2.01671V3.31671ZM12.6833 8.00004C12.6833 10.5866 10.5865 12.6834 8 12.6834V13.9834C11.3045 13.9834 13.9833 11.3045 13.9833 8.00004H12.6833ZM14.4202 2.54955L12.3774 0.835445L11.5418 1.8313L13.5846 3.5454L14.4202 2.54955ZM3.62497 0.835527L1.58219 2.54963L2.41781 3.54548L4.4606 1.83138L3.62497 0.835527Z"
      fill={strokeColor}
    />
  </svg>
);
export default ClockIcon;
