import React, { useState, useEffect } from 'react';
import DoubleChevronUp from '../../../static/svg/DoubleChevronUp';

const ScrollToTopButton = ({scrollContainerRef}) => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (scrollContainerRef.current.scrollTop > 400) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', toggleVisibility);
      return () => {
        scrollContainer.removeEventListener('scroll', toggleVisibility);
      };
    }
  }, []);

  const scrollToTop = () => {
    scrollContainerRef.current.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    isVisible && (
      <button 
        onClick={scrollToTop} 
        style={styles.button}  // you can also use className and define CSS in an external file
      >
        <DoubleChevronUp />
      </button>
    )
  );
};

const styles = {
  button: {
    position: 'fixed',
    bottom: '80px',
    right: '20px',
    padding: '10px 10px',
    fontSize: '20px',
    backgroundColor: '#FFF',
    color: '#3A3D3E',
    border: 'none',
    borderRadius: '50%',
    cursor: 'pointer',
    boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.08)',
  },
};

export default ScrollToTopButton;
