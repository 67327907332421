import * as React from "react";
const BoltIcon = ({fillColor='none',height=16,width=16,strokeColor="#838383"}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill={fillColor}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.12167 9.33535C3.97682 9.33535 3.92373 9.2353 4.00328 9.11155L8.5228 2.08118C8.60226 1.95758 8.66667 1.97621 8.66667 2.12961V6.39241C8.66667 6.54276 8.78839 6.66465 8.92894 6.66465H11.6093C11.7541 6.66465 11.8072 6.7647 11.7276 6.88845L7.20812 13.9188C7.12866 14.0424 7.06425 14.0238 7.06425 13.8704V9.60759C7.06425 9.45724 6.94253 9.33535 6.80198 9.33535H4.12167Z"
      stroke={strokeColor}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default BoltIcon;
