import * as React from "react";
const StatusUpIcon = ({fillColor='none',height=16,width=16,strokeColor="#838383"}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill={fillColor}
  >
    <path d="M4.58643 12.1V10.72" stroke={strokeColor} strokeLinecap="round" />
    <path d="M8 12.1001V9.34009" stroke={strokeColor} strokeLinecap="round" />
    <path d="M11.4136 12.1V7.95337" stroke={strokeColor} strokeLinecap="round" />
    <path
      d="M11.4131 3.90015L11.1064 4.26015C9.40643 6.24681 7.12643 7.65348 4.58643 8.28681"
      stroke={strokeColor}
      strokeLinecap="round"
    />
    <path
      d="M9.45996 3.90015H11.4133V5.84681"
      stroke={strokeColor}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.00016 14.6666H10.0002C13.3335 14.6666 14.6668 13.3333 14.6668 9.99992V5.99992C14.6668 2.66659 13.3335 1.33325 10.0002 1.33325H6.00016C2.66683 1.33325 1.3335 2.66659 1.3335 5.99992V9.99992C1.3335 13.3333 2.66683 14.6666 6.00016 14.6666Z"
      stroke={strokeColor}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default StatusUpIcon;
