import React, { useState, useRef, useContext, useEffect } from 'react'
import { Divider, Button, Select, Spin } from 'antd';
import { LoadingOutlined, EditOutlined, UserOutlined } from '@ant-design/icons';
import './AccountContainerStyle.scss'
import BrandingSectionContainer from './AccountSection/AdminView/BrandingSection/BrandingSectionContainer';
import TeamSectionContainer from './AccountSection/AdminView/TeamSectionContainer';
import AccountDetailsContainer from './AccountSection/AdminView/AccountDetailsContainer';
import MLOnboarding from '../../mlOnboarding/mlOnboarding.component';
import { AppContext } from '../../../App';
import TeamMemberView from './AccountSection/TeamMemberView/TeamMemberView';
import PageHeaderComponent from '../../common/PageHeader';
import LockingComponent from '../../common/Locking.component';
import UnsavedChangesModal from './common/UnsavedChangesModal';
import TeamLeaderView from './AccountSection/TeamLeaderView/TeamLeaderView';
import CONSTANTS from '../../../constants/constants';
import { trackEvent } from '../../../service/engagementMonitoringService';
import AccountLocking from './common/AccountLocking';
import VLOnboardingV2 from '../../mlOnboarding/VLOnboardingV2';

const AccountContainer = () => {
    const { Option } = Select;
    const [teamKeyValue, setTeamKeyValue] = useState({
        key: "brandingAndTeam",
        value: "Team",
    });
    const [brandingAndTeamKeyValue, setBrandingAndTeamKeyValue] = useState({
        key: "brandingAndTeam",
        value: "Branding And Team",
    });
    const [accountDetailsKeyValue, setAccountDetailsKeyValue] = useState({
        key: "accountDetails",
        value: "Account Details",
    });
    const [showBrandingAndTeamSection, setShowBrandingAndTeamSection] = useState(true);
    const [showAccountDetailsSection, setShowAccountDetailsSection] = useState(false);
    const onboardingRef = useRef(null);
    const [isEditingOnboardingDetails, setIsEditingOnboardingDetails] = useState(false);
    const [isEditingBrandingDetails, setIsEditingBrandingDetails] = useState(false);
    const [showBrandingSaveBtnLoader, setShowBrandingSaveBtnLoader] = useState(false);
    const { mitraReducer, mitraDispatch, setAlert } = useContext(AppContext);
    const brandingRef = useRef(null);
    const [breadcrumb, setBreadcrumb] = useState([{ link: '/account', label: 'Account' }, { link: '/account', label: 'Team' }]);
    const [showAuthModal, setShowAuthModal] = useState(false);
    const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false);
    const [hasBrandingAccess, setHasBrandingAccess] = useState(false);
    const [firstSectionKeyValue, setFirstSectionKeyValue] = useState({
        key: "",
        value: ""
    })

    useEffect(() => {
        trackEvent('viewed_account_page', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
    }, [])

    useEffect(() => {
        if (mitraReducer?.mitraInfo?.role == 'admin') {
            // Set default breadcrumb before checking if the user has Branding access
            setBreadcrumb([{ link: '/account', label: 'Account' }, { link: '/account', label: 'Team' }])
        }
        else if (mitraReducer?.mitraInfo?.role == 'teamLeader') {
            setBreadcrumb([{ link: '/account', label: 'Account' }, { link: '/account', label: 'Team' }])
        }
        else {
            setBreadcrumb([{ link: '/account', label: 'Account' }])
        }

        if (mitraReducer && mitraReducer.allowedFeatures) {
            setHasBrandingAccess(mitraReducer?.allowedFeatures[CONSTANTS.BRANDING_ACCESS]?.isEnabledOverall)
            if (mitraReducer?.allowedFeatures[CONSTANTS.BRANDING_ACCESS]?.isEnabledOverall) {
                // Update breadcrumb in case user has Branding access
                setBreadcrumb([{ link: '/account', label: 'Account' }, { link: '/account', label: 'Branding And Team' }])
            }
        }
    }, [mitraReducer])

    useEffect(() => {
        if (hasBrandingAccess) {
            setFirstSectionKeyValue(brandingAndTeamKeyValue)
        }
        else {
            setFirstSectionKeyValue(teamKeyValue)
        }
    }, [hasBrandingAccess])

    const changeAccountSection = (key) => {
        if (checkForAuth()) {
            switch (key) {
                case firstSectionKeyValue.key:
                    hasBrandingAccess ? setBrandingAndTeamSection() : setTeamSection();
                    break;
                case teamKeyValue.key:
                    setTeamSection()
                    break;
                case accountDetailsKeyValue.key:
                    setAccountDetailsSection();
                    break;
            }
        }
    }

    const setTeamSection = () => {
        trackEvent('viewed_branding_and_team_section', { brandingAccess: hasBrandingAccess }, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
        setShowBrandingAndTeamSection(true)
        setShowAccountDetailsSection(false)
        setBreadcrumb([{ link: '/account', label: 'Account' }, { link: '/account', label: 'Team' }])
    }

    const setBrandingAndTeamSection = () => {
        trackEvent('viewed_branding_and_team_section', { brandingAccess: hasBrandingAccess }, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
        if (isEditingOnboardingDetails) {
            setShowUnsavedChangesModal(true)
            return;
        }
        else {
            setShowBrandingAndTeamSection(true)
            setShowAccountDetailsSection(false)
            setBreadcrumb([{ link: '/account', label: 'Account' }, { link: '/account', label: 'Branding And Team' }])
        }
    }

    const setAccountDetailsSection = () => {
        trackEvent('viewed_account_section', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
        if (isEditingBrandingDetails) {
            setShowUnsavedChangesModal(true)
            return;
        }
        else {
            setShowAccountDetailsSection(true)
            setShowBrandingAndTeamSection(false)
            setBreadcrumb([{ link: '/account', label: 'Account' }, { link: '/account', label: 'Account Details' }])
        }
    }

    const editBrandingDetails = () => {
        if (checkForAuth()) {
            trackEvent('editing_brand_details', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
            setIsEditingBrandingDetails(true)
        }
    }

    const onSubmitOnboardingDetails = () => {
        if (onboardingRef.current) {
            onboardingRef.current.submitForm(); // Call the child's method
        }
    }

    const onSubmitCancel = () => {
        setIsEditingOnboardingDetails(false)
    }

    const onCancelEditingBrandingDetails = () => {
        setIsEditingBrandingDetails(false)
    }

    const onSubmitBrandingDetails = () => {
        if (brandingRef && brandingRef.current) {
            brandingRef.current.onSubmitBrandingDetails()
        }
    }

    const openTermsAndConditions = () => {
        return window.open("/terms-and-conditions")
    }

    const checkForAuth = () => {
        let isAllowed = false
        if (!localStorage.getItem('payoutAccessToken')) {
            setShowAuthModal(true)
            isAllowed = false
        }
        else {
            setShowAuthModal(false)
            isAllowed = true
        }
        return isAllowed
    }

    const leaveUnsavedChanges = () => {
        if (isEditingBrandingDetails) {
            setIsEditingBrandingDetails(false);
            setShowUnsavedChangesModal(false);
            setShowAccountDetailsSection(true)
            setShowBrandingAndTeamSection(false)
            setBreadcrumb([{ link: '/account', label: 'Account' }, { link: '/account', label: 'Account Details' }])
        }
        else {
            setIsEditingOnboardingDetails(false);
            setShowUnsavedChangesModal(false)
            setShowBrandingAndTeamSection(true)
            setShowAccountDetailsSection(false)
            setBreadcrumb([{ link: '/account', label: 'Account' }, { link: '/account', label: 'Branding And Team' }])
        }
    }

    const toggleSaveBtnLoader = (progress) => {
        if (progress == "started")
            setShowBrandingSaveBtnLoader(true)
        else {
            setIsEditingBrandingDetails(false)
            setShowBrandingSaveBtnLoader(false)
        }
    }

    return (
        <>
            <div className='account-page-parent mainContentZ'>
                {
                    mitraReducer?.allowedFeatures?.[CONSTANTS.VAHAN_PRO_ACCESS]?.isEnabledOverall ? (
                        <span className='unbounded-semibold-24-28 plain-black hero-header' style={{marginBottom: '24px'}}>Account Settings</span>
                    ): (
                        <div className='ap-header'>
                            <div className="containerTop">
                                <div className="jp-new-breadcrumb-header header">
                                    <>
                                        <PageHeaderComponent backBtnBool={true} breadcrumb={breadcrumb} />
                                    </>
                                </div>

                            </div>
                            <Divider />
                        </div>
                    )
                }

                <AccountLocking path="account" pathName="Account" visibleAuth={showAuthModal} setVisibleAuth={setShowAuthModal} dontGoBack={true} onCancelClick={() => setShowAuthModal(false)} />

                {mitraReducer?.mitraInfo?.role == 'admin' ?
                    <div>
                        <div className='ap-tabs-section d-flex justify-content-between'>
                            <div className='ap-tabs d-flex show-only-desktop'>
                                <Button onClick={() => changeAccountSection(firstSectionKeyValue.key)} className={showBrandingAndTeamSection ? "ap-selected-tab" : "ap-unselected-tab"}>
                                    {firstSectionKeyValue.value}
                                </Button>
                                <Button onClick={() => changeAccountSection(accountDetailsKeyValue.key)} className={showAccountDetailsSection ? "ap-selected-tab" : "ap-unselected-tab"}>
                                    {accountDetailsKeyValue.value}
                                </Button>
                            </div>

                            <div className='show-only-mobile ap-tabs-container-mobile'>
                                <div className='ap-tabs d-flex '>
                                    <Select
                                        placeholder="Select Section"
                                        style={{ width: '75%' }}
                                        onChange={(section) => { changeAccountSection(section) }}
                                        value={showBrandingAndTeamSection ? hasBrandingAccess ? brandingAndTeamKeyValue.key : teamKeyValue.key : accountDetailsKeyValue.key}
                                    >
                                        <Option value={brandingAndTeamKeyValue.key} key={brandingAndTeamKeyValue.key}>{brandingAndTeamKeyValue.value}</Option>
                                        <Option value={accountDetailsKeyValue.key} key={accountDetailsKeyValue.key}>{accountDetailsKeyValue.value}</Option>
                                    </Select>
                                </div>
                            </div>
                        </div>

                        <Divider />

                        <div className='ap-admin-view'>
                            {
                                showBrandingAndTeamSection ?
                                    <div className='ap-branding-team-container'>
                                        {
                                            hasBrandingAccess ?
                                                <div>
                                                    <div className='ap-branding-container'>
                                                        <BrandingSectionContainer onEditClick={editBrandingDetails} isEditing={isEditingBrandingDetails} cancelEditing={() => setIsEditingBrandingDetails(false)} ref={brandingRef} apiCalling={(progress) => toggleSaveBtnLoader(progress)} />
                                                    </div>
                                                    <Divider />
                                                </div>
                                                :
                                                null
                                        }

                                        <div className='ap-team-container'>
                                            <TeamSectionContainer />
                                        </div>
                                    </div>
                                    :
                                    <div className='ap-account-details-onboarding-container'>
                                        <div className='ap-account-details-container'>
                                            <AccountDetailsContainer />
                                        </div>

                                        <Divider />

                                        <div className='ap-onboarding-details-container'>
                                            <div className='d-flex justify-content-between'>
                                                <div className='ap-onboarding-details-header d-flex'>
                                                    <div className='d-flex align-items-center'>
                                                        <UserOutlined className='ap-onboarding-details-header-icon' />
                                                        <span className='ap-onboarding-details-header-txt semibold16-24 black85'>
                                                            Onboarding Details
                                                        </span>
                                                    </div>
                                                    <div className='ap-onboarding-details-sub-txt regular14-22 black45'>
                                                        Manage your bank account info and other details
                                                    </div>
                                                </div>

                                                {
                                                    !isEditingOnboardingDetails ?
                                                        <div className='ap-onboarding-details-edit-btn-container d-flex justify-content-center'>
                                                            <Button onClick={() => setIsEditingOnboardingDetails(true)} className='vl-secondary-btn ap-onboarding-details-edit-btn w-100 d-flex align-items-center justify-content-center'>
                                                                <EditOutlined className='ap-onboarding-details-edit-btn-icon primary-orange2' />
                                                                <span>
                                                                    Edit
                                                                </span>
                                                            </Button>
                                                        </div>
                                                        :
                                                        null
                                                }

                                            </div>

                                            <div className={`ap-onboarding-form ${isEditingOnboardingDetails ? "" : "ap-pointer-none"}`}>
                                                {/* <MLOnboarding parent={"account-page"} reference={onboardingRef} isFormSubmitted={() => setIsEditingOnboardingDetails(false)} /> */}
                                                <VLOnboardingV2 parent={"account-page"} reference={onboardingRef} isFormSubmitted={() => setIsEditingOnboardingDetails(false)} />
                                            </div>
                                        </div>
                                    </div>
                            }
                            
                        </div>
                    </div>
                    :
                    mitraReducer?.mitraInfo?.role == 'teamLeader' ?
                        <div className='ap-tl-view'>
                            <TeamLeaderView />
                        </div>
                        :
                        <div className='ap-member-view'>
                            <TeamMemberView />
                        </div>
                }
                {
                    showUnsavedChangesModal && <UnsavedChangesModal isModalOpen={showUnsavedChangesModal} closeModal={() => setShowUnsavedChangesModal(false)} onStayClicked={() => { setShowUnsavedChangesModal(false) }} onLeaveClicked={() => { leaveUnsavedChanges() }} />
                }
            </div>
            <div className='ap-footer d-flex justify-content-between align-items-center'>
                <div className='ap-tc-txt d-flex align-items-center jp-cp' onClick={() => openTermsAndConditions()}>
                    Terms And Conditions
                </div>

                {
                    isEditingOnboardingDetails ?
                        <div className='ap-footer-btns-container d-flex w-50 justify-content-end'>
                            <span onClick={() => onSubmitCancel()} className='ap-cancel-btn primary-orange2 bold16-24 jp-cp'>
                                Cancel
                            </span>
                            <Button onClick={() => onSubmitOnboardingDetails()} className='vl-primary-btn ap-submit-btn'>
                                Submit
                            </Button>

                        </div>
                        :
                        null
                }
                {
                    isEditingBrandingDetails ?
                        <div className='ap-footer-btns-container d-flex w-50 justify-content-end'>
                            <span onClick={() => onCancelEditingBrandingDetails()} className='ap-cancel-btn primary-orange2 bold16-24 jp-cp'>
                                Cancel
                            </span>
                            <Button onClick={() => onSubmitBrandingDetails()} className='vl-primary-btn ap-submit-btn d-flex align-items-center justify-content-center'>
                                <span className='bold16-24 plain-white'>Save</span>
                                {
                                    showBrandingSaveBtnLoader ?
                                        <span><Spin indicator={<LoadingOutlined className='ap-submit-btn-loader' spin />} /></span>
                                        :
                                        null
                                }

                            </Button>

                        </div>
                        :
                        null
                }

            </div>
        </>
        
    )
}

export default AccountContainer; 