import * as React from "react";
const UserOctagon = ({fillColor='none',height=16,width=16,strokeColor="#838383"}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill={fillColor}
  >
    <path
      d="M14.0533 5.72006V10.28C14.0533 11.0267 13.6533 11.7201 13.0066 12.1001L9.04661 14.3867C8.39994 14.7601 7.59994 14.7601 6.94661 14.3867L2.98661 12.1001C2.33994 11.7267 1.93994 11.0334 1.93994 10.28V5.72006C1.93994 4.9734 2.33994 4.28004 2.98661 3.90004L6.94661 1.61337C7.59328 1.24004 8.39327 1.24004 9.04661 1.61337L13.0066 3.90004C13.6533 4.28004 14.0533 4.96673 14.0533 5.72006Z"
      stroke={strokeColor}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.00011 7.33333C8.85799 7.33333 9.55344 6.63787 9.55344 5.77999C9.55344 4.92211 8.85799 4.22668 8.00011 4.22668C7.14223 4.22668 6.44678 4.92211 6.44678 5.77999C6.44678 6.63787 7.14223 7.33333 8.00011 7.33333Z"
      stroke={strokeColor}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.6668 11.1068C10.6668 9.90677 9.4735 8.93347 8.00016 8.93347C6.52683 8.93347 5.3335 9.90677 5.3335 11.1068"
      stroke={strokeColor}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default UserOctagon;
