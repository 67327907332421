import * as React from "react";
const MenuIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
    {...props}
  >
    <path
      d="M6.66663 22.6667L17.3333 22.6667M6.66663 16H25.3333M14.6666 9.33337L25.3333 9.33337"
      stroke="#252526"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default MenuIcon;
