import * as React from "react";
const ReassignmentIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      d="M3.81006 13.5918L9.63493 6.33142C10.1411 5.70056 10.9061 5.3335 11.7149 5.3335L14.0001 5.3335V5.3335"
      stroke="#1D5FFF"
      strokeWidth={1.33333}
      strokeLinecap="round"
    />
    <path
      d="M12 2.6665L14.6667 5.33317L12 7.99984"
      stroke="#1D5FFF"
      strokeWidth={1.33333}
      strokeLinecap="round"
    />
    <path
      d="M1.9999 5.33346H4.28506C5.09385 5.33346 5.85894 5.70053 6.36506 6.33139L12.1899 13.5918"
      stroke="#1D5FFF"
      strokeWidth={1.33333}
      strokeLinecap="round"
    />
    <path
      d="M4 8L1.33333 5.33333L4 2.66667"
      stroke="#1D5FFF"
      strokeWidth={1.33333}
      strokeLinecap="round"
    />
  </svg>
);
export default ReassignmentIcon;
