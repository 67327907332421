import axiosInstance from "./interceptorService";


export const getSupportConversations = async ({mitraId, offset, limit, status, sortBy}) => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/support/${mitraId}/getConversationTickets?offset=${offset}&limit=${limit}&status=${status}&sortBy=${sortBy}`;
    return axiosInstance.get(url, { withCredentials: true });
};

export const getSupportFilters = async ({mitraId}) => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/support/${mitraId}/getConversationFilters`;
    return axiosInstance.get(url, { withCredentials: true });
};

export const getCountOfUnreadSupportConversations = async (mitraId) => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/support/${mitraId}/getCountOfUnreadConversations`;
    return axiosInstance.get(url, { withCredentials: true });
};

export const getMessagesOfASupportConversation = async ({mitraId, conversationId}) => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/support/${mitraId}/getConversationMessages?conversationId=${conversationId}`;
    return axiosInstance.get(url, { withCredentials: true });
};

export const postMessageToAConversation = async ({mitraId, data}) => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/support/${mitraId}/getNextConversation`;
    return axiosInstance.post(url, data, { withCredentials: true });
}

export const updateLastSeenOfASupportConversation = async ({mitraId, data}) => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/support/${mitraId}/setLastSeenOfConversation`;
    return axiosInstance.post(url, data, { withCredentials: true });
}