import React from "react";
import { DatePicker, Button, PageHeader, Modal } from 'antd';
import { DownOutlined, FileDoneOutlined, InfoCircleOutlined, GlobalOutlined, CloudDownloadOutlined, CloseOutlined, PhoneOutlined, UserOutlined, LinkOutlined, SearchOutlined, DownloadOutlined, ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { trackEvent } from "../../service/engagementMonitoringService";
import CONSTANTS from "../../constants/constants";
import CreateJobPostPopUp from "../jobs/createJobPostPopUp";
import FilterPopup from "../leads/Filter-popup.component";
import moment from "moment";
import {getActiveClientsForFilter, disabledDate, getStatusListForFilters, getRapidoDocumentStatuses, getLocationForFilter} from './../../service/commonService'
import {FilterOutlined, WhatsAppOutlined} from '@ant-design/icons';
import SideSheet from "../common/SideSheet"
import LocationFilterComponent from '../common/LocationFilter.component';
import {Tag , Space ,Typography, Skeleton, Pagination, Empty, Tabs, Result, Dropdown, Menu, notification } from 'antd';
import {AppContext} from '../../App';
import * as jobDemandsService from '../../service/jobDemandsService';
import { getDisplayableClientName } from '../../utils/commonFunction';

const { RangePicker } = DatePicker;


const DEFAULT = 'DEFAULT';

// temp to be removed:
const statusesSelected = [];

const saveFilters = (filterData) => {
    localStorage.setItem('filterData', JSON.stringify(filterData));
}
const JobRequirements = (props) => {
    const [loaded, setLoaded] = React.useState(false);
    const [isAdmin, setIsAdmin] = React.useState(false);
	const [mitra, setMitra] = React.useState();
    const [jobDemands, setJobDemands] = React.useState([]);
    const [validJobDemandsCities, setValidJobDemandsCities] = React.useState([]);
	const [validJobDemandsVerticals, setValidJobDemandsVerticals] = React.useState([])
    const [validJobDemandsClients, setValidJobDemandsClients] = React.useState([]);
    const [jobPostingClientSelected, setJobPostingClientSelected] = React.useState('');
	const [selected, setSelected] = React.useState({});
	const [jobPostingData, setJobPostingData] = React.useState({});
	const [viewLocations, setViewLocations] = React.useState(true);
    const DEFAULT = 'DEFAULT';
    let minSalary = DEFAULT;
    let maxSalary = DEFAULT;
    const handleSalaryRange = (e) => {
		const salary = e.target.value;
		const tempSalaryArr = salary.split('-');
		e.target.value = DEFAULT;
	}
    const clearAll=()=>{
		clientSelected.map(item => {
			item.value=false;
		})
		statusesSelected.map(item => {
			item.value=false;
		})
        locationList.map(item => {
            item.value=false;
        })
		setFilterApplied(true)
	}
    const [filterApplied, setFilterApplied] = React.useState(false);
    const {mitraReducer, setAlert,setSpin } = React.useContext(AppContext);
    React.useEffect(() => { // updates the job demands and everything after checking all the filters having value: 'true' and passing it to function.
        let ClientList = [];
		let categoryList = [];
		let CityList = [];
        
		validJobDemandsClients.map((client) => {
			if (client.value === true) {
				ClientList.push(client.label);
			}
			return null;
		});
        clientSelected.map((client) => {
			if (client.value === true) {
				ClientList.push(client.label);
			}
			return null;
		});
		VerticalList.map((vertical) => {
			if (vertical.value === true) {
				categoryList.push(vertical.label);
			}
			return null;
		});

		validJobDemandsCities.map((city) => {
			if (city.value === true) {
				CityList.push(city.label);
			}
			return null;
		});
        locationList.map((city) => {
            if(city.value === true){
                CityList.push(city.label);
            }
            return null;
        })
        
		if (viewLocations) {
			validJobDemandsCities.map((city) => {
				if (city.value === true) {
					city.value = false;
				}
			});
			CityList = [];
		} else if (CityList.length > 0) {
			ClientList.push(client);
		}
        if( salaryFilter ){
            let min = Number.MAX_SAFE_INTEGER;
            let max = 0;
            salaryFilter.filter(salaryObj => salaryObj.value === true).map((salary) => {
                min = Math.min(parseInt(salary.label.split('-')[0]), min);
                max = Math.max(parseInt(salary.label.split('-')[1]), max);
            });
            if(max === 0) max = 100000;
            if(min === Number.MAX_SAFE_INTEGER) min = 0;
            minSalary = min;
            maxSalary = max;
        }
        getJobDemandsOnFiltersChange(minSalary, maxSalary, CityList, ClientList, categoryList);
        setFilterApplied(false)
    },[filterApplied])

    const [salaryFilter, setSalaryFilter] = React.useState([]);
	const [clientSelected, setClientSelcted] = React.useState([])
    const [startDate, setStartDate] = React.useState(moment().subtract(7, 'days').format('YYYY-MM-DD'));
    const dateFormat = 'YYYY/MM/DD';
    const [endDate, setEndDate] = React.useState(moment().format('YYYY-MM-DD'));
    const [locationList, setLocationList] = React.useState([])
    const [VerticalList, setVerticalList] = React.useState([])
    const [_, forceUpdate] = React.useReducer((x) => x + 1, 0);
    

    const commonTag = (label,arrObj) =>{
        return <div className='tag-containers'>
            <div>{label}</div>
            {label === "Date Range" ?
                <Space>
                    <RangePicker
                        defaultValue={[moment(startDate, dateFormat), moment(endDate, dateFormat)]}
                        value={[moment(startDate, dateFormat), moment(endDate, dateFormat)]}
                        format={dateFormat}
                        onChange={handleDateRange}
                        ranges={{
							Today: [moment().startOf('day'), moment().endOf('day')],
                            'This Month': [moment().startOf('month'), moment().endOf('month')],
                        }}
                        disabledDate={disabledDate}
                        allowClear={false}
                        className="range-picker-leads"
                    />
                </Space> : 
                label === "Location" ? 
                <LocationFilterComponent
                    locationList={locationList}
                    onClickTags={onClickTags}
                    label={label}
                    setLocationList={setLocationList}
                />
                :
                <div className='tag-wrapper-container'>
                    {arrObj.map((item,index)=>{
                        return <Tag className={!item.value ? 'tags':'tags tag-active'}
                        key={index}
                        // closable={item.value}
                        onClick={()=>{onClickTags(label,index)}}
                        >
                            {label === "Clients" ? getDisplayableClientName(item.label) : item.label}
                        </Tag>
                    })}
                </div>
            }
            
        </div>
    }
    const handleDateRange = (dates) => {
        if(dates === null){
            setStartDate(moment().subtract(7, 'days').format('YYYY-MM-DD'));
            setEndDate(moment());
        }
        else{
            setStartDate(dates[0]);
            setEndDate(dates[1]);
        }
    }
    const applyFilterCondition = () =>{
        let result=false;
        let arr = [statusesSelected,clientSelected,endDate,locationList,VerticalList, salaryFilter];
        arr.map(item=>{
            if(item){
                for (let i = 0; i < item.length; i++) {
                    if (item[i].value === true) {
                        result=true;
                        break;
                    }
                  }
            }
            if(moment(endDate).format("YYYY-MM-DD") !== moment().format("YYYY-MM-DD")) {
                result = true;
            } 
        })
        return result;
    }
    const onClickTags=(label,index)=>{
		if(label === 'Status'){
			statusesSelected[index].value=!statusesSelected[index].value;
		}
		if(label === 'Clients'){
			clientSelected[index].value=!clientSelected[index].value;
		}
		if(label === 'Location'){
			locationList[index].value=!locationList[index].value;
		}
        if(label === 'Salary'){
            salaryFilter[index].value=!salaryFilter[index].value;
        }
        if(label === 'Job Type'){
            VerticalList[index].value=!VerticalList[index].value;
        }
		forceUpdate();
	}
    const [filterSider,setfilterSider] = React.useState(false);
    const submitFilter = () => {
        setFilterApplied(true)
    }
    const {
        showValuePropModal,
        setCurrentClient,
        handleViewCreateWebLinks,
        JobsGAEventsTracker,
        handleViewLocations,
        client,
        viewCreateWebLink,
        setViewCreateWebLink,
        setJobPostingId,
        setJobPostingCity,
        createJobPostingDropdown,
        showClientFilterPopup,
        setClientShowFilterPopup,
        showVerticalFilterPopup,
        setVerticalShowFilterPopup,
        setShowBreadcrumb,
        deleteChip,
        handleTabSwitchtoJobLeads,
    } = props;
    const getJobDemandsOnFiltersChange = async (minSalary, maxSalary, city, clientList, vertical) => {
		try {
            let cityList = locationList.filter(ele => ele.value === true).map(ele => ele.label)
            // console.log("locationList:>> after filter change", cityList)
			console.log("getJobDemandsForFilterChange in JOB REQUIREMENTS:>>>");
            // console.log("----->Fetching Job Demands for:>>",minSalary, maxSalary, city, clientList, vertical)
            saveFilters({minSalary, maxSalary, cityList, clientList, vertical})
			jobDemandsService.getJobDemandsOnFiltersChange(minSalary, maxSalary, cityList, clientList, vertical).then((result) => {
				if (result && result.data.viewData.allJobDemands) {
					const jobDemands = result.data.viewData.allJobDemands;
					setJobDemands(jobDemands);
					if (jobDemands.length === 1) {
						setSelected(jobDemands[0]);
					} else if (jobDemands.length === 0) {
						setSelected();
					} else {
						jobDemands.map(ele => {
							if (ele.companyName === client) {
								setSelected(ele);
							}
						})
					}
				}
			});
		} catch (error) {
			console.log(error);
		}
	};
    React.useEffect(() => { // This triggers on every time mitraReducer changes.
        
        getActiveClientsForFilter().then(data => {
        setClientSelcted(data.data.activeClients)
        })  
        getLocationForFilter().then(data => {
            setLocationList(data.data.locationList)
        })
        setVerticalList(validJobDemandsVerticals);
        const getJobDemandsData = async (e) => {
            try { 
                jobDemandsService.getJobDemandsData().then((result) => {
                    if (result && result.data.viewData) {
                        if (result.data.viewData.allJobDemands) {
                            //setJobDemands(result.data.viewData.allJobDemands);
                        }
                        if (result.data.viewData.validJobDemandsCities) {
                            setValidJobDemandsCities(result.data.viewData.validJobDemandsCities);
                        }
                        if (result.data.viewData.validJobDemandsClients) {
                            setValidJobDemandsClients(result.data.viewData.validJobDemandsClients);
                        }
                        if (result.data.viewData.validJobDemandsVerticals) {
                            console.log(result.data.viewData.validJobDemandsVerticals, "verticals in job req")
                            setValidJobDemandsVerticals(result.data.viewData.validJobDemandsVerticals);
                        }
                    }
                });
            } catch (error) {
                console.log(error);
            }
        };
        getJobDemandsData();
        setSalaryFilter(CONSTANTS.SALARY_FILTER_TAGS)
        if (mitraReducer?.mitraInfo) {
            setIsAdmin(mitraReducer?.mitraInfo?.role === 'admin' ? true : false)
            setMitra(mitraReducer?.mitraInfo ? mitraReducer?.mitraInfo : {})
        }
    }, [mitraReducer])
    return (
        <div className="jobs-page">
        <div>
            <CreateJobPostPopUp client={client} heading="Create Weblink" visible={viewCreateWebLink} setShowFilterPopup={setViewCreateWebLink}
                setCategory={setJobPostingId} setJobPostingCity={setJobPostingCity} setJobPostingClientSelected={setJobPostingClientSelected} list={createJobPostingDropdown} setJobPostingData={setJobPostingData} />

            {/* <JobPostingSidesheet open={showSideSheet} setOpen={setShowSideSheet} setViewCreateWebLink={setViewCreateWebLink} setJobId={setJobPostingId} setJobPostingResponseById={setJobPostingResponseById} setJobPostingCity={setJobPostingCity} setJobPostingClientSelected={setJobPostingClientSelected} client={client} JobPostingList={jobPostingsByClient} setJobPostingData={setJobPostingData} /> */}
        </div>
        <h4>Jobs</h4>
        <div className="bottom-detail-wrapper">
            {
                validJobDemandsClients.length > 0 ?
                    <FilterPopup key="f-client-validJobDemandsClients" heading="Select Client" visible={showClientFilterPopup} setShowFilterPopup={setClientShowFilterPopup} setCategory={setValidJobDemandsClients} list={validJobDemandsClients} setShowBreadcrumb={setShowBreadcrumb} />
                    : null
            }
            {
                validJobDemandsVerticals.length > 0 ?
                    <FilterPopup key="f-jobType-validJobDemandsVerticals" heading="Select Job Type" visible={showVerticalFilterPopup} setShowFilterPopup={setVerticalShowFilterPopup} setCategory={setValidJobDemandsVerticals} list={validJobDemandsVerticals} setShowBreadcrumb={setShowBreadcrumb} />
                    : null
            }
        </div>
        <div style={{background: '#F0F2F5', padding: '1rem', textAlign: 'left'}}>
            <div id="status-filter" style={{padding: '10px'}}>
                {/* <div onClick={() => {
                    setClientShowFilterPopup(true);
                    JobsGAEventsTracker("ClickOnJobsClientFilter")
                }} */}
                    {/* className="Dropdown-control filter-label">
                    <div style={{margin: 'auto 0px'}}>Client</div>
                    <DownOutlined style={{lineHeight: 'inherit', margin: 'auto 0px'}} />
                </div> */}
                {/* <div onClick={() => {
                    setVerticalShowFilterPopup(true);
                    JobsGAEventsTracker("ClickOnJobsJobTypeFilter")
                }}
                    className="Dropdown-control filter-label">
                    <div style={{margin: 'auto 0px'}}>Job Type</div>
                    <DownOutlined style={{lineHeight: 'inherit', margin: 'auto 0px'}} />
                </div> */}
                {/* ["0-10000", "10000-25000", "25000-50000", "50000-100000"] */}
                <Button style={(applyFilterCondition()) ? {color:'var(--primary-theme-color)',backgroundColor:'var(--primary-theme-light)',border:'1px solid var(--primary-theme-color)'} :null} onClick={()=>setfilterSider(true)} icon={<FilterOutlined />}>Apply Filters</Button>
                {/* <select defaultValue={DEFAULT} style={{fontSize: 'inherit'}} id="client-filter" onChange={handleSalaryRange} className="salary-filter button Dropdown-control filter-label">
                    <option value={DEFAULT} disabled>Salary Range </option>
                    <option value="0-10000">Less than 10,000 </option>
                    <option value="10000-25000">10,000-25,000 </option>
                    <option value="25000-50000">25,000-50,000 </option>
                    <option value="50000-100000">More than 50,000 </option>
                </select> */}
            </div>
            <div className="Chips-container">
                {
                    validJobDemandsClients.map((single_client,index) => {
                        if (single_client.value) {
                            return <div key={index} className="single-chip">
                                <span className="chip-text">{single_client.label}</span>
                                <span className="chip-cross" onClick={() => {deleteChip('client', single_client)}}> <CloseOutlined /> </span>
                            </div>
                        }
                    })
                }

                {
                    validJobDemandsVerticals.map((single_vertical,index) => {
                        if (single_vertical.value) {
                            return <div key={index} className="single-chip">
                                <span className="chip-text">{single_vertical.label}</span>
                                <span className="chip-cross" onClick={() => {deleteChip('vertical', single_vertical)}}> <CloseOutlined /> </span>
                            </div>
                        }
                    })
                }
                {
                    maxSalary != 'DEFAULT' && minSalary != 'DEFAULT' ?
                        <div className="single-chip">
                            <span className="chip-text">
                                {maxSalary == 10000 ? 'Less than 10,000'
                                    : maxSalary == 25000 ? '10,000-25,000'
                                        : maxSalary == 50000 ? '25,000-50,000'
                                            : maxSalary == 100000 ? 'More than 50,000'
                                                : null}
                            </span>
                            <span className="chip-cross" onClick={() => {deleteChip('salary', maxSalary)}}> <CloseOutlined /> </span>
                        </div>
                        : null
                }
            </div>
            <div className="tabs-container">
                <button id={!isAdmin ? 'full-size' : null}
                    className="tab active-tab" >
                    Job Requirements
                </button>
                {isAdmin ?
                    <button id="atRiskApplication" className="tab"
                        onClick={(e) => {handleTabSwitchtoJobLeads();}}>
                        <InfoCircleOutlined style={{color: 'var(--primary-theme-color)', verticalAlign: 'baseline'}} />&nbsp;
                        Job Leads
                    </button>
                    : null}
            </div>
        </div>
        {filterSider && 
				<SideSheet submit={{disabled:false
					,onClick:submitFilter
				}}
				clearAll={{disabled:false,onClick:clearAll}}
				sidername={"Filter Jobs"} 
				onDismiss={() => {setfilterSider(false)}}>
					<div className='filter-home-wrapper'>
                        {commonTag("Job Type",VerticalList)}
						{commonTag("Clients",clientSelected)}
						{commonTag("Location", locationList)}
                        {commonTag("Salary",salaryFilter)} 
					</div>
				</SideSheet>
			}	
        {/* {
            showBanner && (isAdmin ?
                <div className="job-demand-banner-container">
                    <div className="job-demand-banner-icon"><GlobalOutlined /></div>
                    <div className="job-demand-banner-text">
                        <div className="banner-text">
                            <strong>New! Create your Website link </strong>
                            <br />
                            and get leads from social media
                        </div>
                        <div className="banner-vedio">
                            <iframe className="video-tag" src="https://www.loom.com/embed/7b7d3940ab044b6282f5659c0ea0372f" frameborder="0" webkitallowfullscreen="true" mozallowfullscreen="true" allowfullscreen="true" ></iframe>
                        </div>
                    </div>
                    <div className="cross" onClick={() => setShowBanner(false)}><CloseOutlined /></div>
                </div>
                : null)
        } */}

<div className="form_control job-demands-table">
            {jobDemands.length == 0 ? <div className="leads-error">No Job Demands found</div> : null}
            {jobDemands.map((v, i) => (
                <div key={'jobDemands' + i} className="detail-element-body">
                    <div className="card-container" style={{display: 'inline-block', textAlign: 'left'}} key={i}>
                        <div className="client-logo" style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                            <img className="imgResponse" alt={getDisplayableClientName(v.companyName)} src={v.logo} />
                            <Button style={{fontWeight:'700',background:'var(--primary-theme-color)',color:'#fff'}} onClick={() => { showValuePropModal();setCurrentClient(v)}}>Why {getDisplayableClientName(v.companyName)}?</Button>
                        </div>
                        <div>
                            <h6><br></br>Type of Job</h6>
                            <h6 className="content">{v.jobType + ' - ' + v.vertical}</h6>
                        </div>
                        <div>
                            <h6><br></br>Salary Range</h6>
                            <h6 className="content">{v.maxSalary > v.baseSalary ? 'Rs.' + v.baseSalary + ' - Rs.' + v.maxSalary : 'Rs.' +
                                v.maxSalary} {v.requirements && v.requirements.jobPreference ? v.requirements.jobPreference : ''}</h6>
                        </div>
                        <div>
                            <h6><br></br>Requirements</h6>
                            <h6 className="content" style={{lineHeight: '1.3rem'}}>{v.requirements && v.requirements.education ? v.requirements.education.map((v, i) => (<li key={'k' + i}>{v}</li>)) : ''}</h6>
                            <h6 className="content" style={{lineHeight: '1.3rem'}}>{v.requirements && v.requirements.englishProficiency ? v.requirements.englishProficiency.map((v, i) => (<li key={'l' + i}>{v}</li>)) : ''}</h6>
                            <h6 className="content" style={{lineHeight: '1.3rem'}}>{v.requirements && v.requirements.experience ? v.requirements.experience.map((v, i) => (<li key={'m' + i}>{v}</li>)) : ''}</h6>
                        </div>
                        {isAdmin ?
                            <button className="createViewWeblink" onClick={(e) => {handleViewCreateWebLinks(v); JobsGAEventsTracker("ViewLocations"); trackEvent('create_view_weblink_clicked', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);}}>
                                <LinkOutlined />
                                &nbsp;&nbsp;Create/View Poster
                            </button>
                            : null}
                        <button className="viewLocations" id={!isAdmin ? 'full-size' : null} onClick={(e) => {handleViewLocations(v); JobsGAEventsTracker("ViewLocations")}}>
                            <SearchOutlined />
                            &nbsp;&nbsp;View Locations
                        </button>
                    </div>
                </div>
            ))}
        </div>
    </div>
    )
};

export default JobRequirements;
