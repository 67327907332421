import React, { useContext, useEffect, useRef, useState } from 'react';
import '../styles/SupportChatBot.scss'
import Lottie from 'react-lottie';
import LoadingAnimation from './../../../static/lottie/blue-circle-loader.json';
import CrossIcon from '../../../static/svg/CrossIcon';
import MessageLoadingView from './MessageLoadingView';
import { Button, Input, notification } from 'antd';
import {SendOutlined} from '@ant-design/icons';
import { getMessagesOfASupporConversation, getMessagesOfASupportConversation, postMessageToAConversation, updateLastSeenOfASupportConversation } from '../../../service/SupportV2';
import { AppContext } from '../../../App';
import ReloadIcon from '../../../static/svg/ReloadIcon';

const { Search } = Input;

function SupportChatBot({setShowChatbox, conversationId=null, source, sourceRhId=null, getAllSupportConversations=null}) {
  const [loading, setLoading] = useState(true);
  const messagesEndRef = useRef(null);
  const [messages, setMessages] = useState([]);
  const [optionsForLastUnattendedQuestion, setOptionsForLastUnattendedQuestion] = useState([]);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const {mitraReducer} = useContext(AppContext);

  const [supportConversationId, setSupportConversationId] = useState(conversationId);
  const [recommendationHistoryId, setRecommendationHistoryId] = useState(sourceRhId);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: LoadingAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const messagesContainerRef = useRef(null);

  const scrollToBottom = () => {
    const container = messagesContainerRef.current;
    if (container) {
      if (isFirstLoad) {
        // Instant scroll on first load
        container.scrollTop = container.scrollHeight;
        setIsFirstLoad(false);
      } else {
        // Smooth scroll for subsequent messages
        container.scrollTo({
          top: container.scrollHeight,
          behavior: 'smooth'
        });
      }
    }
  };

  const getConversationMessages = () => {
    const payload = {
      mitraId: mitraReducer?.mitraInfo?.id,
      conversationId
    }
    getMessagesOfASupportConversation(payload)
    .then(res=> {

      if(res?.data?.data?.userReplyData?.responses?.length && res?.data?.data?.userReplyData?.responseType === 'select') {
        setOptionsForLastUnattendedQuestion(res?.data?.data?.userReplyData?.responses);
      } else {
        setOptionsForLastUnattendedQuestion([]);
      }

      if(res?.data?.data?.messageList?.length && res?.data?.data?.userReplyData?.responseType === 'input') {
        
        setMessages((oldMessages) =>[
          ...res.data.data.messageList,
          {
            id: `msg-${Date.now()}`, // Unique ID
            text: res?.data?.data?.userReplyData?.responseText,
            type: res?.data?.data?.userReplyData?.responseType || null,
            selectOptions: res?.data?.data?.userReplyData?.responses || [],
          }
    ]
        );
      } else if(res?.data?.data?.messageList?.length){
        setMessages([...res.data.data.messageList]);
      } else {
        setMessages([]);
      }
    }).catch(err=> {
      console.log(err);
      setOptionsForLastUnattendedQuestion([]);
    })
  };

  const addLoaderMessageAtBottom = () => {
      setMessages((oldMessages) => {
        console.log(oldMessages);
        return [...oldMessages, {id: 'loader'}]
  });
  };

  const postMessage = (payload) => {
    // This either creates a new conversation or posts a message to an existing one.

    // post the message
    // once the response is received, replace the loader message with the new one.
    postMessageToAConversation({mitraId: mitraReducer?.mitraInfo?.id, data: payload})
    .then(res => {
      if(res?.data?.status) {

        if(res?.data?.data?.conversationId && !supportConversationId) {
          setSupportConversationId(res?.data?.data?.conversationId);
        }

        if(res?.data?.data?.recommendationHistoryId && !recommendationHistoryId) {
          setRecommendationHistoryId(res?.data?.data?.recommendationHistoryId);
        }

        setMessages((oldMessages) =>
          oldMessages.map((msg) =>
              msg.id === 'loader'
                  ? {
                        id: `msg-${Date.now()}`, // Unique ID
                        text: res?.data?.data?.responseText,
                        type: res?.data?.data?.responseType || null,
                        selectOptions: res?.data?.data?.responses || [],
                    }
                  : msg
          )
        );

        if(!res?.data?.data?.responseType && res?.data?.data?.responses?.[0]?.nextBotResponseId) {
          const nextBotResponseId = res?.data?.data?.responses?.[0]?.nextBotResponseId;
          const userResponse = res?.data?.data?.responses?.[0]?.userResponseText;
          const actionForBE = res?.data?.data?.responses?.[0]?.actionForBE;
          const conversationId = res?.data?.data?.conversationId;
          addLoaderAndPostMessage(nextBotResponseId, userResponse, actionForBE, conversationId);
        }
      }
    })
  };

  const addLoaderAndPostMessage = (
    nextBotResponseId=null, 
    userResponse=null, 
    actionForBE=null, 
    conversationId=supportConversationId,
    rhId=recommendationHistoryId
  ) => {

    addLoaderMessageAtBottom();

    // create a payload
    const payload = {
      "conversationId": conversationId,
      "lastBotResponseId": null, // Not in use
      "nextBotResponseId": nextBotResponseId,
      "userResponse": userResponse,
      "conversationType": source,
      "recommendationHistoryId": rhId,
      "actionForBE": actionForBE
    };

    setTimeout(() => {
      postMessage(payload);
    }, 1000);
  };

  const onSelectOption = (option) => {

    if(option?.recommendationHistoryId) {
      setRecommendationHistoryId(option?.recommendationHistoryId);
    }

    setMessages((oldMessages) => [...oldMessages, {
      text: option.userResponseText,
      type: null,
      role: 'user',
      selectOptions: []
    }]);
    
    addLoaderAndPostMessage(
      option.nextBotResponseId,
      option.userResponseText,
      option.actionForBE,
      supportConversationId,
      option?.recommendationHistoryId
    );

    setOptionsForLastUnattendedQuestion([]);
  }

  const onEnterUserResponse = (e) => {

    if(!e) {
      notification['error'](
        {
          message: 'Enter your response to continue..'
        }
      )

      return;
    }

    setMessages((oldMessages) => [...oldMessages, {
      text: e,
      type: null,
      role: 'user',
      selectOptions: []
    }]);
    
    addLoaderAndPostMessage(
      messages[messages?.length-1].selectOptions?.[0]?.nextBotResponseId,
      e,
      messages[messages?.length-1].selectOptions?.[0]?.actionForBE
    );
  };

  const updateLastSeen = async() => {
    const payload = {
      mitraId: mitraReducer?.mitraInfo?.id,
      data: {
        conversationId:supportConversationId
      }
    }
    await updateLastSeenOfASupportConversation(payload);
  }


  const setLoaderAndGetConversationMessages = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      
      getConversationMessages();
    }, 1500);
  }

  useEffect(() => {
    // Starts from here

    setTimeout(() => {
      setLoading(false);
      
      if(conversationId) {
        getConversationMessages();
      } else {
        addLoaderAndPostMessage();
      }
    }, 1500);

    return () => {
      updateLastSeen();
      if(getAllSupportConversations) {
        getAllSupportConversations();
      }
    }
  }, [conversationId, source]);

  useEffect(() => {
    if(messages?.length) {
      scrollToBottom();
    }
  }, [messages, isFirstLoad]);

  const chatBotLoadingLayout = () => (
    <div className='d-flex align-items-center justify-content-center flex-column' style={{height: '100%'}}>
      <Lottie 
      options={defaultOptions}
      height={80}
      width={80}
      speed={1}
      />
      <span>Loading...</span>
    </div>
  );

  const chatInterfaceLayout = () => (
    <>
      <div className='chat-bot-header'>
        <span className='unbounded-semibold-16-24 plain-black'>
       
          {mitraReducer?.customBrandDetails?.brandName ? '':'VahanPro '} 
          Support

        </span>
        <Button 
        onClick={setLoaderAndGetConversationMessages}
        style={{height: 'auto',padding: '2px 6px',border: 'none',marginLeft: 'auto', marginRight: '12px'}}>
          <ReloadIcon height={16} width={16} strokeWidth={2}/>
        </Button>
        <div className='cursor-pointer' onClick={()=>setShowChatbox(false)}>
          <CrossIcon />
        </div>
      </div>

      <div className="chat-bot-content" ref={messagesContainerRef}>
        <div className="welcome-div new-theme-shadow inter-regular-12-16 plain-black">
          Welcome to 
          {mitraReducer?.customBrandDetails?.brandName ? ' ':' VahanPro '} 
          Support!
        </div>

        {
          messages?.length ? (
            <div className="messages-container" style={{marginBottom: messages?.length && messages[messages?.length-1].id === 'loader' ? '48px': '0px'}}>
              {/* Messages */}
              {
                messages?.map((item, index)=> (
                  item.id != 'loader' ? item.text ? (
                  <div 
                  key={index} 
                  dangerouslySetInnerHTML={{ __html: item.text }}
                  className={`message-bubble ${item?.role != 'user' ? 'bubble-left': 'bubble-right'}`}>
                  </div>): null:  <MessageLoadingView />
                ))
              }

              <div className="response-container" style={{marginTop: 'auto', paddingTop: '2px'}}>

                {/* Option Selection Section */}
                {
                  messages?.length && messages?.[messages?.length-1].type === 'select' ? (
                    <div className='single-select-operation'>
                      {
                        messages?.[messages?.length-1].selectOptions?.map((option, index) => (
                          <div key={index} 
                          className={`scb-single-option text-capitalise ${option?.userResponseText?.toLowerCase() === 'start chat again' ? 'red-content': ''}`} 
                          onClick={()=>onSelectOption(option)}>{option.userResponseText}</div>
                        ))
                      }
                    </div>
                  ): null
                }

                {
                  optionsForLastUnattendedQuestion?.length ? (
                    <div className='single-select-operation'>
                      {
                        optionsForLastUnattendedQuestion?.map((option, index) => (
                          <div 
                          key={index} 
                          className={`scb-single-option text-capitalise ${option?.userResponseText?.toLowerCase() === 'start chat again' ? 'red-content': ''}`} 
                          onClick={()=>onSelectOption(option)}>{option.userResponseText}</div>
                        ))
                      }
                    </div>
                  ): null
                }

                {/* Input box */}
                {
                  messages?.length && messages?.[messages?.length-1].type === 'input' ? (
                    <div className="input-operation">
                      <Search 
                        placeholder="Type your response.." 
                        onSearch={onEnterUserResponse} 
                        allowClear 
                        enterButton={<SendOutlined />}/>
                    </div>
                  ) : null
                }

              </div>

            </div>
          ): null
        }
        
      </div>
    </>
  );
  
  return (
    <div className='chatbot-overlay'>
      <div className="chatbot-main-container">
        <div className="chat-box">
          {
            loading ? chatBotLoadingLayout(): chatInterfaceLayout()
          }
        </div>
        <div className="pro-icon visibility-desktop">
          <img src='/image/swan-charged.webp' style={{height: '55%',width:'55%'}} />
        </div>
      </div>
    </div>
  )
}

export default SupportChatBot