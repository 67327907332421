import React, { useEffect, useState } from 'react'
import './LeadCard.css';
import { Checkbox, Divider } from 'antd';
import LocationPin from '../../../static/svg/LocationPin';
import ClockIcon from '../../../static/svg/ClockIcon';
import CrossIcon from '../../../static/svg/CrossIcon';
import UserOctagon from '../../../static/svg/UserOctagon';
import CallIcon from '../../../static/svg/CallIcon';
import LeadDetailsPopup from '../LeadDetailsPopup';
import moment from 'moment';
import { keyToIconMap } from '../../../constants/utils';
import SupportChatBot from '../../HelpAutomation/components/SupportChatBot';

function LeadCard({ leadInfo, mitraReducer, filters, getLeadsDashboardConfiguration }) {
  const [checkboxTicked ,setCheckboxTicket] = useState(false);
  const [openDetailsModal, setOpenDetailsModal] = useState(false);  
  const [showChatbot, setShowChatbox] = useState(false);
  
  const [selectedRhId, setSelectedRhId] = useState(null);
  const [supportConversationId, setSupportConversationId] = useState(null);
  
  return (
    <>
  
        <div className='individual-card'>
          <div className="ic-content-section" onClick={()=>setOpenDetailsModal(true)}>

            <div className="ic-content-header">
              <div className="user-client-details">

                {
                  leadInfo?.leadPoolId ?  (
                    <img src={leadInfo?.applications?.[0]?.companyLogo} style={{height: '48px', width:'48px',borderRadius: '4px'}} />
                  ):
                  leadInfo?.applications?.length > 1 ? (
                    <div className="image-stack">
                      <div className="image-container">
                        {/* Gray indicator behind */}
                        <div className="indicator"></div>

                        {/* First image in front */}
                        <img src={leadInfo?.applications?.[0]?.companyLogo} alt="Front Image" className="image-front" />

                        {/* Second image behind the first */}
                        <img src={leadInfo?.applications?.[1]?.companyLogo} alt="Back Image" className="image-back" />
                      </div>
                    </div>
                  ):(
                    <img src={leadInfo?.applications?.[0]?.companyLogo} style={{height: '48px', width:'48px',borderRadius: '4px',border: '1px solid #80808063'}} />
                  )
                }

                <div className="ic-user-details">
                  <span className='inter-semibold-16-24 plain-black'>{leadInfo?.candidateFirstName} {leadInfo?.candidateLastName?.toLowerCase() === 'unknown' ? '': leadInfo?.candidateLastName}</span>
                  <div className='ic-contact-details'>
                    <span className='inter-regular-12-16 color-252526'>{leadInfo?.candidatePhoneNumber}</span>
                    {
                      leadInfo?.applications?.[0]?.referredCity ? (
                        <div className='ic-location-details'><LocationPin /> <span className='inter-regular-12-16 color-252526'>{leadInfo?.applications?.[0]?.referredCity}</span></div>
                      ):null
                    }
                  </div>
                </div>
              </div>

              <div className="user-referral-details">
                {
                  leadInfo?.leadType === 'preReferral' ? (
                    <>
                      <span className='inter-regular-12-16 color-252526'>Uploaded on {leadInfo?.createdAt && moment(leadInfo?.createdAt).format('DD MMM yyyy')}</span>
                    </>
                  ): (
                    <>
                      <span className='inter-regular-12-16 color-252526'>Referred on {leadInfo?.applications?.[0]?.applicationRefferdAt && moment(leadInfo?.applications?.[0]?.applicationRefferdAt).format('DD MMM yyyy')}</span>
                      <div><ClockIcon /> <span className='inter-regular-12-16 color-838383'>{leadInfo?.applications?.[0]?.currentAchievedMilestone?.expiryDate}</span></div>
                    </>
                  )
                }
                
              </div>
            </div>

            <div className="ic-tags-section">

                {
                  leadInfo?.applications?.[0]?.referralMilestone?.iconTag ? (
                    <div className='ic-tag' style={{background: leadInfo?.applications?.[0]?.referralMilestone?.bgColor}}>
                      {keyToIconMap(leadInfo?.applications?.[0]?.referralMilestone?.iconTag, {
                                                    strokeColor: '#FFF'
                                                })}
                      <span className='inter-semibold-12-18 plain-white'>{leadInfo?.applications?.[0]?.referralMilestone?.label}</span>
                    </div>
                  ): null
                }

              {
                leadInfo?.applications?.[0]?.tags?.map((tag, tagIndex) => (
                  <div className='ic-tag' key={tagIndex} style={{background: tag?.bgColor}}>
                    {keyToIconMap(tag?.iconTag, {
                                                    strokeColor: '#FFF'
                                                })}
                    <span className='inter-semibold-12-18 plain-white'>{tag?.label}</span>
                  </div>
                ))
              }
            </div>

            <div className="ic-milestone-section">
              <div className="success-indicator"></div>
              <div className="step-info">
                <span className='inter-semibold-14-16 color-4EBB57 text-align-start'>{leadInfo?.applications?.[0]?.currentAchievedMilestone?.label}</span>
                <span className='inter-regular-12-16 color-838383 text-align-start' style={{whiteSpace: 'nowrap'}}>{leadInfo?.applications?.[0]?.currentAchievedMilestone?.date && moment(leadInfo?.applications?.[0]?.currentAchievedMilestone?.date).format('DD MMM YYYY')}</span>
              </div>
              <div className="progress-line-indicator"></div>
              <div className="inprogress-indicator"></div>
              <div className="step-info">
                <span className='inter-semibold-14-16 color-E8BC1D text-align-start'>{leadInfo?.applications?.[0]?.nextAchievableMilestone?.label}</span>
                <span className='inter-regular-12-16 color-838383 text-align-start' style={{whiteSpace: 'nowrap'}}>Next</span>
              </div>
            </div>

            <Divider style={{backgroundColor: '#E8E9EB', margin:'20px auto'}}/>

            <div className="ic-footer-section d-flex  align-items-center justify-content-between">
              <div className='d-flex align-items-center' style={{gap: '4px'}}>
                <UserOctagon />
                <span className='inter-regular-12-16 color-838383 visibility-desktop'>Assigned to: <span className='inter-semibold-14-16' style={{marginLeft: '4px'}}>{ leadInfo?.leadType === 'preReferral' ? leadInfo?.mitraName:leadInfo?.applications?.[0]?.referredMitraName}</span></span>
                <span className='inter-semibold-14-16 visibility-mobile'>{ leadInfo?.leadType === 'preReferral' ? leadInfo?.mitraName:leadInfo?.applications?.[0]?.referredMitraName}</span>
              </div>

              {
                leadInfo?.applications?.[0]?.lastCallStatusAt ? (
                  <div className='d-flex align-items-center' style={{gap: '4px'}}>
                    <div>
                      <CallIcon height={16} width={16} strokeColor='#838383'/>
                    </div>
                    <span className='inter-regular-12-16 color-838383'>Last Call: {leadInfo?.applications?.[0]?.lastCallStatusAt && moment(leadInfo?.lastCallStatusAt).format('DD MMM YYYY')}</span>
                  </div>
                ): null
              }
              
            </div>
          </div>
        </div>

        {
          openDetailsModal ? <LeadDetailsPopup 
          openDetailsModal={openDetailsModal} 
          setOpenDetailsModal={setOpenDetailsModal} 
          filters={filters}
          leadInfo={leadInfo} 
          getLeadsDashboardConfiguration={getLeadsDashboardConfiguration}
          setShowChatbox={setShowChatbox}
          setSelectedRhId={setSelectedRhId}
          setSupportConversationId={setSupportConversationId}
          supportConversationId={supportConversationId}
          mitraReducer={mitraReducer}/>: null
        }

        {
            showChatbot ? <SupportChatBot setShowChatbox={setShowChatbox} source={'candidate_popup'} conversationId={supportConversationId} sourceRhId={selectedRhId}/>: null
        }
        
    </>
  )
}

export default LeadCard