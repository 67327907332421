import React, { useContext } from 'react'
import { AppContext } from '../../App';

function Branding(
    {
        brandingLogoHeight='36px',
        brandingLogoWidth='36px',
        vahanProLogoWidth='150px',
        proLogoHeight='18px',
        proLogoWidth='36px',
        showBrandName=true,
        showProIcon=true,
        className
    }
) {
    const {mitraReducer} = useContext(AppContext);

    if(className === 'visibility-desktop' && window.innerWidth<800) return null;
  return (
    mitraReducer?.customBrandDetails?.brandName ? ( // for gig share domains, show their logo,name with pro icon
        <div className='d-flex align-items-center flex-gap-8'>
            <img 
            alt="Logo" 
            src={mitraReducer?.customBrandDetails?.logo} 
            style={{height: brandingLogoHeight,width: brandingLogoWidth,objectFit: 'contain', borderRadius: '6px', border: '1px solid rgb(255, 204, 255)'}} 
            />

            {
                showBrandName ? (
                    <span className='inter-bold-16-18 plain-black' style={{letterSpacing: '1.2px'}}>{mitraReducer?.customBrandDetails?.brandName}</span>
                ): null
            }

            {
                showProIcon ? (
                    <img 
                    alt="pro" 
                    src='/image/pro.png' 
                    style={{height: proLogoHeight,width: proLogoWidth,objectFit: 'contain'}} 
                    />
                ): null
            }
            
        </div>
    ): ( // For non-gig share domains, show vahan pro logo
        <img 
        alt="Logo" 
        src="/image/vahan-pro.png" 
        style={{width: vahanProLogoWidth}} 
        className='visibility-desktop'/>
    )
  )
}

export default Branding