import React, { useCallback, useEffect, useState, useContext, useRef, forwardRef, useImperativeHandle } from "react";
import { Formio } from "react-formio"
import { useHistory } from "react-router-dom";
import { addDetailsForMitra, getMitraInfo } from "../../service/mlOnboardingService";
import { AppContext } from '../../App';

const MLOnboarding = forwardRef(({ parent = "", reference, isFormSubmitted = () => { } }) => {
    const { mitraReducer, setSpin } = useContext(AppContext);
    const history = useHistory()
    const [mitra, setMitra] = useState()
    const [sub, setSub] = useState({
        data: {}
    })
    const formRef = useRef(null);
    const formInstanceRef = useRef(null);

    const goToHomePage = () => {
        setTimeout(() => {
            setSpin({
                loading: false,
                delay: null,
                tip: 'Uploading Onboarding Details Please Wait...'
            });
            history.replace({ pathname: "/home" });
        }, 1000);
    };

    const submitForm = async () => {
        if (formInstanceRef.current) {
            formInstanceRef.current.submit();
        }
    }

    useImperativeHandle(reference, () => ({
        submitForm,
    }));

    const options = {
        noAlerts: true,
        breadcrumbSettings: {
            clickable: false
        }
    }
    const fetchForm = useCallback(async (formURL) => {
        Formio.createForm(document.getElementById("form-io"), formURL, options)
            .then(async function (form) {
                formInstanceRef.current = form; // Store form instance in ref
                renderFormInsideAccountSection()
                form.on("change", async (submission) => {
                    const key = submission.changed ? submission.changed.component.key : undefined
                    if (key && submission.data[key] && Array.isArray(submission.data[key]) && submission.data[key].length > 0 && submission.data[key][0].data) {
                        submission.data[key][0].url = submission.data[key][0].data.imageUrl
                        form.submission = {
                            data: submission.data
                        }
                        let dummyData = {};
                        dummyData[key] = submission.data[key];
                        if (document.querySelector('.btn-wizard-nav-submit'))
                            document.querySelector('.btn-wizard-nav-submit').disabled = true;
                        let errorStatus = await validateFile({ data: dummyData });
                        if (errorStatus) {
                            const div = document.querySelector('.formio-component-' + key);
                            div.querySelector('.fa-remove').click()
                        }
                        if (document.querySelector('.btn-wizard-nav-submit'))
                            document.querySelector('.btn-wizard-nav-submit').disabled = false;
                    }
                })
                form.on("submit", async (submission) => {
                    setSpin({
                        loading: true,
                        delay: null,
                        tip: 'Uploading Onboarding Details Please Wait...'
                    });
                    const submitButtomEl = document.querySelector(".btn-wizard-nav-submit")
                    if (submitButtomEl) {
                        submitButtomEl.disabled = true;
                        submitButtomEl.insertAdjacentHTML("beforeend", `<i class="fa fa-refresh fa-spin button-icon-right"></i>`)
                    }
                    let errors = [];
                    const res = await addDetailsForMitra(submission.data, mitra);
                    if (submitButtomEl) {
                        submitButtomEl.disabled = false;
                        submitButtomEl.removeChild(submitButtomEl.lastChild)
                    }

                    if (parent == "account-page") {
                        isFormSubmitted();
                        setSpin({
                            loading: false,
                            delay: null
                        });
                    }
                    else {
                        return goToHomePage();
                    }
                })
                form.submission = sub

                setTimeout(() => {
                    document.querySelectorAll("img").forEach(el => {
                        el.addEventListener("click", (e) => {
                            window.open(e.target.getAttribute("src"))
                        })
                    })
                }, 1000)
            })
    }, [options, history, mitra])

    useEffect(() => {
        setTimeout(async () => {
            await fetchInfo()
        }, 400);

    }, [])

    const renderFormInsideAccountSection = () => {
        const submitBtn = document.querySelector('.btn-wizard-nav-submit')
        if (parent == "account-page" && submitBtn) {
            submitBtn.style.display = "none"
        }

        if (parent == "account-page" && document.querySelector('.btn-wizard-nav-submit')) {
            document.querySelector('.btn-wizard-nav-submit').style.display = "none"
        }

        setTimeout(() => {
            if (parent == "account-page" && document.querySelector(".page-link")) {
                const pageLinks = document.querySelectorAll('.page-link');
                pageLinks.forEach(link => {
                    link.style.display = 'none';
                });
            }
        }, 500)
    }

    const fetchInfo = async () => {
        const populatedRes = await getMitraInfo();
        const populatedData = {}
        if (populatedRes?.data && populatedRes?.data?.length) {
            populatedRes.data.forEach(el => {
                try {
                    const parsedValue = JSON.parse(el.value)
                    if (typeof parsedValue === "number")
                        populatedData[el.type] = `${el.value}`
                    else
                        populatedData[el.type] = [{ ...parsedValue, originalName: parsedValue.url, storage: "url" }]
                } catch (error) {
                    populatedData[el.type] = el.value
                }
            })
        }
        if (populatedData && !Array.isArray(populatedData.panCard)) {
            delete populatedData.panCard;
        }
        setSub({ data: populatedData })
    }

    const validateFile = async (data) => {
        const res = await addDetailsForMitra(data.data, mitra);
        console.log(res);
        let error = false;
        for (let key in res.data) {
            if (res.data[key] !== "200") {
                let errMsg = res.data[key].split("Error: ").join("").split("500: ").join("");
                console.log(errMsg);
                alert(`${key}: ${errMsg}`);
                error = true;
            }
        }
        return error;
    }


    useEffect(() => {
        fetchForm(process.env.REACT_APP_ML_ONBOARDING_FORM)
        setMitra(mitraReducer?.mitraInfo)
    }, [fetchForm, history])

    return (
        <div className="AdditionalInfo1 mainContentZ">
            {
                parent && parent !== "account-page"
                    ?
                    <h2 className="heading_1 add-job">
                        Onboarding Details
                    </h2>
                    :
                    parent == "" ?
                        <h2 className="heading_1 add-job">
                            Onboarding Details
                        </h2>
                        :
                        null
            }

            <div>
                {mitra && <div id="form-io" ref={formRef}></div>}
            </div>
        </div>
    )
})

MLOnboarding.displayName = 'MLOnboarding';
export default MLOnboarding;
