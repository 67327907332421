import React from "react";
import { DatePicker, Button, PageHeader, Modal, Alert } from 'antd';
import { DownOutlined, FileDoneOutlined, InfoCircleOutlined, GlobalOutlined, CloudDownloadOutlined, CloseOutlined, PhoneOutlined, UserOutlined, LinkOutlined, SearchOutlined, DownloadOutlined, ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { trackEvent } from "../../service/engagementMonitoringService";
import CONSTANTS from "../../constants/constants";
import FilterPopup from "../leads/Filter-popup.component";
import useGAEventsTracker from "../../service/useGSEventsTracker";
import BasicInfoForm from "../BasicInfoForm";
import SideSheet from "../common/SideSheet"
import {FilterOutlined, WhatsAppOutlined} from '@ant-design/icons';
import moment from "moment";
import {getActiveClientsForFilter, disabledDate, getStatusListForFilters, getRapidoDocumentStatuses, getLocationForFilter} from './../../service/commonService'
import {Tag , Space ,Typography, Skeleton, Pagination, Empty, Tabs, Result, Dropdown, Menu, notification } from 'antd';
import LocationFilterComponent from '../common/LocationFilter.component';
import * as jobDemandsService from '../../service/jobDemandsService';
import {getDisplayableClientName} from './../../utils/commonFunction'

const { RangePicker } = DatePicker;

const statusesSelected = [];
const clientSelected = [];
const getFilters = () => {
    const filterData = localStorage.getItem('filterData');
    if(filterData){
        return JSON.parse(filterData);
    }
    else return null;
}
const removeFilters = () => {
    localStorage.removeItem('filterData');
}
const JobLocations = (props) => {
    // useEffect
    const [jobDemands, setJobDemands] = React.useState([]);
    const [filterApplied, setFilterApplied] = React.useState(false);

    React.useEffect(() => {
        getLocationForFilter().then(data => {
            setLocationList(data.data.locationList)
        })
        let CityList = [];
        let ClientList = [];
		let VerticalList = [];
        let filterData  = getFilters();
        if(filterData){
            filterData.cityList.map((city) => {
                CityList.push(city);
                locationList.map((location) => {
                    if(location.label === city){
                        location.value = true;
                    }
                })
            })
            let minSalary = 0;
            let maxSalary = 100000;
            getJobDemandsOnFiltersChange(minSalary, maxSalary, CityList, ClientList, VerticalList);
        }
    }, [])
    const [_, forceUpdate] = React.useReducer((x) => x + 1, 0);
    const onClickTags=(label,index)=>{
		if(label === 'Status'){
			statusesSelected[index].value=!statusesSelected[index].value;
		}
		if(label === 'Clients'){
			clientSelected[index].value=!clientSelected[index].value;
		}
		if(label === 'Location'){
			locationList[index].value=!locationList[index].value;
		}
		forceUpdate();
	}
    React.useEffect(() => {
        let ClientList = [];
		let VerticalList = [];
		let CityList = [];
        let filterData  = getFilters();
        if(filterData){
            filterData.cityList.map((city) => {
                CityList.push(city);
                locationList.map((location) => {
                    if(location.label === city){
                        location.value = true;
                    }
                })
            })
            
        }
        if(locationList.length == 0){
            let filterData  = getFilters();
            if(filterData){
                filterData.cityList.map((city) => {
                    CityList.push(city);
                })
            }
        }
        locationList.map((city) => {
            if(city.value === true){
                CityList.push(city.label);
            }
            return null;
        })
        
		if (viewLocations) {
			validJobDemandsCities.map((city) => {
				if (city.value === true) {
					city.value = false;
				}
			});
			CityList = [];
		} else if (CityList.length > 0) {
			ClientList.push(client);
		}
        let minSalary = 0;
        let maxSalary = 100000;
        CityList = [...new Set(CityList)];
        getJobDemandsOnFiltersChange(minSalary, maxSalary, CityList, ClientList, VerticalList);
        
        setFilterApplied(false)
        removeFilters();
    },[filterApplied])
    const getJobDemandsOnFiltersChange = async (minSalary, maxSalary, city, clientList, vertical) => {
		try {
            let cityList = locationList.filter(ele => ele.value === true).map(ele => ele.label)
            if(city.length > 0){
                cityList.push(...city)
            }
            cityList = [...new Set(cityList)]
			jobDemandsService.getJobDemandsOnFiltersChange(minSalary, maxSalary, cityList, clientList, vertical).then((result) => {
				if (result && result.data.viewData.allJobDemands) {
					const jobDemands = result.data.viewData.allJobDemands;
					setJobDemands(jobDemands);
					if (jobDemands.length === 1) {
						setSelected(jobDemands[0]);
					} else if (jobDemands.length === 0) {
						setSelected();
					} else {
						jobDemands.map(ele => {
							if (ele.companyName === client) {
								setSelected(ele);
							}
						})
					}
				}
			});
		} catch (error) {
			console.log(error);
		}
	};
    const [locationList, setLocationList] = React.useState([])

    const dateFormat = 'YYYY/MM/DD';
    const handleDateRange = (dates) => {
        if(dates === null){
            setStartDate(moment().subtract(7, 'days').format('YYYY-MM-DD'));
            setEndDate(moment());
        }
        else{
            setStartDate(dates[0]);
            setEndDate(dates[1]);
        }
    }

    const [startDate, setStartDate] = React.useState(moment().subtract(7, 'days').format('YYYY-MM-DD'));

    const commonTag = (label,arrObj) =>{
        return <div className='tag-containers'>
            <div>{label}</div>
            {label === "Date Range" ?
                <Space>
                    <RangePicker
                        defaultValue={[moment(startDate, dateFormat), moment(endDate, dateFormat)]}
                        value={[moment(startDate, dateFormat), moment(endDate, dateFormat)]}
                        format={dateFormat}
                        onChange={handleDateRange}
                        ranges={{
							Today: [moment().startOf('day'), moment().endOf('day')],
                            'This Month': [moment().startOf('month'), moment().endOf('month')],
                        }}
                        disabledDate={disabledDate}
                        allowClear={false}
                        className="range-picker-leads"
                    />
                </Space> : 
                label === "Location" ? 
                <LocationFilterComponent
                    locationList={locationList}
                    onClickTags={onClickTags}
                    label={label}
                    setLocationList={setLocationList}
                />
                :
                <div className='tag-wrapper-container'>
                    {arrObj.map((item,index)=>{
                        return <Tag className={!item.value ? 'tags':'tags tag-active'}
                        key={index}
                        // closable={item.value}
                        onClick={()=>{onClickTags(label,index)}}
                        >
                            {item.label}
                        </Tag>
                    })}
                </div>
            }
            
        </div>
    }
    const clearAll=()=>{
		clientSelected.map(item => {
			item.value=false;
		})
		statusesSelected.map(item => {
			item.value=false;
		})
        locationList.map(item => {
            item.value=false;
        })
        removeFilters();
		setFilterApplied(true)
	}
    const submitFilter = () => {
        setFilterApplied(true)
    }
    const [filterSider,setfilterSider] = React.useState(false);
    const [endDate, setEndDate] = React.useState(moment().format('YYYY-MM-DD'));
    const applyFilterCondition = () => {
        let result=false;
        let arr = [statusesSelected,clientSelected,endDate];
        arr.map(item=>{
            if(item){
                for (let i = 0; i < item.length; i++) {
                    if (item[i].value === true) {
                        result=true;
                        break;
                    }
                  }
            }
            if(moment(endDate).format("YYYY-MM-DD") !== moment().format("YYYY-MM-DD")) {
                result = true;
            } 
        })
        return result;
    }
    const {
        showAlert,
        setShowAlert,
        showJoiningBonusModal,
        imageElements,
        setImageElements,
        imageKeys,
        setImageKeys,
        setHighlightedHubName,
        viewLocations,
        moreLocalities,
        jobLeadsTab,
        basicForm,
        validJobDemandsCities,
        showCityFilterPopup,
        setCityShowFilterPopup,
        setValidJobDemandsCities,
        setShowBreadcrumb,
        setViewLocations,
        setMoreLocalities,
        searchLocality,
        deleteChip,
        selected,
        setSelected,
        handleReferCandidateWithCityDetails,
        handleMoreLocalities,
        client,
        selectedLocalities,
        handleBackArrowClick,
        mitra,
        basicInfo
    } = props;
    const JobsGAEventsTracker = useGAEventsTracker("jobs");
    const filterNullAndEmptyArray = (obj) => {
        const filteredObject = {};
      
        for (const [key, value] of Object.entries(obj)) {
          if (value !== null && (!Array.isArray(value) || value.length !== 0) && !(key.toLowerCase().includes('header'))) {
            // Add the key-value pair to the filtered object if value is not null or empty array
            filteredObject[key] = value;
          }
        }
      
        return filteredObject;
      };
    const handleButtonClick = (params) => {
        let joiningbonusconstruct = params.joiningbonusconstruct;
        let allData = params.allData;
        showJoiningBonusModal();
        let joiningbonusconstructObj = JSON.parse(joiningbonusconstruct);
        joiningbonusconstructObj = filterNullAndEmptyArray(joiningbonusconstructObj);
        const imageKeys = Object.keys(joiningbonusconstructObj);
        const imageElementsTemp = imageKeys.map((key) => (
          <img key={key} src={joiningbonusconstructObj[key]} id='jb-image' alt={key} />

        ));
        setImageElements(imageElementsTemp);
        setImageKeys(imageKeys);
        setHighlightedHubName(allData.locality);
      };
      
      
    return (
        <div className="jobs-page">
            {!viewLocations && !moreLocalities && !jobLeadsTab && !basicForm ?
                <div className="header">
                    <div className="bottom-detail-wrapper">
                        {
                            validJobDemandsCities.length > 0 ?
                                <FilterPopup key="f-city-validJobDemandsCities" heading="Select City" visible={showCityFilterPopup} setShowFilterPopup={setCityShowFilterPopup} setCategory={setValidJobDemandsCities} list={validJobDemandsCities} setShowBreadcrumb={setShowBreadcrumb} />
                                : null
                        }
                    </div>
                    <div className="header-text">
                        <span className="small-size-screen" style={{cursor: 'pointer'}} onClick={(e) => {setViewLocations(true)}} ><ArrowLeftOutlined /></span>
                        <span className="big-screen">
                            <span onClick={(e) => {setViewLocations(true)}} style={{cursor: 'pointer'}}>Jobs</span> &nbsp;&nbsp;
                            <ArrowRightOutlined style={{color: '#666565'}} />&nbsp;&nbsp;
                        </span>
                        <span className="requirements-title">View Locations for {client}</span>
                    </div>
                </div>
                : (!viewLocations && moreLocalities && !jobLeadsTab && !basicForm ?
                    <div className="header">
                        <div className="header-text">
                            <span className="small-size-screen" style={{cursor: 'pointer'}} onClick={(e) => {setMoreLocalities(false)}} ><ArrowLeftOutlined /></span>
                            <span className="big-screen">
                                <span onClick={(e) => {setViewLocations(true)}} style={{cursor: 'pointer'}}>Jobs</span> &nbsp;&nbsp;
                                <ArrowRightOutlined style={{color: '#666565'}} />&nbsp;&nbsp;
                                <span onClick={(e) => {setMoreLocalities(false)}} style={{cursor: 'pointer'}}>{client} Location</span> &nbsp;&nbsp;
                                <ArrowRightOutlined style={{color: '#666565'}} />&nbsp;&nbsp;
                            </span>
                            <span className="requirements-title">Localities</span>
                        </div>
                        <div className="header-search">
                            <input className="search-input" onInput={searchLocality} type="text" placeholder="Search" />
                            <div className="search-icon">
                                <SearchOutlined />
                            </div>
                        </div>
                    </div>
                    : null)
                    }
            <div className="row" style={{background: '#F0F2F5'}}>
                {!viewLocations && !jobLeadsTab && !basicForm ?
                    <div>
                        <div className="col" style={{padding: '0', background: '#F0F2F5'}}>
                            <h6 className="allCities"><i className="fas fa-map-marker-alt"></i> &nbsp;&nbsp;All Cities</h6> </div>
                        <div className="col" style={{textAlign: 'right', padding: '10px', background: '#F0F2F5'}}>
                            {/* <div id="status-filter"
                                onClick={() => {
                                    setCityShowFilterPopup(true);
                                    JobsGAEventsTracker("ClickOnJobsCityFilter")
                                }}
                                className="Dropdown-control filter-label">
                                <div style={{margin: 'auto 0px'}}>City</div>
                                <DownOutlined style={{lineHeight: 'inherit', margin: 'auto 0px'}} />
                            </div> */}
                            { !moreLocalities && <Button style={(applyFilterCondition()) ? {color:'var(--primary-theme-color)',backgroundColor:'var(--primary-theme-light)',border:'1px solid var(--primary-theme-color)'} :null} onClick={()=>setfilterSider(true)} icon={<FilterOutlined />}>Filter Cities</Button>}
                            <div className="Chips-container">
                                {
                                    validJobDemandsCities.map((single_city,index) => {
                                        if (single_city.value) {
                                            return <div key={index} className="single-chip">
                                                <span className="chip-text">{single_city.label}</span>
                                                <span className="chip-cross" onClick={() => {deleteChip('city', single_city)}}> <CloseOutlined /> </span>
                                            </div>
                                        }
                                    })
                                }
                            </div>
                        </div>
                        {selected ?
                            <div style={{textAlign: 'left', paddingLeft: '1rem'}}>
                                <div className="row" style={{background: 'white'}}>
                                    <div className="col-5 client-logo" style={{textAlign: 'left'}}>
                                        <img className="imgResponse" alt={getDisplayableClientName(selected.companyName)} src={selected.logo} />
                                    </div>
                                    <div className="col-7" style={{textAlign: 'left'}}>
                                        <h6 className="content">{selected.jobType} - {selected.vertical}</h6>
                                        <h6>Salary - {selected.maxSalary > selected.baseSalary ? 'Rs.' + selected.baseSalary + ' - Rs.' + selected.maxSalary : 'Rs.' +
                                            selected.maxSalary}</h6>
                                    </div>
                                </div>
                            </div>
                            : null}
                    </div>
                    : null}
                {!viewLocations && !moreLocalities && !jobLeadsTab && !basicForm ?
                    (selected ? selected.demands.map((v, i) => (
                        <div key={i}><br></br><br></br>
                            <h6 className="cityHeading" style={{textAlign: 'left'}}><i className="fas fa-map-marker-alt"></i> &nbsp;&nbsp;<b className="bold">{v.city}</b> - {v.data.length} {v.data.length > '1' ? 'Localities' : 'Locality'}</h6>
                            <div className="row">
                                <div className="col" style={{background: '#F0F0F0'}}><b className="bold">{v.data[0].locality}</b></div>
                                <div className="col">Part Time <br></br> {v.data[0].requirements && v.data[0].requirements.jobPreference ? (v.data[0].requirements.jobPreference.toLowerCase() === 'part time' ? <b className="bold">{v.data[0].numOpenings}</b> : '-') : '-'}</div>
                                <div className="col">Full Time <br></br> {v.data[0].requirements && v.data[0].requirements.jobPreference ? (v.data[0].requirements.jobPreference.toLowerCase() === 'full time' ? <b className="bold">{v.data[0].numOpenings}</b> : '-') : '-'}</div>
                                <div className="col">Both Part Time and Full Time <br></br> {v.data[0].requirements && v.data[0].requirements.jobPreference ? (v.data[0].requirements.jobPreference.toLowerCase() === 'both full time and part time' ? <b className="bold">{v.data[0].numOpenings}</b> : '-') : '-'}</div>
                                <div className="col">Vehicle <br></br> <b className="bold">{v.data[0].vehicle? v.data[0].vehicle : '-'}</b></div>
                                <div className="col">Joining Bonus <br></br> <b className="bold">
                                     {v.data[0].joiningbonusconstruct?
                                    <button onClick={() => handleButtonClick({joiningbonusconstruct: v.data[0].joiningbonusconstruct, allData: v.data[0]})}><a>Click to View</a></button> : '-'}
                                    </b></div>
                                {selected && selected.companyName === 'Zomato' ?
                                <div className="col">Total OB Fee <br></br>{v.data[0].onboardingFee ? v.data[0].onboardingFee : '-'}</div>                                
                                : null }
                                {selected && selected.companyName === 'Zomato' ?                                
                                <div className="col">Upfront OB Fee <br></br>{v.data[0].upfrontFee ? v.data[0].upfrontFee : '-'}</div>
                                : null }
                                <div className="col"> <br></br> <Button onClick={() => handleReferCandidateWithCityDetails(selected?.companyName, selected?.jobType, selected?.demands[i]?.city, selected?.demands[i]?.data[0]?.locality)}>Refer Candidate</Button></div>
                            </div>
                            {v.data.length > '1' ?
                                <button className="moreLocalities" onClick={(e) => {handleMoreLocalities(v); JobsGAEventsTracker("ClickOnLocalityButton")}}>
                                    &nbsp;&nbsp;+ {v.data.length - 1} more {v.data.length - 1 > '1' ? 'localities' : 'locality'}
                                </button>
                                : null}
                        </div>
                    )) :
                        <div style={{fontSize: '1.2rem'}}>
                            <span style={{textTransform: 'capitalize'}}>{client}</span> has no jobs in selected cities
                        </div>) : (!viewLocations && moreLocalities && !jobLeadsTab && !basicForm ?
                            <div><br></br><br></br>
                                <h6 className="cityHeading" style={{textAlign: 'left'}}><i className="fas fa-map-marker-alt"></i> &nbsp;&nbsp;<b className="bold">{selectedLocalities.city}</b> - {selectedLocalities.data.length} {selectedLocalities.data.length > '1' ? 'Localities' : 'Locality'}</h6>
                                {selectedLocalities.data.map((m, n) => (
                                    <div key={n} className="row">
                                        <div className="col" style={{background: '#F0F0F0'}}><b className="bold">{m.locality}</b></div>
                                        <div className="col">Part Time <br></br> {m.requirements && m.requirements.jobPreference ? (m.requirements.jobPreference.toLowerCase() === 'part time' ? <b className="bold">{m.numOpenings}</b> : '-') : '-'}</div>
                                        <div className="col">Full Time <br></br> {m.requirements && m.requirements.jobPreference ? (m.requirements.jobPreference.toLowerCase() === 'full time' ? <b className="bold">{m.numOpenings}</b> : '-') : '-'}</div>
                                        <div className="col">Both Part Time and Full Time <br></br> {m.requirements && m.requirements.jobPreference ? (m.requirements.jobPreference.toLowerCase() === 'both full time and part time' ? <b className="bold">{m.numOpenings}</b> : '-') : '-'}</div>
                                        <div className="col">Vehicle <br></br> <b className="bold">{m.vehicle? m.vehicle : '-'}</b></div>
                                        <div className="col">Joining Bonus <br></br> <b className="bold">
                                     {m.joiningbonusconstruct?
                                    <button onClick={() => handleButtonClick({joiningbonusconstruct: m.joiningbonusconstruct, allData: m})}><a>Click to View</a></button> : '-'}
                                    </b></div>
                                        { selected && selected.companyName === 'Zomato' ?
                                        <div className="col">Total OB Fee <br></br>{m.onboardingFee ? m.onboardingFee : '-'}</div>
                                        : null }
                                        { selected && selected.companyName === 'Zomato' ?
                                        <div className="col">Upfront OB Fee <br></br>{m.upfrontFee ? m.upfrontFee : '-'}</div>
                                        : null }
                                        <div className="col"> <br></br> <Button onClick={() => handleReferCandidateWithCityDetails(selected?.companyName, selected?.jobType, selectedLocalities.city, m?.locality)}>Refer Candidate</Button></div>
                                    </div>
                                ))}
                            </div>
                            : !jobLeadsTab && basicForm ?
                                <div>
                                    <PageHeader
                                        className="site-page-header"
                                        onBack={(e) => handleBackArrowClick(e)}
                                        title="Add Candidate"
                                    />
                                    <BasicInfoForm mitra={mitra} name={basicInfo.name} phoneNumber={basicInfo.phoneNumber} />
                                </div>
                                : null)}

            </div>
            {filterSider && 
				<SideSheet submit={{disabled:false
					,onClick:submitFilter
				}}
				clearAll={{disabled:false,onClick:clearAll}}
				sidername={"Filter Candidates"} 
				onDismiss={() => {setfilterSider(false)}}>
					<div className='filter-home-wrapper'>
						{commonTag("Location", locationList)}
					</div>
				</SideSheet>
			}
        </div>
        
    )
};

export default JobLocations;
