import React from 'react'
import SectionLayout from '../common/SectionLayout.component';
import { InfoCircleOutlined, InteractionTwoTone, ProjectFilled, RocketFilled, DashboardTwoTone, DollarCircleTwoTone, RetweetOutlined, CaretRightOutlined, CaretUpOutlined, CaretDownOutlined, RightCircleTwoTone, EyeTwoTone, EyeInvisibleOutlined, ReloadOutlined, DownOutlined, DownloadOutlined, FilterOutlined, MobileOutlined } from '@ant-design/icons';
import { DatePicker, Select, Tag, Card, Skeleton, List, Typography, Button, Empty, Tooltip, Table, Modal, Input, message, Dropdown, Space, Menu, notification } from 'antd';
import Moment from 'react-moment';
import moment from 'moment';

const { Text } = Typography;

function PayoutDashboardContainer({
    displayPayoutTopSection,
    selectedDate,
    monthlyPayoutData,
    payoutProcessingBanner,
    monthlyPayoutDataBreakup,
    payoutDashboardHeadersArray,
    onExpandPayoutDashItem,
    showDownArrow,
    onClickTotalLead,
    applyFilterCondition,
    isAdmin,
    tab,
    setFilterSider,
    downloadLoading,
    onclickDownloadPerformance,
    downloadMenu,
    preventDefault,
    getDisplayableClientName,
    selectedClient,
    showDateChip,
    deleteChip,
    selectedTeamMember,
    startDate,
    endDate,
    clientFilterList,
    teamList,
    locationList,
    milestoneList
}) {
  return (
    <>
        <div className="more-filters" style={{paddingTop: '16px'}}>

            <div className="filter-set-container">

                <Button style={(applyFilterCondition()) ? { color: 'var(--primary-theme-color)', backgroundColor: 'var(--primary-theme-light)', border: '1px solid var(--primary-theme-color)' } : null} onClick={() => setFilterSider(true)} icon={<FilterOutlined />}>Apply Filters</Button>

                {/* <DatePicker value={selectedDate} picker="month" disabledDate={disabledDate} onChange={handleDateChange} defaultValue={selectedDate} format="MMM-YYYY" allowClear={false} /> */}

                {/* {tab === "performance-dashboard" && <RangePicker
                    defaultValue={[moment(startDate, dateFormat), moment(endDate, dateFormat)]}
                    format={dateFormat}
                    onChange={handleDateRange}
                    ranges={{
                        Today: [moment(), moment()],
                        'This Month': [moment().startOf('month'), moment().endOf('month')],
                    }}
                    disabledDate={disabledDate}
                    numberOfMonths={1}
                    allowClear
                />} */}

                {/* {tab === "payout-dashboard" &&
                    <DatePicker
                        onChange={handleDateRange}
                        format="MMMM YYYY"
                        allowClear={false}
                        picker="month"
                        value={selectedDate}
                        disabledDate={disabledDateForPayoutDash}
                        defaultValue={selectedDate}
                        style={{width: 150}} />
                } */}


                {/* <Select
                    className="Dropdown-control filter-label"
                    value={selectedClient === '' ? "All Clients" : selectedClient}
                    placeholder="Select Client"
                    onChange={handleClientChange}
                >
                    {clientFilterList.map((item, index) => {
                        return <Option key={"clientFilter" + index} value={item.label}>{item.label}</Option>
                    })}
                </Select> */}


                {/* {tab !== 'payout-dashboard' ? <Select
                    className="Dropdown-control filter-label"
                    value={typeOfLead}
                    placeholder="Type of leads"
                    onChange={handleTypeOfLeadsChange}
                >
                    <Option key={"Eligible Leads"} value={"Eligible Leads"}>Eligible Leads</Option>
                    <Option key={"All Leads"} value={"All Leads"}>All Leads</Option>
                </Select> : null
                } */}


                {isAdmin ?
                    <>
                        {/* {tab !== 'payout-dashboard' && <Select
                            className="Dropdown-control filter-label"
                            value={selectedTeamMember === '' ? null : selectedTeamMember}
                            placeholder="Select Team Member"
                            onChange={handleTeamMemberChange}
                        >
                            {teamList.map((item, index) => {
                                return <Option key={"teamList" + index} value={index}>{item.name}</Option>
                            })}
                        </Select>*/}

                        {
                            tab !== 'payout-dashboard' && <Button danger loading={downloadLoading} style={{ margin: '0px 10px 0px auto' }}
                                onClick={() => {
                                    onclickDownloadPerformance();
                                }}>
                                Download File
                            </Button>
                        }
                        {
                            tab === 'payout-dashboard' && <div style={{ margin: '0px 10px 0px auto' }}>
                                <Dropdown overlay={downloadMenu} >
                                    <Button danger>
                                        <Space>
                                            Download
                                            <DownOutlined />
                                        </Space>
                                    </Button>
                                </Dropdown>
                            </div>
                        }


                    </>
                    : null}

            </div>
            <div className="Chips-container">
                {
                    (tab === 'performance-dashboard' && startDate !== null && endDate !== null) ?
                        <>
                            <Tag className="single-chip" onClose={(e) => preventDefault(e, 'date', startDate)}> <Moment format="DD MMM YYYY" withTitle>{startDate}</Moment> </Tag>
                            <Text style={{ marginRight: '3px' }}>to</Text>
                            <Tag className="single-chip" onClose={(e) => preventDefault(e, 'date', endDate)}> <Moment format="DD MMM YYYY" withTitle>{endDate}</Moment> </Tag>
                        </>
                        :
                        <>
                            <Tag className="single-chip" onClose={(e) => preventDefault(e, 'date', startDate)}> <Moment format="DD MMM YYYY" withTitle>{moment(selectedDate).startOf('month')}</Moment> </Tag>
                            <Text style={{ marginRight: '3px' }}>to</Text>
                            <Tag className="single-chip" onClose={(e) => preventDefault(e, 'date', endDate)}> <Moment format="DD MMM YYYY" withTitle>{selectedDate.endOf('month')}</Moment> </Tag>
                        </>
                }
                {
                    clientFilterList?.map((single_category, index) => {
                        if (single_category?.value) {
                            return <Tag key={'tagLead' + index} className="single-chip" closable onClose={(e) => preventDefault(e, 'client', single_category?.key)}>{getDisplayableClientName(single_category.label)} </Tag>
                        }
                    })
                }

                {
                    teamList?.map((teamMember, index) => {
                        if (teamMember?.value) {
                            return <Tag key={'tagLead' + index} className="single-chip" closable onClose={(e) => preventDefault(e, 'teamMember', teamMember?.label)}>{teamMember?.label} </Tag>
                        }
                    })
                }
                {
                    [...new Set(locationList)]?.map((single_status, index) => {
                        if (single_status?.value) {
                            return <Tag key={index} className="single-chip" closable onClose={(e) => preventDefault(e, 'location', single_status)}>{single_status?.label} </Tag>
                        }
                    })
                }
                {(tab === 'performance-dashboard') &&
                    milestoneList?.map((single_category, index) => {
                        if (single_category?.value) {
                            return <Tag key={'tagLead' + index} className="single-chip" closable onClose={(e) => preventDefault(e, 'milestone', single_category?.key)}>{single_category?.label} </Tag>
                        }
                    })
                }
            </div>
        </div>

        {
        (selectedTeamMember !== 0 || selectedClient !== '' || showDateChip) ?
        <div className="Chips-container" style={{ padding: "0 20px" }}>
            {/* {
            <Tag className="single-chip" closable onClose={()=>deleteChip('month')}> {moment(startDate).format("DD-MMM-YYYY")-moment(endDate).format("DD-MMM-YYYY")}-</Tag>
        } */}
            {
                selectedTeamMember !== 0 ?
                    <Tag className="single-chip" closable onClose={() => deleteChip('teamMember')}> {teamList[selectedTeamMember].name}</Tag>
                    : null
            }
            {
                selectedClient !== '' ?
                    <Tag className="single-chip" closable onClose={() => deleteChip('client')}> {selectedClient}</Tag>
                    : null
            }
        </div>
        : null
        }

        <div className="main-container" style={{background: 'var(--primary-bg-color)'}}>
            {
                displayPayoutTopSection ?
                    <SectionLayout themeWhite={true} icon={<DollarCircleTwoTone />} title={`Total ${moment(selectedDate).format("MMMM")} Payouts`}>
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            {monthlyPayoutData.length > 0 && monthlyPayoutData?.map((item, index) => {
                                return <Card key={index} className='overall-card' style={{ marginBottom: '20px', marginRight: '14px', flex: '1 1 0', backgroundColor: (index === 3 ? '#52C41A' : null) }}>
                                    <div className='overall-header'>
                                        <Text style={{ color: (index === 3 ? 'white' : null) }} className='summary-header'>{item.name}</Text>
                                        <Tooltip title={item.tooltip}>
                                            <InfoCircleOutlined style={{ color: (index === 3 ? 'white' : null) }} />
                                        </Tooltip>
                                    </div>
                                    <div className='overall-number'>
                                        <Text className='number' style={{ fontFamily: 'var(--primary-font-family)',fontWeight:'700', color: (index === 0 ? null : index === 1 ? '#52C41A' : index === 2 ? '#FF4D4F' : '#FFFFFF') }}>
                                            {
                                                item.amount === '--' ? null : <Text style={{ fontFamily: 'var(--primary-font-family)',fontWeight:'700', color: (index === 0 ? null : index === 1 ? '#52C41A' : index === 2 ? '#FF4D4F' : '#FFFFFF') }}>{item.deduction ? '-' : (index === 3 ? '=' : null)} </Text>
                                            }
                                            {item.amount}
                                        </Text>
                                    </div>
                                </Card>
                            })}
                        </div>
                    </SectionLayout>
                    : null
            }

            <SectionLayout themeWhite={true} icon={<RetweetOutlined />} title={`${moment(selectedDate).format("MMMM")} Payout Breakup`}>
                {
                    payoutProcessingBanner && payoutProcessingBanner?.showBanner ?
                        <div className='payout-process-banner-container'>
                            <div className='payout-banner-left'>
                                <div>{payoutProcessingBanner?.bannerText}</div>
                                <div>Check back soon, Working on payout calculation.</div>
                            </div>
                            <div className='payout-banner-right'>
                                <Button className="payout-banner-button" style={{ margin: '0px 10px 0px auto' }}>
                                    Working on calculation
                                </Button>
                            </div>
                        </div>
                        :
                        null
                }

                <div className='payout-breakup-container'>
                    <div className='payout-breakup-header'>
                        {
                            monthlyPayoutDataBreakup?.data && monthlyPayoutDataBreakup?.data?.length > 0 ?
                                <table style={{ width: '100%', height: '55px' }}>
                                    <tr style={{ background: '#f0f0f0' }}>
                                        {monthlyPayoutDataBreakup?.columns?.map((item, index) => { return <th style={{ padding: '16px' }} key={index}>{item.title}</th> })}
                                    </tr>
                                    <tr style={{ height: '24px', background: 'white' }}></tr>
                                    {
                                        monthlyPayoutDataBreakup?.data?.length > 0 ?
                                            <>
                                                {
                                                    monthlyPayoutDataBreakup?.data.map((campaign, index) => {
                                                        return <>
                                                            {
                                                                campaign?.campaignName === 'Base Pay' ?
                                                                    <tr className='payout-breakup-basic-pay' >
                                                                        {
                                                                            payoutDashboardHeadersArray?.map((item, index) => {
                                                                                return <th style={{ padding: '16px' }} key={index}>
                                                                                    {item ? campaign[item] : <div onClick={() => { onExpandPayoutDashItem(campaign?.campaignName) }}> {showDownArrow(campaign.campaignName) ? <CaretUpOutlined /> : <CaretDownOutlined />} </div>}
                                                                                    {item === 'totalLeads' ? <CaretRightOutlined onClick={() => onClickTotalLead(campaign)} /> : null} </th>
                                                                            })
                                                                        }
                                                                    </tr>
                                                                    : null
                                                            }
                                                            {
                                                                campaign?.campaignName === 'Base Pay' && campaign?.expandedRows?.length > 0 && showDownArrow(campaign?.campaignName) ?
                                                                    campaign?.expandedRows.map((singleRow, index) => {
                                                                        return <tr style={{ background: 'var(--primary-theme-light)' }} key={index}>
                                                                            {
                                                                                payoutDashboardHeadersArray?.map((item, index) => {
                                                                                    return <th style={{ padding: '16px' }} key={index + 100}>
                                                                                        {item != 'campaignName' ? singleRow[item] : (singleRow['displayName'] ? singleRow['displayName'] : null || singleRow[item])}
                                                                                        {item === 'totalLeads' ? <CaretRightOutlined onClick={() => onClickTotalLead(singleRow)} /> : null}
                                                                                    </th>
                                                                                })
                                                                            }
                                                                        </tr>
                                                                    })
                                                                    : null
                                                            }
                                                            {
                                                                campaign?.campaignName === 'Additions' ?
                                                                    <tr className='payout-breakup-additions'>
                                                                        {
                                                                            payoutDashboardHeadersArray?.map((item, index) => {
                                                                                return <th style={{ padding: '16px' }} key={index}>{item ? campaign[item] : <div onClick={() => { onExpandPayoutDashItem(campaign?.campaignName) }}> {showDownArrow(campaign.campaignName) ? <CaretUpOutlined /> : <CaretDownOutlined />} </div>}</th>
                                                                            })
                                                                        }
                                                                    </tr>
                                                                    : null
                                                            }
                                                            {
                                                                campaign?.campaignName === 'Additions' && campaign?.expandedRows?.length > 0 && showDownArrow(campaign?.campaignName) ?
                                                                    campaign?.expandedRows.map((singleRow, index) => {
                                                                        return <tr style={{ background: '#f6ffed' }} key={index}>
                                                                            {
                                                                                payoutDashboardHeadersArray?.map((item, index) => {
                                                                                    return <th style={{ padding: '16px' }} key={index + 100}>{item ? singleRow[item] : null}</th>
                                                                                })
                                                                            }
                                                                        </tr>
                                                                    })
                                                                    : null
                                                            }
                                                            {
                                                                campaign?.campaignName === 'Deductions' ?
                                                                    <tr className='payout-breakup-deductions'>
                                                                        {
                                                                            payoutDashboardHeadersArray?.map((item, index) => {
                                                                                return <th style={{ padding: '16px' }} key={index}>{item ? campaign[item] : <div onClick={() => { onExpandPayoutDashItem(campaign?.campaignName) }}>{showDownArrow(campaign?.campaignName) ? <CaretUpOutlined /> : <CaretDownOutlined />} </div>}</th>
                                                                            })
                                                                        }
                                                                    </tr>
                                                                    : null
                                                            }
                                                            {
                                                                campaign?.campaignName === 'Deductions' && campaign?.expandedRows?.length > 0 && showDownArrow(campaign?.campaignName) ?
                                                                    campaign?.expandedRows.map((singleRow, index) => {
                                                                        return <tr style={{ background: '#fff1f0' }} key={index}>
                                                                            {
                                                                                payoutDashboardHeadersArray?.map((item, index) => {
                                                                                    return <th style={{ padding: '16px' }} key={index + 100}>{item ? singleRow[item] : null}</th>
                                                                                })
                                                                            }
                                                                        </tr>
                                                                    })
                                                                    : null
                                                            }
                                                            {
                                                                campaign?.campaignName === 'Total Payout' ?
                                                                    <tr className='payout-breakup-total'>
                                                                        {
                                                                            payoutDashboardHeadersArray?.map((item, index) => {
                                                                                return <th style={(item === 'campaignName' ? { padding: '16px', color: 'white', background: '#595959' } : { padding: '16px', color: 'back', background: 'white' })} key={index}>{item ? campaign[item] : null}</th>
                                                                            })
                                                                        }
                                                                    </tr>
                                                                    : null
                                                            }
                                                            <tr style={{ height: '24px', background: 'white' }}></tr>
                                                        </>
                                                    })
                                                }
                                            </>
                                            : null
                                    }
                                </table>
                                :
                                <Empty active />
                        }
                    </div>
                </div>

                {/* <Table
                    className="components-table-demo-nested"
                    columns={monthlyPayoutDataBreakup.columns}
                    scroll={{x: 320}}
                    rowClassName="row-layout"
                    expandable={{
                        expandedRowRender,
                        expandIcon: ({expanded, onExpand, record}) =>
                            record?.expandedRows?.length ? (
                                expanded ? (
                                    <CaretDownOutlined onClick={(e) => onExpand(record, e)} />
                                ) : (
                                    <CaretRightOutlined onClick={(e) => onExpand(record, e)} />
                                )
                            ) : null,
                    }}
                    dataSource={monthlyPayoutDataBreakup.data}
                    pagination={false}
                /> */}

            </SectionLayout>

            {/* {
                expiringLeads.length > 0 ?  (expiringLeads[0] !== 'No Data'? (
                    <SectionLayout themeWhite={true} icon={<ReloadOutlined twoToneColor="var(--primary-theme-color)" />} title={"Non-eligible Leads"}>
                            {
                                expiringLeads.map((lead, index)=> {
                                    return (
                                        <Tag 
                                        key={index}
                                        className='action-banner-payout-dash'>
                                            <div className='tag-description'>
                                                <InfoCircleOutlined /> {lead.key}
                                            </div>
                                            <div className='tag-leads-information'>
                                                <div>{lead.value}</div> <div style={{display:'flex', cursor:'pointer'}} onClick={() => {
                                                    onClickTotalLead(lead)
                                                }}><RightCircleTwoTone style={{marginLeft: 6}} twoToneColor="var(--primary-theme-color)" />
                                                </div>
                                            </div>
                                            <div className='tag-amount' style={{flex:'1'}}>
                                                ₹ 0
                                            </div>
                                        </Tag>
                                    )
                                    
                                })
                            }
                            
                    </SectionLayout>
                ) : null) : <Skeleton style={{padding: '20px'}} active avatar />
            } */}

        </div>
    </>
  )
}

export default PayoutDashboardContainer