import { Divider, Skeleton } from 'antd'
import React from 'react'

function LeadCardSkeleton() {
  return (
    [1,2,3,4].map(item => (
        <div key={item} className='individual-card' style={{overflow: 'hidden'}}>
        <div className="ic-content-section">

        <div className="ic-content-header">
            <div className="user-client-details">
                <Skeleton.Avatar active size={'large'}/>
            <div className="ic-user-details">
                <Skeleton.Input active size={'large'} />
            </div>
            </div>

            <div className="user-referral-details">
                <Skeleton.Input active />
            </div>
        </div>

        <div className="ic-tags-section">
            <Skeleton.Input active />
        </div>

        <div className="ic-milestone-section">
            <div className="step-info">
                <Skeleton.Input active />
            </div>
            <div className="step-info">
                <Skeleton.Input active />
            </div>
        </div>

        <Divider style={{backgroundColor: '#E8E9EB', margin:'20px auto'}}/>

        <div className="ic-footer-section d-flex  align-items-center justify-content-between">
            <div className='d-flex align-items-center' style={{gap: '4px'}}>
                <Skeleton.Input active/>
            </div>

            <div className='d-flex align-items-center' style={{gap: '4px'}}>
                <Skeleton.Input active />
            </div>
        </div>
        </div>
    </div>
    ))
  )
}

export default LeadCardSkeleton