import React from "react";
import { Card, Space, Input, Tag, Typography, Button } from "antd";
import {PhoneFilled,EditOutlined} from '@ant-design/icons';
import moment from "moment";
import { useHistory } from "react-router-dom";
import { trackEvent } from "../../../service/engagementMonitoringService";
import CONSTANTS from "../../../constants/constants";

const {TextArea} = Input;
const { Text } = Typography;

const CallHistoryCard = ({history}) => {
    const routerNavigation = useHistory();
    const editCallRemark = () => {
        trackEvent('click_add_remark_incoming_calls', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
        const callAnswered = history.receiverStatus === 'ANSWER' ? true:false;
        routerNavigation.push(`/call-status?callId=${history.callId}&&callAnswered=${callAnswered}`);
    }
    return (
        <Card bodyStyle={{padding:'16px'}} style={{marginBottom:'5px'}}>
            <Space direction='vertical' size={8} style={{ width:"100%"}}>
            <div className='call-history-header d-flex justify-content-between align-items-center'>
                <div className='call-history-date'>
                {moment(history.createdAt).format('DD MMM hh:mm A')}
                </div>
                <div className='call-duration'>
                { history.billedDuration? `Duration ${history.billedDuration} sec`: ''}
                </div>
            </div>
            
            <div className='call-history-tabs' style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                <div className="call-history-tags">
                {
                    history.callType==='incoming' ? <Tag color="blue" style={{display:'inline-flex', alignItems:'center'}}><PhoneFilled style={{color:'blue'}}/> <Text>Incoming Call</Text></Tag> :
                    history.isCallConnected ? 
                    <Tag color="green" key={1} style={{display:'inline-flex', alignItems:'center'}}>
                        <PhoneFilled style={{color:'#52C41A'}}/> <Text>Connected</Text>
                    </Tag>:
                    <Tag color="red" key={1} style={{display:'inline-flex', alignItems:'center'}}>
                       <PhoneFilled style={{color:'#F5222D'}}/> <Text>Not Connected</Text>
                    </Tag>
                }
                {history.tag ? <Tag key={2}>{history.tag}</Tag>:null}
                </div>
                
                {!history.tag && history.callType === 'incoming' ?
                <Button
                style={{color:'var(--primary-theme-color)',background:'var(--primary-theme-light)',fontSize:'12px',height:'24px', padding:'2px 5px 2px 5px'}}
                onClick={editCallRemark}
                ><EditOutlined />Edit</Button> : null}
                
            </div>
            {
               history.comment ? (<TextArea
                placeholder="Add Comment"
                autoSize={{ minRows: 2, maxRows: 6 }}
                value={history.comment}
                disabled
            /> ) : null
            }
            </Space>
        </Card>
    );
}

export default CallHistoryCard;
