import React, { useEffect, useState } from 'react'

function MessageLoadingView() {
  const [dots, setDots] = useState([
    {
      id: 0,
      isGlowing: true
    },
    {
      id: 1,
      isGlowing: false
    },
    {
      id: 2,
      isGlowing: false
    }
  ]);

  const animateLoader = () => {
    setDots((prevDots) => 
      prevDots.map((dot, idx) => ({
        ...dot,
        isGlowing: idx === (prevDots.findIndex(d => d.isGlowing) + 1) % prevDots.length,
      }))
    );     
  }

  useEffect(() => {
    const loaderInterval = setInterval(() => {
      animateLoader();
    }, 400);

    return () => {
      if(loaderInterval) {
        clearInterval(loaderInterval);
      }
    }
  }, [])
  
  return (
    <div className="message-loading message-bubble bubble-left" style={{marginBottom: '48px', padding: '8px 8px'}}>
      {dots.map((dot) => (
        <div
          key={dot.id}
          className="message-loading-dot"
          style={{ animationDelay: `${dot.id * 0.5}s` }} // Delay each dot
        ></div>
      ))}
    </div>
  )
}

export default MessageLoadingView