import * as React from "react";
const ArrowSquareRight = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={17}
    height={16}
    viewBox="0 0 17 16"
    fill="none"
    {...props}
  >
    <path
      d="M6.50016 14.6666H10.5002C13.8335 14.6666 15.1668 13.3333 15.1668 9.99992V5.99992C15.1668 2.66659 13.8335 1.33325 10.5002 1.33325H6.50016C3.16683 1.33325 1.8335 2.66659 1.8335 5.99992V9.99992C1.8335 13.3333 3.16683 14.6666 6.50016 14.6666Z"
      stroke="white"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.66016 10.3534L10.0068 8.00006L7.66016 5.64673"
      stroke="white"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default ArrowSquareRight;
