import * as React from "react";
const DiamondIcon = ({fillColor='none',height=16,width=16,strokeColor="#FFF"}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill={fillColor}
  >
    <g clipPath="url(#clip0_1067_13730)">
      <path
        d="M9.50846 13.5625L13.5625 9.50846C14.0906 8.98043 14.3545 8.71646 14.4534 8.41202C14.5405 8.14423 14.5405 7.85571 14.4534 7.58792C14.3545 7.28348 14.0906 7.01951 13.5625 6.49149L9.50846 2.43741L9.50756 2.43651C8.98013 1.90909 8.71637 1.64532 8.4121 1.54646C8.14431 1.45945 7.85579 1.45945 7.588 1.54646C7.28363 1.64536 7.0197 1.90928 6.4919 2.43708L6.49157 2.43741L2.43749 6.49149L2.43467 6.49431C1.90853 7.02045 1.64496 7.28402 1.54621 7.58792C1.4592 7.85571 1.4592 8.14423 1.54621 8.41202C1.64496 8.71592 1.90855 8.97951 2.43472 9.50569L2.43749 9.50846L6.49157 13.5625L6.4922 13.5632C7.0198 14.0908 7.28368 14.3546 7.588 14.4535C7.85579 14.5405 8.14431 14.5405 8.4121 14.4535C8.71631 14.3547 8.98004 14.091 9.50726 13.5637L9.50846 13.5625Z"
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1067_13730">
        <rect width={width} height={height} fill={strokeColor} />
      </clipPath>
    </defs>
  </svg>
);
export default DiamondIcon;
