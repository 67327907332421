import React from "react";
import {Space,Typography,Switch} from 'antd';
import { BarChartOutlined} from '@ant-design/icons';
import '../../css/sectionLayout.scss';

const { Text } = Typography;

const SectionLayout = (props) => {
    return (
        <>
            <div align="start" style={props.themeWhite ? {backgroundColor:'var(--primary-bg-color)'} : props?.style ? {...props.style}: null} className='section-card-layout'>
                <Space className="layout-header">
                    <Space>
                        {props.icon}
                        <Text strong>{props.title}</Text>
                    </Space>
                    {props.onChangeGrid && 
                    <>
                        <div className="switch-view" style={props.gridView ? {backgroundColor:'var(--primary-theme-light)'} :null}>
                            <BarChartOutlined style={{color:'var(--primary-theme-color)'}} />
                            <Text className="siwtch-name">{props.switchName}</Text>
                            <Switch defaultChecked={props.gridView} onChange={props.onChangeGrid} />
                        </div> 
                    </>}
                    { 
                        props?.datepicker && (
                            <Space>
                                {props.datepicker}
                            </Space>
                        )
                    }
                </Space>
                <div className="layout-content">
                    {props.children}
                </div>
            </div>
        </>
        
    )
}

export default SectionLayout