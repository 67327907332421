import * as React from "react";
const BookOpenIcon = ({fillColor='none',height=16,width=16,strokeColor="#838383"}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill={fillColor}
  >
    <path
      d="M8 6.53341V13.3334M8 6.53341C8 5.4133 8 4.85324 8.21799 4.42542C8.40973 4.04909 8.7158 3.74311 9.09212 3.55136C9.51995 3.33337 10.0798 3.33337 11.1999 3.33337H12.9332C13.3066 3.33337 13.4933 3.33337 13.6359 3.40604C13.7614 3.46995 13.8635 3.57195 13.9274 3.69739C14.0001 3.84 14 4.02666 14 4.40002V10.2667C14 10.6401 14.0001 10.8268 13.9274 10.9694C13.8635 11.0948 13.7614 11.1968 13.6359 11.2607C13.4933 11.3334 13.3067 11.3334 12.9333 11.3334H11.0459C10.4198 11.3334 10.1068 11.3334 9.82259 11.4197C9.57101 11.4961 9.33701 11.6213 9.13389 11.7883C8.90445 11.9769 8.73068 12.2374 8.38336 12.7583L8 13.3334M8 6.53341C8 5.4133 7.99989 4.85324 7.7819 4.42542C7.59015 4.04909 7.28444 3.74311 6.90811 3.55136C6.48029 3.33337 5.92017 3.33337 4.80007 3.33337H3.06673C2.69336 3.33337 2.50654 3.33337 2.36393 3.40604C2.23849 3.46995 2.13658 3.57195 2.07266 3.69739C2 3.84 2 4.02666 2 4.40002V10.2667C2 10.6401 2 10.8268 2.07266 10.9694C2.13658 11.0948 2.23849 11.1968 2.36393 11.2607C2.50654 11.3334 2.69336 11.3334 3.06673 11.3334H4.95414C5.58029 11.3334 5.89331 11.3334 6.17749 11.4197C6.42907 11.4961 6.66309 11.6213 6.86621 11.7883C7.09565 11.9769 7.26922 12.2374 7.61654 12.7583L8 13.3334"
      stroke={strokeColor}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default BookOpenIcon;
