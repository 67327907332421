import React, {useReducer, useState} from 'react';
import {useHistory} from 'react-router-dom';
import '../../css/lead.scss';
import trackingConstants from '../../constants/trackingConstants';
import {AdditionalFormsURLEnum} from '../../utils/utility';
// import Modal from 'react-modal';
import useGAEventsTracker from '../../service/useGSEventsTracker';
import moment from "moment";
// import useMoengage from '../../hooks/useMoengage';
import { trackEvent } from '../../service/engagementMonitoringService';
import CONSTANTS from '../../constants/constants';
import {getAllStatuses} from './../../service/reportService'
import { InfoCircleOutlined, PhoneOutlined, UploadOutlined, ArrowRightOutlined } from '@ant-design/icons';




const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};


// Modal.setAppElement('#root')

const LeadCard = ({lead, setShowDetail, setSelectedLead, fromSearch, setSpin}) => {

  const GAEventsTracker = useGAEventsTracker("Action buttons clicked")

  const history = useHistory();
  const documents = ['Aadhar', 'PAN', 'RC', 'Bank account'];
  // const [createMoengageUser, trackEvent] = useMoengage();
  // const handleOpenAndAddDocuments = async () => {
  //   if (lead && lead.id && lead.mitraId) {
  //     logEvent(
  //       'ClickDocumentsPage',
  //       trackingConstants.EVENT_GROUP_VIEW_LEAD,
  //       fromSearch
  //         ? trackingConstants.EVENT_SCREEN_SEARCH
  //         : trackingConstants.EVENT_SCREEN_CANDIDATE
  //     );
  //     window.open(process.env.REACT_APP_JF1_BASE_URL + `/documents?l=${lead.id}&m=${lead.mitraId}`);
  //   } else {
  //     console.log('Lead and Mitra id not found');
  //   }
  // };
  // const openForm = (lead) => {
  // localStorage.setItem('lead', JSON.stringify(lead))
  // setDynamicForm(true);
  // };

  // const checkClientAdditionalForm = (clientName) => {
  //   return clientName in AdditionalFormsURLEnum;
  // };

  const diffBetweenTwoDates = (first, last) => {
    const diff = last.diff(first, "days")
    if (diff < 10) {
      return `0${diff}`
    }
    else return diff;
  }

  const checkAtRisk = (lead) => {
    let data = false
    if (lead && lead.latestMilestone) {
      const DateObj = new Date();
      const differnceInDates = (DateObj.getTime() - Date.parse(lead.activatedAt)) / (1000 * 3600 * 24);
      if (differnceInDates >= 7 && differnceInDates <= 14 && lead.latestMilestone === 'activation_date' && lead.companyName && (lead.companyName.toLowerCase() === "shadowfax" || lead.companyName.toLowerCase() === "shadowfax_3pl")) {
        data = true
      }
    }
    return data
  }

  const mapStatusToCompany = (companyName, status) => {
    if (status !== null && companyName !== null) {
      if(status.toLowerCase() === 'marked_unique'){
        return 'Candidate Unique'
      }
      else if(status.toLowerCase() === 'marked_non_unique'){
        return 'Candidate Not Unique'
      }
      else if(status.toLowerCase() === 'activation_date'){
        return 'Activated'
      }
      else if (status.toLowerCase() === 'first_date_of_work'){
        return '1st Trip Completed'
      }
      else if (status.toLowerCase() === '10th_order_date'){
        return '10th Trip Completed'
      } 
      else if (status.toLowerCase() === 'signup_date'){
        return 'App Downloaded'
      } 
      else if (status.toLowerCase() === 'waiting for client data'){
        return 'Waiting for client data'
      }
      else {
        return 'Application Created'
      }
    }
    else {
      return "Application Created";
    }
  }
  const viewAdditionalForm = (lead) => {
    localStorage.setItem('lead', JSON.stringify(lead))
    if(lead?.companyName?.toLowerCase().indexOf('zomato') !== -1) {
      history.push({
        pathname: '/zomato-additional-form',
        route: !lead.scheduledInterviewId ? 'jobRecommendationPage' : null,
        search:new URLSearchParams(lead).toString()
      })
    } else if(lead?.companyName?.toLowerCase() === 'blinkit') {
      history.push({
        pathname: '/blinkit-additional-form',
        route: !lead.scheduledInterviewId ? 'jobRecommendationPage' : null,
        search:new URLSearchParams(lead).toString()
      })
    } else if (lead?.companyName?.toLowerCase() === 'blinkit darkstore') {
      history.push({
        pathname: '/blinkit-darkstore-additional-form',
        route: !lead.scheduledInterviewId ? 'jobRecommendationPage' : null,
        search:new URLSearchParams(lead).toString()
      })
    } else if(lead?.companyName?.toLowerCase().indexOf('swiggy soc') !== -1) {
      history.push({
        pathname: '/swiggy-soc-additional-form',
        route: !lead.scheduledInterviewId ? 'jobRecommendationPage' : null,
        search:new URLSearchParams(lead).toString()
      })
    } else if (lead?.companyName?.toLowerCase() === 'zepto') {
      history.push({
        pathname: '/zepto-additional-form',
        route: !lead.scheduledInterviewId ? 'jobRecommendationPage' : null,
        search:new URLSearchParams(lead).toString()
      })
    } else if(lead?.companyName?.toLowerCase().indexOf('swiggy') !== -1) {
      history.push({
        pathname: '/swiggy-additional-form',
        route: !lead.scheduledInterviewId ? 'jobRecommendationPage' : null,
        search:new URLSearchParams(lead).toString()
      })
    } else {
      history.push({
        pathname: '/moreInfo',
        route: !lead.scheduledInterviewId ? 'jobRecommendationPage' : null,
        search:new URLSearchParams(lead).toString()
      })
    }
    
  }

  const trackViewDetails = () => {
    trackEvent('view_details_button_clicked', {},CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
  }

  const viewDetailsHandler = () => {
    // getAllStatuses({scheduleInterviewId: lead.scheduledInterviewId, companyName: lead.companyName})
    //   .then(response => {
    //     const newLeadData = {
    //       name: lead.firstName,
    //       phoneNumber: lead.phoneNumber,
    //       userId: lead.userId,
    //       jobs: [
    //         {status: response.data.status.length !== 0 ? response.data.status : [{title: null, StatusTimeStamp: null}], companyName: lead.companyName, createdAt: lead.createdAt}
    //       ]
    //     }
        setShowDetail(true);
        setSelectedLead({recommendationHistoriesId: [lead.recommendationHistoryId], mitraName: lead.mitraName, id: lead.id})
      //   setSpin({
      //     loading: false, //false or true when actived
      //     delay: null,
      //     tip: 'Loading...'  //Loading copy here
      //   })
      //   setSelectedLead(newLeadData)
      // })

  }

  return (
    <div className="detail-body-container">
      <div className="detail-element-body">
        <div className="card-container">
          <div style={{width: '80%'}}>
            <div className="contact-details-container">
              <div className="contact-details">
                <div className="lead-name">
                  {lead.firstName}
                </div>
                <div className="phone-container" onClick={(e) => {
                    viewDetailsHandler()
                  // logEvent('ShowDetails', trackingConstants.EVENT_GROUP_VIEW_LEAD, fromSearch? trackingConstants.EVENT_SCREEN_SEARCH : trackingConstants.EVENT_SCREEN_CANDIDATE);
                    e.stopPropagation();
                }}>
                  <PhoneOutlined style={{verticalAlign: 'baseline'}} />
                  <div className="phoneNumber">{lead.phoneNumber}</div>
                </div>
              </div>
            </div>
            <div className="job-container">
              {
                // lead ? lead.jobs ?
                // lead.jobs.map((job, index) => (
                <div className="single-job-container">
                  <div className="client-logo">
                    <img className="imgResponse" src={lead.logo} />
                  </div>
                  {
                    !isNaN(diffBetweenTwoDates(moment(lead.activatedAt), moment()))
                      ?
                      <div className="onboarding-schedule-report">
                        <div className="diffInDate">
                          Last Updated: {diffBetweenTwoDates(moment(lead.activatedAt), moment())} days ago.
                        </div>
                        <div className="leadsCardStatus d-flex flex-row" style={{color: 'var(--primary-theme-color)'}}>
                          {mapStatusToCompany(lead.companyName, lead.latestMilestone)}
                          {
                            // index == lead.jobs.length - 1 && AdditionalFormsURLEnum[job.companyName] && !job.scheduledInterviewId ? <>
                            //   <span className="complete-application-button upload-Icon" style={{background: 'rgb(211, 70, 18)', color: '#fff', 'marginRight': '5px'}} onClick={() => {viewAdditionalForm(job)}}>
                            //     <UploadOutlined />
                            //     <span className="btntext">Complete Application</span>
                            //   </span>
                            // </>
                            //   : null
                          }
                        </div>
                      </div>
                      :
                      <div className="onboarding-schedule">
                        <div className="diffInDate">
                          Last Updated: {diffBetweenTwoDates(moment(lead.activatedAt), moment())} days ago.
                        </div>
                        <div className="leadsCardStatus d-flex flex-row" style={{color: 'var(--primary-theme-color)'}}>
                          Application Created
                          {
                            // index == lead.jobs.length - 1 && AdditionalFormsURLEnum[job.companyName] && !job.scheduledInterviewId ? <>
                            //   <span className="complete-application-button upload-Icon" onClick={() => {viewAdditionalForm(job)}} style={{background: 'rgb(211, 70, 18)', color: '#fff'}}>
                            //     <UploadOutlined />
                            //     <span className="btntext">Complete Application</span>
                            //   </span>
                            // </>
                            //   : null
                          }
                        </div>
                      </div>
                  }
                </div>
                // ))
                //   : null : null
              }
            </div>
          </div>
          <div className="user-image-view-detail" style={{width: '20%'}}>
            <div className="user-image-container">
              <div className="user-image">
                {lead.firstName.charAt(0).toUpperCase()}
                {
                  checkAtRisk(lead) ? <div className="counter counter-lg"> <InfoCircleOutlined /> </div> : null
                }
              </div>
            </div>
            <div className="d-flex justify-content-end" style={{marginTop: 'auto'}}>
              {/* {
              lead.jobs.length && AdditionalFormsURLEnum[lead.jobs[lead.jobs.length - 1].companyName] && !lead.jobs[lead.jobs.length - 1 ].scheduledInterviewId? <div onClick={() => {viewAdditionalForm(lead.jobs[lead.jobs.length - 1])}} className="complete-application-button upload-Icon-mobile" style={{background: 'rgb(211, 70, 18)', color: '#fff'}}>
                <UploadOutlined />
              </div> : null */}


              <div className="view-details-container"
                onClick={(e) => {
                  // setShowDetail(true);
                  // setSpin({
                  //   loading: true, //false or true when actived
                  //   delay: null,
                  //   tip: 'Loading...'  //Loading copy here
                  // })
                  viewDetailsHandler()
                  // logEvent('ShowDetails', trackingConstants.EVENT_GROUP_VIEW_LEAD, fromSearch? trackingConstants.EVENT_SCREEN_SEARCH : trackingConstants.EVENT_SCREEN_CANDIDATE);
                  // setSelectedLead(lead);
                  GAEventsTracker('OpenViewDetails')
                  e.stopPropagation();
                }}>
                <ArrowRightOutlined />
                <button className="view-details-button" onClick={trackViewDetails}>View Details</button>
              </div>
            </div>

          </div>

        </div>

      </div>
      <div>
        {lead.createdAtForSearch ? (
          <div className="lead-added">Added - {lead.createdAtForSearch}</div>
        ) : null}
      </div>
    </div>
  );
};
export default LeadCard;
