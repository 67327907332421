import * as React from "react";
const HomeIcon = ({fillColor='none',height=16,width=16,strokeColor="#838383"}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill={fillColor}
  >
    <path
      d="M8 12V10"
      stroke={strokeColor}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.71318 1.87998L2.09318 5.57998C1.57318 5.99331 1.23985 6.86665 1.35318 7.51998L2.23985 12.8266C2.39985 13.7733 3.30652 14.54 4.26652 14.54H11.7332C12.6865 14.54 13.5999 13.7666 13.7599 12.8266L14.6465 7.51998C14.7532 6.86665 14.4199 5.99331 13.9065 5.57998L9.28652 1.88665C8.57318 1.31331 7.41985 1.31331 6.71318 1.87998Z"
      stroke={strokeColor}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default HomeIcon;
