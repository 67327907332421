import React, { useContext, useEffect, useRef, useState } from 'react'
import './LeadsV2MainContainerStyles.scss'
import FilterSection from './FilterSection';
import LeadCard from './components/LeadCard';
import { Button, Checkbox, Empty, Input, Modal, notification, Skeleton } from 'antd';
import PlusIcon from '../../static/svg/PlusIcon';
import AddViewModal from './components/AddViewModal';
import BulkReassignmentModal from './components/BulkReassignmentModal';
import { getCandidateListV2, getLeadsPageConfiguration, saveEditViews } from '../../service/LeadsPageV2Service';
import { AppContext } from '../../App';
import CONSTANTS from '../../constants/constants';
import LeadCardSkeleton from './skeletons/LeadCardSkeleton';
import useDebouncedValue from '../../hooks/useDebouncedValue';
import moment from 'moment';
import ScrollToTopButton from '../SamvaadiniV2/components/ScrollToTopButton';

const TYPE_OF_LEADS = CONSTANTS.TYPE_OF_LEADS;
function LeadsV2MainContainer() {

  const {mitraReducer} = useContext(AppContext);
  const [viewsArray, setViewsArray] = useState([]);
  const scrollContainerRef = useRef(null);

  const [chosenFilters, setChosenFilters] = useState({});
  const debouncedFilters = useDebouncedValue(chosenFilters, 1000);

  const [totalCandidatesCount, setTotalCandidatesCount] = useState(0);

  const [leadCardList, setLeadCardList] = useState([]);
  const [leadCardLoading, setLeadCardLoading] = useState(true);

  const [openReassignmentModal, setOpenReassignmentModal] = useState(false);
  const [openAddViewModal, setOpenAddViewModal] = useState(false);

  const [selectedView, setSelectedView] = useState({});

  const onViewSelection = (view, idx) => {
    // Marking the selected view as Active and others as inActive
    const modifiedViewsArray = [...viewsArray];

    for (let i = 0; i < modifiedViewsArray.length; i++) {
      if(i === idx) {
        modifiedViewsArray[i].isActive = true;
        setSelectedView(modifiedViewsArray[i]);
      } else {
        modifiedViewsArray[i].isActive = false;
      }
    }

    setViewsArray([...modifiedViewsArray]);
  }

const getLeadsDashboardConfiguration = () => {
  getLeadsPageConfiguration({mitraId: mitraReducer?.mitraInfo?.id})
  .then(res=> {
    if(res?.data?.data?.length) {
      const listOfViews = JSON.parse(JSON.stringify([...res?.data?.data]));
      listOfViews[0].isActive = true;
      setViewsArray([...listOfViews]);
      setSelectedView(listOfViews?.[0]);
    } else {
      setViewsArray([]);
      setSelectedView({});
    }
  }).catch(err=> {
    console.log(err, 'Error Config Leads Page');
  })
};

const submitViewsJson = (viewId, subViewIndex, subView) => {
  const selectedView = viewsArray.find(item=>item.id === viewId);

  selectedView.subViews[subViewIndex] = subView;

  const payload = {
    mitraId: mitraReducer?.mitraInfo?.id,
    data: {
      "type": CONSTANTS.CONFIG_TYPE.LEADS_PAGE,
      "view": selectedView?.label?.toLowerCase().trim().replaceAll(' ', '_'),
      "subViews": selectedView?.subViews,
      "label": selectedView?.label,
      "functionalityType": CONSTANTS.VIEW_SUBMISSION_TYPE.UPDATE
    }
  }
  
  saveEditViews(payload)
  .then(res=> {
    if(!res?.data?.status) {
      notification['error'] ({
        message: res?.data?.message
      })
    } else {
      notification['success'] ({
        message: res?.data?.message
      });

      getLeadsDashboardConfiguration();
    }
  }).catch(err=> {
    console.log(err);
  })
  
}

const getLeadList = () => {
  if(Object.keys(debouncedFilters).length && mitraReducer?.mitraInfo?.id) {
    setLeadCardLoading(true);

   getCandidateListV2(debouncedFilters,mitraReducer?.mitraInfo?.id)
   .then(res=> {
    setTotalCandidatesCount((res?.data?.totalRecords && parseInt(res?.data?.totalRecords)) || 0);
    if(res?.data?.data?.length) {
      setLeadCardList([...res?.data?.data]);
    } else {
      setLeadCardList([]);
    }

    setLeadCardLoading(false);
   }).catch(err => {
    console.log(err);
   })
  }
}

useEffect(() => {
  if(mitraReducer?.mitraInfo?.id) {
    getLeadsDashboardConfiguration();
  }
}, [mitraReducer?.mitraInfo?.id])

  useEffect(() => {

    if(selectedView?.subViews?.length) {
      for (let index = 0; index < selectedView?.subViews.length; index++) {
        if(selectedView?.subViews?.[index].isActive && selectedView?.subViews?.[index].key === 'default_uploaded') {
          debouncedFilters.typeOfLeads = TYPE_OF_LEADS.PRE_REFERRAL;
          break;
        }
        debouncedFilters.typeOfLeads = TYPE_OF_LEADS.REFERRED;
      }
    }

    console.log(debouncedFilters, 'Debounced filters');
    getLeadList();
  }, [debouncedFilters]);

  useEffect(() => {
    setLeadCardLoading(true);
  }, [chosenFilters])


  return (
    <>
      <div className='vahan-pro-component-container' ref={scrollContainerRef}>
        <span className='unbounded-semibold-24-28 plain-black hero-header'>Leads</span>

        <div className="views-container">
            <div className="views">

                {
                  viewsArray?.length ? (
                    viewsArray?.map((filter, idx) => (
                      <div 
                      key={idx} 
                      style={{maxWidth: '100px'}}
                      className={`text-ellipsis single-view ${filter.isActive ? 'active-view': ''}`} 
                      onClick={()=>onViewSelection(filter, idx)}
                      >
                        {filter.label}
                      </div>
                    ))
                  ): <Skeleton.Input active size={'large'} />
                }

                {
                  viewsArray.length >= 5 ? null: (
                    <div 
                      onClick={()=>setOpenAddViewModal(true)}
                      className='d-flex align-items-center cursor-pointer'
                      >
                        <PlusIcon height={20} width={20}/>
                        <span className='inter-semibold-12-18 color-1D5FFF'>Add View</span>
                    </div>
                  )
                }
                
            </div>
        </div>
        
        {
          selectedView ? (
          <FilterSection 
          selectedViewDetails={selectedView} 
          totalCandidatesCount={totalCandidatesCount}
          setChosenFilters={setChosenFilters}
          chosenFilters={chosenFilters}
          getLeadsDashboardConfiguration={getLeadsDashboardConfiguration}
          setOpenReassignmentModal={setOpenReassignmentModal}
          submitViewsJson={submitViewsJson}/>
        ): null
        }

        <div className="leads-card-container">

          {
            leadCardLoading ? <LeadCardSkeleton />:
            !leadCardList?.length ? (
                <div className='d-flex align-items-center justify-content-center w-100 flex-column'>
                  <Empty description=''/>
                  <span className='inter-medium-14-20'>No Candidates Found</span>
                </div>
            ): (leadCardList.map((item, index)=>(
              <LeadCard
              key={index} 
              leadInfo={item}
              filters={debouncedFilters}
              mitraReducer={mitraReducer}
              getLeadsDashboardConfiguration={getLeadsDashboardConfiguration}
              />
            )))
          }
        </div>

        <div className="leads-card-main-container"></div>
      </div>

      <ScrollToTopButton scrollContainerRef={scrollContainerRef}/>

      {/* Modal for bulk reassignment */}

      {
        openReassignmentModal ? (
          <BulkReassignmentModal 
          openReassignmentModal={openReassignmentModal} 
          filters={debouncedFilters}
          totalCandidatesCount={totalCandidatesCount}
          getLeadsDashboardConfiguration={getLeadsDashboardConfiguration}
          setOpenReassignmentModal={setOpenReassignmentModal} />
        ): null
      }

      {/* Modal to add a new View */}
      {
        openAddViewModal ? (
          <AddViewModal 
          getLeadsDashboardConfiguration={getLeadsDashboardConfiguration}
          openAddViewModal={openAddViewModal} 
          setOpenAddViewModal={setOpenAddViewModal}/>
        ): null
      }

    </>
  )
}

export default LeadsV2MainContainer