import moment from "moment";
import CONSTANTS from "./constants";
import DangerIcon from "../static/svg/DangerIcon";
import React from "react";
import CheckIcon from "../static/svg/CheckIcon";
import CrossIcon from "../static/svg/CrossIcon";
import DiamondIcon from "../static/svg/DiamondIcon";
import StarIcon from "../static/svg/StarIcon";
import ClockIcon from "../static/svg/ClockIcon";
import FileEditIcon from "../static/svg/FileEdit";
import DartIcon from "../static/svg/DartIcon";
import AndroidMobileIcon from "../static/svg/AndroidMobileIcon";

export const rollOutCallHistory = (mitraInfo) => {

    const applicableIds = [
    // 'ecd3bb3f-dd53-45ae-a89a-7e0f3aeecbdc',
    // 'dab2c349-6730-4cbb-8211-65d1b296da37',
    // '5b141fa5-cf56-4aa1-a3d2-cfdd26f05a48',
    // '3346e261-8eda-488a-b78a-4cf16df8678c',
    // '88a200d1-42fe-4493-a58d-7b98029473c9',
    // 'b269a7a0-df59-4512-9a61-87293b2f0b07',
    // '59647eb8-ecf2-424f-978e-61ad63cecaf3'
    ];

    // if (applicableIds.indexOf(mitraInfo.id) > -1 || applicableIds.indexOf(mitraInfo.managerMitraID) > -1){
    //     return true;
    // } 
    // else {
    //     return false;
    // }
    
    //disabling kaleyra for all 
    return false;
};

// export const hideJobCategoryField = (mitraInfo) => {

//     const applicableIds = [
//         // 'da8c578e-c234-4e55-9c06-4ecb7dd0f3f6',
//         // '88a200d1-42fe-4493-a58d-7b98029473c9',
//         // '09821adf-81ba-4b07-9878-c54ac6e72a8d',
//         // 'f75dc377-ba49-4889-adda-1848a8ccff6d',
//         // 'b269a7a0-df59-4512-9a61-87293b2f0b07',
//             '36e3e751-1b3c-4abf-8551-ee250055c015'
//     ];
 
//     if (applicableIds.indexOf(mitraInfo.id) > -1 || applicableIds.indexOf(mitraInfo.managerMitraID) > -1){
//         return true;
//     } 
//     else {
//         return false;
//     }
// };
export const rollOutConversationHistory = (mitraInfo) => {
    const applicableIds = [
        '10bdda2e-5c47-41ab-851a-bb7924d35039',
        '09821adf-81ba-4b07-9878-c54ac6e72a8d',
        'da8c578e-c234-4e55-9c06-4ecb7dd0f3f6',
        '85351f6e-b4fa-42c6-8913-c9580eebd904',
        'b269a7a0-df59-4512-9a61-87293b2f0b07',
        '32d63c6a-39c0-4a0f-b5cd-88ced89afeec',
        '133fbf3e-8fd9-47d0-9e13-a360301a1a1e',
        '6694dccc-3a01-4d6b-b2b1-82a8f66d72a5'
    ];

    return (applicableIds.indexOf(mitraInfo.id) > -1 || applicableIds.indexOf(mitraInfo.managerMitraID) > -1);
};

export const rollOutNewSupportForm = (mitraInfo) => {
    const applicableIds = [
        'da8c578e-c234-4e55-9c06-4ecb7dd0f3f6',
        '2dbfebb5-0756-40c9-a41f-86b49858a084',  
        '365bcb8c-417f-4375-938a-fcde74fd00af',
        '92206b52-3c8c-4adf-993c-ec9a1fd6224a',      
        'ecd3bb3f-dd53-45ae-a89a-7e0f3aeecbdc',   

        // testing
        '36e3e751-1b3c-4abf-8551-ee250055c015',
        '6694dccc-3a01-4d6b-b2b1-82a8f66d72a5'
    ];

    // for above mitra, show new support form
    // return (applicableIds.indexOf(mitraInfo.id) > -1 || applicableIds.indexOf(mitraInfo.managerMitraID) > -1);

    // for all mitra, show new support form
    return true;
};

export const jobseekerApplicationInML = (mitraInfo) => {
    const applicableIds = [
        'c4a9f396-1c69-46b6-ad85-1d373c6b1a7d',

        // testing
        // '36e3e751-1b3c-4abf-8551-ee250055c015',
    ];

    return (applicableIds.indexOf(mitraInfo?.id) > -1 || applicableIds.indexOf(mitraInfo?.managerMitraID) > -1);
};

export const suiteCrmMLView = (mitraInfo) => {
    const applicableIds = [
        'c4a9f396-1c69-46b6-ad85-1d373c6b1a7d'
    ];

    return applicableIds.indexOf(mitraInfo) > -1;
};

export const aBTestingRollOut = (mitraInfo) => {
    const applicableIds = [
        '36e3e751-1b3c-4abf-8551-ee250055c015',
    ];

    return (applicableIds.indexOf(mitraInfo?.id) > -1 || applicableIds.indexOf(mitraInfo?.managerMitraID) > -1);
};

export const rollOutRecruitmentMilestoneVisibility = (mitraInfo) => {
    const applicableIds = [   
        '3904f9e3-764d-4d25-b5ca-c48a3ef0aa08',   
        '2c261d60-7459-4315-81a9-0c238557bc81',
        'a4861a32-c2a2-4a3a-a081-5bea31de9899',
        'ab9697d3-a5b0-4fdc-bc73-ab71177235ad',        
        '2dbfebb5-0756-40c9-a41f-86b49858a084',
        'b269a7a0-df59-4512-9a61-87293b2f0b07',
        'e16729f5-acc8-4c07-9d07-fef9a82bb6ac',
        '7f99a9fe-61ac-4b3e-ab75-23d13b6334d8',

        // testing
        '36e3e751-1b3c-4abf-8551-ee250055c015',
        '76ed34fe-9616-45d2-b071-57868c4ad168'
    ];
    //initially selctive rollout
    // return (applicableIds.indexOf(mitraInfo.id) > -1 || applicableIds.indexOf(mitraInfo.managerMitraID) > -1);
    //roll out for all mitras   
    return true
};

export const rollOutStaffingMilestoneVisibility = (mitraInfo) => {
    const applicableIds = [   
        'efe3061d-ff90-4b22-9023-22300d9308cb',   
        'e63339e8-9454-4766-972c-f0f60b6166ac',
        '882bd640-fdeb-4595-bcee-3c8377896f22',
        'cb060e1f-f31c-493e-a4ec-ed570944815f',        
        '5c23e2c0-4506-4957-8b99-ba4fd21edc71',
        'e16729f5-acc8-4c07-9d07-fef9a82bb6ac',
        '7f99a9fe-61ac-4b3e-ab75-23d13b6334d8',
        'eb862705-4fea-47a2-8a56-a40b1316cf9f',
        'ab9697d3-a5b0-4fdc-bc73-ab71177235ad',
        '09821adf-81ba-4b07-9878-c54ac6e72a8d',
        '63236cab-f671-4413-a71e-ed4927a05856',
        'eb94a771-667b-459d-b543-aa82062c76d8',
        '2dbfebb5-0756-40c9-a41f-86b49858a084',
        'c4a9f396-1c69-46b6-ad85-1d373c6b1a7d',
        '102642ad-9799-4801-83d5-4dfc1e0eb0f2',

        // testing
        '36e3e751-1b3c-4abf-8551-ee250055c015',
        '76ed34fe-9616-45d2-b071-57868c4ad168'
    ];

    //initially selctive rollout
    // return (applicableIds.indexOf(mitraInfo.id) > -1 || applicableIds.indexOf(mitraInfo.managerMitraID) > -1);
    //roll out for all mitras   
    return true;
};

export const whatsappFeatureEnabledMitras = (mitraInfo) => {
    const applicableIds = CONSTANTS.crmExceptionMitras;
    return mitraInfo && applicableIds.indexOf(mitraInfo.id) > -1;
}


export const getMenuKeyFromUrlPathname = (pathname) => {
    const urlPathnameToMenuKeyMap = {
        '/home': 'HOME_PAGE',
        '/add-single-candidate': 'HOME_PAGE',
        '/bulk-actions': 'HOME_PAGE',
        '/bulk-referral': 'HOME_PAGE',
        '/recruiter-candidates': 'LEADS',
        '/leads/action-needed-candidates': 'LEADS',
        '/leads/all-candidates': 'LEADS',
        '/leads-dashboard': 'LEADS',
        '/samvadini': 'SAMVADINI',
        '/samvadini-processed-list': 'SAMVADINI',
        '/job-demands/job-requirements': 'JOB_DEMANDS',
        '/job-demands/leads-from-posters': 'JOB_DEMANDS',
        '/report/performance-dashboard': 'PERFORMANCE_DASH',
        '/leads-page': 'PERFORMANCE_DASH',
        '/report/payout-dashboard': 'PAYOUT_DASH',
        '/incentives': 'INCENTIVES',
        '/notification': 'NOTIFICATION',
        '/training-hub': 'TRAINING_HUB',
        '/refer-and-earn': 'REFER_AND_EARN',
        '/account': 'ACCOUNT',
        '/help': 'HELP',
        '/help-lp-auto': 'HELP',
        '/download-hotline-app': 'HOTLINE',
        '/samvadini-v2': 'SAMVADINI',
        '/samvadini-session-list': 'SAMVADINI',
        '/samvadini-session-details': 'SAMVADINI',
        '/samvadini-advance-filter': 'SAMVADINI',
        '/samvadini-smart-logic': 'SAMVADINI',
        '/samvadini-file-upload': 'SAMVADINI',
        '/telecallerDashboard': 'TC_DASHBOARD'
    };

    return urlPathnameToMenuKeyMap[pathname] || null; 
}

export const returnSamvaadiniFilteredLeadsBgColor = (index) => {
    const color1 = '#FFF5EC';
    const color2 = '#CCE5E2';
    let selectedColor = '';
    const patternIndex = Math.floor(index / 4) * 4;
    if ([patternIndex, patternIndex + 3, patternIndex + 4].includes(index)) {
        selectedColor = color1;
    } else {
        selectedColor = color2;
    }

    return selectedColor;
}

export const largeNumberSuffix = (val) => {
    if (val >= 10000000) {
      return 'Cr';
    } else if (val >= 100000) {
      return 'L';
    } else if (val >= 1000) {
      return 'k';
    } else {
      return '';
    }
  };

  export const formatLargeNumbers = (val) => {
    const reviews = parseInt(val);
  
    if (reviews >= 10000000) {
      return (reviews/10000000).toFixed(0);
    } else if (reviews >= 100000) {
      return (reviews/100000).toFixed(0);
    } else if (reviews >= 1000) {
      return (reviews/1000).toFixed(0);
    } else {
      return reviews;
    }
  };

  export const getTime = (createdDate) => {
    if(!createdDate) return '';
    if (moment().diff(moment(createdDate).utc(),"days")){
        return `${moment().diff(moment(createdDate).utc().subtract(330, 'minutes'),"days")}d ago`;
    } else if(moment().diff(moment(createdDate).utc().subtract(330, 'minutes'),"hours")){
        return `${moment().diff(moment(createdDate).utc().subtract(330, 'minutes'),"hours")}hrs ago`;
    } else {
        return `${moment().diff(moment(createdDate).utc().subtract(330, 'minutes'),"minutes")}mins ago`;
    }
}

export const keyToIconMap = (key, props) => {
    let icon;
    switch (key) {
        case 'icon-incomplete-application':
            icon = <DangerIcon {...props} />;   
            break;
        case 'icon-unique':
            icon = <CheckIcon {...props}/>; 
            break;
        case 'icon-non-unique':
            icon = <CrossIcon {...props}/>; 
            break;
        case 'icon-not-referred': 
            icon = <DiamondIcon {...props}/>;
            break;
        case 'icon-ai-hot-lead-tag': 
            icon = <StarIcon strokeColor="#FFF" {...props}/>  
            break;
        case 'clock-icon':
            icon = <ClockIcon {...props}/>
            break;
        case 'file-edit':
            icon = <FileEditIcon {...props}/>
            break;
        case 'dart-icon':
            icon = <DartIcon {...props}/>
            break;
        case 'mobile-icon':
            icon = <AndroidMobileIcon {...props}/>
            break;
        default:
            break;
    }

    return icon;
}
