import { Button, Input, InputNumber, Modal, notification, Popover, Select } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import CrossIcon from '../../../static/svg/CrossIcon'
import CustomSearchDropdown from '../../SamvaadiniV2/components/CustomSearchDropdown'
import { getOverallTeamCount } from '../../../service/reportService'
import CONSTANTS from '../../../constants/constants'
import { reAssignLeadsV2 } from '../../../service/LeadsPageV2Service'
import { AppContext } from '../../../App';
import Lottie from 'react-lottie';
import LoadingAnimation from './../../../static/lottie/blue-circle-loader.json';
import BlueTickAnimation from './../../../static/lottie/blue-circle-tick.json';
import InfoIcon from '../../../static/svg/InfoIcon'
import SearchIcon from '../../../static/svg/SearchIcon'

const LAYOUTS = {
  'REASSIGNMENT': 'REASSIGNMENT',
  'LOTTIE_VIEW': 'LOTTIE_VIEW'
}

const {Option} = Select;

function BulkReassignmentModal({openReassignmentModal, setOpenReassignmentModal,totalCandidatesCount,filters, getLeadsDashboardConfiguration}) {

  const [currentLayout, setCurrentLayout] = useState(LAYOUTS.REASSIGNMENT);
  const [teamDropdownOptions, setTeamDropdownOptions] = useState([]);
  const {mitraReducer} = useContext(AppContext);
  const [selectedMembersToReassignLeadsTo, setSelectedMembersToReassignLeadsTo] = useState([]);
  const [excludeTeamMemberIdMap,setExcludeTeamMemberIdMap] = useState({});
  const [totalReassignmentCount, setTotalReassignmentCount] = useState(null);
  const [membersOfChosedTeam, setMembersOfChosenTeam] = useState([]);
  const [animation, setAnimation] = useState(LoadingAnimation);

    const onTelecallerSearch = (val) => {

      // if(!val) {
      //     // set dropdown values to empty arr
      //     setTeamDropdownOptions([]);
      //     return
      // }

      let data = {
          search: val
      }

      getOverallTeamCount(data).then((teamListRes) => {
        if(teamListRes?.data?.teamList?.length) {
            const teamList = [];

              for(let i=0;i<teamListRes?.data?.teamList?.length; i++) {
                if(excludeTeamMemberIdMap[teamListRes?.data?.teamList[i].id] || excludeTeamMemberIdMap[teamListRes?.data?.teamList[i].team_id]) continue;
                  teamList.push({label:teamListRes?.data?.teamList[i].name, value: teamListRes?.data?.teamList[i].name, data: teamListRes?.data?.teamList[i]});
              }

              //set dropdown values in the filter obj
              setTeamDropdownOptions([...teamList]);
              
        } else {
            // set dropdown values to empty arr
            setTeamDropdownOptions([]);
            
        }
      }).catch(err=> {
          // set dropdown values to empty arr
          setTeamDropdownOptions([]);
      })
    }
    
    const onSelectTelecaller = (ids) => {
      // val.map((items)=>{
      //   items.isSelected = true
      // });

      const chosenMembers = []
      for (let i = 0; i < teamDropdownOptions.length; i++) {
        if(ids.includes(teamDropdownOptions[i]?.data?.id)) {
          chosenMembers.push(teamDropdownOptions[i]);
        }
      }
      setSelectedMembersToReassignLeadsTo([...chosenMembers]);
    };

    const fetchTcsIfTeamIsSelected = () => {
      getOverallTeamCount({
        search: ''
    }).then((teamListRes) => {
        if(teamListRes?.data?.teamList?.length) {

          const teamList = [];

          const membersofchosenteam = [];
          const members = teamListRes?.data?.teamList;
          for (let i = 0; i < members.length; i++) {
            if(excludeTeamMemberIdMap[members[i].team_id] && !excludeTeamMemberIdMap[members[i].id]) {
              membersofchosenteam.push(members[i]);
            }

            if(excludeTeamMemberIdMap[teamListRes?.data?.teamList[i].id] || excludeTeamMemberIdMap[teamListRes?.data?.teamList[i].team_id]) continue;
            teamList.push({label:teamListRes?.data?.teamList[i].name, value: teamListRes?.data?.teamList[i].name, data: teamListRes?.data?.teamList[i]});
          }

            setMembersOfChosenTeam([...membersofchosenteam]);
            setTeamDropdownOptions([...teamList]);
        }
      }).catch(err=> {
        // set dropdown values to empty arr
        notification['error']({
          message: 'Error loading team information.'
        })
      })
    }

    useEffect(() => {
      let teamMemberIdToExcludeMap = {}
      if(filters?.teamMembers?.length) {
        for(let teamMember of filters?.teamMembers) {
          teamMemberIdToExcludeMap[teamMember.id] = true;
        }
      }

      if(filters?.teams?.length) {
        for(let team of filters?.teams) {
          teamMemberIdToExcludeMap[team.id] = true;
        }
      }

      setExcludeTeamMemberIdMap(teamMemberIdToExcludeMap);
    }, []);

    useEffect(() => {
      if(filters?.teams?.length && Object.keys(excludeTeamMemberIdMap).length) {
        fetchTcsIfTeamIsSelected();
      } else if(!filters?.teams?.length && Object.keys(excludeTeamMemberIdMap).length) {
        onTelecallerSearch('');
      }
    }, [excludeTeamMemberIdMap])
    

    const formatFilters = () => {
      const filterPayload = {
        "filtersData": filters,
        "mitraIdForReassignmentArray": [],
        "reassignmentCount": null,
        'typeOfLead': filters.typeOfLeads || 'referred'
      }

      filterPayload.reassignmentCount = totalReassignmentCount;
      filterPayload.mitraIdForReassignmentArray = selectedMembersToReassignLeadsTo.map(member => member.data.id);
      filterPayload.filtersData.featureName = 'all';

      if(!totalReassignmentCount) {
        notification['error']({
          message: 'Please add lead count to re-assign.'
        });
        return null;
      }

      if(!filterPayload.mitraIdForReassignmentArray || !filterPayload.mitraIdForReassignmentArray.length) {
        notification['error']({
          message: 'Please select telecallers for re-assignment.'
        });
        return null;
      }

      return filterPayload;
    }

    const reAssignLeads = async() => {
      const payload = formatFilters();

      if(!payload) return;

      setCurrentLayout(LAYOUTS.LOTTIE_VIEW);

      try {
        const reassignmentTrigger = await reAssignLeadsV2(payload, mitraReducer?.mitraInfo?.id);

        if(reassignmentTrigger?.data?.success) {
          setAnimation(BlueTickAnimation);
        } else {
          // something 
        }

      } catch(err) {
        console.log(err)
      }

      // setOpenReassignmentModal(false);
    }

    const reAssignmentLayout = () => (
      <>
        <div className="leads-filter-header">
            <span className='unbounded-semibold-20-24 plain-black'>Re-assign Leads</span>
            <div style={{cursor: 'pointer'}} onClick={()=>setOpenReassignmentModal(false) }>
              <CrossIcon height={18} width={18}/>
            </div>
          </div>

          <div className="new-call-note-main-container custom-scroll-bar">
            <div className='call-connection-type-tab-container new-theme-shadow-light'>
              <span className={`${window.innerWidth<800 ? 'inter-regular-14-20': 'inter-regular-16-24'} color-252526 d-flex`}>Number of Selected Leads</span>
              <Input 
              disabled
              value={totalCandidatesCount}
              className={`samvaadini-search-candidate-new lr-input ${window.innerWidth<800 ? 'mt-12': 'mt-24'}`}
              placeholder=""/>
            </div>

            <div className={`call-connection-type-tab-container new-theme-shadow-light ${window.innerWidth<800 ? 'mt-12': 'mt-24'}`}>
              <span className={`${window.innerWidth<800 ? 'inter-regular-14-20': 'inter-regular-16-24'} color-252526 d-flex`}>Currently Assigned Telecallers</span>
              <div className="telecaller-list">

                {
                  filters?.teamMembers?.map((teamMember, index) => (
                    <div key={index} className="telecaller">{teamMember?.label}</div>
                  ))
                }

                {
                  membersOfChosedTeam?.map((teamMember, index) => (
                    <div key={index} className="telecaller">{teamMember?.name}</div>
                  ))
                }              
              </div>
            </div>

            <div className={`call-connection-type-tab-container new-theme-shadow-light ${window.innerWidth<800 ? 'mt-12': 'mt-24'}`}>
              <span className={`${window.innerWidth<800 ? 'inter-regular-14-20': 'inter-regular-16-24'} color-252526 d-flex`}>
                Select Number of Leads to Re-assign
                <span style={{top: '-5px',color: '#FF4545'}}>*</span>

                <div className='cursor-pointer'>
                  <Popover 
                      content={'Most recent will be selected'} 
                      title={null} 
                      // visible={true} 
                      trigger={'hover'}
                      className='ai-filtering-popup'
                      getPopupContainer={(trigger) => trigger.parentNode}
                      placement='bottom'>
                    <span><InfoIcon height={14} width={14} strokeColor='#000'/></span>
                  </Popover>
                </div>
              </span>
              <InputNumber
              className={`samvaadini-search-candidate-new lr-input ${window.innerWidth<800 ? 'mt-12': 'mt-24'}`} 
              min={1}
              max={totalCandidatesCount}
              precision={0}
              value={totalReassignmentCount}
              onChange={(val)=>setTotalReassignmentCount(val)}
              placeholder=""/>
            </div>

            <div className={`call-connection-type-tab-container new-theme-shadow-light ${window.innerWidth<800 ? 'mt-12': 'mt-24'} d-flex dsktp-alignment flex-gap-16`}>
              <span className={`${window.innerWidth<800 ? 'inter-regular-14-20': 'inter-regular-16-24'} color-252526 d-flex`}>
                Select New Telecallers
                <span style={{top: '-5px',color: '#FF4545'}}>*</span>
                <div className='cursor-pointer'>
                  <Popover 
                      content={'Leads will be assigned one by one'} 
                      title={null} 
                      // visible={true} 
                      trigger={'hover'}
                      className='ai-filtering-popup'
                      getPopupContainer={(trigger) => trigger.parentNode}
                      placement='bottom'>
                    <span><InfoIcon height={14} width={14} strokeColor='#000'/></span>
                  </Popover>
                </div>
              </span>
              {/* <CustomSearchDropdown 
                placeholder={'Search for a telecaller'}
                onSearch={(value)=>onTelecallerSearch(value)}
                dropdownOptions={teamDropdownOptions}
                onSelect={(data)=>onSelectTelecaller(data)}
                value={selectedMembersToReassignLeadsTo}
                checkboxGroupVisible={false}
                inputBoxCustomClass='filter-search-box'
                searchIconSize={16}
                showSelection={true}
                /> */}

              <Select
                  mode="multiple"
                  placeholder={'Select telecallers..'}
                  className="mobile-sortby-dd-filter no-adjacent-borders"
                  style={{border: 0}}
                  // value={selectedMembersToReassignLeadsTo?.[0]?.label || null}
                  onChange={(value)=>onSelectTelecaller(value)}
                  suffixIcon={<SearchIcon />}
                  dropdownClassName='custom-scroll-bar'
                  showSearch
                  showArrow
                  filterOption={(input, option) =>
                      (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
                  }
              >
                  {
                  teamDropdownOptions.map((item, index) => (
                      <Option key={index} value={item?.data?.id} className='text-capitalise'>{item?.label}</Option>
                  ))
                  }
              </Select>
            </div>
          </div>

          <div className="leads-filter-footer">
            <Button className='primary-btn-styles-v2 leads-filter-footer-button' onClick={()=>reAssignLeads()}> 
              <span className='btn-text-semibold-14-16 plain-white'>Confirm</span>
            </Button>
          </div>
      </>
    );

    const defaultOptions = {
      loop: false,
      autoplay: true,
      animationData: animation,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
  };

    const lottieLayout = () => (
      <>
          <div className="leads-filter-header">
              <span className='unbounded-semibold-20-24 plain-black'>Re-assign Leads</span>
              <div style={{cursor: 'pointer'}} onClick={()=> {
                getLeadsDashboardConfiguration();
                setOpenReassignmentModal(false)
              }}>
                  <CrossIcon height={18} width={18}/>
              </div>
          </div>

          <div className='new-call-note-main-container align-items-center justify-content-center d-flex flex-column mobile-full-height'>
              <Lottie 
              options={defaultOptions}
              height={120}
              width={120}
              speed={1}
              />
              <span className='inter-regular-16-24 color-252526'>
                  {
                      animation === LoadingAnimation ? 'Re-assigning Leads to New Telecallers': 'Leads have been re-assigned'
                  }
              </span>
          </div>
      </>
  );
    
  return (
    <Modal 
        title={null}
        visible={openReassignmentModal}
        className="leads-filter-modal"
        closable={false}
        maskClosable={false}
        bodyStyle={{borderRadius: '8px',padding: '0px'}}
        footer={null}
        >
          {
            currentLayout === LAYOUTS.REASSIGNMENT ? reAssignmentLayout(): null
          }

          {
            currentLayout === LAYOUTS.LOTTIE_VIEW ? lottieLayout(): null
          }
      </Modal>
  )
}

export default BulkReassignmentModal