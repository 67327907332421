import React, { Fragment, useContext, useEffect, useRef, useState } from 'react'
import Navbar from './Navbar'
import {PlusOutlined,ArrowLeftOutlined,CloudDownloadOutlined,SlidersOutlined, UserOutlined, CopyOutlined,PhoneOutlined} from '@ant-design/icons'
import { Avatar, Button, DatePicker, Divider, Pagination, Popover, Skeleton, notification } from 'antd';
import StatisticCard from './components/StatisticCard';
import { useHistory } from 'react-router-dom';
import InterestedCandidateCard from './components/InterestedCandidateCard';
import SessionCard from './components/SessionCard';
import LeftArrow from '../../static/svg/LeftArrow';
import FoldingHands from '../../static/images/folding-hands.gif';
import './styles/SessionListV2.css'
import FilterIcon from '../../static/svg/FilterIcon';
import { getSessionList, getUploadedListOfFiles, statisticCardInformation } from '../../service/SamvadiniApiService';
import { AppContext } from '../../App';
import PopoverDropdown from './components/PopoverDropdown';
import NewCustomPagination from './components/NewCustomPagination';
import DownArrow from '../../static/svg/DownArrow';
import UpArrow from '../../static/svg/UpArrow';
import SessionListSkeleton from './skeleton/SessionListSkeleton';
import ScrollToTopButton from './components/ScrollToTopButton';
import SessionCreationFloaterButton from './components/SessionCreationFloaterButton';
import StatisticCardSkeleton from './skeleton/StatisticCardSkeleton';
import SideSheetComponent from '../common/SideSheet';
import CrossIcon from '../../static/svg/CrossIcon';
import CalendarIcon from '../../static/svg/CalendarIcon';
import CityIcon from '../../static/svg/CityIcon';
import TeamMemberAddIcon from '../../static/svg/TeamMemberAddIcon';
import StarIcon from '../../static/svg/StarIcon';
import useDebouncedValue from '../../hooks/useDebouncedValue';
import {UpOutlined, DownOutlined, CloseOutlined} from '@ant-design/icons'
import moment from 'moment';
import { enableLast30DaysForSelection, getActiveClientsForFilter, getLocationForFilter } from '../../service/commonService';
import CustomSearchDropdown from './components/CustomSearchDropdown';

const { RangePicker } = DatePicker;

function SessionListV2() {
    const scrollContainerRef = useRef(null);

    const history = useHistory();
    const {mitraReducer} = useContext(AppContext);
    const [searchTerm, setSearchTerm] = useState('');
    const [activeSessionDetailsTab, setActiveSessionDetailsTab] = useState('pastSession'); // 'currentSession' | 'pastSession'
    const [sessionListData, setSessionListData] = useState([]);
    const [showFloaterMenu, setShowFloaterMenu] = useState(false);
    const [allSessionsSummary, setAllSessionsSummary] = useState({});

    const [loading, setLoading] = useState(true);
    const [sessionListLoading, setSessionListLoading] = useState(false);
    const [statisticCardLoading, setStatisticCardLoading] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const totalItemsPerPage = 9;

    const [showFilter, setShowFilter] = useState(false);
    const [filterData, setFilterData] = useState([
        { id: 0, name: 'Client', isActive: false },
        { id: 1, name: 'City',isActive: false },
        { id: 2, name: 'Referral Date', isActive: false }
    ]);

    const [showSessionTypeFilter, setShowSessionTypeFilter] = useState(false);
    const [selectedSessionType, setSelectedSessionType] = useState('All');
    const [sessionTypeFilterItems, setSessionTypeFilterItems] = useState([
        { id: 3, name: 'All', isActive: true },
        { id: 0, name: 'Smart Logic', isActive: false },
        { id: 1, name: 'File Upload',isActive: false },
        { id: 2, name: 'Advance Filter', isActive: false },
        { id: 4, name: 'Bulk Upload', isActive: false }
    ]);

    // All Filters related var
    const [filterList, setFilterList] = useState([
        {
            id: 0,
            header: 'Session Creation Date',
            icon: <CalendarIcon />,
            isExpanded: true
        },
        {
            id: 1,
            header: 'City',
            icon: <CityIcon />,
            isExpanded: false
        },
        {
            id: 3,
            header: 'Clients',
            icon: <StarIcon />,
            isExpanded: false
        }
    ]);

    const [selectedCities, setSelectedCities] = useState([]);
    const [selectedClients, setSelectedClients] = useState([]);
    const [expandedFilterId, setExpandedFilterId] = useState(null);
    const [cityOptions, setCityOptions] = useState([]);
    const [citySearchText, setCitySearchText] = useState('');
    const cityDebouncedSearchText = useDebouncedValue(citySearchText);
    const [popularCities, setPopularCities] = useState([
        {
            id: 0,
            label: 'Delhi',
            isChecked: false
        },
        {
            id: 1,
            label: 'Mumbai',
            isChecked: false
        },
        {
            id: 2,
            label: 'Bangalore',
            isChecked: false
        },
        {
            id: 3,
            label: 'Pune',
            isChecked: false
        }
    ]);
    const [startDate, setStartDate] = useState();
	const [endDate, setEndDate] = useState();
    const dateFormat = 'YYYY/MM/DD';
    const [clientList, setClientList] = useState([])

    const onExpandFilter = (id) => {
        const modifiedFilter = [...filterList];

        setCityOptions([]);

        for (let item = 0; item < modifiedFilter.length; item++) {
            if(modifiedFilter[item].id === id) {
                if(id === expandedFilterId) {
                    modifiedFilter[item].isExpanded = false;
                    setExpandedFilterId(null);
                }
                else {
                    modifiedFilter[item].isExpanded = true;
                    setExpandedFilterId(id);
                }

            } else {
                modifiedFilter[item].isExpanded = false;
            }
        }

        setFilterList([...modifiedFilter]);
    }

    const onSearchCities = (label) => {

        if(!label) {
            setCityOptions([]);
            return
        }

        let data = {
            search: label
        }
        getLocationForFilter(data).then(response => {
            if(response?.data?.locationList.length) {
                const locationData = [];

                for(let i=0;i<response?.data?.locationList?.length; i++) {
                    locationData.push({label:response?.data?.locationList[i], value: response?.data?.locationList[i]});
                }

                setCityOptions([...locationData]);
            } else {
                setCityOptions([]);
            }
        }).catch(err=> {
            console.log(err);
            setCityOptions([]);
        })
    }

    useEffect(() => {
        onSearchCities(cityDebouncedSearchText)
    }, [cityDebouncedSearchText])

    const [statisticCardData, setStatisticCardData] = useState([]);

    const [showSideSheet, setShowSideSheet] = useState(false);

    const changeDataOnStatisticCards = (type) => {
        const typeToAPIPropertyMap = {
            'All':'total',
            'Smart Logic': 'SMART_SELECT',
            'File Upload': 'FILE_UPLOAD',
            'Advance Filter': 'ADVANCE_FILTER',
            'Bulk Upload': 'BULK_UPLOAD'
        }

        const statistic = [
            {
                id: 1,
                label: 'Total Session',
                count: null,
                imageUrl: '/image/Time-Machine.png'
            },
            {
                id: 0,
                label: 'Leads Uploaded',
                count: null,
                imageUrl: '/image/add-properties.png'
            },
            {
                id: 2,
                label: 'Interested',
                count: null,
                imageUrl: '/image/done-ai.png'
            }
        ]

        if(type && typeToAPIPropertyMap[type]) {
            if(allSessionsSummary.summaryData?.summary?.totalSessions?.[typeToAPIPropertyMap[type]]) {
                statistic[0].count = allSessionsSummary.summaryData?.summary?.totalSessions?.[typeToAPIPropertyMap[type]];
            }
    
            if(allSessionsSummary.summaryData?.summary?.leadsUploaded?.[typeToAPIPropertyMap[type]]) {
                statistic[1].count = allSessionsSummary.summaryData?.summary?.leadsUploaded?.[typeToAPIPropertyMap[type]];
            }
    
            if(allSessionsSummary.summaryData?.summary?.interestedLeadCount?.[typeToAPIPropertyMap[type]]) {
                statistic[2].count = allSessionsSummary.summaryData?.summary?.interestedLeadCount?.[typeToAPIPropertyMap[type]];
                
                if(allSessionsSummary.summaryData?.summary?.leadsUploaded?.[typeToAPIPropertyMap[type]]) {
                    statistic[2].percentage = ((parseInt(allSessionsSummary.summaryData?.summary?.interestedLeadCount?.[typeToAPIPropertyMap[type]])/parseInt(allSessionsSummary.summaryData?.summary?.leadsUploaded?.[typeToAPIPropertyMap[type]]))*100).toFixed(2);
                }
            }
        }

        setStatisticCardData([...statistic]);
    }

    const getAllSessionList = async() => {
        setSessionListLoading(true);
        try{
            const offset = (currentPage-1)*totalItemsPerPage;
            const payload = {
                type: selectedSessionType === 'Smart Logic' ? 'Smart Select': selectedSessionType,
                limit: totalItemsPerPage,
                offset,
            }

            payload.startDate = startDate? moment(startDate).format('YYYY-MM-DD'): null;
            payload.endDate = endDate ? moment(endDate).format('YYYY-MM-DD'): null;

            if(selectedCities.length) {
                const cityList = [];
    
                for (let i = 0; i < selectedCities.length; i++) {
                    cityList.push(selectedCities[i].label.toLowerCase());
                }
    
                payload.cities = [...cityList];
            }
    
            if(selectedClients.length) {
                const clientList = [];
    
                for (let i = 0; i < selectedClients.length; i++) {
                    clientList.push(selectedClients[i].label.toLowerCase());
                }
    
                payload.clients = [...clientList];
            }

            const response = await getSessionList(payload);

            console.log(response?.data?.dashboardPageData?.sessions, 'SESSIONS');

            if(response?.data?.dashboardPageData?.sessions?.length) {
                setSessionListData([...response.data.dashboardPageData?.sessions]);
                setTotalItems(response?.data?.dashboardPageData?.totalSessions || 0);

                setLoading(false)
            } else {
                setSessionListData([]);
                setTotalItems(0);
            }
            setSessionListLoading(false);
        } catch (e) {
            setSessionListData([]);
            setTotalItems(0);

            notification['error'] ({
                message: 'Something went wrong while fetching list of files.'
            });

            setSessionListLoading(false);
        }
    }

    const getSessionLevelSummaryInformation = () => {
        setStatisticCardLoading(true);
        statisticCardInformation().
        then(response=> {

            setAllSessionsSummary(response.data);

            const statistic = [
                {
                    id: 1,
                    label: 'Total Session',
                    count: null,
                    imageUrl: '/image/Time-Machine.png'
                },
                {
                    id: 0,
                    label: 'Leads Uploaded',
                    count: null,
                    imageUrl: '/image/add-properties.png'
                },
                {
                    id: 2,
                    label: 'Interested',
                    count: null,
                    imageUrl: '/image/done-ai.png'
                }
            ]

            if(response?.data?.summaryData?.summary?.totalSessions?.total) {
                statistic[0].count = response?.data?.summaryData?.summary?.totalSessions?.total;
            }

            if(response?.data?.summaryData?.summary?.leadsUploaded?.total) {
                statistic[1].count = response?.data?.summaryData?.summary?.leadsUploaded?.total;
            }

            if(response?.data?.summaryData?.summary?.interestedLeadCount?.total) {
                statistic[2].count = response?.data?.summaryData?.summary?.interestedLeadCount?.total;
                
                if(response?.data?.summaryData?.summary?.leadsUploaded?.total) {
                    statistic[2].percentage = ((parseInt(response?.data?.summaryData?.summary?.interestedLeadCount?.total)/parseInt(response?.data?.summaryData?.summary?.leadsUploaded?.total))*100).toFixed(2);
                }
            }

            setStatisticCardData([...statistic]);
            setStatisticCardLoading(false);
        }).catch(err=> {
            setStatisticCardLoading(false);
            console.log(err);
        })
    }

    useEffect(() => {
        if(mitraReducer?.mitraInfo?.id) {
            getSessionLevelSummaryInformation();
            getActiveClientList();
        }
    }, [mitraReducer?.mitraInfo?.id])

    const onSessionTypeFilterSelection = (item) => {
        const modifiedFilterData = [...sessionTypeFilterItems];

        for (let i = 0; i < modifiedFilterData.length; i++) {
            if(modifiedFilterData[i].id === item.id) {
                modifiedFilterData[i].isActive = true;
                setSelectedSessionType(modifiedFilterData[i].name);
                setCurrentPage(1);
                changeDataOnStatisticCards(modifiedFilterData[i].name);
            } else {
                modifiedFilterData[i].isActive = false;
            }
        }

        setSessionTypeFilterItems([...modifiedFilterData]);
    }

    const handlePageChange = (pageNumber) => {
        console.log('Page changed to:', pageNumber);
        setCurrentPage(pageNumber);
    };

    useEffect(() => {
        getAllSessionList();
    }, [selectedSessionType, currentPage])
    

    const goToLiveSession = () => {
        if(allSessionsSummary?.summaryData?.summary?.status === 'Processing' && allSessionsSummary?.summaryData?.summary?.intentCallingSessionId) {
            const payload = {
                intentCallingSessionId: allSessionsSummary?.summaryData?.summary?.intentCallingSessionId
            }
            const url = `/samvadini-session-details?sessionDetails=${JSON.stringify(payload)}`;
            history.push(url);
        } else {
            notification['warning']({
                message: mitraReducer?.mitraInfo?.managerMitraID ? 'No active session':'No active session - Create one to filter AI Hot Leads.'
            })
        }
    }

    const createNewSessionBtnDesktop = () => {
        return <div 
        className={`
            ${allSessionsSummary?.summaryData?.summary?.allowToCreateNewSession ? 'create-session-btn':'create-session-btn-disabled'}`}
        onClick={()=>allSessionsSummary?.summaryData?.summary?.allowToCreateNewSession ? history.push('/samvadini-v2'): 1}>
            <PlusOutlined style={{fontSize:'16px',color:"#FFF"}}/> 
            <span className='create-session-btn-text'>Create New Session</span>
        </div>
    }

    const createNewSessionBtnMobile = () => {
        return <div 
        className={`create-session-btn-mobile 
            ${allSessionsSummary?.summaryData?.summary?.allowToCreateNewSession ? 'create-session-btn':'create-session-btn-disabled'}`}
        onClick={()=> allSessionsSummary?.summaryData?.summary?.allowToCreateNewSession ? setShowFloaterMenu(true): 1}>
            <PlusOutlined style={{fontSize:'16px',color:"#FFF"}}/> 
        </div>
    }

    const onFilter = (item) => {
        console.log(item);
        
    }
    const handleDateRange = (dates) => {
        console.log(dates);
		if (dates === null) {
			setStartDate(null);
			setEndDate(null);
		}
		else {
			setStartDate(dates[0]);
			setEndDate(dates[1]);
		}
	}

    const resetFilters = () => {
        setSelectedCities([]);
        setSelectedClients([]);
        setStartDate(null);
        setEndDate(null);
        setPopularCities([
            {
                id: 0,
                label: 'Delhi',
                isChecked: false
            },
            {
                id: 1,
                label: 'Mumbai',
                isChecked: false
            },
            {
                id: 2,
                label: 'Bangalore',
                isChecked: false
            },
            {
                id: 3,
                label: 'Pune',
                isChecked: false
            }
        ]);

        

        setFilterList([
            {
                id: 0,
                header: 'Referral dates',
                icon: <CalendarIcon />,
                isExpanded: true
            },
            {
                id: 1,
                header: 'City',
                icon: <CityIcon />,
                isExpanded: false
            },
            {
                id: 3,
                header: 'Clients',
                icon: <StarIcon />,
                isExpanded: false
            }
        ])

        getActiveClientList();
    }

    const getActiveClientList = () => {
        getActiveClientsForFilter().then(data => {
			console.log(data.data.activeClients)

            if(data?.data?.activeClients?.length) {
                const activeClients = [...data.data.activeClients];

                for (let i = 0; i < activeClients.length; i++) {
                    activeClients[i].isExpanded = false;
                    activeClients[i].id = i;
                }

                setClientList([...activeClients]);
            }
		});
    }

    const applyFilters = () => {
        setShowFilter(false);
        if(currentPage != 1) {
            setCurrentPage(1); // this state change will inturn call session list API
        } else {
            getAllSessionList();
        }
    }

    const getFilterInformation = () => {
        return (
            <>
                {/* <Divider style={{margin:'16px 0px'}}/> */}

                {
                    filterList.map((filterItem, idx)=>(
                        <Fragment key={idx}>
                            <div className="filter-item" onClick={()=>onExpandFilter(filterItem.id)}>
                                <div className='filter-details'>
                                    {/* {filterItem.icon} */}
                                    <span className='inter-medium-14-20 color-252526' style={{userSelect:'none'}}>{filterItem.header}</span>
                                </div>
                                {
                                    filterItem?.isExpanded ? <UpOutlined />: <DownOutlined />
                                }
                                
                            </div>

                            {
                                filterItem.id === 0 && filterItem.isExpanded ? (
                                    <>
                                        <RangePicker 
                                        className="range-picker-leads adv-filter-selection"
                                        onChange={handleDateRange}
                                        value={
                                            startDate && endDate ? 
                                            [moment(startDate, dateFormat), moment(endDate, dateFormat)] : [null, null]
                                        }
                                        disabledDate={enableLast30DaysForSelection}
                                        style={{marginLeft:'28px'}}
                                        format={dateFormat}
                                        />
                                    </>
                                ):null
                            }

                            {
                                filterItem.id === 1 && filterItem.isExpanded ? (
                                    // <AdvanceFilterCitySelection onCitySelect={(data)=>setSelectedCities([...data])}/>
                                    <CustomSearchDropdown 
                                    placeholder={'e.g Delhi'}
                                    onSearch={(value)=>setCitySearchText(value)}
                                    dropdownOptions={cityOptions}
                                    onSelect={(data)=>setSelectedCities([...data])}
                                    value={selectedCities}
                                    checkboxGroupVisible={true}
                                    checkboxGroupItems={popularCities}
                                    />
                                ): null
                            }

                            {
                                filterItem.id === 3 && filterItem.isExpanded ? (
                                    <CustomSearchDropdown 
                                        showSearch={false}
                                        checkboxGroupVisible={true}
                                        checkboxGroupItems={clientList}
                                        onSelect={(data)=>setSelectedClients([...data])}
                                        value={selectedClients}
                                    />
                                ): null
                            }
                            <Divider style={{margin:'16px 0px'}}/>
                        </Fragment>
                    ))
                }

                {/* <div className="clear-filter-container" onClick={resetFilters}><span className='inter-regular-12-16 color-252526'>Remove all filters</span></div> */}
                <Button className='primary-btn-styles-v2 w-100' onClick={()=>applyFilters()}>
                    <span className='inter-regular-14-20 plain-white'>Apply</span>
                </Button>
            </>
        )
    }

  return (
    <>
        <div className='gray-main-container' ref={scrollContainerRef}>
            {/* <Navbar searchTerm={searchTerm} setSearchTerm={setSearchTerm}/> */}

            {
                searchTerm ? null: (
                    <>
                        <div className="back-navigation d-flex align-items-center" onClick={()=>history.push('samvadini-v2')}>
                            <LeftArrow />
                            <span className='back-text'>Back</span>
                        </div>

                        <div className="d-flex justify-content-between align-items-center flex-wrap" style={{margin:'9px 32px 0px', gap: '16px'}}>
                            <span className='lead-list-hero-text d-flex' style={{margin: 0}}>
                                Leads Dashboard
                                <img src={FoldingHands} style={{width: '34px', height:'34px', marginLeft: '12px', marginTop: '-12px'}} />
                            </span>

                            {
                                mitraReducer?.mitraInfo?.managerMitraID ? null :  allSessionsSummary?.summaryData?.summary?.allowToCreateNewSession ? createNewSessionBtnDesktop(): (
                                    <Popover
                                    content={<span className='inter-regular-10-16'>Wait for the current session to finish before starting another.</span>} 
                                    trigger={'hover'}
                                    placement='bottom'
                                    >
                                        { createNewSessionBtnDesktop() }
                                    </Popover>
                                )
                            }
                        </div>

                        <div className="tab-and-btn-container d-flex justify-content-between">
                            <div className="session-tab-container">
                                <div 
                                className={`single-tab ${activeSessionDetailsTab === 'currentSession' ? 'active-tab':''}`}
                                onClick={()=>goToLiveSession()}
                                > 
                                    {
                                        activeSessionDetailsTab === 'currentSession' ? <div className="active-dot"></div> : null
                                    }
                                    
                                    Current Session
                                </div>

                                <div 
                                className={`single-tab ${activeSessionDetailsTab != 'currentSession' ? 'active-tab':''}`}
                                onClick={()=>setActiveSessionDetailsTab('pastSession')}
                                >
                                    Past Sessions
                                </div>
                            </div>

                            {
                                mitraReducer?.mitraInfo?.managerMitraID ? null : allSessionsSummary?.summaryData?.summary?.allowToCreateNewSession ? createNewSessionBtnMobile(): (
                                    <Popover
                                    content={<span className='inter-regular-10-16'>Wait for the current session to finish before starting another.</span>} 
                                    trigger={'hover'}
                                    placement='bottom'
                                    >
                                        { createNewSessionBtnMobile() }
                                    </Popover>
                                )
                            }

                        </div>

                        <div className='d-flex align-items-start justify-content-between filter-section-sl' style={{margin: '20px 32px'}}>
                            <div className='d-flex flex-column align-items-start'>
                                <span className='inter-semibold-12-18 color-252526'>View past sessions</span>
                                <span className='color-838383 inter-regular-10-16'>See all of them now</span>
                            </div>

                            <PopoverDropdown
                            content={sessionTypeFilterItems}
                            visible={showSessionTypeFilter}
                            setVisible={setShowSessionTypeFilter}
                            onSelect={(item)=>onSessionTypeFilterSelection(item)}
                            >
                                <div className='session-type-filter'>
                                    <span className='inter-semibold-10-18' style={{color: '#000', flex: 4}}>{selectedSessionType}</span>
                                    <div style={{flex: 1}}>
                                    {
                                        showSessionTypeFilter ? <UpArrow />: <DownArrow />
                                    }
                                    </div>
                                </div>
                            </PopoverDropdown>

                        </div>

                        <Divider />

                        <div className="card-tile-container margin-horizontal-32">
                        
                            {
                                statisticCardLoading ? <StatisticCardSkeleton />:
                                statisticCardData.map((item, idx) => {
                                    return <StatisticCard key={idx} value={item}/>
                                })
                            }
                        </div>

                        <div className="filtered-candidate-card-container margin-horizontal-32 session-list-container">

                            <div className='sticky-filter-and-pagination'>
                                <div className="filtered-candidate-top-section d-flex justify-content-between align-items-center">
                                    <div className="filtered-candidate-tab-container d-flex">
                                        <div 
                                        className={`filtered-candidate-single-tab filtered-candidate-active-tab`}
                                        >
                                            {selectedSessionType}
                                        </div>
                                    </div>
                                    <div className="filtered-candidate-options d-flex">
                                        <div className='sessionlist-filter-popup'>
                                            <Popover
                                            content={getFilterInformation}
                                            trigger={'click'}
                                            className='ai-hot-leads-filter-popup'
                                            placement='topLeft'
                                            getPopupContainer={(trigger) => trigger.parentNode}
                                            title={<b>Add filters</b>} 
                                            >
                                                <div className="single-option-container d-flex align-items-center">
                                                    <FilterIcon />
                                                    <span className='single-option-text'>Filter</span>
                                                </div>
                                            </Popover>
                                        </div>
                                        <div 
                                        onClick={()=>setShowFilter(true)}
                                        className="single-option-container session-mobile-sidebar">
                                            <FilterIcon />
                                            <span className='single-option-text'>Filter</span>
                                        </div>
                                    </div>
                                    
                                </div>

                                <div className="session-list-pagination-container d-flex justify-content-end mt-16">
                                    {
                                        totalItems ? (
                                            <NewCustomPagination
                                                totalItems={totalItems}
                                                totalItemsPerPage={totalItemsPerPage}
                                                currentPage={currentPage}
                                                onPageChange={handlePageChange}
                                            />
                                        ): null
                                    }
                                
                                </div>
                            </div>
                                
                            <div className="filtered-candidate-cards d-flex">
                                
                                {
                                    sessionListLoading ? (<>
                                        <Skeleton active/>
                                    </>):
                                    sessionListData?.length ? sessionListData.map((item,idx) => {
                                        return <SessionCard key={idx} item={item}/>
                                    }): null
                                }
                            
                            </div>
                            

                        </div>
                    </>
                )
            }

            {
                showFloaterMenu ? (
                    <SessionCreationFloaterButton 
                        isSessionCreationAllowed={true} // needs binding from API
                        showButton={false}
                        showMenu={showFloaterMenu}
                        setShowFloaterMenu={()=>setShowFloaterMenu(!showFloaterMenu)}
                    />
                ): null
            }
        
        </div>
        <ScrollToTopButton scrollContainerRef={scrollContainerRef}/>
        {
            showFilter ? (
                    <SideSheetComponent 
                    sidername={'Filters'} 
                    hideDismissIcon={false}
                    hideBottomButtons={true}
                    containerStyle={{width:"85%"}}
                    showNavigator={false}
                    parentContainerStyles={{background: 'rgba(0, 0, 0, 0.74)'}}
                    onDismiss={() => { setShowFilter(false)}}>
                        <div className='mobile-adv-filter-sidesheet'>
                            <div className='d-flex align-items-center justify-content-between'>
                                <span className='inter-bold-16-18 color-252526 text-align-start'>
                                    Filters
                                </span>
                                <div onClick={()=>setShowFilter(false)}>
                                    <CrossIcon />
                                </div>
                            </div>
                            <Divider style={{margin:'16px 0px'}}/>
                            {getFilterInformation()}
                        </div>
                    </SideSheetComponent>
            ): null
        }
    </>
  )
}

export default SessionListV2
