import React, { Fragment, useContext, useEffect, useState } from 'react'
import PopoverDropdown from '../SamvaadiniV2/components/PopoverDropdown'
import UpArrow from '../../static/svg/UpArrow';
import DownArrow from '../../static/svg/DownArrow';
import CrossIcon from '../../static/svg/CrossIcon';
import PlusIcon from '../../static/svg/PlusIcon';
import BookmarkIcon from '../../static/svg/BookmarkIcon';
import { Button, DatePicker, Divider, Modal, Popover, Select, Skeleton } from 'antd';
import CustomSearchDropdown from '../SamvaadiniV2/components/CustomSearchDropdown';
import useDebouncedValue from '../../hooks/useDebouncedValue';
import { getActiveClientsForFilter, getLocationForFilter } from '../../service/commonService';
import SettingsIcon from '../../static/svg/SettingsIcon';
import SideSheetComponent from '../common/SideSheet';
import Dustbin from '../../static/svg/DustbinIcon';
import { getOverallTeamCount } from '../../service/reportService';
import AddViewModal from './components/AddViewModal';
import EditPencilIcon from '../../static/svg/EditPencilIcon';
import ReassignmentIcon from '../../static/svg/ReassignmentIcon';
import DownloadIcon from '../../static/svg/DownloadIcon';
import NewCustomPagination from '../SamvaadiniV2/components/NewCustomPagination';
import FilterSectionSkeleton from './skeletons/FilterSectionSkeleton';
import CONSTANTS from '../../constants/constants';
import { AppContext } from '../../App';
import ExportExcelModal from './components/ExportExcelModal';
import moment from 'moment';

const { RangePicker } = DatePicker;
const { Option } = Select;

function FilterSection(
  {
    selectedViewDetails, 
    submitViewsJson, 
    getLeadsDashboardConfiguration,
    setOpenReassignmentModal,
    setChosenFilters,
    chosenFilters,
    totalCandidatesCount=0
  }
) {
    const {mitraReducer} = useContext(AppContext);
    const [listOfSubViews, setListOfSubViews] = useState([]);
    const [activeSubviewInfo, setActiveSubViewInfo] = useState({});

    const [openEditViewModal, setOpenEditViewModal] = useState(false);
    const [openDownloadModal, setOpenDownloadModal] = useState(false);

    const [totalSelectedFilterCount, setTotalSelectedFilterCount] = useState(0);

    const [showSideSheet, setShowSideSheet] = useState(false);
    const [allChosenFiltersForDisplay, setAllChosenFiltersForDisplay] = useState();

    const [currentActiveSubViewIndex, setCurrentActiveSubViewIndex] = useState(0);

    const [showSubviewFilter, setShowSubviewFilter] = useState(false);

    const [openFilterModal, setOpenFilterModal] = useState(false);
    const [filterSections, setFilterSections] = useState([]);
    const [disableBulkReassignment, setDisableBulkReassignment] = useState(true);
    // the following variable is used to maintain copy of original filters and only synchronised when clicked on apply
    const [temporayFiltersForFiltersPage, setTemporayFiltersForFiltersPage] = useState([]);

    const dateFormat = 'DD MMM YYYY';
    const [customStartDate, setCustomStartDate] = useState(moment());
    const [customEndDate, setCustomEndDate] = useState(moment());
    const [disableDatesBefore, setDisableDatesBefore] = useState(moment());

    const [hasReassignAccess, setHasReassignAccess] = useState(false);

    const [showDurationFilter, setShowDurationFilter] = useState(false);
    const [selectedDuration, setSelectedDuration] = useState('All');
    const [durationFilterItems, setDurationFilterItems] = useState([
        { id: 3, name: 'Last day', isActive: true, value: 'Last day' },
        { id: 0, name: 'Last week', isActive: false, value: 'Last week' },
        { id: 1, name: 'Last 10 days',isActive: false, value: 'Last 10 days' },
        { id: 2, name: 'Custom',isActive: false, value: 'Custom' }
    ]);

    // const [totalItems, setTotalItems] = useState(500);
    const totalItemsPerPage = 10;

  const [showSortByFilter, setShowSortByFilter] = useState(false);
  const [selectedSortBy, setSelectedSortBy] = useState('');
  const [sortByFilterItems, setSortByFilterItems] = useState([
      { id: 3, name: 'Most Recent', isActive: true },
      { id: 0, name: 'Oldest', isActive: false },
  ]);

  const [currentPage, setCurrentPage] = useState(1);

  const changeDateRange = (dateRange) => {
    if (dateRange[0] && dateRange[1]) {
      const startDate = dateRange[0].format("YYYY-MM-DD");
      const endDate = dateRange[1].format("YYYY-MM-DD");

      setCustomStartDate(startDate);
      setCustomEndDate(endDate);

      setActiveSubViewInfo({...activeSubviewInfo, isSaved: false, startDate, endDate});

      extractActiveFilters(filterSections, selectedDuration, selectedSortBy, startDate, endDate);
    }
  };

  const changeDate = (date, category) => {
    if(category === 'startDate') {
      const startDate = date.format("YYYY-MM-DD");
      setCustomStartDate(startDate);

      setActiveSubViewInfo({...activeSubviewInfo, isSaved: false, startDate, customEndDate});

      extractActiveFilters(filterSections, selectedDuration, selectedSortBy, startDate, customEndDate);
    } else {
      const endDate = date.format("YYYY-MM-DD");
      setCustomEndDate(endDate);

      setActiveSubViewInfo({...activeSubviewInfo, isSaved: false, customStartDate, endDate});

      extractActiveFilters(filterSections, selectedDuration, selectedSortBy, customStartDate, endDate);
    }
  }

  const fetchDisabledDates = (current) => {
    //Disable all dates before allowed number of days mentioned in View Data Access 
    return current && current < disableDatesBefore.endOf("day");
  }

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const onSortByFilterSelection = (item) => {
    const modifiedSortByFilterItems = [...sortByFilterItems]

    for (let i = 0; i < modifiedSortByFilterItems.length; i++) {
      if(modifiedSortByFilterItems[i].id === item.id) {
        modifiedSortByFilterItems[i].isActive = true;
        setActiveSubViewInfo({...activeSubviewInfo, sortBy: item.name, isSaved: false});
      } else {
        modifiedSortByFilterItems[i].isActive = false;
      }
    }

    setSortByFilterItems([...modifiedSortByFilterItems]);
    setSelectedSortBy(item?.name);

    extractActiveFilters(filterSections, selectedDuration, item?.name);
  };

  const onSortBySelectionFromMobile = (id) => {
    const selected = sortByFilterItems.find((option) => option.id === id);

    onSortByFilterSelection(selected);
  }

    const [clientList, setClientList] = useState([]);

    const onDurationFilterSelection = (item) => {
      
      const modifiedDurationFilterItems = [...durationFilterItems]

      for (let i = 0; i < modifiedDurationFilterItems.length; i++) {
        if(modifiedDurationFilterItems[i].id === item.id) {
          modifiedDurationFilterItems[i].isActive = true;
          setActiveSubViewInfo({...activeSubviewInfo, duration: item.name, isSaved: false});
        } else {
          modifiedDurationFilterItems[i].isActive = false;
        }
      }

      setDurationFilterItems([...modifiedDurationFilterItems]);
      setSelectedDuration(item?.name);
      extractActiveFilters(filterSections, item.name, selectedSortBy);
    }

    useEffect(() => {
      extractActiveFilters(filterSections);
    }, [currentPage, customStartDate, customEndDate])
    
    useEffect(() => {
      let availableFilters = [];

      if(filterSections.length) {

        for (let sectionIndex = 0; sectionIndex < filterSections.length; sectionIndex++) {
          const section = filterSections[sectionIndex];
        
          for (let filterValIndex = 0; filterValIndex < section.filterValues.length; filterValIndex++) {
            const filterValue = section.filterValues[filterValIndex];
        
            if (filterValue.isSelected) {
              filterValue.filterType = 'base';
              filterValue.sectionIdx = sectionIndex; // Variable to remove item from the list on 'X' click
              filterValue.filterValIdx = filterValIndex; // Variable to remove item from the list on 'X' click
              availableFilters.push(filterValue);
            }
          }
        
          if (section?.selectedOption?.length) {
            for (let sectionOptionIndex = 0; sectionOptionIndex < section.selectedOption.length; sectionOptionIndex++) {
              const selectedOption = section.selectedOption[sectionOptionIndex];
        
              selectedOption.filterType = 'derived';
              selectedOption.sectionIdx = sectionIndex; // Variable to remove item from the list on 'X' click
              selectedOption.sectionOptionIndex = sectionOptionIndex; // Variable to remove item from the list on 'X' click
              availableFilters.push(selectedOption); 
            }
          }
        }        
      
        setAllChosenFiltersForDisplay([...availableFilters]);
      }
    }, [filterSections])
    

    const extractActiveFilters = (
      filters=filterSections, 
      durationVal  = selectedDuration, 
      sortbyVal = selectedSortBy, 
      startDate=null, 
      endDate=null
    ) => {
      const modifiedFilterSections = [...filters];            

      let selectedFilters = {};
      for (let filterOption = 0; filterOption < modifiedFilterSections.length; filterOption++) {
        for (let filterValue = 0; filterValue < modifiedFilterSections[filterOption].filterValues?.length; filterValue++) {
          if(modifiedFilterSections[filterOption].filterValues[filterValue].isSelected) {
            // If any filter item for a section is selected

            const value = modifiedFilterSections[filterOption].filterValues[filterValue];
            if(selectedFilters[modifiedFilterSections[filterOption].key]) {
              // if we already have a key inside the object that we are constructing

              // for team members we push ids instead of value 
              if(modifiedFilterSections[filterOption].key === 'teamMembers') {
                selectedFilters[modifiedFilterSections[filterOption].key].push(value);
              } else {
                selectedFilters[modifiedFilterSections[filterOption].key].push(value);
              }
              
            }else {
              // for team members we push ids instead of value 
              if(modifiedFilterSections[filterOption].key === 'teamMembers') {
                selectedFilters[modifiedFilterSections[filterOption].key] = [value]
              } else {
                selectedFilters[modifiedFilterSections[filterOption].key] = [value]
              }
              
            }

          }
        }

        for (let dropdownChosen = 0; dropdownChosen < modifiedFilterSections?.[filterOption]?.selectedOption?.length; dropdownChosen++) {
          if(modifiedFilterSections[filterOption].selectedOption[dropdownChosen].isSelected) {
            // selectedFilters.push(modifiedFilterSections[filterOption].selectedOption[dropdownChosen].label);

            const value = modifiedFilterSections[filterOption].selectedOption[dropdownChosen].label;
            if(selectedFilters[modifiedFilterSections[filterOption].key]) {
              selectedFilters[modifiedFilterSections[filterOption].key].push(value);
            }else {
              selectedFilters[modifiedFilterSections[filterOption].key] = [value]
            }

          }
        }
      }

      if(selectedFilters?.teamMembers?.length || selectedFilters?.teams?.length) {
        setDisableBulkReassignment(false);
      } else {
        setDisableBulkReassignment(true);
      }

      //send the extracted filters to the API
      // console.log(selectedFilters, durationVal, sortbyVal, 'extracted filters');

      selectedFilters.startDate = customStartDate;
      selectedFilters.endDate = customEndDate;

      selectedFilters.limit = totalItemsPerPage;
      selectedFilters.offset = (currentPage-1) * totalItemsPerPage;

      setChosenFilters({...selectedFilters, durationVal, sortBy:sortbyVal});
    }

    const fetchFilterOptionsForASubView = (activeSubviewDetails) => {
      setActiveSubViewInfo(activeSubviewDetails);
      
      if(activeSubviewDetails?.filterOptions) {
        
        setFilterSections([...activeSubviewDetails?.filterOptions]);
        setTemporayFiltersForFiltersPage([...activeSubviewDetails?.filterOptions]);

        extractActiveFilters([...activeSubviewDetails?.filterOptions], activeSubviewDetails?.duration, activeSubviewDetails?.sortBy);
      } else {
        setFilterSections([]);
        setTemporayFiltersForFiltersPage([]);
      }

      // add logic to get the totalSelectedFilter count - todo
      
    }

    const setSubViewAndFilterDetails = (subViews) => {
      // Following function block is responsible to set subview and set filters based on active subview.
      
      
      for (let i = 0; i < subViews.length; i++) {
        if(i === 0) {
          // Getting the subview that is highlighted
          // call a function that fetches the filters
          fetchFilterOptionsForASubView(subViews[i]);

          setSelectedDuration(subViews[i].duration || 'Last day');
          setSelectedSortBy(subViews[i].sortBy || 'Most Recent');

          setCustomStartDate(subViews[i].startDate || moment().format('YYYY-MM-DD'));
          setCustomEndDate(subViews[i].endDate || moment().format('YYYY-MM-DD'));

          subViews[i].isActive = true;
        } else {
          subViews[i].isActive = false;
        }
      }

      setCurrentPage(1);

      setListOfSubViews(subViews);
    }

    useEffect(() => {
      if (mitraReducer && mitraReducer.allowedFeatures) {
          const viewAccessRestricted = mitraReducer?.allowedFeatures[CONSTANTS.VIEW_DATA_ACCESS]?.isEnable
          if (viewAccessRestricted) {
              let temp_var = moment().subtract(mitraReducer?.allowedFeatures[CONSTANTS.VIEW_DATA_ACCESS]?.numberOfDays, 'days')
              setDisableDatesBefore(moment().subtract(mitraReducer?.allowedFeatures[CONSTANTS.VIEW_DATA_ACCESS]?.numberOfDays, 'days'))
          }

          setHasReassignAccess(mitraReducer?.allowedFeatures?.[CONSTANTS.CAN_REASSIGN_LEADS]?.isEnabledOverall)
      }
  }, [mitraReducer?.allowedFeatures])

    useEffect(() => {
      if(selectedViewDetails?.subViews?.length) {
        // getting list of subviews for the selected view
        // if a particular subview is highlighted from parent component
        // call the function that fetches filters for that sub-view from the API        
        setSubViewAndFilterDetails([...selectedViewDetails?.subViews]);
      }

      return ()=> {
        setTotalSelectedFilterCount(0);
        setCurrentPage(1);
      }
    }, [selectedViewDetails?.subViews]);

    const onSubViewClick = (idx) => {
      setCurrentActiveSubViewIndex(idx);
      setCurrentPage(1);

      // setting the subview and updating filters as per selected subview
      const modifiedSubViews = [...listOfSubViews];

      for (let i = 0; i < modifiedSubViews.length; i++) {
        if(i===idx) {
          modifiedSubViews[i].isActive = true;

          fetchFilterOptionsForASubView(modifiedSubViews[i]);

          setSelectedDuration(modifiedSubViews[i].duration);

          const modifiedSortByFilterItems = [...sortByFilterItems];
          for (let j = 0; j < modifiedSortByFilterItems.length; j++) {
            if(modifiedSortByFilterItems[j].name === modifiedSubViews[i].sortBy) {
              modifiedSortByFilterItems[j].isActive = true;
            } else {
              modifiedSortByFilterItems[j].isActive = false;
            }
          }
          setSortByFilterItems([...modifiedSortByFilterItems]);
          setSelectedSortBy(modifiedSubViews[i].sortBy);

          setCustomStartDate(modifiedSubViews[i].startDate || moment().format('YYYY-MM-DD'));
          setCustomEndDate(modifiedSubViews[i].endDate || moment().format('YYYY-MM-DD'));
          
        } else {
          modifiedSubViews[i].isActive = false;
        }
      }

      setListOfSubViews([...modifiedSubViews]);
    };

    const onSubViewClickFromDropdown = (key) => {

      const subView = listOfSubViews.find((subview) => subview.key === key);
      // setting the subview and updating filters as per selected subview      
      const modifiedSubViews = [...listOfSubViews];

      for (let i = 0; i < modifiedSubViews.length; i++) {
        if(modifiedSubViews[i].header===subView.header) {
          modifiedSubViews[i].isActive = true;

          fetchFilterOptionsForASubView(modifiedSubViews[i]);
        } else {
          modifiedSubViews[i].isActive = false;
        }
      }

      setListOfSubViews([...modifiedSubViews]);
    };

    const onFilterClick = (filterObj, filterSectionIndex, filterIndex, type="temporary") => {
      // function to check/uncheck filters
      // also modifies the count of selected filters in the filter popup

      const modifiedFilterSections = type === 'temporary' ? [...temporayFiltersForFiltersPage]:[...filterSections];

      // setActiveSubViewInfo({...activeSubviewInfo, isSaved: false});

      if(filterObj.isSelected) {
        // while unchecking the chosen filter, decrease the count of selected filter
        modifiedFilterSections[filterSectionIndex].selectedFilterCount -=1;
        setTotalSelectedFilterCount(totalSelectedFilterCount - 1);
      } else {
        modifiedFilterSections[filterSectionIndex].selectedFilterCount +=1;
        setTotalSelectedFilterCount(totalSelectedFilterCount + 1);
      }

      // the following statement accesses the filtervalues of the a specific filterSection 
      // using passed indices of both filterSection and filterValues and checks/unchecks it
      // here filterSection could be 'Clients', 'Last Milestone' and filterValues could be 'Swiggy', 'Unique' respectively
      modifiedFilterSections[filterSectionIndex].filterValues[filterIndex].isSelected = !filterObj.isSelected;

      setTemporayFiltersForFiltersPage([...modifiedFilterSections]);

      if(type !== 'temporary') {
        // Temporary means that the filter item is modified but not applied.
        // happens in case of popup.
        // but if the filter item is removed from the main filter bar on leads page then extract filters and call the API
        extractActiveFilters([...modifiedFilterSections]);
        setFilterSections([...modifiedFilterSections]);
      }

      setActiveSubViewInfo({...activeSubviewInfo, isSaved: false, filterOptions: [...modifiedFilterSections]});
      
    };


    const saveSubViewFilters = (isSaved) => {

      if(isSaved) {
        return;
      }

      // call API to save the subview
      // can send the filter options entirely with subview name to the BE to save it.
      const activeSubview = {...activeSubviewInfo, isSaved: true, duration: selectedDuration, sortBy: selectedSortBy, startDate: customStartDate, endDate: customEndDate} 
      setActiveSubViewInfo(activeSubview);
      
      submitViewsJson(selectedViewDetails.id, currentActiveSubViewIndex, activeSubview);
    }

    const onSearchCities = (label, filterIndex) => {
      const newFilterSections = [...temporayFiltersForFiltersPage];
      console.log(temporayFiltersForFiltersPage);

      if(!label) {
          // set dropdown values to empty arr
          newFilterSections[filterIndex].dropdownOptions = [];
          // setFilterSections([...newFilterSections]);
          setTemporayFiltersForFiltersPage([...newFilterSections]);
          return
      }

      let data = {
          search: label
      }
      getLocationForFilter(data).then(response => {
          if(response?.data?.locationList.length) {
              const locationData = [];

              for(let i=0;i<response?.data?.locationList?.length; i++) {
                  locationData.push({label:response?.data?.locationList[i], value: response?.data?.locationList[i]});
              }

              //set dropdown values in the filter obj
              newFilterSections[filterIndex].dropdownOptions = [...locationData];
              // setFilterSections([...newFilterSections]);
              setTemporayFiltersForFiltersPage([...newFilterSections]);
              
              // setCityOptions([...locationData]);
          } else {
              // set dropdown values to empty arr
              newFilterSections[filterIndex].dropdownOptions = [];
              // setFilterSections([...newFilterSections]);
              setTemporayFiltersForFiltersPage([...newFilterSections]);
          }
      }).catch(err=> {
          // set dropdown values to empty arr
          newFilterSections[filterIndex].dropdownOptions = [];
          // setFilterSections([...newFilterSections]);
          setTemporayFiltersForFiltersPage([...newFilterSections]);
      })
    }

    const onSearchText = (searchText, header, index) => {
      //basis search type, calling appropriate API
      if (header?.toLowerCase() === 'locations') {
        setTimeout(() => {
          onSearchCities(searchText, index);
        }, 300);
      }
    }

    const onSelectOption = (data, header, index) => {
      // as we select an option, using index, updating the values in relevant properties.
      const newSelectedData = data;
      const newFilterSections = JSON.parse(JSON.stringify([...temporayFiltersForFiltersPage]));
      
      newSelectedData.map((items)=>{
        items.isSelected = true
      });

      if(header.toLowerCase() === 'locations') {
        const popularCitiesToDataMap = {};
        for (let i = 0; i < newFilterSections?.[index]?.filterValues.length; i++) {
          // {'Bangalore': 1} || Here 1 is the index of the particular city in the filterValues array
          // Further using index we will tweak the flag to avoid iteration
          popularCitiesToDataMap[newFilterSections?.[index]?.filterValues[i]?.label] = `${i}`;
        }

        let finalNewSelectedData = newFilterSections[index].selectedOption ? [...newFilterSections[index].selectedOption]: []; // If data is present in filtervalues, restoring the values of 'finalNewSelectedData' with previously selected options

        const selectedDataLength = newSelectedData.length-1; // using the last index as that is the chosen value

        if((newSelectedData[selectedDataLength].label) in popularCitiesToDataMap) {
          if(!newFilterSections[index].filterValues[popularCitiesToDataMap[newSelectedData[selectedDataLength].label]].isSelected) {
            newFilterSections[index].selectedFilterCount += 1;
            setTotalSelectedFilterCount((prevVal)=> prevVal + 1);
          }
          newFilterSections[index].filterValues[popularCitiesToDataMap[newSelectedData[selectedDataLength].label]].isSelected = true;
        } else {
          finalNewSelectedData.push(newSelectedData[selectedDataLength]);
          newFilterSections[index].selectedFilterCount += 1;
          setTotalSelectedFilterCount((prevVal)=> prevVal + 1);
        }

        newFilterSections[index].selectedOption = [...finalNewSelectedData];
        // setFilterSections([...newFilterSections]); 
        setTemporayFiltersForFiltersPage([...newFilterSections]); 
      } else {
        setTotalSelectedFilterCount((prevVal)=> prevVal + 1);

        newFilterSections[index].selectedOption = [...newSelectedData];
        // setFilterSections([...newFilterSections]); 
        setTemporayFiltersForFiltersPage([...newFilterSections]); 
      }
            
    }

    const onRemoveNewOption = (filterObj, filterSectionIndex, filterIndex, type='temporary') => {
      // Removing selected option from searched dropdown values.
      // Finished for location
      const newFilterSections = type==='temporary' ? JSON.parse(JSON.stringify([...temporayFiltersForFiltersPage])):JSON.parse(JSON.stringify([...filterSections]));
       
      const newSelectedValues = newFilterSections[filterSectionIndex].selectedOption.filter(item=> item.label!=filterObj.label);

      setTotalSelectedFilterCount((prevVal)=> prevVal - 1);

      newFilterSections[filterSectionIndex].selectedOption = [...newSelectedValues];
      newFilterSections[filterSectionIndex].selectedFilterCount -= 1;

      if(type === 'temporary') {
        setTemporayFiltersForFiltersPage([...newFilterSections]);
      } else {
        setFilterSections([...newFilterSections]);
        extractActiveFilters([...newFilterSections]);
        setTemporayFiltersForFiltersPage([...newFilterSections]);
      }

      setActiveSubViewInfo({...activeSubviewInfo, isSaved: false, filterOptions: [...newFilterSections]});
    }

    const onClickFilterHeader = (filter) => {
      const modifiedFilterSections = [...temporayFiltersForFiltersPage];

      for (let i = 0; i < modifiedFilterSections.length; i++) {
        if(modifiedFilterSections[i].label === filter.label) {
          modifiedFilterSections[i].isExpanded = true;
        } else {
          modifiedFilterSections[i].isExpanded = false;
        }
      }

      // setFilterSections([...modifiedFilterSections]);
      setTemporayFiltersForFiltersPage([...modifiedFilterSections]);
    }

    const onClearAllFilters = () => {
      const modifiedFilterSections = JSON.parse(JSON.stringify([...filterSections]));

      for (let i = 0; i < modifiedFilterSections.length; i++) {
        for (let j = 0; j < modifiedFilterSections[i].filterValues.length; j++) {
          modifiedFilterSections[i].filterValues[j].isSelected = false
        }

        if(modifiedFilterSections[i]?.selectedOption?.length) {
          modifiedFilterSections[i].selectedOption = [];
        }

        modifiedFilterSections[i].selectedFilterCount = 0;
      }

      setTotalSelectedFilterCount(0);

      // setFilterSections([...modifiedFilterSections]);
      setTemporayFiltersForFiltersPage([...modifiedFilterSections]);
    }

    const onApplyFilters = () => {
      
      const newActiveSubviewInfo = activeSubviewInfo;

      newActiveSubviewInfo.filterOptions = [...temporayFiltersForFiltersPage];

      setActiveSubViewInfo({...newActiveSubviewInfo, isSaved: false});

      const newFilterSections = JSON.parse(JSON.stringify([...temporayFiltersForFiltersPage]));
      setFilterSections([...newFilterSections]);
      extractActiveFilters([...newFilterSections]);
      setOpenFilterModal(false);
      setShowSideSheet(false);
    };

    const onSectionFilterClick = (screenType) => {

      setTemporayFiltersForFiltersPage(JSON.parse(JSON.stringify([...filterSections])));

      let totalSelectedFilters = 0;
      const filterOptions = activeSubviewInfo.filterOptions || [];      

      // code that evaluates count of all the selected filters for a subview.
      for (const filterOption of filterOptions) {
        const filterValues = (Array.isArray(filterOption.filterValues) && filterOption.filterValues) || [];
        const selectedOptions = (Array.isArray(filterOption.selectedOption) && filterOption.selectedOption) || [];

        for(const filterValue of filterValues) {
            if(filterValue.isSelected) {
                totalSelectedFilters++;
            }
        }

        for(const selectedOption of selectedOptions) {
            if(selectedOption.isSelected) {
                totalSelectedFilters++;
            }
        }
      }

      setTotalSelectedFilterCount(totalSelectedFilters);

      // post calculation, opening the filter modal
      if(screenType === 'desktop') {
        setOpenFilterModal(true);
      }
      
    }

    const leadsPageFilterContent = () => (
      <div className='leads-filter-content custom-scroll-bar' style={window.innerWidth < 800 ? {paddingBottom: '100px'}: null}>
        {
          temporayFiltersForFiltersPage.map((filter, idx) => (
            <div className="single-filter-card new-theme-shadow-light" key={idx}>
              <div className='single-filter-card-header'>
                <div className='d-flex align-items-center justify-content-between mobile-extra-width' onClick={()=>onClickFilterHeader(filter)}>
                  <div className='d-flex align-items-center'>
                    <span className='inter-regular-16-24 color-252526 d-flex' style={{textTransform: 'capitalize'}}>{filter.label}</span>
                    {
                      filter.selectedFilterCount ? <div className='selected-subfilter-count'>{filter.selectedFilterCount}</div>: null
                    }
                  </div>
                  <div className='visibility-mobile'>
                    {
                      filter.isExpanded ? <UpArrow  height={18} width={18}/>: <DownArrow height={18} width={18}/>
                    }
                  </div>
                </div>

                {
                  filter.showSearchBox && (
                    // The following condition is to handle conditions to show the section for mobile and desktop
                    (
                      filter.isExpanded && window.innerWidth < 800) ||
                      window.innerWidth > 800
                  ) ? (
                    <CustomSearchDropdown 
                      placeholder={filter?.searchPlaceholder}
                      onSearch={(value)=>onSearchText(value, filter.label, idx)}
                      dropdownOptions={filter.dropdownOptions}
                      onSelect={(data)=>onSelectOption([...data], filter.label, idx)}
                      value={filter?.selectedOption}
                      checkboxGroupVisible={false}
                      inputBoxCustomClass='filter-search-box'
                      searchIconSize={16}
                      showSelection={false}
                      allowClear={true}
                      />
                  ): null
                }
              </div>

              {
                  (filter?.filterValues?.length || filter?.selectedOption?.length)   && (
                    // The following condition is to handle conditions to show the section for mobile and desktop
                    (
                      filter.isExpanded && window.innerWidth < 800) ||
                      window.innerWidth > 800
                  ) ? (
                    <div className="single-filter-card-content">

                      {
                        filter?.filterValues?.map((filterItem, filterItemIdx) =>(
                          <div 
                          onClick={()=>onFilterClick(filterItem, idx, filterItemIdx)}
                          className={`new-single-filter-item ${filterItem?.isSelected ? 'new-single-filter-item-selected':''}`}
                          key={`${idx} + ' ' + ${filterItemIdx}`}>
                            {
                              filterItem.logo ? <img src={filterItem.logo} style={{height:'32px', width:'32px', borderRadius: '4px', marginRight: '8px', objectFit: 'contain'}} />: null
                            }
                            <span className='inter-regular-14-20 color-1D5FFF' style={{textTransform: 'capitalize'}}>{filterItem.label}</span>
                            {
                              filterItem?.isSelected ? <CrossIcon strokeColor='#1D5FFF' height={12} width={12}/>: null
                            }
                          </div>
                        ))
                      }

                      {
                        filter?.selectedOption?.map((filterItem, filterItemIdx) =>(
                          <div 
                          onClick={()=>onRemoveNewOption(filterItem, idx, filterItemIdx)}
                          className={`new-single-filter-item ${filterItem?.isSelected ? 'new-single-filter-item-selected':''}`}
                          key={`${idx} + ' ' + ${filterItemIdx}`}>
                            <span className='inter-regular-14-20 color-1D5FFF'>{filterItem.label}</span>
                            {
                              filterItem?.isSelected ? <CrossIcon strokeColor='#1D5FFF' height={12} width={12}/>: null
                            }
                          </div>
                        ))
                      }
                    </div>
                  ): null
              }
              
            </div>
          ))
        }
        
      </div>
    );

    const customDateRangePicker = () => (
      <RangePicker
          format={dateFormat}
          onChange={changeDateRange}
          ranges={{
              Today: [moment().startOf('day'), moment().endOf('day')],
              "Last 1 Day": [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],
              "Last Week": [moment().subtract(1, 'weeks').startOf('week'), moment().subtract(1, 'weeks').endOf('week')],
              "Last 10 Days": [moment().subtract(10, 'days').startOf('day'),moment().endOf('day')],
              'This Month': [moment().startOf('month'), moment().endOf('month')],
          }}
          numberOfMonths={1}
          value={[moment(customStartDate), moment(customEndDate)]}
          allowClear={false}
          className="range-picker-leads custom-date-lp"
          style={{marginTop: '0px !important'}}
          disabledDate={fetchDisabledDates}
          getPopupContainer={trigger => trigger.parentNode}
      >
        Range Picker
      </RangePicker>
    )

    const getChosenFilters = (chosenFilter, chosenFilterIndex) => (
      <div className="leads-filter-item" key={`'chosenFilter' + ${chosenFilterIndex}`}>
          <span className="inter-regular-12-16 color-1D5FFF">{chosenFilter.label}</span>
          <div style={{cursor: 'pointer'}} onClick={()=> {
            chosenFilter.filterType === 'base' ? 
            onFilterClick(chosenFilter, chosenFilter.sectionIdx, chosenFilter.filterValIdx, 'direct-apply'):
            onRemoveNewOption(chosenFilter, chosenFilter.sectionIdx, chosenFilter.sectionOptionIndex, 'permanent')
          }}>
            <CrossIcon width={12} height={12} strokeColor="#1D5FFF" />
          </div>
      </div>
    )
    
  return (
    <>
      <div className="sub-view-filters-container new-theme-shadow">

        {
          !listOfSubViews.length ? <FilterSectionSkeleton />: (
            <>
              {/* Section to show subviews */}
              <div className="sub-view-header">
                <span className="inter-regular-14-20 plain-black">Show</span>
                <div className="sub-views visibility-desktop">
                  {
                    listOfSubViews?.map((subView, idx) => (
                      <div 
                      key={idx} 
                      style={{maxWidth: '150px'}}
                      onClick={()=>onSubViewClick(idx)}
                      className={`text-ellipsis single-sub-view inter-medium-14-20 ${subView.isActive ? 'color-1D5FFF active-sub-view': 'color-838383'}`}>
                        {subView.header}
                      </div>
                    ))
                  }
                </div>

                <div className='visibility-mobile'>
                  {/* <PopoverDropdown
                    content={listOfSubViews}
                    visible={showSubviewFilter}
                    setVisible={setShowSubviewFilter}
                    onSelect={(item) => onSubViewClickFromDropdown(item)}
                  >
                    <div className="custom-dropdown-box" style={{maxWidth: '120px'}}>
                      <span className="inter-medium-14-20 color-323232 text-ellipsis" style={{ flex: 4 }}>
                        {activeSubviewInfo?.header}
                      </span>
                      <div style={{ flex: 1 }}>{showSubviewFilter ? <UpArrow /> : <DownArrow />}</div>
                    </div>
                  </PopoverDropdown> */}

                  <Select
                      className="mobile-sortby-dd-filter"
                      style={{border: 0, width: '140px'}}
                      value={activeSubviewInfo?.header}
                      onChange={onSubViewClickFromDropdown}
                  >
                    {
                      listOfSubViews.map((item, index) => (
                        <Option key={index} value={item?.key}>{item?.header}</Option>
                      ))
                    }
                  </Select>
                  
                </div>

                <span className="inter-regular-14-20 plain-black">leads</span>

                <div style={{marginLeft: 'auto'}} className='d-flex align-items-center'>
                  {
                    selectedViewDetails?.value === 'default' ? null: (
                      <div onClick={()=>setOpenEditViewModal(true)} className='d-flex align-items-center flex-gap-4 cursor-pointer'>
                        <EditPencilIcon strokeColor="#1D5FFF" height={window.innerWidth < 800 ? 24: 16} width={window.innerWidth < 800 ? 24: 16}/>
                        <span className='btn-text-semibold-12-16 color-1D5FFF visibility-desktop'>Edit View</span>
                      </div>
                    )
                  }

                  <div className='visibility-mobile' style={{marginLeft: '12px'}} onClick={()=>{
                    setShowSideSheet(true);
                    onSectionFilterClick('mobile')
                  }}> 
                    <SettingsIcon />
                  </div>
                </div>

  
              </div>

              {/* Section that paints filters of selected subview */}
              <div className={`filter-view visibility-desktop`}>
                <div className='d-flex align-items-center flex-gap-16 lp-datepicker-customised'>
                  <span className="inter-regular-14-20 plain-black">From</span>
                  
                  {customDateRangePicker()}
                </div>
                <div className="selected-filters-section">
                  <span className="inter-regular-14-20 plain-black">Filtered by</span>

                  <div className="selected-filters-box">

                    {/* Section to show filters, max 3 */}
                    {
                      allChosenFiltersForDisplay?.map((chosenFilter, chosenFilterIndex) => (
                        chosenFilterIndex < 3 ? getChosenFilters(chosenFilter, chosenFilterIndex)
                        : null
                      ))
                    }

                    {/* Popover showing additionally selected filters, more than 3 */}
                    {
                      allChosenFiltersForDisplay?.length > 3 ? (
                      <Popover content={
                        <div className='d-flex align-items-center flex-gap-8 flex-wrap' style={{width: '300px'}}>
                          {
                            allChosenFiltersForDisplay?.map((chosenFilter, chosenFilterIndex) => (
                              chosenFilterIndex >= 3 ? getChosenFilters(chosenFilter, chosenFilterIndex)
                              : null
                            ))
                          }
                        </div>
                      } title={null}>
                        <span className='inter-regular-14-20 plain-black'>+{allChosenFiltersForDisplay.length - 3} more</span>
                      </Popover>
                    ): null
                    }

                    <div className="add-filter-btn" style={{ gap: '8px' }} onClick={()=>onSectionFilterClick('desktop')}>
                      <PlusIcon width={18} height={18} strokeColor="#1D5FFF" />
                      <span className="color-1D5FFF inter-semibold-12-18">Add Filter</span>
                    </div>
                  </div>

                  {
                    selectedViewDetails?.value === 'default' ? null: (
                      <div className={`save-subview ${activeSubviewInfo?.isSaved ? 'saved-subview':''}`} onClick={()=> saveSubViewFilters(activeSubviewInfo?.isSaved)}>
                        <BookmarkIcon strokeColor={activeSubviewInfo?.isSaved ? '#FFF': '#1D5FFF'}/>
                        <span className={`${activeSubviewInfo?.isSaved ? 'plain-white':'color-1D5FFF'} inter-semibold-14-16 `}>{activeSubviewInfo?.isSaved ? 'Saved': 'Save'}</span>
                      </div>
                    )
                  }
                  
                </div>
              </div>
            </>
          )
        }
        
      </div>

        {
          !listOfSubViews.length ? null: (
            <div className="pagination-section">
              <div className='d-flex align-items-center flex-gap-16 custom-checkbox-styles'>
                
                {
                  totalCandidatesCount ? (<span className='inter-regular-14-20 black85'>{totalCandidatesCount} leads</span>): null
                }

                {
                  totalCandidatesCount && mitraReducer?.allowedFeatures?.[CONSTANTS.CAN_REASSIGN_LEADS]?.isEnabledOverall ? (
                    <div 
                    className={`icon-link-container d-flex align-items-center flex-gap-4 cursor-pointer ${disableBulkReassignment ? 'disable-bulk-reassignment': ''}`} 
                    onClick={()=> disableBulkReassignment ? 1: setOpenReassignmentModal(true)}>
                      {
                        hasReassignAccess ? (
                        disableBulkReassignment ? (
                          <Popover className='d-flex flex-gap-4' content={'Select a team member to enable re-assignment'} title={null}>
                            <ReassignmentIcon />
                            <span className='inter-semibold-12-18 color-1D5FFF'>Re-assign</span>
                          </Popover>
                        ):(
                          <>
                            <ReassignmentIcon />
                            <span className='inter-semibold-12-18 color-1D5FFF'>Re-assign</span>
                          </>
                        )
                      ): null
                      }
                      
                    </div>
                  ): null
                }

                {
                  totalCandidatesCount && mitraReducer?.allowedFeatures?.[CONSTANTS.DOWNLOAD_DATA_ACCESS]?.isEnabledOverall ? (
                    <div 
                    onClick={()=>setOpenDownloadModal(true)}
                    className='icon-link-container d-flex align-items-center flex-gap-4 cursor-pointer new-ld-download-btn'>
                      <DownloadIcon />
                      <span className='inter-semibold-12-18 color-1D5FFF'>Download</span>
                    </div>
                  ): null
                }
              
                
              </div>

              <div className="filter-pagination-lvmc">

                <div className="lvmc-filter visibility-desktop">
                  <span className="inter-regular-14-20 plain-black">Sort by</span>
                  {/* <PopoverDropdown
                    content={sortByFilterItems}
                    visible={showSortByFilter}
                    setVisible={setShowSortByFilter}
                    onSelect={(item) => onSortByFilterSelection(item)}
                  >
                    <div className="custom-dropdown-box">
                      <span className="inter-medium-14-20 color-323232" style={{ flex: 4 }}>
                        {selectedSortBy}
                      </span>
                      <div style={{ flex: 1 }}>{showSortByFilter ? <UpArrow /> : <DownArrow />}</div>
                    </div>
                  </PopoverDropdown> */}

                  <Select
                      className="mobile-sortby-dd-filter"
                      style={{border: 0}}
                      value={selectedSortBy}
                      onChange={onSortBySelectionFromMobile}
                  >
                    {
                      sortByFilterItems.map((item, index) => (
                        <Option key={index} value={item?.id}>{item?.name}</Option>
                      ))
                    }
                  </Select>
                </div>

                {
                  totalCandidatesCount ? (
                    <NewCustomPagination
                      totalItems={totalCandidatesCount || 0}
                      totalItemsPerPage={totalItemsPerPage}
                      currentPage={currentPage}
                      onPageChange={handlePageChange}
                    />
                  ): null
                }
              
              </div>
          </div>
          )
        }


      {/* Section that paints popup to select filters */}
      <Modal 
        title={null}
        visible={openFilterModal}
        className="leads-filter-modal"
        closable={false}
        maskClosable={false}
        bodyStyle={{borderRadius: '8px',padding: '0px'}}
        footer={null}
        >
            <div className="leads-filter-header">

              <div className='d-flex align-items-center'>
                <span className='unbounded-semibold-20-24 plain-black'>
                  Add Filter 
                </span>
                {totalSelectedFilterCount ? <div className='selected-subfilter-count'>{totalSelectedFilterCount}</div>: null}
              </div>

              <div style={{cursor: 'pointer'}} onClick={()=>setOpenFilterModal(false) }>
                <CrossIcon height={18} width={18}/>
              </div>
            </div>

            {leadsPageFilterContent()}

            <div className="leads-filter-footer d-flex justify-content-between">
              <Button className='secondary-btn-styles-v2 leads-filter-footer-button' onClick={()=>onClearAllFilters() }> 
                <Dustbin height={16} width={16} strokeColor='#1D5FFF'/>
                <span className='btn-text-semibold-14-16 color-1D5FFF'>Clear Filters</span>
              </Button>
              <Button className='primary-btn-styles-v2 leads-filter-footer-button' onClick={()=>onApplyFilters()}> 
                <PlusIcon height={16} width={16} strokeColor='#FFF'/>
                <span className='btn-text-semibold-14-16 plain-white'>Add</span>
              </Button>
            </div>
        </Modal>

      {/* Section responsible for mobile sidebar for filters */}
      {
          showSideSheet ? (
              <SideSheetComponent 
              sidername={'Filters'} 
              hideDismissIcon={false}
              hideBottomButtons={true}
              containerStyle={{width:"85%"}}
              showNavigator={false}
              parentContainerStyles={{background: 'rgba(0, 0, 0, 0.74)'}}
              onDismiss={() => { setShowSideSheet(false)}}>

                      <div className="leads-filter-header">
                        <div className='d-flex align-items-center flex-gap-8'>
                          <SettingsIcon height={16} width={16} strokeColor='#000'/>
                          <span className='unbounded-semibold-20-24 plain-black'>Leads</span>
                        </div>
                        <div style={{cursor: 'pointer'}} onClick={()=>setShowSideSheet(false) }>
                          <CrossIcon height={18} width={18}/>
                        </div>
                      </div>

                      <div className='lp-mobile-filter-top-section'>
                        {/* Duration filter for mobile */}
                        <div className='d-flex align-items-center flex-gap-12 w-100'>
                          <span className="inter-regular-14-20 plain-black">From</span>
                          <DatePicker 
                          format={dateFormat}
                          value={moment(customStartDate)}
                          onChange={(date)=>changeDate(date, 'startDate')}
                          disabledDate={fetchDisabledDates}
                          allowClear={false}
                          getPopupContainer={(trigger) => trigger.parentNode}
                          style={{ width: '100%' }}
                          />
                        </div>

                        <div className='d-flex align-items-center flex-gap-12 w-100'>
                          <span className="inter-regular-14-20 plain-black">To</span>
                          <DatePicker 
                          format={dateFormat}
                          value={moment(customEndDate)}
                          onChange={(date)=>changeDate(date, 'endDate')}
                          disabledDate={(current) =>
                            current && current < moment(customStartDate) // Ensure end date is after start date
                          }
                          allowClear={false}
                          getPopupContainer={(trigger) => trigger.parentNode}
                          style={{ width: '100%' }}
                          />
                        </div>
                      </div>

                      <div className="lvmc-filter" style={{padding: '12px',backgroundColor: '#FDFAF9',gap: '12px'}}>
                        <span className="inter-regular-14-20 plain-black">Sort by</span>
                        {/* <PopoverDropdown
                          content={sortByFilterItems}
                          visible={showSortByFilter}
                          setVisible={setShowSortByFilter}
                          onSelect={(item) => onSortByFilterSelection(item)}
                        >
                          <div className="custom-dropdown-box" style={{width: 'none'}}>
                            <span className="inter-medium-14-20 color-323232">
                              {selectedSortBy}
                            </span>
                            <div>{showSortByFilter ? <UpArrow /> : <DownArrow />}</div>
                          </div>
                        </PopoverDropdown> */}

                        <Select
                            className="mobile-sortby-dd-filter"
                            style={{border: 0}}
                            value={selectedSortBy}
                            onChange={onSortBySelectionFromMobile}
                        >
                          {
                            sortByFilterItems.map((item, index) => (
                              <Option key={index} value={item?.id}>{item?.name}</Option>
                            ))
                          }
                        </Select>
                      </div>

                      <Divider style={{margin: '0px',backgroundColor: '#FDFAF9', padding: '12px 0px'}}/>

                      <div className='d-flex align-items-center' style={{backgroundColor: '#FDFAF9', textAlign: 'start', padding: '0px 12px'}}>
                        <span className='inter-semibold-16-24 plain-black'>Filters</span>
                        {totalSelectedFilterCount ? <div className='selected-subfilter-count d-flex align-items-center justify-content-center'>{totalSelectedFilterCount}</div>: null}
                      </div>

                      {leadsPageFilterContent()}

                      <div className="leads-filter-footer w-100 flex-gap-8" style={{position:'absolute',bottom: 0}}>
                        <Button className='secondary-btn-styles-v2 leads-filter-footer-button' onClick={()=>onClearAllFilters() }> 
                          <Dustbin height={16} width={16} strokeColor='#1D5FFF'/>
                          <span className='btn-text-semibold-14-16 color-1D5FFF'>Clear Filters</span>
                        </Button>
                        <Button className='primary-btn-styles-v2 leads-filter-footer-button' onClick={()=> onApplyFilters()}> 
                          <span className='btn-text-semibold-14-16 plain-white'>Save</span>
                        </Button>
                      </div>
              </SideSheetComponent>
          ): null
      }

      {/* Section to edit a view */}
      {
        openEditViewModal ? (
          <AddViewModal 
          openAddViewModal={openEditViewModal} 
          setOpenAddViewModal={setOpenEditViewModal} 
          getLeadsDashboardConfiguration={getLeadsDashboardConfiguration}
          selectedViewDetails={selectedViewDetails}/>
        ): null
      }

      {
        // Modal to download excel
        openDownloadModal ? (
          <ExportExcelModal 
          open={openDownloadModal}
          setOpen={setOpenDownloadModal}
          chosenFilters={chosenFilters}
          mitraReducer={mitraReducer}
          />
        ): null
      }
      
    </>
    
  );
}

export default FilterSection