import { Button, Checkbox, Input, Modal, notification } from 'antd'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import CrossIcon from '../../../static/svg/CrossIcon'
import PlusIcon from '../../../static/svg/PlusIcon'
import Lottie from 'react-lottie';
import LoadingAnimation from '../../../static/lottie/blue-circle-loader.json';
import BlueTickAnimation from '../../../static/lottie/blue-circle-tick.json';
import CONSTANTS from '../../../constants/constants';
import { AppContext } from '../../../App';
import { downloadNewLeadsPageReport } from '../../../service/LeadsPageV2Service';

const LAYOUTS = {
    'DOWNLOAD_LAYOUT': 'DOWNLOAD_LAYOUT',
    'LOTTIE_VIEW': 'LOTTIE_VIEW'
}

function ExportExcelModal({open, setOpen, chosenFilters}) {
    const [animation, setAnimation] = useState(LoadingAnimation);
    const [currentLayout, setCurrentLayout] = useState();
    const {mitraReducer, mitraDispatch} = useContext(AppContext);
    const [emailId, setEmailId] = useState(mitraReducer?.mitraInfo?.email);
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const [error, setError] = useState('');
    const [saveEmail, setSaveEmail] = useState(false);

    const handleEmailChange = (e) => {
        setEmailId(e.target.value);
        if (emailRegex.test(e.target.value)) {
          setError('');
        } else {
          setError('Invalid email ID');
        }
      };

    const destroyModalContents = () => {
        setCurrentLayout(LAYOUTS.WELCOME_LAYOUT);
        setOpen(false);
    }

    useEffect(() => {
      setCurrentLayout(LAYOUTS.DOWNLOAD_LAYOUT);
    }, [])
    
    const onDownload = () => {
        if(!emailId) {
            notification['error']({
                message: 'Add email ID to continue..'
            });

            setError('Enter email ID to continue..');
            return;
        }

        if(error) {
            notification['error']({
                message: 'Enter a valid email ID..'
            });

            return;
        }

        const payload = chosenFilters;

        payload.changeEmailBoolean = saveEmail;
        payload.email = emailId;

        if(saveEmail) {
            mitraDispatch({ type: 'LoginInfo', value: {...mitraReducer?.mitraInfo, email: emailId} });
        }

        downloadNewLeadsPageReport(payload, mitraReducer?.mitraInfo?.id)
        .then(res=> {
            if(res?.data?.success) {
                setCurrentLayout(LAYOUTS.LOTTIE_VIEW);
            } else {
                notification['error']({
                    message: 'Error downloading report, please try again later.'
                });
            }
        }).catch(err=> {
            notification['error']({
                message: 'Error downloading report, please try again later.'
            });
        })

    };
    
    const downloadLayout = () => (
        <>
            <div className="leads-filter-header">
                <span className='unbounded-semibold-20-24 plain-black'>Download</span>
                <div style={{cursor: 'pointer'}} onClick={()=>destroyModalContents() }>
                    <CrossIcon height={18} width={18}/>
                </div>
            </div>

            <div className="new-call-note-main-container custom-scroll-bar d-flex align-items-center justify-content-center">
                <div 
                className={`section-addition-main-container new-theme-shadow-light d-flex w-100 flex-gap-24 ${window.innerWidth<800 ? 'align-items-start flex-column': 'align-items-center flex-row'}`}>
                    <img src='/image/export-leads-excel.webp' style={{width: '96px', height:'96px', objectFit: 'contain'}} />
                    <div className='d-flex align-items-start flex-column flex-gap-24 w-100'>
                        <span className='inter-regular-16-24 color-252526'>Send file to email ID</span>
                        <div className='w-100 d-flex flex-column'>
                            <Input 
                            onChange={handleEmailChange}
                            value={emailId}
                            style={{fontWeight:'600',fontSize:'16px',color: '#000',fontFamily: 'Inter'}}
                            className={`samvaadini-search-candidate-new w-100 ${error ? 'input-error':'lr-input'}`}
                            placeholder=""/>
                            {
                                error ? (
                                    <span className='inter-regular-12-16 text-align-start' style={{color: '#e5696b',marginTop: '4px'}}>{error}</span>
                                ): null
                            }
                        </div>
                        <div className='d-flex align-items-center flex-gap-8'>
                            <div className='custom-checkbox-styles'>
                                <Checkbox 
                                checked={saveEmail}
                                onChange={(e)=>setSaveEmail(e.target?.checked)}
                                />
                            </div>
                            <span className='inter-regular-14-20 plain-black'>Save email ID for future</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="leads-filter-footer justify-content-end">
                <Button className='primary-btn-styles-v2 leads-filter-footer-button' onClick={()=>onDownload()}> 
                    <span className='btn-text-semibold-14-16 plain-white'>Send</span>
                </Button>
            </div>
        </>
    )

    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: animation,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
    };

    const handleAnimationComplete = () => {
        setAnimation(BlueTickAnimation);
    }

    const viewCreationAcknowledgementLayout = () => (
        <>
            <div className="leads-filter-header">
                <span className='unbounded-semibold-20-24 plain-black'>Add View</span>
                <div style={{cursor: 'pointer'}} onClick={()=>destroyModalContents() }>
                    <CrossIcon height={18} width={18}/>
                </div>
            </div>

            <div className='new-call-note-main-container align-items-center justify-content-center d-flex flex-column mobile-full-height'>
                <Lottie 
                options={defaultOptions}
                eventListeners={[
                    {
                      eventName: 'complete',
                      callback: handleAnimationComplete,
                    },
                  ]}
                height={animation === LoadingAnimation ? 120: 90}
                width={animation === LoadingAnimation ? 120: 90}
                speed={1}
                />
                <span className='inter-regular-16-24 color-252526'>
                    {
                        animation === LoadingAnimation ? 'Sending file to ' + emailId: 'File has been sent to ' + emailId
                    }
                </span>
            </div>
        </>
    );

  return (
    <Modal
    title={null}
    visible={open}
    className="leads-filter-modal"
    closable={false}
    maskClosable={false}
    destroyOnClose={true}
    bodyStyle={{borderRadius: '8px',padding: '0px'}}
    footer={null}
    >
        {
            currentLayout === LAYOUTS.DOWNLOAD_LAYOUT ? downloadLayout(): null
        }

        {
            currentLayout === LAYOUTS.LOTTIE_VIEW ? viewCreationAcknowledgementLayout(): null
        }
    </Modal>
  )
}

export default ExportExcelModal;