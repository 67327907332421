import * as React from "react";
const LogoutIcon = ({fillColor='none',height=16,width=16,strokeColor="#FF4545"}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill={fillColor}
  >
    <path
      d="M5.93311 5.04003C6.13977 2.64003 7.37311 1.66003 10.0731 1.66003H10.1598C13.1398 1.66003 14.3331 2.85337 14.3331 5.83337V10.18C14.3331 13.16 13.1398 14.3534 10.1598 14.3534H10.0731C7.39311 14.3534 6.15977 13.3867 5.93977 11.0267"
      stroke={strokeColor}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.0002 8H2.41357"
      stroke={strokeColor}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.89984 5.76672L1.6665 8.00006L3.89984 10.2334"
      stroke={strokeColor}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default LogoutIcon;
