import * as React from "react";
const ReloadIcon = ({height=25,width=25, fillColor='none',strokeColor='#1D5FFF', strokeWidth=1.5}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.069 5.83002C14.199 5.57002 13.239 5.40002 12.179 5.40002C7.38903 5.40002 3.50903 9.28002 3.50903 14.07C3.50903 18.87 7.38903 22.75 12.179 22.75C16.969 22.75 20.849 18.87 20.849 14.08C20.849 12.3 20.309 10.64 19.389 9.26002"
      stroke={strokeColor}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.3089 6.07L13.4189 2.75"
      stroke={strokeColor}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.309 6.07001L12.939 8.53001"
      stroke={strokeColor}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default ReloadIcon;
