import React, { useContext, useState } from 'react';
import { AppContext } from '../../App';
import './../../css/telecallerDashboard.scss';
import PageHeaderComponent from '../common/PageHeader';
import axiosInstance from '../../service/interceptorService';
import CONSTANTS from '../../constants/constants';

const TelecallerDashboard = () => {
  const {mitraReducer } = useContext(AppContext);
	const [breadcrumb, setbreadcrumb] = useState([
    {link: '/recruiter-candidates', label: 'My Follow Up Leads'},
    {link: 'recruiter-candidates', label: 'Telecaller Dashboard'}
  ]);

  const isGodMode = () => {
    return (sessionStorage.getItem('isGodMode') === 'true');
  }
  
  return (
    <div style={{}}>
          {
						mitraReducer?.allowedFeatures?.[CONSTANTS.VAHAN_PRO_ACCESS]?.isEnabledOverall ? (
							<span className='unbounded-semibold-24-28 plain-black hero-header' style={{marginBottom: '24px'}}>Telecaller Dashboard</span>
						): (
              <div className='header'>
                <PageHeaderComponent breadcrumb={breadcrumb} backBtnBool={true}/>
            </div>
            )
          }
        
        {
            mitraReducer && mitraReducer.mitraInfo && mitraReducer.mitraInfo.id &&
            <>
                <div className='telecaller-dashboard-desktop'>
                    <iframe src = {`${process.env.REACT_APP_TC_DASHBOARD_DESKTOP}?embed=true&logedInMitraId=${mitraReducer?.mitraInfo.id}&type=${isGodMode()?"godmode":"normalmode"}`} style={{display: 'block', width: '100%', height: '100vh', marginLeft: '20px'}} ></iframe>
                </div>
                <div className='telecaller-dashboard-mobile'>
                    <iframe src = {`${process.env.REACT_APP_TC_DASHBOARD_MOBILE}?embed=true&logedInMitraId=${mitraReducer?.mitraInfo.id}&type=${isGodMode()?"godmode":"normalmode"}`} style={{display: 'block', width: '100%', height: '100vh'}} ></iframe>
                </div>          
            </>  
        };
    </div>
  );
};
export default TelecallerDashboard;
