import React, { useEffect, useRef, useState } from 'react'
import PauseIcon from '../../../static/svg/PauseIcon';
import PlayIcon from '../../../static/svg/PlayIcon';

function Audio({url}) {

    // Variables to play audio for call recordings
    const audioRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [progress, setProgress] = useState(0);

    const handlePlayPause = () => {
        if (isPlaying) {
        audioRef.current.pause();
        } else {
        audioRef.current.play();
        }
        setIsPlaying(!isPlaying);
    };

    useEffect(() => {
        const audio = audioRef?.current;
        const updateProgress = () => {
            setProgress((audio.currentTime / audio.duration) * 100);
            if(((audio.currentTime / audio.duration) * 100) >=100) {
                setIsPlaying(false);
                setTimeout(() => {
                    setProgress(0); 
                }, 1000);
            }
        };
        
        if (audio) {
            audio.addEventListener('timeupdate', updateProgress);
            return () => {
                audio.removeEventListener('timeupdate', updateProgress);
            };
        }
    }, [url])
  return (
    <div className="audio-player-container mt-16 d-flex flex-row align-items-center w-100">
        <audio ref={audioRef} src={url} />
        {
            isPlaying ? <PauseIcon  onClick={handlePlayPause}/>: <div onClick={handlePlayPause}><PlayIcon /></div>
        }
        {/* <img src='/image/Pause.png' style={{height:'16px',width:'16px'}} onClick={handlePlayPause}/> */}
        {/* <input
            className="samvaadini-audio-progress-bar new-audio-bar"
            type="range"
            min="0"
            max="100"
            value={progress}
            onChange={handleProgressChange}
        /> */}

        <div className="audio-bar">
            <div className="track">
                <div className="track-fill" style={{width: `${parseInt(progress)}%`, transition: 'width 0.3s ease-in-out'}}></div>
                <div className='track-pointer' style={{left: `${parseInt(progress)}%`,transition: 'left 0.3s ease-in-out'}}>
                    <div className="pointer"></div>
                </div>
            </div>
            <div className="time-indicator d-flex justify-content-between" style={{marginTop: '4px'}}>
                <span className='inter-semibold-12-18 color-252526'>{audioRef?.current?.currentTime?.toFixed(1)}</span>
                <span className='inter-semibold-12-18 color-252526'>{isNaN(audioRef?.current?.duration?.toFixed(1)) ? "": audioRef?.current?.duration?.toFixed(1)}</span>
            </div>
        </div>
    </div>
  )
}

export default Audio