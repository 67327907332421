import { Button, Divider, Modal } from 'antd';
import React, { useState } from 'react';
import WavingHand from '../../../static/images/wave.gif';
import { UserOutlined, BellOutlined, ArrowRightOutlined, SearchOutlined, CloseOutlined } from '@ant-design/icons';
import ReactPlayer from 'react-player/lazy';

function SamvaadiniAdvertisement({isExpanded=false, header="AI Hot Leads", icon=WavingHand}) {
    const [showAd, setShowAd] = useState(isExpanded);
    const [startPlayingVideo, setStartPlayingVideo] = useState(false);

  return (
    <>
    <Modal 
    title={<span className='unbounded-semibold-24-28'>AI Hot Leads</span>}
    visible={startPlayingVideo} 
    onCancel={()=>setStartPlayingVideo(false)}
    footer={null}
    className="leads-filter-modal" 
    destroyOnClose={true}
    >
        <ReactPlayer
            className="normal-image"
            url={'https://mesl-uploads-2.s3.ap-southeast-1.amazonaws.com/Untitled+design+(1).mp4'}
            playing={false}
            controls
            muted={false}
            width="100%"
            height="100%"
        />
    </Modal>
        {
            showAd ? (
                <>
                <div className="advertisement-and-product-demo-container">
                    <div className="advertisement-and-product-demo">
                        <div className="hero-text show-ad mob-bottom-margin unbounded-semibold-24-28">
                            {header} <img src={icon} style={{width: '26px', height:'26px'}} />
                        </div>
                        <span className='placement-text'>Get <span className='highlighted-text'>2X Placements</span> everyday</span>
                        <span className='more-placement'>More placements and more money with AI</span>
                        <div className="demo-container">
                            <Button className='new-btn demo-btn btn-52h btn-width-250' onClick={()=>setStartPlayingVideo(true)}><span className='font-inter-white demo-video-text'>Watch Video</span></Button>
                            {/* <span className='learn-more-text'>Learn more <ArrowRightOutlined /></span> */}
                        </div>
                    </div>
                    <div className="ad-image-and-hide-option-container d-flex">
                        <img src='/image/samvaadini-v2-money-2.png' className='hero-img-show-ad'/>
                        <CloseOutlined className='close-ad' style={{fontSize: '24px', cursor:'pointer'}} onClick={()=>setShowAd(false)}/>
                    </div>
                </div>
                <Divider className='custom-divider'/>
                </>
            ): (
                <>
                    <div className="no-ad-container">
                        <div className="hero-text regular24-32 unbounded-semibold-24-28">
                            {header} <img src={icon} style={{width: '26px', height:'26px'}} />
                        </div>  
                        <div className="image-btn-container" onClick={()=>setShowAd(true)}>
                            <img src='/image/samvaadini-v2-money-2.png' className='hero-img-2'/>
                            <div className='placement-btn'><span className='placement-btn-text'>2X Placements</span></div>
                        </div>
                    </div> 
                    <Divider style={{minWidth: '90%',width:'90%', marginLeft: 'auto', marginRight:'auto'}}/>
                </>
            )
        } 
    </>
  )
}

export default SamvaadiniAdvertisement