import React, { useState, useEffect } from 'react';
import '../styles/NewCustomPagination.css';

const NewCustomPagination = ({ totalItems, totalItemsPerPage, currentPage, onPageChange }) => {
  const [page, setPage] = useState(currentPage);
  const totalPages = Math.ceil(totalItems / totalItemsPerPage);

  useEffect(() => {
    setPage(currentPage);
  }, [currentPage]);

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setPage(newPage);
      onPageChange(newPage);
    }
  };

  const renderPageNumbers = () => {
    let pages = [];

    if (totalPages <= 5) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(
          <button
            key={i}
            onClick={() => handlePageChange(i)}
            className={page === i ? 'pagination-btn active-pagination-btn' : 'pagination-btn'}
          >
            {i}
          </button>
        );
      }
    } else {
      if (page <= 3) {
        for (let i = 1; i <= 4; i++) {
          pages.push(
            <button
              key={i}
              onClick={() => handlePageChange(i)}
              className={page === i ? 'pagination-btn active-pagination-btn' : 'pagination-btn'}
            >
              {i}
            </button>
          );
        }
        pages.push(<span key="dots">...</span>);
      } else if (page > 3 && page < totalPages - 2) {
        pages.push(<button key="1" className={'pagination-btn'} onClick={() => handlePageChange(1)}>1</button>);
        pages.push(<span key="dots1">...</span>);
        for (let i = page - 1; i <= page + 1; i++) {
          pages.push(
            <button
              key={i}
              onClick={() => handlePageChange(i)}
              className={page === i ? 'pagination-btn active-pagination-btn' : 'pagination-btn'}
            >
              {i}
            </button>
          );
        }
        pages.push(<span key="dots2">...</span>);
        pages.push(
          <button className={'pagination-btn'} key={totalPages} onClick={() => handlePageChange(totalPages)}>
            {totalPages}
          </button>
        );
      } else {
        pages.push(<button key="1" className={'pagination-btn'} onClick={() => handlePageChange(1)}>1</button>);
        pages.push(<span key="dots">...</span>);
        for (let i = totalPages - 3; i <= totalPages; i++) {
          pages.push(
            <button
              key={i}
              onClick={() => handlePageChange(i)}
              className={page === i ? 'pagination-btn active-pagination-btn' : 'pagination-btn'}
            >
              {i}
            </button>
          );
        }
      }
    }
    return pages;
  };

  return (
    <div className="pagination">
    {/* {
      page === 1 ? null: (
        
      )
    } */}

      <button
        className={`pagination-btn ${page === 1 ? 'disabled-pagination-btn':'active-pagination-btn'}`}
        onClick={() => handlePageChange(page - 1)}
        disabled={page === 1}
      >
        {'<'}
      </button>
      
      {renderPageNumbers()}
      {/* {
        page === totalPages ? null: (
          
        )
      } */}

      <button
            className={`pagination-btn ${page === totalPages ? 'disabled-pagination-btn':'active-pagination-btn'}`}
            onClick={() => handlePageChange(page + 1)}
            disabled={page === totalPages}
          >
        {'>'}
      </button>
      
    </div>
  );
};

export default NewCustomPagination;
