import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../../../App';
import { Button, Divider, Input, Modal, Result, notification } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import { addApplicationCompleteFlag, triggerZeptoOtp, verifyZeptoOtp } from '../../../../service/ZeptoRecAdditionalInfoService';
import { useHistory } from "react-router-dom";
import '../styles/style.css'
import { createApplication } from '../../../../service/additionalInfoService';
import Lottie from 'react-lottie';
import OTPAnimation from '../../../../static/lottie/otp-animation.json';
import CopyTextCheckMark from '../../../../static/svg/CopyTextCheckMark';
import CopyIcon from '../../../../static/svg/CopyIcon';

const OTPTimerConstant = 30;
const LAYOUTS = {
  'OTP_LAYOUT': 'OTP_LAYOUT',
  'VERIFIED_SESSION_LAYOUT': 'VERIFIED_SESSION_LAYOUT',
  'SINGLE_BUTTON_REQUEST_OTP_LAYOUT': 'SINGLE_BUTTON_REQUEST_OTP_LAYOUT',
  'APP_LINK_COPIED_LAYOUT': 'APP_LINK_COPIED_LAYOUT',
  'MODAL_CLOSURE_CONFIRMATION': 'MODAL_CLOSURE_CONFIRMATION'
}

function ZeptoOtpScreen({ lead, isOtpVerified, setIsOtpVerified, determineCurrentStep, submissionData }) {
  const [otp, setOtp] = useState(['', '', '', '', '', '']);
  const inputRef = [];
  const [showResendOtpTimer, setShowResendOtpTimer] = useState(false);
  const [timerToShow, setTimerToShow] = useState(OTPTimerConstant);
  const [currentLayout, setCurrentLayout] = useState();
  const { mitraReducer, mitraDispatch, setAlert, setSpin } = useContext(AppContext);
  const history = useHistory();
  const [showLeadReferredModal, setShowLeadReferredModal] = useState(false);
  const [showOtpPopupLayout, setShowOtpPopupLayout] = useState(true);
  const [copyOperationSuccessful, setCopyOperationSuccessful] = useState(false);
  const [activeTab, setActiveTab] = useState('Enter Otp');
  const [isApplicationCreated, setIsApplicationCreated] = useState(false);
  const [isLinkCopied, setIsLinkCopied] = useState(false);
  const [isCodeCopied, setIsCodeCopied] = useState(false);
  let otpTimerInterval;
  let timerVal;

  const showLoader = (value) => {
    setSpin({
      loading: value, //false or true when actived
      delay: null,
      tip: 'Loading...'  //Loading copy here
    })
  }

  const handleOTPChange = (text, index) => {
    if (/^\d*$/.test(text)) {
      const otpArray = otp;

      // Update the OTP array with the new input
      otpArray[index] = text;

      // Move focus to the next input or the previous one if the text is deleted
      if (text.length === 1 && index < otp.length - 1) {
        inputRef[index + 1].focus();
      } else if (text.length === 0 && index > 0) {
        inputRef[index - 1].focus();
      }

      setOtp([...otpArray]);

      // When all inputs are filled, you can do something with the OTP (e.g., submit it)
      if (otpArray.join('').length === otp.length) {
        const otp = otpArray.join('');
        // Do something with the OTP, e.g., send it to a server for verification
      }
    }
  };

  const sendOTPToLead = async () => {
    // function to trigger OTP
    if (lead?.phoneNumber) {
      const payload = {
        phoneNumber: lead?.phoneNumber
      }

      triggerZeptoOtp(payload)
        .then(res => {

          console.log(res);
          if (res?.data?.success) {
            notification['success']({
              message: 'OTP sent successfully.',
            });
          }

        })
        .catch(err => {
          console.log(err);
          notification['error']({
            message: 'Something went wrong, please try again after sometime.',
          });
        })
    }
  }

  const triggerOtp = () => {
    sendOTPToLead();

    timerVal = OTPTimerConstant;
    setShowResendOtpTimer(true);

    otpTimerInterval = setInterval(() => {
      if (timerVal <= 0) {
        clearInterval(otpTimerInterval);
        setShowResendOtpTimer(false);
      } else {
        timerVal = timerVal - 1;
        setTimerToShow(timerVal < 10 ? `0${timerVal}` : timerVal);
      }
    }, 1000);

  };

  const closeModalAndGoToNextStep = () => {
    setShowLeadReferredModal(false);
    setIsOtpVerified(true);
  }

  const submitWithoutOTP = () => {
    if (!lead?.scheduledInterviewId?.length || lead?.scheduledInterviewId === 'null') {
      if(submissionData?.jobDemandId && submissionData?.jobId) {
        lead.jobDemandId = submissionData?.jobDemandId;
        lead.jobId = submissionData?.jobId;
      }
      createApplicationForZepto(true);
    }
    // else {
    //   goToCandidatesScreen()
    // }
  }

  const createApplicationForZepto = (isSubmittingWithoutOTP = false) => {
    // code to create SI
    if (isSubmittingWithoutOTP) {
      showLoader(true);
    }

    createApplication({}, mitraReducer?.mitraInfo, lead)
      .then(res => {
        console.log(res);
        if (isSubmittingWithoutOTP) {
          // goToCandidatesScreen()
          showLoader(false);
          setIsApplicationCreated(true);
        }

        if(res?.data?.createApplication !== '200') {
          notification['error']({
            message: 'Error referring the candidate!!'
          });
        } else {
          notification['success']({
            message: 'Application completed successfully.'
          });
        }
      }).catch(err => {
        console.log(err);
        notification['error']({
          message: 'Error referring the candidate!!'
        })
      })
  }

  const submitOtp = async () => {

    if (otp.join('').length !== otp.length) {
      notification['error']({
        message: 'Please enter the OTP to continue',
      });

      return;
    }

    showLoader(true);
    try {
      const payload = {
        otp: otp.join(''),
        phoneNumber: lead?.phoneNumber,
        city: lead?.companyCity
      }

      const response = await verifyZeptoOtp(payload);

      if (response?.data?.success) {
        if (!lead?.scheduledInterviewId?.length || lead?.scheduledInterviewId === 'null') {
          setShowLeadReferredModal(true);
          createApplicationForZepto();
        } else {
          closeModalAndGoToNextStep();
        }
      } else {
        notification['error']({
          message: response?.data?.message || 'Something went wrong, please try after sometime.',
        });
      }
      showLoader(false);
    } catch (e) {
      showLoader(false);
      notification['error']({
        message: 'Something went wrong, please try again after sometime.',
      });
    }
  };

  const enableOtpFormAndTriggetOtpLayout = () => {
    setCurrentLayout(LAYOUTS.OTP_LAYOUT);
    triggerOtp();
  }

  const goToCandidatesScreen = () => {
    history.replace({ pathname: "/recruiter-candidates" });
  }

  useEffect(() => {
    if (isOtpVerified) {
      // setCurrentLayout(LAYOUTS.VERIFIED_SESSION_LAYOUT);
      // now if the otp is verified then directly go to next step
      determineCurrentStep();
    } else {
      setCurrentLayout(LAYOUTS.SINGLE_BUTTON_REQUEST_OTP_LAYOUT);
    }
  }, [isOtpVerified])

  useEffect(() => {
    if(lead?.scheduledInterviewId?.length && lead?.scheduledInterviewId !== 'null') {
      setIsApplicationCreated(true);
    }
  }, [lead])
  

  const goToCurrentStep = () => {
    determineCurrentStep();
  }

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: OTPAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const crossButtonOperation = () => {
    // if application is created, i.e si id is present then close the popup and move to candidates page
    // if the si is not present and layout is otp layout then show donot close layout

    if(!isApplicationCreated) {
      // show layout resisting closing the modal
      setCurrentLayout(LAYOUTS.MODAL_CLOSURE_CONFIRMATION);
    } else {
      history.push('/recruiter-candidates')
    } 
  }

  const copyLink = async(info) => {
    try {

        let copyText = '';
        if(info === 'code') {
          setIsCodeCopied(true);
          setIsLinkCopied(false);

          copyText = 'A0351';
        } else {
          setIsCodeCopied(false);
          setIsLinkCopied(true);

          copyText = `${process.env.REACT_APP_BACKEND_URL}/zepto/appDownloadLink?rhId=${lead?.recommendationHistoryId}`;
        }

        await navigator.clipboard.writeText(copyText);
        
        setCurrentLayout(LAYOUTS.APP_LINK_COPIED_LAYOUT);

      } catch (err) {
        console.log(err);
      }

    submitWithoutOTP(); // As per new flow, creating the SI as soon as the user clicks on copy link
    
    try {
      const data = await addApplicationCompleteFlag({rhId: lead?.recommendationHistoryId });

      if(!data?.data?.status) {
        notification['error']({
          message: 'Error saving application completion flag',
        });
      }
    } catch(err) {
      notification['error']({
        message: 'Error saving application completion flag',
      });
    }
  }

  const triggerOTPSection = () => (
    <>
      <span className='bold18-26 plain-black'>Enter lead OTP</span>
      <Lottie 
        options={defaultOptions}
        height={200}
        width={200}
        speed={1}
        />
      <Button className='vl-primary-btn' onClick={() => enableOtpFormAndTriggetOtpLayout()}>
        <span className='semibold14-22 plain-white'>Send OTP to rider</span>
      </Button>
    </>
  )

  const enterOtpSection = () => (
    <>
      <span className='bold18-26 plain-black'>Enter lead OTP</span>
      <div>The OTP will be sent to {lead?.name} on (+91)-{lead?.phoneNumber}</div>

      <div className='d-flex flex-gap-8'>
        {
          otp.map((item, index) => {
            return (
              <Input
                key={index}
                className='otp-input-popup'
                maxLength={1}
                value={item}
                ref={(ref) => (inputRef[index] = ref)}
                onChange={(e) => handleOTPChange(e.target.value, index)}
              />
            )
          })
        }
      </div>

       {
          showResendOtpTimer ? (
            <div style={{ marginTop: 24 }}>{`Resend OTP in (00:${timerToShow})`}</div>
          ) : (
            <div>
              <Button className='button-styles-secondary' onClick={() => triggerOtp()}>Request OTP</Button>
            </div>
          )
        }
      
      <Button className='vl-primary-btn' onClick={() => submitOtp()}>
        <span className='semibold14-22 plain-white'>Submit OTP and Verify</span>
      </Button>
    </>
  )

  const copyAppLinkSection = () => (
    <div className='referral-link-container-box section-width-dsktp '>
      <span style={{color:'#000000'}} className='bold16-24'>Share the link with the lead</span>
      <span style={{color:'#000000A6'}} className='semibold14-22 mt-20'>Share the link below with the lead for downloading the app</span>
      <Button className='border-none vl-secondary-btn-wo-border mt-16 align-items-center flex-gap-8 d-flex' onClick={()=>copyLink('link')}>
        {
            isLinkCopied ? <CopyTextCheckMark strokeColor='var(--primary-theme-color)' height={20} width={20} style={{marginTop:'-6px'}}/>:<div className='mt-2'><CopyIcon strokeColor='var(--primary-theme-color)' height={24} width={24}/></div>
        }
        <span style={{color: 'var(--primary-theme-color)'}} className='semibold14-22'>
          {
            isLinkCopied ? 'Link Copied': 'Copy Link'
          }
          
        </span>
      </Button>
      <Divider style={{margin: '32px 0px'}}>or</Divider>
      <span className='semibold14-22' style={{color:'#000000A6'}}>Ask the lead to enter the referral code <b>A0351</b> in the <b>rider app</b></span>
      <Button className='border-none vl-secondary-btn-wo-border mt-16 align-items-center flex-gap-8 d-flex' onClick={()=>copyLink('code')}>
        {
            isCodeCopied ? <CopyTextCheckMark strokeColor='var(--primary-theme-color)' height={20} width={20} style={{marginTop:'-6px'}}/>:<div className='mt-2'><CopyIcon strokeColor='var(--primary-theme-color)' height={24} width={24}/></div>
        }
        <span style={{color: 'var(--primary-theme-color)'}} className='semibold14-22'>
          {
            isCodeCopied ? 'Referral Code Copied': 'Copy Referral Code'
          }
          
        </span>
      </Button>
    </div>
  )

  const applicationCreatedWithoutOtpLayout = () => (
    <div className='d-flex flex-column mobile-full-height' style={{background: '#FFEAE0'}}>
      <span style={{color:'#000000D9'}} className='semibold20-28 mt-24'>Application has been created.</span>
      <div className='inner-highlighted-section'>
        <span>Share the link below with the lead for downloading the app</span>
        <Button className='border-none vl-secondary-btn-wo-border mt-16 align-items-center flex-gap-8 d-flex' onClick={()=>copyLink('link')}>
          {
              isLinkCopied ? <CopyTextCheckMark strokeColor='var(--primary-theme-color)' height={20} width={20} style={{marginTop:'-6px'}}/>:<div className='mt-2'><CopyIcon strokeColor='var(--primary-theme-color)' height={24} width={24}/></div>
          }
          <span style={{color: 'var(--primary-theme-color)'}} className='semibold14-22'>
            {
              isLinkCopied ? 'Link Copied': 'Copy Link'
            }
            
          </span>
        </Button>
        <Divider style={{margin: '32px 0px'}}>or</Divider>
        <span className='semibold14-22' style={{color:'#000000A6'}}>Ask the lead to enter the referral code <b>A0351</b> in the <b>rider app</b></span>
        <Button className='border-none vl-secondary-btn-wo-border mt-16 align-items-center flex-gap-8 d-flex' onClick={()=>copyLink('code')}>
          {
              isCodeCopied ? <CopyTextCheckMark strokeColor='var(--primary-theme-color)' height={20} width={20} style={{marginTop:'-6px'}}/>:<div className='mt-2'><CopyIcon strokeColor='var(--primary-theme-color)' height={24} width={24}/></div>
          }
          <span style={{color: 'var(--primary-theme-color)'}} className='semibold14-22'>
            {
              isCodeCopied ? 'Code Copied': 'Copy Referral Code'
            }
            
          </span>
        </Button>
      </div>
    </div>
  )

  const closeConfirmationLayout = () => (
    <div className='d-flex flex-column align-items-center'>
      <img src='/image/incomp-app.png' style={{height:'144px',width:'144px',objectFit:'contain'}} />
      <span className='bold20-28 plain-black mt-12'>Are you sure you want to leave?</span>
      <span className='regular16-24 mt-24' style={{color: '#FF4D4F'}}>Your application is still incomplete.</span>
      <span className='regular16-24 plain-black mt-24'>With an incomplete application, <b>you wouldn&apos;t get payouts for the lead</b>.</span>

      <Divider />
      <div className='d-flex justify-content-end flex-gap-16 w-100'>
        <Button className='vl-primary-btn' onClick={()=>setCurrentLayout(LAYOUTS.SINGLE_BUTTON_REQUEST_OTP_LAYOUT)}>Cancel</Button>
        <Button className='vl-secondary-btn' onClick={()=>history.push('/recruiter-candidates')}>Confirm</Button>
      </div>
    </div>
  )

  const otpPopupLayout = () => (
    <>
      <Modal
      visible={showOtpPopupLayout}
      title="Complete Lead Application"
      closable
      onCancel={crossButtonOperation}
      className='otp-layout-modal'
      footer={null}
      bodyStyle={{padding: currentLayout === LAYOUTS.APP_LINK_COPIED_LAYOUT ? '0px': '24px'}}
      >
        {
          currentLayout === LAYOUTS.APP_LINK_COPIED_LAYOUT ? ( // modal contents when the app link or code is copied
            applicationCreatedWithoutOtpLayout()
          ): 
          currentLayout === LAYOUTS.MODAL_CLOSURE_CONFIRMATION ? (
            closeConfirmationLayout()
          ):(
            // Desktop Layout
            <div className='d-flex flex-column mobile-full-height'>

              {
                submissionData?.isApplicationCompletedWithoutOtp || lead?.scheduledInterviewId ? (
                  <span style={{color:'#000000D9'}} className='semibold20-28 mt-24'>Application has been created.</span>
                ): (
                  <>
                    <span className='bold20-28 color-FF4D4F' style={{fontFamily: 'var(--primary-font-family)'}}>Your application is still incomplete</span>
                    <span style={{color:'#000000A6'}} className='regular16-24 mt-24'>Choose one of the options to complete the application</span>
                  </>
                )
              }
              

              {
                window.innerWidth < 800 ? (
                  // For Mobile
                  <div className='mt-40'>
                    <div className="zepto-otp-top-section">
                      <div className="zepto-otp-tab-container d-flex">
                          <div
                              className={`zepto-otp-single-tab ${activeTab === 'Enter Otp' ? 'zepto-otp-active-tab' : ''}`}
                              onClick={() => setActiveTab('Enter Otp')}
                          >
                              Enter OTP
                          </div>
                          <div
                              className={`zepto-otp-single-tab ${activeTab === 'Share Link' ? 'zepto-otp-active-tab' : ''}`}
                              onClick={() => setActiveTab('Share Link')}
                          >
                              Share Link
                          </div>
                      </div>
                    </div>

                    {
                      activeTab === 'Enter Otp' ? (
                        <div className='referral-link-container-box otp-container-box section-width-dsktp mt-24'>
                          {
                            currentLayout === LAYOUTS.SINGLE_BUTTON_REQUEST_OTP_LAYOUT ? triggerOTPSection(): null
                          }

                          {
                            currentLayout === LAYOUTS.OTP_LAYOUT ? enterOtpSection(): null
                          }
                        </div>
                      ): <div className="mt-24">{copyAppLinkSection()}</div>
                    }
                  </div>
                ): (
                  // For Desktop
                  <div className='d-flex justify-content-between mt-16 dynamic-direction'>
                    <div className='referral-link-container-box otp-container-box section-width-dsktp '>
                      {
                        currentLayout === LAYOUTS.SINGLE_BUTTON_REQUEST_OTP_LAYOUT ? triggerOTPSection(): null
                      }

                      {
                        currentLayout === LAYOUTS.OTP_LAYOUT ? enterOtpSection(): null
                      }
                    </div>

                    {copyAppLinkSection()}
                  </div>
                )
              }
              
            </div>
          )
        }
        
      </Modal>
    </>
  );

  return (
    <>
      <Modal title="Lead Referred"
        visible={showLeadReferredModal}
        onOk={closeModalAndGoToNextStep}
        onCancel={goToCandidatesScreen}
        okText={'Complete Additional Form'}
        cancelText={'Go To Candidates Page'}
        className='lead-referred-modal'
      >
        <Result
          status="success"
          title={<div style={{ fontSize: '22px' }}>{`${lead?.name} has been referred successfully!`}</div>}
        />
      </Modal>
      {/* {
        currentLayout === LAYOUTS.OTP_LAYOUT ? otpLayout() : null
      }

      {
        currentLayout === LAYOUTS.SINGLE_BUTTON_REQUEST_OTP_LAYOUT ? singleButtonRequestOtpLayout() : null
      }

      {
        currentLayout === LAYOUTS.VERIFIED_SESSION_LAYOUT ? verifiedLayout() : null
      }

      <div className='note-wo-otp'>
        NOTE: If you&apos;re submitting the form without an OTP, please make sure to ask the rider to enter the following code in the referral code section: <span className='wo-otp-code'>A0351</span>
      </div> */}

      
        {
          otpPopupLayout()
        }
    </>
  )
}

export default ZeptoOtpScreen