import React, { useContext, useEffect, useState } from 'react'
import LocationPin from '../../../static/svg/LocationPin';
import { Button } from 'antd'
import moment from 'moment';
import SupportChatBot from './SupportChatBot';
import LeadDetailsPopup from '../../LeadsV2/LeadDetailsPopup';
import { AppContext } from '../../../App';

function HelpCard({ticketDetails, getAllSupportConversations}) {
    const [showChatbot, setShowChatbox] = useState(false);
    const [openDetailsModal, setOpenDetailsModal] = useState(false);
    const {mitraReducer} = useContext(AppContext);
    const [leadInfoForDetailsPopup, setLeadDetailsForDetailsPopup] = useState({});

    useEffect(() => {

        const leadInfo = {
            applications: [
                {
                    companyLogo:ticketDetails?.company_logo, 
                    companyName: ticketDetails?.company_name,
                    applicationId: ticketDetails?.rh_id,
                    recommendationHistoryId: ticketDetails?.rh_id
                }], 
            leadPoolId: ticketDetails?.leads_pool_id, 
            userId: ticketDetails?.user_id, 
            candidatePhoneNumber: ticketDetails?.user_phone_number, 
            candidateFirstName: ticketDetails?.name
        };

        setLeadDetailsForDetailsPopup(leadInfo);
    }, [ticketDetails])
    

  return (
    <>
        <div className='help-card-container'>
            <div className='d-flex align-items-center flex-gap-8 w-100'>
                <img src={ticketDetails?.company_logo} style={{height: '48px',width:'48px',borderRadius:'4px',objectFit:'contain'}} />
                <div className='d-flex align-items-start flex-column flex-gap-8'>
                    <span 
                    onClick={()=>setOpenDetailsModal(true)}
                    className='plain-black inter-semibold-16-24 cursor-pointer help-name'>{ticketDetails?.name}</span>
                    <div className='d-flex align-items-center flex-gap-12'>
                        <span className='inter-regular-12-16 color-252526'>{ticketDetails?.user_phone_number}</span>
                        <div className='d-flex align-items-center flex-gap-4'>
                            <LocationPin />
                            <span className='inter-regular-12-16 color-252526'>{ticketDetails?.job_city}</span>
                        </div>
                        
                    </div>
                </div>
            </div>

            {/* <span className='plain-black inter-regular-12-16'>#1</span>

            <span className='color-252526 inter-regular-12-16'>Activation Issue</span> */}

            <div className='help-card-child-elements'>
                <span className='color-252526 inter-regular-12-16'>{ticketDetails?.createdAt ? moment(ticketDetails.createdAt).format('DD MMM YYYY, hh:mm a'): '-'}</span>
            </div>

            <div className='rilc-divider visibility-mobile' style={{marginTop: '24px',marginBottom: '-8px'}}></div>

            <div className='help-card-child-elements'>
                <div className='d-flex align-items-center flex-gap-8'>
                    <div 
                    style={{background: ticketDetails?.status?.toLowerCase() === 'closed' ? '#838383':'#D7AF1D'}}
                    className='active-dot-hc'></div>
                    <span className='inter-semibold-14-16 text-capitalise' 
                    style={{color: ticketDetails?.status?.toLowerCase() === 'closed' ? '#838383':'#D7AF1D'}}
                    >
                        {ticketDetails?.status?.toLowerCase() === 'closed' ? 'Resolved': 'Active'}
                    </span>
                </div>
            </div>

            <div className='help-card-child-elements'>
                <Button style={{border: 'none',background: 'transparent', boxShadow: 'none'}} onClick={()=>setShowChatbox(true)}>
                    {
                        ticketDetails?.status?.toLowerCase() === 'closed' ? (
                            ticketDetails?.is_read ? (
                                <img src='/image/closed-chat-read.png' style={{height: '24px', width: '24px',objectFit: 'contain'}} />
                            ): (
                                <img src='/image/closed-chat-unread.png' style={{height: '24px', width: '24px',objectFit: 'contain'}} />
                            )
                        ):(
                            ticketDetails?.is_read ? (
                                <img src='/image/Chat_Circle_Dots.png' style={{height: '24px', width: '24px',objectFit: 'contain'}} />
                            ): (
                                <img src='/image/chat-circle-dot-unread.png' style={{height: '24px', width: '24px',objectFit: 'contain'}} />
                            )
                        )
                    }
                    
                </Button>
            </div>
        </div>

        {
            showChatbot ? <SupportChatBot 
            setShowChatbox={setShowChatbox} 
            source={'candidate_popup'}
            getAllSupportConversations={getAllSupportConversations}
            sourceRhId={ticketDetails?.rh_id}
            conversationId={ticketDetails?.conversation_id}/>: null
        }

        {
          openDetailsModal ? <LeadDetailsPopup 
          openDetailsModal={openDetailsModal} 
          setOpenDetailsModal={setOpenDetailsModal} 
          filters={null} // used for reassignment
          leadInfo={leadInfoForDetailsPopup} //{applications: [{companyLogo}], leadPoolId, userId, candidatePhoneNumber, candidateFirstName, }
          getLeadsDashboardConfiguration={()=>1} // used for reassignment and post call notes
          setShowChatbox={setShowChatbox}
          setSelectedRhId={()=>1}
          setSupportConversationId={()=>1} // used to trigger support conversation from popup
          supportConversationId={ticketDetails?.conversation_id}
          source={'help_section'}
          mitraReducer={mitraReducer}/>: null
        }
    </>
    
  )
}

export default HelpCard