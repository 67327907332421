import * as React from "react";
const CallingIcon = ({fillColor='none',height=16,width=16,strokeColor="#838383"}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill={fillColor}
  >
    <path
      d="M14.6468 12.2199C14.6468 12.4599 14.5935 12.7066 14.4802 12.9466C14.3668 13.1866 14.2202 13.4133 14.0268 13.6266C13.7002 13.9866 13.3402 14.2466 12.9335 14.4133C12.5335 14.5799 12.1002 14.6666 11.6335 14.6666C10.9535 14.6666 10.2268 14.5066 9.46016 14.1799C8.6935 13.8533 7.92683 13.4133 7.16683 12.8599C6.40016 12.2999 5.6735 11.6799 4.98016 10.9933C4.2935 10.2999 3.6735 9.57325 3.12016 8.81325C2.5735 8.05325 2.1335 7.29325 1.8135 6.53992C1.4935 5.77992 1.3335 5.05325 1.3335 4.35992C1.3335 3.90659 1.4135 3.47325 1.5735 3.07325C1.7335 2.66659 1.98683 2.29325 2.34016 1.95992C2.76683 1.53992 3.2335 1.33325 3.72683 1.33325C3.9135 1.33325 4.10016 1.37325 4.26683 1.45325C4.44016 1.53325 4.5935 1.65325 4.7135 1.82659L6.26016 4.00659C6.38016 4.17325 6.46683 4.32659 6.52683 4.47325C6.58683 4.61325 6.62016 4.75325 6.62016 4.87992C6.62016 5.03992 6.5735 5.19992 6.48016 5.35325C6.3935 5.50659 6.26683 5.66659 6.10683 5.82659L5.60016 6.35325C5.52683 6.42659 5.4935 6.51325 5.4935 6.61992C5.4935 6.67325 5.50016 6.71992 5.5135 6.77325C5.5335 6.82659 5.5535 6.86659 5.56683 6.90659C5.68683 7.12659 5.8935 7.41325 6.18683 7.75992C6.48683 8.10659 6.80683 8.45992 7.1535 8.81325C7.5135 9.16659 7.86016 9.49325 8.2135 9.79325C8.56016 10.0866 8.84683 10.2866 9.0735 10.4066C9.10683 10.4199 9.14683 10.4399 9.1935 10.4599C9.24683 10.4799 9.30016 10.4866 9.36016 10.4866C9.4735 10.4866 9.56016 10.4466 9.6335 10.3733L10.1402 9.87325C10.3068 9.70659 10.4668 9.57992 10.6202 9.49992C10.7735 9.40659 10.9268 9.35992 11.0935 9.35992C11.2202 9.35992 11.3535 9.38659 11.5002 9.44659C11.6468 9.50659 11.8002 9.59325 11.9668 9.70659L14.1735 11.2733C14.3468 11.3933 14.4668 11.5333 14.5402 11.6999C14.6068 11.8666 14.6468 12.0333 14.6468 12.2199Z"
      stroke={strokeColor}
      strokeMiterlimit={10}
    />
    <path
      d="M12.3333 6.00008C12.3333 5.60008 12.02 4.98675 11.5533 4.48675C11.1267 4.02675 10.56 3.66675 10 3.66675"
      stroke={strokeColor}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.6667 5.99992C14.6667 3.41992 12.58 1.33325 10 1.33325"
      stroke={strokeColor}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default CallingIcon;
