import React, { Fragment, useState } from 'react';
import '../styles/HelpAutomation.scss';
import PlayIconWhite from '../../../static/svg/PlayIconWhite';
import { Divider, Modal } from 'antd';
import ReactPlayer from 'react-player';
import UpArrow from '../../../static/svg/UpArrow';
import DownArrow from '../../../static/svg/DownArrow';

const VIDEOS = {
    CREATE_TICKET: 'https://mitra-app-assets.s3.ap-south-1.amazonaws.com/Vahan+Leader+App+Assets/How+to+raise+support+issue.mp4',
    TACKLE_ACTIVATION_ISSUE: 'https://mitra-app-assets.s3.ap-south-1.amazonaws.com/Vahan+Leader+App+Assets/How+to+tackle+an+ID+activation+issue.mp4',
    NAVIGATE_THROUGH_TICKETS: 'https://mitra-app-assets.s3.ap-south-1.amazonaws.com/Vahan+Leader+App+Assets/How+to+navigate+through+existing+tickets.mp4'
}

function HelpGuide() {
    const [activeTab, setActiveTab] = useState('Videos');
    const [startPlayingVideo, setStartPlayingVideo] = useState(false);
    const [videoUrl, setVideoUrl] = useState('');
    const mobileVideoInfoList = [
        {
            img: '/image/support/raise-ticket.png',
            videoLink: VIDEOS.CREATE_TICKET
        },
        {
            img: '/image/support/id-activation.png',
            videoLink: VIDEOS.TACKLE_ACTIVATION_ISSUE
        },
        {
            img: '/image/support/check-raised.png',
            videoLink: VIDEOS.NAVIGATE_THROUGH_TICKETS
        }
    ];

    const [faqs, setFaqs] = useState([
        {
            id: 0,
            title: 'How much time will it take to get the first resolution?',
            isExpanded: false,
            content: 'It usually takes up to 5 mins to get the first resolution.'
        },
        {
            id: 1,
            title: 'Will the agents be available here for help?',
            isExpanded: false,
            content: 'Yes, the agents will be available to help you with a resolution.'
        },
        {
            id: 2,
            title: 'Where can I raise concerns about the support team?',
            isExpanded: false,
            content: <span>You can reach out to us on <b>vahanpro.support@vahan.co</b> for any feedback.</span>
        }
    ]);

    const setVideoUrlAndStartVideoPlayer = (url) => {
        setVideoUrl(url);
        setStartPlayingVideo(true);
    }

    const videoSection = (videoUrl=VIDEOS.CREATE_TICKET, thumbnail='/image/support/raise-ticket.png') => (
        <div className='hero-image-container-vph' onClick={()=>setVideoUrlAndStartVideoPlayer(videoUrl)}>
            <img src={thumbnail} className='vph-hero-image'/> 
        </div> 
    );

    const onFaqClick = (item) => {
        const modifiedFaqs = [...faqs];

        for (let i = 0; i < modifiedFaqs.length; i++) {
            if(modifiedFaqs[i].id === item.id) {
                modifiedFaqs[i].isExpanded = !modifiedFaqs[i].isExpanded;
            } else {
                modifiedFaqs[i].isExpanded = false;
            }
        }

        setFaqs([...modifiedFaqs]);
    }

    const faqSection = () => {
        return (

            <div className='d-flex flex-column flex-gap-12 mt-12' style={{zIndex: '1'}}>
                {
                    faqs.map((item, index) =>(
                        <div key={index} className="single-filter-card new-theme-shadow-light" style={{padding: '12px'}}>
                            <div className='single-filter-card-header flex-column align-items-start flex-gap-12'>
                                <div className='d-flex align-items-start justify-content-between mobile-extra-width w-100'>
                                    <div className='d-flex align-items-center cursor-pointer' onClick={()=>onFaqClick(item)}>
                                        <span className='unbounded-regular-14 color-252526 d-flex text-align-start'>{item?.title}</span>
                                    </div>
                                    <div style={{marginLeft: '12px'}}>
                                        {
                                            item.isExpanded ? <UpArrow  height={18} width={18}/>: <DownArrow height={18} width={18}/>
                                        }
                                        
                                    </div>
                                </div>
        
                                {
                                    item.isExpanded ? (
                                        <span className='text-align-start inter-regular-14-20'>{item.content}</span>
                                    ): null
                                }
                            </div>
                        </div>
                    ))
                }
            </div>
            
        )
    }
  return (
    <>
        <div className='videos-and-faqs-container new-theme-shadow visibility-desktop flex-wrap'>

            {
                videoSection()
            }

            <div className='help-video-stack'>
                <div>
                    <div className='video-thumbnail-small' onClick={()=>setVideoUrlAndStartVideoPlayer(VIDEOS.TACKLE_ACTIVATION_ISSUE)}>
                        <img src='/image/support/id-activation-desktop.png' style={{height: '100%', width: '100%',objectFit: 'contain'}} />
                    </div>
                    <span className='inter-regular-12-16 plain-black text-align-start'>How to solve ID Activation issue?</span>
                </div>
                <div>
                    <div className='video-thumbnail-small' onClick={()=>setVideoUrlAndStartVideoPlayer(VIDEOS.NAVIGATE_THROUGH_TICKETS)}>
                        <img src='/image/support/raised-issue-desktop.png' style={{height: '100%', width: '100%',objectFit: 'contain'}} />
                    </div>
                    <span className='inter-regular-12-16 plain-black text-align-start'>How to navigate through tickets?</span>
                </div>
            </div>

            <div className='line-divider'>
            </div>

            <div className='d-flex flex-column align-items-start' style={{flex: 1,position: 'relative'}}>
                <span className='unbounded-semibold-16-24 plain-black'>FAQs</span>
                    {faqSection()}
                <img src='/image/help-other-img.webp' className='vph-hero-other'/> 
            </div>
        </div>

        <div className='videos-and-faqs-container new-theme-shadow visibility-mobile flex-column'>
            <div className='filtered-candidate-top-section' style={{margin: '0px', width: '100%'}}>
                <div className="filtered-candidate-tab-container d-flex" style={{paddingLeft: '12px'}}>
                    <div
                        className={`filtered-candidate-single-tab ${activeTab === 'Videos' ? 'filtered-candidate-active-tab' : ''}`}
                        onClick={() => setActiveTab('Videos')}
                    >
                        Videos
                    </div>
                    <div
                        className={`filtered-candidate-single-tab ${activeTab === 'FAQs' ? 'filtered-candidate-active-tab' : ''}`}
                        onClick={() => setActiveTab('FAQs')}
                    >
                        FAQs
                    </div>
                </div>
            </div>

            {
                activeTab === 'Videos' ? (
                    <div className="card-tile-container margin-horizontal-32">
                        {
                            mobileVideoInfoList.map((item, idx) => {
                                return (
                                    <div key={idx} className='shaded-tile-container' style={{border: 'none'}}>
                                        <div className='single-tile' style={{padding: '0px'}}>{videoSection(item.videoLink, item.img)}</div>
                                    </div>
                                )
                                
                            })
                        }
                    </div>
                ): null
            }

            {
                activeTab === 'FAQs' ? (
                    <div className='d-flex align-items-start flex-column' style={{padding: '16px'}}>
                        {faqSection()}
                    </div>
                ): null
            }
            
        </div>

        <Modal 
        title={<span className='unbounded-semibold-24-28'>Vahan Support</span>}
        visible={startPlayingVideo}
        className="leads-filter-modal" 
        onCancel={()=>setStartPlayingVideo(false)}
        footer={null}
        destroyOnClose={true}
        >
            <ReactPlayer
                className="normal-image"
                url={videoUrl}
                playing={false}
                controls
                muted={false}
                width="100%"
                height="100%"
            />
        </Modal>
    </>
  )
}

export default HelpGuide