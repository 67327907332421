import React, { useEffect, useState } from 'react'
import './AddCallNoteStyles.css';
import CONSTANTS from '../../constants/constants';
import PopoverDropdown from '../SamvaadiniV2/components/PopoverDropdown';
import UpArrow from '../../static/svg/UpArrow';
import DownArrow from '../../static/svg/DownArrow';
import { Button, Checkbox, Input, notification } from 'antd';
import PlusIcon from '../../static/svg/PlusIcon';
import { addCustomCallNotes } from '../../service/kaleyraCallService';

const { TextArea } = Input;

function AddCallNote({applications, referralDetails, leadDetails, mitraReducer, resetModal}) {
    const [callConnectedTags, setCallConnectedTags] = useState(JSON.parse(JSON.stringify([...CONSTANTS.CALL_TAGS.CONNECTED_TAGS])));
    const [callNotConnectedTags, setCallNotConnectedTags] = useState(JSON.parse(JSON.stringify([...CONSTANTS.CALL_TAGS.NOT_CONNECTED_TAGS])));
    
    const [clientList, setClientList] = useState([]);
    const [selectedClient, setSelectedClient] = useState('');
    const [selectedRecommendationHistoryId, setSelectedRecommendationHistoryId] = useState(referralDetails?.recommendationHistoryId);
    const [showClientList, setShowClientList] = useState(false);

    const [followupOptions, setFollowupOptions] = useState([
        {
            id: 0,
            label: '1 Hour',
            key: 'hour',
            isChecked: false
        },
        {
            id: 1,
            label: 'Tomorrow',
            key: 'tomorrow',
            isChecked: false
        },
        {
            id: 2,
            label: 'Custom',
            key: 'custom',
            isChecked: false
        },
    ]);

    const [remark, setRemark] = useState(null);

    const [connectionTypes, setConnectionTypes] = useState([
        {
            id: 0,
            header: 'Connected',
            key: 'connected',
            isActive: true,
            statuses: ''
        },
        {
            id: 0,
            header: 'Not Connected',
            key: 'not_connected',
            isActive: false
        }
    ]); 

    const [selectedType, setSelectedType] = useState('connected');

    const onSelect = (idx) => {
        const modifiedConnectionTypes = [...connectionTypes];

        for (let i = 0; i < modifiedConnectionTypes.length; i++) {
        if(i === idx) {
            modifiedConnectionTypes[i].isActive = true;
            setSelectedType(modifiedConnectionTypes[i].key);
        } else {
            modifiedConnectionTypes[i].isActive = false;
        }
        }

        setConnectionTypes([...modifiedConnectionTypes]);
    };

    useEffect(() => {

        if(applications?.length) {
            const modifiedApplications = [...applications];

            modifiedApplications.map(application=> application.name = application.companyName);

            setSelectedClient(modifiedApplications[0].name);
            modifiedApplications[0].isActive = true;

            setClientList([...modifiedApplications]);
        }
    }, [applications])

    const onCheckboxValueChange = (index, val) => {
        const newFollowupOptions = [...followupOptions];

        newFollowupOptions[index].isChecked = val?.target?.checked;
        setFollowupOptions([...newFollowupOptions]);
    }

    const onCommentChange = (event) => {
        setRemark(event.target.value)
    }
    

    const onTagChange = (tagList, tagId, tagType) => {
        const modifiedTags = JSON.parse(JSON.stringify([...tagList]));

        for (let i = 0; i < modifiedTags.length; i++) {
            if(modifiedTags[i].id === tagId) {
                modifiedTags[i].isChecked = true;
            } else {
                modifiedTags[i].isChecked = false;
            }
        }

        if(tagType === 'CONNECTED') {
            setCallConnectedTags(JSON.parse(JSON.stringify([...modifiedTags])));
        } else {
            setCallNotConnectedTags(JSON.parse(JSON.stringify([...modifiedTags])));
        }
    };

    const onClientListSelect = (item) => {        
        const modifiedApplications = [...applications];
        for (let i = 0; i < modifiedApplications.length; i++) {
            if(modifiedApplications[i].applicationId === item.applicationId) {
                setSelectedClient(item.name);
                setSelectedRecommendationHistoryId(modifiedApplications[i].recommendationHistoryId);
                modifiedApplications[i].isActive = true;
            } else {
                modifiedApplications[i].isActive = false;
            }
        }
    };

    const onSubmitCallNote = () => {

        let selectedTag;

        if(selectedType === 'connected') {
            for (let i = 0; i < callConnectedTags.length; i++) {
                if(callConnectedTags[i].isChecked) {
                    selectedTag = callConnectedTags[i].value;
                    break
                }
            }
        } else {
            for (let i = 0; i < callNotConnectedTags.length; i++) {
                if(callNotConnectedTags[i].isChecked) {
                    selectedTag = callNotConnectedTags[i].value;
                    break
                }
            }
        }

        const payload = {
            mitraId: mitraReducer?.mitraInfo?.id,
            userId: leadDetails?.userId,
            recommendationHistoryId: selectedRecommendationHistoryId || null,
            managerMitraId: mitraReducer?.mitraInfo?.managerMitraID,
            comment: remark,
            tag: selectedTag,
            isFollowUpNeeded: false,
            callerNumber: mitraReducer?.mitraInfo?.phoneNumber,
            receiverNumber: leadDetails?.candidatePhoneNumber,
            isCallConnected: selectedType === 'connected'
        }

        if(!payload.tag) {
            notification['error']({
                message: 'Select a status to continue. '
            });
            return;
        }

        addCustomCallNotes(payload)
        .then(res=> {
            if(res?.data?.status) {
                notification['success']({
                    message: 'Call log registered.'
                })
                resetModal();
            } else {
                notification['error']({
                    message: 'Error registering call log.'
                })
            }
            
        }).catch(err=> {
            notification['error']({
                message: 'Error registering call log.'
            });
            console.log(err);
        })
    }

  return (
    <>
        <div className='new-call-note-main-container custom-scroll-bar'>
            <div className="call-connection-type-tab-container new-theme-shadow-light">
                <div className="views-container" style={{padding: '0px'}}>
                    <div className="views">

                        {
                        
                            connectionTypes.map((filter, idx) => (
                            <div 
                            key={idx} 
                            className={`single-connection ${filter.isActive ? (filter.key === 'connected' ? 'current-active-connection active-connected': 'current-active-connection active-not-connected'): 'color-AFAFAF'}`} 
                            onClick={()=>onSelect(idx)}
                            >
                                {filter.header}
                            </div>
                            ))
                        
                        }
                    </div>
                </div>

                <span className='mt-24 d-flex inter-regular-14-20 color-323232'>Select Status <span style={{top: '-5px',color: '#FF4545'}}>*</span></span>

                <div className='call-tags-v2'>
                    {
                        selectedType === 'connected' ? (
                            callConnectedTags?.map((item,index) => {
                                return (
                                    <div 
                                    onClick={()=> onTagChange(callConnectedTags, item.id, 'CONNECTED')}
                                    key={item.id} 
                                    className={`color-1D5FFF individual-tag ${item.isChecked ? 'active-tag inter-semibold-14-16':'inter-regular-14-20'}`}
                                    >
                                        {item.value}
                                    </div>
                                )
                            })
                        ):(
                            callNotConnectedTags?.map((item,index) => {
                                return (
                                    <div 
                                    onClick={()=> onTagChange(callNotConnectedTags, item.id, 'NOT_CONNECTED')}
                                    key={item.id} 
                                    className={`color-1D5FFF individual-tag ${item.isChecked ? 'active-tag inter-semibold-14-16':'inter-regular-14-20'}`}
                                    >
                                        {item.value}
                                    </div>
                                )
                            })
                        )
                    }
                </div>
            </div>

            {
                leadDetails?.leadType === CONSTANTS.TYPE_OF_LEADS.PRE_REFERRAL ? null: (
                    <div className={`call-connection-type-tab-container new-theme-shadow-light mt-24 d-flex ${window.innerWidth<800 ? 'align-items-start': 'align-items-center'} flex-gap-16`}>
                        <span className='d-flex inter-regular-14-20 color-323232'>Select Client <span style={{top: '-5px',color: '#FF4545'}}>*</span></span>
                        <PopoverDropdown
                            content={clientList}
                            visible={showClientList}
                            setVisible={setShowClientList}
                            onSelect={(item) => onClientListSelect(item)}
                        >
                            <div className={`custom-dropdown-box custom-dropdown-box ${window.innerWidth<800 ? 'w-100':''}`}>
                            <span className="inter-medium-14-20 color-323232" style={{ flex: 4, textTransform:'capitalize' }}>
                                {selectedClient || 'Select Client'}
                            </span>
                            <div style={{ flex: 1 }}>{showClientList ? <UpArrow /> : <DownArrow />}</div>
                            </div>
                        </PopoverDropdown>
                    </div>
                )
            }

            {/* <div className={`call-connection-type-tab-container new-theme-shadow-light mt-24 d-flex ${window.innerWidth<800 ? 'align-items-start': 'align-items-center'} flex-gap-16`}>
                <span className='d-flex inter-regular-14-20 color-323232'>Follow Up</span>
                <div className='d-flex align-items-center flex-gap-16'>
                {
                    followupOptions.map((item, index) => (
                        <div key={item.key} className='d-flex align-items-center flex-gap-4'>
                            <div className='custom-checkbox-styles'>
                                <Checkbox checked={item.isChecked} onChange={(val)=>onCheckboxValueChange(index, val)} />
                            </div>
                            <span className='d-flex inter-regular-14-20 color-323232'>{item.label}</span>
                        </div>
                    ))
                }
                </div>
            </div> */}

            <TextArea
                placeholder="Add a remark.."
                autoSize={{ minRows: 2, maxRows: 6 }}
                onChange={onCommentChange}
                value={remark}
                className='call-note-text-area-vp'
            />
        </div>
        <div className="leads-filter-footer">
            <Button className='primary-btn-styles-v2 leads-filter-footer-button' onClick={()=>onSubmitCallNote()}> 
            <PlusIcon height={16} width={16} strokeColor='#FFF'/>
            <span className='btn-text-semibold-14-16 plain-white'>Add</span>
            </Button>
        </div>
    </>
    
  )
}

export default AddCallNote