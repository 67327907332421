import { Space, Typography, notification, Card, Timeline, Button, Tabs, Empty } from 'antd';
import {
    CloseCircleOutlined,
    UploadOutlined,
    CheckCircleOutlined,
  } from '@ant-design/icons';
import '../../css/lead.scss';
import './connectingCall.css'
import React, { useContext, useEffect, useState } from 'react';
import {useHistory} from 'react-router-dom';
import { AppContext } from '../../App';
import { getCallStatus, triggerRecruiterCall, getCallHistory } from '../../service/kaleyraCallService';
import { getViewDetailsData } from '../../service/leadService';
import moment from 'moment';
import { AdditionalFormsURLEnum } from '../../utils/utility';
import CallHistoryCard from '../leads/candidateProfilePopupV2Components/callHistory';
import { RouterPrompt } from '../RouterPrompt/RouterPrompt';

const { Text, Title} = Typography;
const {TabPane} = Tabs;

const ConnectingCallScreen = () => {
    const {mitraReducer} = useContext(AppContext);
    const [leadDetails, setLeadDetails] = useState([]);
    const [callHistory, setCallHistory] = useState([]);
    const [showPrompt, setShowPrompt] = useState(true);

    const history = useHistory();
    let status;

    const fetchCallStatus = (callId) => {
        status = setInterval(()=> {
            getCallStatus(callId).then(response => {
                // return response, check the property
                if (response.data.receiverStatus != null) {
                    clearInterval(status);
                    const callAnswered = response.data.receiverStatus === 'ANSWER' ? true:false;
                    setShowPrompt(false);
                    setTimeout(() => {
                        history.push(`/call-status?callId=${callId}&&callAnswered=${callAnswered}`);
                    }, 1500);
                }
                // code for if the caller itself doesnt pick the call because of any reason
                if (response.data.callerStatus === 'NOANSWER' || response.data.callerStatus==='FAILED') {
                    clearInterval(status);
                    notification['error']({
                        message: 'Error in processing the call !!'
                      });
                    setShowPrompt(false);
                    setTimeout(() => {
                        history.push('/recruiter-candidates');
                    }, 1500);
                }
            }).catch(error=> {
                //some response
                clearInterval(status)
            })
        },2000);
    };

    const fetchCallId = () => {
        // fetch id and pass it to callStatus API
        const apiParams = {
            fromNumber: mitraReducer.kaleyraCallInformation.callerNumber,
            toNumber: mitraReducer.kaleyraCallInformation.receiverNumber,
            leadId: mitraReducer.kaleyraCallInformation.currentLead.id,
            mitraId: mitraReducer.mitraInfo.id,
            managerMitraId: mitraReducer.mitraInfo.managerMitraID,
            scheduledInterviewId: mitraReducer.kaleyraCallInformation.currentSelectedSiId,
            bridgeNumber: mitraReducer.mitraInfo.bridgeNumber
        };
        triggerRecruiterCall(apiParams).then(response=> {
            console.log('triggerRecruiterCall',response.data.id);
            fetchCallStatus(response.data.id);
        }).catch(err=> {
            console.log(err);
            // Navigate to other page or call the same api
            notification['error']({
                message: 'Bridge Number is not assigned to you, Please contact support !!'
              });
              setShowPrompt(false);
              setTimeout(() => {
                  history.push('/recruiter-candidates');
              }, 1500);
        });
    }

    useEffect(()=> {
        // trigger the callApi, fetch the id from it, use it to trigger status api
        if (!mitraReducer || !mitraReducer.kaleyraCallInformation) 
        {
            setShowPrompt(false);
                    setTimeout(() => {
                        history.push('/recruiter-candidates');
                    }, 1500);

        }
        else {
            fetchCallId();
            let data = {
            recommendationHistoryIds: mitraReducer.kaleyraCallInformation.currentLead.recommendationHistoriesId,
            scheduledInterviewId: mitraReducer.kaleyraCallInformation.currentSelectedSiId,
            };
           
            getViewDetailsData(data).then((result) => {
                setLeadDetails(result.data);
            });

            let apiParams = {
                id: mitraReducer.kaleyraCallInformation.currentLead.id,
                scheduledInterviewId: mitraReducer.kaleyraCallInformation.currentSelectedSiId,
              };
              getCallHistory(apiParams).then((response)=> {
                setCallHistory([...response.data]);
              }).catch((error)=> {
                console.log(error);
              });
        }

        return () => {
            clearInterval(status);
        }
    },[]);

    const getCombinedErrorMsg = (milestone) => {
    let documentStatuses = milestone.value;
    let documentUploadCompleted = milestone.documentUploadCompleted;
    let documentUploadStarted = milestone.documentUploadStarted;
    let documentUploadErrorMsg = '';
    let erroredDocuments = [];
    documentStatuses.forEach(statusMsg => {
        if(!statusMsg.toLowerCase().includes('success')) {
        erroredDocuments.push(statusMsg.split('-')[0].trim());
        }
    });

    if(erroredDocuments && erroredDocuments.length > 0) {
        documentUploadErrorMsg = `Oh ho! There are some isues with – ${erroredDocuments.join(', ')}. Kindly reupload`;
    } else if (documentUploadStarted && documentUploadCompleted){
        documentUploadErrorMsg ='Documents Accepted';
    } else if (documentUploadStarted && !documentUploadCompleted) {
        documentUploadErrorMsg = 'Processing documents, please check again in some time.';
    }
    return documentUploadErrorMsg;
    };
    
    const displayTimelineData = (item, index) => {
        return (
            <Timeline>
                {item.timeline?.map((milestone, index) => {
                    let milestoneValue = milestone.value;
                    if (milestone.url) {
                    milestoneValue = (
                        <a href={milestone.url} rel="noreferrer" target="_blank">
                        {milestone.value}
                        </a>
                    );
                    }
                    return (
                    <Timeline.Item
                        key={'card_profile_' + milestone.label + index}
                        dot={
                        (milestone.date !== ''  || milestone.date === 'NO_DATE_UBER') ? (
                            <CheckCircleOutlined />
                        ) : (
                            <CloseCircleOutlined />
                        )
                        }
                        color={milestone.date !== '' ? '#52C41A' : 'var(--primary-theme-color)'}
                    >
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems:'flex-start' }}>
                            {(milestone.date !== '' && milestone.date !== 'NO_DATE_UBER') ? (
                            <>
                                <strong>{milestone.label}</strong>
                                <div>{milestone.label == 'Document Upload Status' && Array.isArray(milestoneValue) ? <Text mark>{getCombinedErrorMsg(milestone)} <br /></Text> : milestoneValue} . {moment(milestone.date).format('DD MMMM YYYY')}</div>
                            </>
                            ) : 
                            item.companyName.toLowerCase().indexOf('uber') !== -1 ? (
                            <>
                            <strong >{milestone.label}</strong>
                            <div>{milestone.label == 'Document Upload Status' && Array.isArray(milestoneValue) ? 
                            milestoneValue.map(ele => { if (!ele.toLowerCase().includes('success')) return <Text mark>{ele} <br /></Text> }) : milestoneValue} </div>
                            </> 
                            ) :
                            (
                            <>
                                <div>{milestone.label}</div>
                                <div>{milestoneValue}</div>
                            </>
                            )}
                        </div>
                        {milestone.label === 'Application Started' ? (
                            <div className="client-logo" style={{ marginLeft: 'auto' }}>
                            <img className="imgResponse" src={item.logo} />
                            </div>
                        ) : null}
                        {/* {(milestone.label.includes('Lead Referred') ||
                            milestone.label.includes('Application Completed') ||
                            milestone.label.includes('Application Not Completed')) &&
                        AdditionalFormsURLEnum[item.companyName.toLowerCase()] ? (
                            item.id ? (
                            item.companyName.toLowerCase().indexOf('rapido') !== -1 &&
                            (!item?.siMetaData?.rapidoMilestone?.captainId ||
                                item?.siMetaData?.rapidoMilestone?.value
                                ?.toString()
                                .includes('Application Complete')) ? null : (
                                <Button
                                style={{ margin: '5px 0px 5px auto' }}
                                icon={<UploadOutlined />}
                                type="primary"
                                onClick={() => {
                                    editViewApplicationClick(
                                    item,
                                    'view_application_clicked_on_application_status_page'
                                    );
                                }}
                                >
                                Edit/View Application
                                </Button>
                            )
                            ) : (
                            <Button
                                style={{ margin: '5px 0px 5px auto' }}
                                icon={<UploadOutlined />}
                                type="primary"
                                onClick={() => {
                                editViewApplicationClick(
                                    item,
                                    'complete_application_clicked_on_application_status_page'
                                );
                                }}
                            >
                                Complete Application
                            </Button>
                            )
                        ) : null} */}
                        </div>
                    </Timeline.Item>
                    );
                })}
            </Timeline>
        )   
    } 

    const displayCandidateDetails = () => {
        return (
            <div style={{ overflow: 'scroll', height: '500px' }}>
                
                    <Card
                        title="Candidate Information"
                        style={
                            { marginTop: '20px' ,border: '1px solid #BFBFBF'}
                        }>
                            
                            <Tabs 
                                defaultActiveKey="callHistory"
                                size={'medium'}
                                centered
                                tabBarStyle={{display:'flex', justifyContent:'space-between'}}>
                                    <TabPane tab={<span className="d-flex align-items-center">
                                        Candidate Information</span>} key="candidateInfo">
                                        {leadDetails.length > 0
                                            ? leadDetails.map((item, index) => {
                                                return (
                                                    <div className='timeline-container' key={'card_profile_' + index}>
                                                        {displayTimelineData(item, index)}   
                                                    </div>
                                                )
                                            })
                                        : null}                     
                                    </TabPane>
                                    <TabPane tab={<span className="d-flex align-items-center">Call History</span>} key="callHistory">
                                        {
                                            callHistory.length>0 ? callHistory.map((item,index)=> {
                                                return (
                                                    <CallHistoryCard
                                                    history={item}
                                                    key={index} />
                                                )
                                            }): <Empty
                                            description={
                                                <span>
                                                No Call History!!
                                                </span>
                                            }
                                                />
                                        }
                                    </TabPane>
                            </Tabs>
                    </Card>
                    
            
        </div>
        )
    };

    return (
        <div className='main-connecting-call-container'>
                <RouterPrompt
                        when={showPrompt}
                        title="Leave this page"
                        cancelText="No"
                        okText="Yes"
                        onOK={() => true}
                        onCancel={() => false}
                        promptMessage="The call is in progress. Are you sure you want to leave this page?"
                    />
                <Space direction='vertical' size={4}>
                    <div className="loader-custom">Loading...</div>
                    <Text type='secondary'>Calling on {mitraReducer?.kaleyraCallInformation?.receiverNumber || ''}</Text>
                    <Title level={3}>
                    {mitraReducer?.kaleyraCallInformation ? 
                            mitraReducer.kaleyraCallInformation.currentLead.firstName+ ' '+
                            ((mitraReducer.kaleyraCallInformation.currentLead.lastName).includes('Unknown') ? '':mitraReducer.kaleyraCallInformation.currentLead.lastName)
                            : ''}
                        </Title>
                    {
                        displayCandidateDetails()
                    }
                </Space>
        </div>
        
    );
} ;

export default ConnectingCallScreen;
