import React, { useContext, useEffect, useState } from 'react'
import './styles/SignupFormV2.scss';
import { Button, Checkbox, Input, notification, Select, Switch } from 'antd';
import { getpopularCities } from '../../service/bulkActionService';
import { checkMitraDuplicacy, sendOTPToPhone } from '../../service/signupService';
import { AppContext } from '../../App';
import { Localization } from '../../utils/language';

function SignupFormV2({setOtpSent, setSignupData, selectedLanguage}) {
    const [formValues, setFormValues] = useState({
        name: null,
        phoneNumber: null,
        email: null,
        selectedCityToOperate: null,
        vahanTermsAck: true
    });
    const [errorField, setErrorField] = useState(null);
    const [operationalCities, setOperationalCities] = useState([]);
    const {setSpin} = useContext(AppContext);

    const setFormInformation = (val, field) => {
        setFormValues({...formValues, [field]: val})
    }

    const handleCityChange = (val) => {
        setFormInformation(val, 'selectedCityToOperate')
    }

    const showLoader = (value) => {
        setSpin({
            loading: value, //false or true when actived
            delay: null,
            tip: 'Updating Information...'  //Loading copy here
        })
      }

    const getListOfOperationalCities = () => {
        getpopularCities()
        .then(res => {
            if(res?.data?.premiumCities?.length) {

                const cityList = [];
                for (let city of res?.data?.premiumCities) {
                    cityList.push({label: city, value: city});
                }
                setOperationalCities([...cityList]);
            } else {
                setOperationalCities([]);
            }
        }).catch(err=> {
            setOperationalCities([]);
            notification['error']({
                message: 'Error fetching operational cities, try again later.'
            })
        })
    }

    useEffect(() => {
      getListOfOperationalCities();
    }, []);

    const checkIfAllRequiredValuesArePresentAndValid = () => {

        if(!formValues?.name) {
            notification['error']({
                message: 'Add your name to continue.'
            });
            setErrorField('name');
            return false;
        }

        if(formValues?.name && !(/^[a-zA-Z\s]+$/).test(formValues.name)) {
            notification['error']({
                message: 'Name should contain only alphabets.'
            });
            setErrorField('name');
            return false;
        }
        
        if(!formValues.phoneNumber) {
            notification['error']({
                message: 'Add phone number to continue.'
            });
            setErrorField('phoneNumber');
            return false;
        }

        if(formValues.phoneNumber && !(/^[0-9]+$/).test(formValues.phoneNumber)) {
            notification['error']({
                message: 'Enter a valid phone number.'
            });
            setErrorField('phoneNumber');
            return false;
        }

        if(!formValues?.email) {
            notification['error']({
                message: 'Add email to continue.'
            });
            setErrorField('email');
            return false;
        }

        if(formValues?.email && !(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/).test(formValues?.email)) {
            notification['error']({
                message: 'Add a valid email address to continue.'
            });
            setErrorField('email');
            return false;
        }

        if(!formValues?.selectedCityToOperate) {
            notification['error']({
                message: 'Choose a city to continue.'
            });
            setErrorField('selectedCityToOperate');
            return false;
        }

        if(!formValues?.vahanTermsAck) {
            notification['error']({
                message: 'Check the terms to continue.'
            });
            return false;
        }
        return true;
    }
    
    const signup = async() => {
        if(!checkIfAllEntriesArePresent()) return;
        // validate the entries
        // check for duplicacy
        // check for acquisition source
        // send otp

        showLoader(true);
        if(checkIfAllRequiredValuesArePresentAndValid()) {
            // check for duplicacy
            try {
                const res = await checkMitraDuplicacy(formValues?.phoneNumber)
                if (res.data.isMitra) {
                    notification['error']({
                        message: 'Phone number is already registered!.'
                    });
                    showLoader(false);
                    return;
                }
            } catch (err) {
                showLoader(false);
                notification['error']({
                    message: 'Error in duplicacy check, try again later.'
                });
            }

            // check for acquisition source
            if (window.location.href.toLowerCase().includes('?source')) {
                const urlSearchParams = new URLSearchParams(window.location.search);
                const params = Object.fromEntries(urlSearchParams.entries());
                formValues.acquisitionSource = params.source ? params.source : 'Self Signup';
            } else {
                formValues.acquisitionSource = 'Self Signup';
            }

            setSignupData({
                "name": formValues?.name,
                "phoneNumber": formValues?.phoneNumber,
                "email": formValues?.email,
                "city": formValues?.selectedCityToOperate,
                "acceptedTermsAndConditions": true,
                "submit": true,
                "acquisitionSource": formValues.acquisitionSource,
                "fbclid": null,
                "gclid": null
            })


            // send OTP
            sendOTPToPhone(formValues.phoneNumber)
            .then(response =>{
                if(response.data.otpSent === 'true' || response.data.otpSent === true) {
                    setOtpSent(true);
                }
                else {
                    notification['error']({
                        message: 'OTP service unavailable, please try again later.'
                    });
                }
                showLoader(false);
            }).catch(err => {
                showLoader(false);
                notification['error'] ({
                    message: 'OTP service is not responding, please try again later.'
                })
            });
        } else {
            showLoader(false);
        }
    }

    const checkIfAllEntriesArePresent = () => {
        return formValues.name && formValues.email && formValues.phoneNumber && formValues.selectedCityToOperate && formValues.vahanTermsAck
    }

  return (
    <div style={{margin: '24px'}}>
        <div className='label'>{Localization[selectedLanguage]['Full name']}<sup>*</sup></div>
        <Input
            placeholder={Localization[selectedLanguage]['Enter Full Name']}
            value={formValues.name}
            className={errorField==='name' ? 'input-error':'input'}
            onChange={(e) => setFormInformation(e.target.value, 'name')}
        />

        <div className='label mt-24'>{Localization[selectedLanguage]['Phone Number']}<sup>*</sup></div>
        <Input
            placeholder={`__________`}
            value={formValues.phoneNumber}
            maxLength={10}
            className={errorField==='phoneNumber' ? 'input-error':'input'}
            onChange={(e) => setFormInformation(e.target.value, 'phoneNumber')}
        />

        <div className='label mt-24'>{Localization[selectedLanguage]['Email Id']}<sup>*</sup></div>
        <Input
            placeholder={Localization[selectedLanguage]['Enter Email Id']}
            value={formValues.email}
            className={errorField==='email' ? 'input-error':'input'}
            onChange={(e) => setFormInformation(e.target.value, 'email')}
        />

        <div className='label mt-24'>{Localization[selectedLanguage]['City you will operate in']}<sup>*</sup></div>
        <Select
            className='dropdown-styles-suf-v2'
            placeholder={Localization[selectedLanguage]['City you will operate in']}
            value={formValues.selectedCityToOperate}
            onChange={handleCityChange}
            optionLabelProp="label"
            showSearch
            options={operationalCities}
        />

        <div className='mt-24 d-flex flex-gap-4'>
            <Checkbox className='text-align-start' onChange={()=>setFormInformation(!formValues.vahanTermsAck, 'vahanTermsAck')} checked={formValues.vahanTermsAck}>
                <div 
                dangerouslySetInnerHTML={{ __html: Localization[selectedLanguage]["By proceeding, you accept Vahan’s <a href='/terms-and-conditions' target='_blank'>Terms and Conditions </a>"]}}
                ></div>
            </Checkbox>
        </div>

        <Button 
        disabled={!checkIfAllEntriesArePresent()}
        className={`signup-btn-class`}
        style={{opacity: checkIfAllEntriesArePresent() ? '1': '0.6', cursor: checkIfAllEntriesArePresent() ? 'pointer': 'not-allowed' }}
        onClick={signup}>
            SIGN UP
        </Button>
    </div>
  )
}

export default SignupFormV2