import * as React from "react";
const PlusCircle = ({fillColor='none',height=25,width=24,strokeColor="#1D5FFF",strokeWidth=2.5}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 24 25"
    fill={fillColor}
  >
    <path
      d="M11.9998 22.5C17.5226 22.5 21.9998 18.0228 21.9998 12.5C21.9998 6.97715 17.5226 2.5 11.9998 2.5C6.47691 2.5 1.99976 6.97715 1.99976 12.5C1.99976 18.0228 6.47691 22.5 11.9998 22.5Z"
      stroke={strokeColor}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 8.5V16.5"
      stroke={strokeColor}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.99976 12.5H15.9998"
      stroke={strokeColor}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default PlusCircle;
