import React, { useContext } from "react";
import './LevelDetails.scss';
import { Timeline,Typography, Collapse, Card,Space } from "antd";
import {LockFilled, UnlockFilled,CaretUpOutlined} from '@ant-design/icons';
import { AppContext } from "../../App";

const {Text} = Typography;
const {Panel} = Collapse;

const RoadToBronze = () => {
    const timelineData = [
        {
            "label": "ML app activated",
            "description": "On completion of signup",
            "rewards": "Welcome,  :smile:",
            "date": null,
            "status": "Completed"
        },
        {
            "label": "Explorer",
            "description": "Explore the features on the product :<br>    1. Visit the jobs page<br>   2. Visit the training page",
            "rewards": "[https://uploads-a.vahan.co/ML_training_Tamil_explorer_reward.mp4](https://uploads-a.vahan.co/ML_training_Tamil_explorer_reward.mp4)\n\n    Training Video Link - Tamil\n    \n    [https://uploads-a.vahan.co/ML_training_Hindi_explorer_reward.mp4](https://uploads-a.vahan.co/ML_training_Hindi_explorer_reward.mp4)\n    \n    Training Video Link - Hindi\n    \n    [https://uploads-a.vahan.co/ML_training_Telugu_explorer_reward.mp4](https://uploads-a.vahan.co/ML_training_Telugu_explorer_reward.mp4)\n    \n    Training Video Link - Telugu",
            "date": null,
            "status": "In Progress"
        },
        {
            "label": "Bronze Vahan Leader",
            "description": "Onboard your first rider",
            "rewards": "To receive your daily payment, push the rider to the next milestones : <X> days remaining :motor_scooter: !\n    To receive your payouts, push the rider to the next milestones :motor_scooter:!",
            "date": null,
            "status": "Not Completed"
        },
        // {
        //     "label": "Pay Master",
        //     "description": "Receive your first payment from Vahan :money_with_wings:",
        //     "rewards": "Masterclass on maximum payment. Document link",
        //     "date": null,
        //     "status": "Not Completed"
        // },
        // {
        //     "label": "10x expert(Recruitment Wizard)",
        //     "description": "10 of your riders take their 1st trip in the last 4 weeks :rocket:",
        //     "rewards": "Have advanced Account manager support support to help you move up in funnel",
        //     "date": null,
        //     "status": "Not Completed"
        // },
        // {
        //     "label": "30x expert(Recruitment Wizard)",
        //     "description": "30 of your riders take their 1st trip in the last 4 weeks :rocket:",
        //     "rewards": "You will get a chance to attend bi-weekly sessions with Vahan team to solve specific problem statements",
        //     "date": null,
        //     "status": "Not Completed"
        // },
        // {
        //     "label": "Silver Mitra Leader",
        //     "description": "50 of your riders take their 1st trip in the last 4 weeks :rocket:",
        //     "rewards": "10% higher payout per placement Access to Loans Insurance policies for you and your team",
        //     "date": null,
        //     "status": "Not Completed"
        // }
    ]

    return (
        <Card className='progress-bar'>
            <div className='progress-bar-header'>Your Journey to becoming a Bronze Vahan Leader</div>
            <div className="progress-bar-detail-content">
                <div className='d-flex justify-content-between align-items-center custom-width-mobile' style={{gap:'5px', width:'100%'}}>
                    <img src='/image/progress-bar-initial.png' alt='starting-point' />
                    {
                        timelineData.map((item,index)=> {
                            return (
                                <div 
                                key={index} 
                                className={item.status==='Completed' ? 'level-indicators level-completed': 
                                item.status==='In Progress' ? 'level-indicators level-in-progress': 'level-indicators level-yet-to-begin'}
                                >
                                    {item.status==='In Progress' ? (
                                        <div className="current-position-pointer">
                                            <CaretUpOutlined style={{marginTop:'5px'}}/>
                                            <Text className="pointer-text">You</Text>
                                        </div>
                                    
                                    ): null }
                                </div>
                            )
                        })
                    }
                    <img src='/image/progress-bar-bronze.png' alt='starting-point' />
                </div>
                <Timeline style={{marginTop:'15px', gap:'2em'}} className="timeline-details">
                   {
                   timelineData.map((item,index)=> {
                        return (
                            <Timeline.Item 
                            key={index} 
                            dot={item.status!=='Not Completed' ? 
                            <UnlockFilled 
                            style={
                                item.status==='Completed' ? {color:'#52C41A'}: {color:'black'}
                            }/>: 
                            <LockFilled /> }
                            style={item.status==='Not Completed'? {opacity:'0.5'}: null}
                            >
                                <div style={{display:"flex", flexDirection:'column', gap:'1em'}}>
                                    <strong className='d-flex' style={{marginTop: '3px'}}>{item.label}</strong>
                                    {
                                        item.status === 'Completed' ? (
                                            <Space direction="vertical" size={24} style={{background:'#F5F5F5', padding:'10px',borderRadius:'5px'}}>
                                                <Text className='d-flex'>
                                                <div
                                                    className="d-flex flex-column justify-content-between align-items-start"
                                                    style={{opacity:'0.5'}}
                                                    dangerouslySetInnerHTML={{ __html: item.rewards }}
                                                ></div>
                                                    
                                                </Text>
                                            </Space>
                                        ): null
                                    }
                                    {
                                        item.status === 'In Progress' ? (
                                            <Space direction="vertical" size={24} style={{background:'var(--primary-theme-light)', padding:'10px',borderRadius:'5px'}}>
                                                <Text className='d-flex'>
                                                <div
                                                    className="d-flex flex-column justify-content-between align-items-start"
                                                    dangerouslySetInnerHTML={{ __html: item.description }}
                                                ></div>
                                                    
                                                </Text>
                                            </Space>
                                        ): null
                                    }
                                    {
                                        item.status === 'Not Completed' ? (
                                            <Space direction="vertical" size={24} style={{background:'#BFBFBF', padding:'10px',borderRadius:'5px'}}>
                                                <Text className='d-flex'>
                                                <div
                                                    className="d-flex flex-column justify-content-between align-items-start"
                                                    style={{opacity:'0.5'}}
                                                    dangerouslySetInnerHTML={{ __html: item.description }}
                                                ></div>
                                                    
                                                </Text>
                                            </Space>
                                        ): null
                                    }
                                </div>
                            </Timeline.Item>
                        )
                   })
                   }
                </Timeline>
            </div>
        </Card>
    )
};

export default RoadToBronze;
