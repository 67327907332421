import { Skeleton } from 'antd'
import React from 'react'

function FilterSectionSkeleton() {
  return (

    <>
        {/* Section to show subviews */}
        <div className="sub-view-header">
            <span className="inter-regular-14-20 plain-black">Show</span>
            <div className="visibility-desktop">
                <Skeleton.Input active size={'default'} />
            </div>

            <div className='visibility-mobile'>
                <Skeleton.Input active size={'large'} />
            </div>

            <span className="inter-regular-14-20 plain-black">leads</span>
        </div>

        {/* Section that paints filters of selected subview */}
        <div className="filter-view visibility-desktop">
        
            <span className="inter-regular-14-20 plain-black">From</span>
            <Skeleton.Input active size={'large'} />
        

            <div className="selected-filters-section">
            <span className="inter-regular-14-20 plain-black">Filtered by</span>

            <div className="selected-filters-box">
                <Skeleton.Input active size={'default'} />
            </div>

                <Skeleton.Input active />
            </div>
        </div>
    </>
  )
}

export default FilterSectionSkeleton