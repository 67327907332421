import React, { useEffect, useState } from 'react'
import {Button, Select, Input, notification} from 'antd';
import { getZeptoStores } from '../../../../service/ZeptoRecAdditionalInfoService';
import { postAdditionalInfo } from '../../../../service/additionalInfoService';

function ZeptoFormPart1({lead, mitraReducer, leadAdditionalDetails, goToNextScreen}) {
    const [selectedStore, setSelectedStore] = useState(null);
    const [selectedMaritalStatus, setSelectedMaritalStatus] = useState(null);
    const [selectedVehicleType, setSelectedVehicleType] = useState(null);
    const [selectedContractType, setSelectedContractType] = useState(null);
    const [selectedShiftPreference, setSelectedShiftPreference] = useState(null);
    const [selectedWeekOff, setSelectedWeekOff] = useState(null);
    const [aadharNumber, setAadharNumber] = useState(null);

    const [storeOptions, setStoreOptions] = useState([]);
    const [shiftOptions, setShiftOptions] = useState([]);
    const [dayOffOptions, setDayOffOptions] = useState([]);

    const [responseObj, setResponseObj] = useState({});

    const [errorField, setErrorField] = useState(null);

    const [maritalStatusOptions, setMaritalStatusOptions] = useState([
        {
            label: 'Married',
            key: 'MARRIED',
            value: 'MARRIED'
        },
        {
            label: 'Single',
            key: 'SINGLE',
            value: 'SINGLE'
        }
    ]);

    const [vehicleTypeOptions, setVehicleTypeOptions] = useState([
        {
            label: 'Petrol Bike',
            key: 'Petrol Bike',
            value: 'Petrol Bike'
        },
        {
            label: 'E-Bike/E-Scooter',
            key: 'E-Bike/E-Scooter',
            value: 'E-Bike/E-Scooter'
        },
        {
            label: 'E-Cycle',
            key: 'E-Cycle',
            value: 'E-Cycle'
        },
        {
            label: 'Cycle',
            key: 'Cycle',
            value: 'Cycle'
        }
    ]);

    const [contractTypeOptions, setContractTypeOptions] = useState([
        {
            label: 'Full Time',
            key: 'fullTime',
            value: 'fullTime'
        },
        {
            label: 'Part Time',
            key: 'partTime',
            value: 'partTime'
        },
    ]);

    const extractPreferredWeekOffInformation = (value) => {
        const weekOffOptions = [];

        const availableWeekOffsForAShift = shiftOptions?.find(shift=>shift?.shift === value)?.weekOffs || [];

        if(availableWeekOffsForAShift?.length) {
            for (let i = 0; i < availableWeekOffsForAShift.length; i++) {
                weekOffOptions.push({label:availableWeekOffsForAShift[i], value: availableWeekOffsForAShift[i]});
            }
        }

        setDayOffOptions([...weekOffOptions]);

    }

    const extractShiftInformation = (value) => {
        let shiftsObjectForAStore;
        if(value === 'fullTime') {
            shiftsObjectForAStore = responseObj?.fullTimeShifts?.filter(shift=>shift?.storeName === selectedStore) || [];
        } else {
            shiftsObjectForAStore = responseObj?.partTimeShifts?.filter(shift=>shift?.storeName === selectedStore) || [];
        }

        const shifts = shiftsObjectForAStore?.[0]?.shifts?.length ? shiftsObjectForAStore?.[0]?.shifts: [];

        for (let shift = 0; shift < shifts.length; shift++) {
            shifts[shift].label = shifts[shift].shift;
            shifts[shift].value = shifts[shift].shift;
        }

        setShiftOptions([...shifts]);
    }

    const extractStoreInformation = (response) => {
        if(response?.stores?.length) {
            const stores = response?.stores;

            for (let store = 0; store < stores.length; store++) {
                stores[store].label = stores[store].storeName;
                stores[store].value = stores[store].storeName;            
            }

            setStoreOptions([...stores]);
        }
    }

    const getStoreInformation = () => {

        const city=leadAdditionalDetails?.cityPreference;
        getZeptoStores(city).
        then(res=> {
            if(res?.data?.status) {
                if(res?.data?.response) {
                    setResponseObj(res?.data?.response);
                    extractStoreInformation(res?.data?.response);
                }
            } else {
                setStoreOptions([]);
                setContractTypeOptions([]);
                setShiftOptions([]);
                setDayOffOptions([]);
            }
        }).catch(err=> {
            setStoreOptions([]);
            setContractTypeOptions([]);
            setShiftOptions([]);
            setDayOffOptions([]);
            console.log(err);
        })

    }

    const handleStoreChange = (value, object) => {
        setSelectedStore(value);

        setSelectedContractType(null);
        setSelectedShiftPreference(null);
        setSelectedWeekOff(null);
        setErrorField(null);
    }

    const handleMaritalStatusChange = (value, object) => {
        setSelectedMaritalStatus(value);
        setErrorField(null);
    }

    const handleVehicleTypeChange = (value, object) => {
        setSelectedVehicleType(value);
        setErrorField(null);
    }

    const handleContractTypeChange = (value, object) => {
        setSelectedContractType(value);
        
        setSelectedShiftPreference(null);
        setSelectedWeekOff(null);

        // call to get shift preference
        extractShiftInformation(value);

        setErrorField(null);
    }

    const handleShiftChange = (value, object) => {
        setSelectedShiftPreference(value);

        setSelectedWeekOff(null);
        setErrorField(null);

        // call to get weekoff preference
        extractPreferredWeekOffInformation(value);
    }

    const handleWeekOffChange = (value, object) => {
        setSelectedWeekOff(value);
        setErrorField(null);

    }

    const handleAadharNumberChange = (event) => {
        const inputValue = event.target.value;
        // Check if the input value is numeric using a simple regex
        if (/^\d*$/.test(inputValue)) {
          setAadharNumber(inputValue);
          setErrorField(null);
        }
      }

    useEffect(() => {
        getStoreInformation();
    }, [])

    const autofillInformation = () => {
        if(leadAdditionalDetails?.zeptoStoreName) {
            setSelectedStore(leadAdditionalDetails?.zeptoStoreName);
        }
        if(leadAdditionalDetails?.maritalStatus) {
            setSelectedMaritalStatus(leadAdditionalDetails?.maritalStatus);
        }
        if(leadAdditionalDetails?.vehicleType) {
            setSelectedVehicleType(leadAdditionalDetails?.vehicleType);
        }
        if(leadAdditionalDetails?.onBoardingPreference) {
            setSelectedContractType(leadAdditionalDetails?.onBoardingPreference);
        }
        if(leadAdditionalDetails?.shiftPreference) {
            setSelectedShiftPreference(leadAdditionalDetails?.shiftPreference);
        }
        if(leadAdditionalDetails?.preferredWeekOff) {
            setSelectedWeekOff(leadAdditionalDetails?.preferredWeekOff);
        }
        if(leadAdditionalDetails?.aadhaarNumber) {
            setAadharNumber(leadAdditionalDetails?.aadhaarNumber);
        }
    }

    useEffect(() => {
      autofillInformation();
    }, [leadAdditionalDetails])
    

    const checkIfAllFieldsArePopulated = () => {
        if(!selectedStore) {
            notification['error'] ({
                message: 'Select a store'
            });

            setErrorField('store');

            return false
        } else if(!selectedMaritalStatus) {
            notification['error'] ({
                message: 'Select marital status'
            });

            setErrorField('maritalStatus');

            return false
        } else if(!selectedVehicleType) {
            notification['error'] ({
                message: 'Select a vehicle type'
            });

            setErrorField('vehicleType');

            return false
        } else if(!selectedContractType) {
            notification['error'] ({
                message: 'Select a contract type'
            });

            setErrorField('contractType');

            return false
        } else if(shiftOptions.length > 0 && !selectedShiftPreference) {
            notification['error'] ({
                message: 'Select a shift preference'
            });

            setErrorField('shift');

            return false
        } else if(!aadharNumber) {
            notification['error'] ({
                message: 'Enter your aadhar number'
            });

            setErrorField('aadhar');

            return false
        } else if(aadharNumber?.length < 12) {
            notification['error'] ({
                message: 'Enter your complete aadhar number'
            });

            setErrorField('aadhar');

            return false
        }

        return true
    }

    const checkErrors = (res) => {
        const newErrors = [];
        for (let key in res.data) {
            if (res.data[key] !== "200") {
                newErrors.push(`${key}: ${res.data[key]}`)
            }
        }
    
        if(newErrors.length) {
            // setErrors([...newErrors]);
            alert(newErrors.join("\n"))
            return true;
        } 
        return false;
    }

    const onSubmit = async() => {
        if(checkIfAllFieldsArePopulated()) {
            const submissionData = {
                zeptoStoreName: selectedStore,
                maritalStatus: selectedMaritalStatus,
                vehicleType: selectedVehicleType,
                onBoardingPreference: selectedContractType,
                shiftPreference: selectedShiftPreference,
                preferredWeekOff: selectedWeekOff,
                aadhaarNumber: aadharNumber
            }

            const response = await postAdditionalInfo(submissionData, mitraReducer?.mitraInfo, lead);

            if(!checkErrors(response)) {
                notification['success']({
                    message: 'Details submitted successfully'
                });

                goToNextScreen();
            }
            
        }
    }
    

  return (
    <>
    <div className='vehicle-type-container'>
            <div className='container-header'>Work Preference</div>

            <div style={{margin: '16px'}}>
                <div className='label'>Zepto Store Name<sup>*</sup></div>
                <Select
                    style={{ width: '100%', textAlign: 'start' }}
                    placeholder="Select the store.."
                    value={selectedStore}
                    onChange={(v,o)=>handleStoreChange(v,o)}
                    className={`${errorField==='store'? 'input-error':''}`}
                    optionLabelProp="label"
                    options={storeOptions}>
                </Select>

                <div className='label mt-24'>Marital Status<sup>*</sup></div>
                <Select
                    style={{ width: '100%', textAlign: 'start' }}
                    placeholder="Select marital status.."
                    value={selectedMaritalStatus}
                    onChange={(v,o)=>handleMaritalStatusChange(v,o)}
                    className={`${errorField==='maritalStatus'? 'input-error':''}`}
                    optionLabelProp="label"
                    options={maritalStatusOptions}>
                </Select>

                <div className='label mt-24'>Vehicle Type<sup>*</sup></div>
                <Select
                    style={{ width: '100%', textAlign: 'start' }}
                    placeholder="Select the vehicle type.."
                    value={selectedVehicleType}
                    onChange={(v,o)=>handleVehicleTypeChange(v,o)}
                    className={`${errorField==='vehicleType'? 'input-error':''}`}
                    optionLabelProp="label"
                    options={vehicleTypeOptions}>
                </Select>

                <div className='label mt-24'>Contract Type<sup>*</sup></div>
                <Select
                    style={{ width: '100%', textAlign: 'start' }}
                    placeholder="Select the contract type.."
                    value={selectedContractType}
                    onChange={(v,o)=>handleContractTypeChange(v,o)}
                    className={`${errorField==='contractType'? 'input-error':''}`}
                    optionLabelProp="label"
                    options={contractTypeOptions}>
                </Select>

                {shiftOptions.length > 0 && (
                    <>
                        <div className='label mt-24'>
                            Shift Preference{shiftOptions.length > 0 && <sup>*</sup>}
                        </div>
                        <Select
                            style={{ width: '100%', textAlign: 'start' }}
                            placeholder="Select the shift preference.."
                            value={selectedShiftPreference}
                            onChange={(v,o)=>handleShiftChange(v,o)}
                            className={`${errorField==='shift'? 'input-error':''}`}
                            optionLabelProp="label"
                            options={shiftOptions}>
                        </Select>
                    </>
                )}

                {dayOffOptions.length > 0 && (
                    <>
                        <div className='label mt-24'>Preferred Week Off</div>
                        <Select
                            style={{ width: '100%', textAlign: 'start' }}
                            placeholder="Select preferred week off.."
                            value={selectedWeekOff}
                            onChange={(v,o)=>handleWeekOffChange(v,o)}
                            optionLabelProp="label"
                            options={dayOffOptions}>
                        </Select>
                    </>
                )}

                <div className='label mt-24'>Aadhar Number<sup>*</sup></div>
                <Input
                    className={`${errorField==='aadhar'? 'input input-error':'input'}`}
                    maxLength={12}
                    placeholder={`Enter your Aadhar Number`}
                    value={aadharNumber}
                    onChange={handleAadharNumberChange}
                />
            </div>
        </div>

        <div className='button-container'>
            <Button className='submit-button' onClick={onSubmit}>Next</Button>
        </div>
    </>
  )
}

export default ZeptoFormPart1