import React, { Fragment, useContext, useEffect, useState } from 'react'
import './DesktopSidebar.css'
import { useHistory, useLocation } from 'react-router-dom';
import { AppContext } from '../../App';
import { getMenuKeyFromUrlPathname } from '../../constants/utils';
import HomeIcon from '../../static/svg/HomeIcon';
import PeopleIcon from '../../static/svg/PeopleIcon';
import SuitcaseIcon from '../../static/svg/SuitcaseIcon';
import CloudUploadIcon from '../../static/svg/CloudUploadIcon';
import BoltIcon from '../../static/svg/BoltIcon';
import CallingIcon from '../../static/svg/CallingIcon';
import StatusUpIcon from '../../static/svg/StatusUpIcon';
import RupeeSymbol from '../../static/svg/RupeeSymbol';
import { Divider } from 'antd';
import PeopleIcon2 from '../../static/svg/PeopleIcon2';
import { trackEvent } from '../../service/engagementMonitoringService';
import CONSTANTS from '../../constants/constants';
import { showPayoutDashboard } from '../../service/reportService';

function DesktopSidebar() {
    const history = useHistory();
    const location = useLocation();
    const { mitraReducer, mitraDispatch } = useContext(AppContext);
    const [sections, setSections] = useState([
        {
            header: '',
            subMenu: [
                {
                    menuLabel: 'Home',
                    menuKey: 'HOME_PAGE',
                    path: '/home',
                    icon: <HomeIcon />,
                    isActive: true,
                    activeIcon: <HomeIcon strokeColor='#fff'/>
                },
                {
                    menuLabel: 'Leads',
                    menuKey: 'LEADS',
                    path: '/leads-dashboard',
                    icon: <PeopleIcon />,
                    isActive: false,
                    activeIcon: <PeopleIcon strokeColor='#fff'/>
                },
                {
                    menuLabel: 'Jobs',
                    menuKey: 'JOB_DEMANDS',
                    path: '/job-demands/job-requirements',
                    icon: <SuitcaseIcon />,
                    isActive: false,
                    activeIcon: <SuitcaseIcon strokeColor='#fff'/>
                }
            ]
        },
        {
            header: 'Smart Flows',
            subMenu: [
                // {
                //     menuLabel: 'Refer',
                //     menuKey: 'REFER_AND_EARN',
                //     path: '/refer-and-earn',
                //     icon: <CloudUploadIcon />,
                //     isActive: false,
                //     activeIcon: <CloudUploadIcon strokeColor='#fff'/>
                // },
                {
                    menuLabel: 'AI Hot Leads',
                    menuKey: 'SAMVADINI',
                    path: '/samvadini-v2',
                    icon: <BoltIcon />,
                    isActive: false,
                    activeIcon: <BoltIcon strokeColor='#fff'/>
                },
                {
                    menuLabel: 'Get Hotline App',
                    menuKey: 'HOTLINE',
                    path: '/download-hotline-app',
                    icon: <CallingIcon />,
                    isActive: false,
                    activeIcon: <CallingIcon strokeColor='#fff'/>
                }
            ]
        },
        {
            header: 'Reports',
            subMenu: [
                {
                    menuLabel: 'Performance',
                    menuKey: 'PERFORMANCE_DASH',
                    path: '/report/performance-dashboard',
                    icon: <StatusUpIcon />,
                    isActive: false,
                    activeIcon: <StatusUpIcon strokeColor='#fff'/>
                },
                {
                    menuLabel: 'Payouts',
                    menuKey: 'PAYOUT_DASH',
                    path: '/report/payout-dashboard',
                    icon: <RupeeSymbol />,
                    isActive: false,
                    activeIcon: <RupeeSymbol strokeColor='#fff'/>
                },
                {
                    menuLabel: 'TC Dashboard',
                    menuKey: 'TC_DASHBOARD',
                    path: '/telecallerDashboard',
                    icon: <PeopleIcon2 />,
                    isActive: false,
                    activeIcon: <PeopleIcon2 strokeColor='#fff'/>
                },
            ]
        }
    ]);

    const onMenuChange = (clickedMenu, changeLocation=true) => {
        const modifiedSections = [...sections];

        for (let section = 0; section < sections.length; section++) {
            for (let menu = 0; menu < modifiedSections[section].subMenu.length; menu++) {
                if(modifiedSections[section].subMenu[menu].menuKey === clickedMenu.menuKey) {
                    modifiedSections[section].subMenu[menu].isActive = true;

                    if(modifiedSections[section].subMenu[menu].path && changeLocation) {
                        history.push(modifiedSections[section].subMenu[menu].path);
                    }
                } else {
                    modifiedSections[section].subMenu[menu].isActive = false;
                }
            }
        }

        setSections([...modifiedSections]);

        trackEvent('vp_menu_clicked',  {
            menu: clickedMenu?.menuLabel,
            phoneNumber: mitraReducer?.mitraInfo?.phoneNumber,
        }, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
    };

    useEffect(() => {
		if (mitraReducer?.utilities?.currentMenu) {
			const clickedMenu = {
                menuKey: mitraReducer?.utilities?.currentMenu
            };

            onMenuChange(clickedMenu, false);

		}
	}, [mitraReducer?.utilities?.currentMenu]);

    useEffect(() => {
		if(location.pathname) {
			const menuKey = getMenuKeyFromUrlPathname(location.pathname);

			if(menuKey) {
                const clickedMenu = {
                    menuKey: menuKey
                };
    
                onMenuChange(clickedMenu, false);

				mitraDispatch(
					{ 
						type: 'UTILITIES', 
						value: { 
							currentMenu: 
							(menuKey === 'TEAM_PERFORMANCE' && mitraReducer?.mitraInfo.role === 'admin') ? 'PERFORMANCE_DASH' 
							: menuKey } 
					}
				);
			}
		}
	}, [location])

  return (
    <div className='desktop-sidebar-main-container'>

        {
            mitraReducer?.mitraInfo?.brandName && !mitraReducer?.customBrandDetails?.brandName ? ( // For gigshare domains we need to hide this section
                <div className="branding-section">
                    <div className=''> </div>
                    {
                        mitraReducer?.mitraInfo?.logo ? (
                            <img src={mitraReducer?.mitraInfo?.logo} style={{height:'48px', width: '48px',objectFit: 'contain',borderRadius: '6px',border: '1px solid #fcf'}} />
                        ): null
                    }
                    
                    <span className='inter-semibold-14-16 color-323232 text-capitalise'>{mitraReducer?.mitraInfo?.brandName}</span>
                </div>
            ): null
        }
        
        {
            sections.map((section, sectionIdx) => (
                section.header === 'Smart Flows' && (
                    !CONSTANTS.samvadiniCallingEligibleIds.includes(mitraReducer?.mitraInfo?.id) &&
                    !CONSTANTS.samvadiniCallingEligibleIds.includes(mitraReducer?.mitraInfo?.managerMitraID) && 
                    !CONSTANTS.samvadiniCallingEligibleIds.includes(mitraReducer?.mitraInfo?.adminMitraId)
                ) && (
                    !CONSTANTS.hotlineDownloadApplicableIds.includes(mitraReducer?.mitraInfo?.id) &&
                    !CONSTANTS.hotlineDownloadApplicableIds.includes(mitraReducer?.mitraInfo?.managerMitraID) && 
                    !CONSTANTS.hotlineDownloadApplicableIds.includes(mitraReducer?.mitraInfo?.adminMitraId)
                ) ? null:
                <Fragment key={sectionIdx}>
                    <Divider style={{margin: '0 auto', width: '90%', minWidth: '90%'}}/>
                    <div className={`desktop-sibar-individual-section`} key={sectionIdx}>
                            <span className='inter-regular-12-16 color-838383 text-align-start menu-section-header'>{section.header}</span>
                            {
                                section.subMenu.map((menu, menuIndex) => (
                                    
                                    // TC Dashboard only to be accessible by ADMIN users
                                    (
                                        menu.menuKey === 'TC_DASHBOARD' && mitraReducer?.mitraInfo?.role != 'admin'
                                    ) || 
                                    (
                                        menu.menuKey === 'SAMVADINI' &&
                                        !CONSTANTS.samvadiniCallingEligibleIds.includes(mitraReducer?.mitraInfo?.id) &&
                                        !CONSTANTS.samvadiniCallingEligibleIds.includes(mitraReducer?.mitraInfo?.managerMitraID) && 
                                        !CONSTANTS.samvadiniCallingEligibleIds.includes(mitraReducer?.mitraInfo?.adminMitraId)
                                    ) || (
                                        menu.menuKey === 'HOTLINE' &&
                                        !CONSTANTS.hotlineDownloadApplicableIds.includes(mitraReducer?.mitraInfo?.id) &&
                                        !CONSTANTS.hotlineDownloadApplicableIds.includes(mitraReducer?.mitraInfo?.managerMitraID) && 
                                        !CONSTANTS.hotlineDownloadApplicableIds.includes(mitraReducer?.mitraInfo?.adminMitraId)
                                    ) || (
                                        menu.menuKey === 'PAYOUT_DASH' && !showPayoutDashboard(mitraReducer?.mitraInfo)
                                    ) ? null :
                                    
                                    <div 
                                    className={`single-menu-item ${menu.isActive ? 'active-menu-highlight':''}`} 
                                    key={`${sectionIdx} + ' ' +${menuIndex}`}
                                    onClick={()=>onMenuChange(menu)}
                                    >
                                        {menu.isActive ? menu.activeIcon : menu.icon}
                                        <span className={`desktop-menu-header ${menu.isActive ? 'inter-regular-14-20 plain-white':'inter-regular-14-20 color-323232'}`}>{menu.menuLabel}</span>
                                    </div>
                                ))
                            }
                    </div>
                </Fragment>
            ))
        }
    </div>
  )
}

export default DesktopSidebar