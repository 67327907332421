import * as React from "react";
const CloudUploadIcon = ({fillColor='none',height=16,width=16,strokeColor="#838383"}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill={fillColor}
  >
    <path
      d="M7.99984 10.6666L7.99984 6.66659M7.99984 6.66659L5.99984 7.99992M7.99984 6.66659L9.99984 7.99992M15.3332 9.99992C15.3332 8.52716 14.1393 7.33325 12.6665 7.33325C12.6507 7.33325 12.6353 7.33339 12.6196 7.33366C12.2963 5.07193 10.351 3.33325 7.99984 3.33325C6.1354 3.33325 4.52663 4.42661 3.77913 6.00713C2.04121 6.12088 0.666504 7.56655 0.666504 9.33324C0.666504 11.1742 2.15889 12.6666 3.99984 12.6666H12.6665C14.1393 12.6666 15.3332 11.4727 15.3332 9.99992Z"
      stroke={strokeColor}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default CloudUploadIcon;
