import * as React from "react";
const LocationPin = ({fillColor='none',height=16,width=16,strokeColor="#252526"}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill={fillColor}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.34975 5.90461C2.58597 3.03887 4.98074 0.833374 7.8562 0.833374H8.14416C11.0196 0.833374 13.4144 3.03887 13.6506 5.90461C13.7775 7.44404 13.302 8.97263 12.3242 10.1684L9.12883 14.0763C8.5455 14.7897 7.45486 14.7897 6.87153 14.0763L3.67616 10.1684C2.69838 8.97264 2.22286 7.44404 2.34975 5.90461ZM7.8562 1.83337C5.50117 1.83337 3.53984 3.63969 3.34637 5.98676C3.24077 7.26799 3.63653 8.5402 4.4503 9.53542L7.64568 13.4433C7.8289 13.6674 8.17146 13.6674 8.35468 13.4433L11.5501 9.53542C12.3638 8.5402 12.7596 7.26799 12.654 5.98676C12.4605 3.63969 10.4992 1.83337 8.14416 1.83337H7.8562Z"
      fill={strokeColor}
    />
    <path
      d="M9.64678 6.3384C9.64678 7.24795 8.90945 7.98528 7.9999 7.98528C7.09036 7.98528 6.35303 7.24795 6.35303 6.3384C6.35303 5.42886 7.09036 4.69153 7.9999 4.69153C8.90945 4.69153 9.64678 5.42886 9.64678 6.3384Z"
      stroke={strokeColor}
    />
  </svg>
);
export default LocationPin;
