import React, { useState, useEffect, useContext } from 'react';
import { FireOutlined, FilterOutlined, InboxOutlined, InfoCircleOutlined, EyeTwoTone,EyeInvisibleOutlined } from '@ant-design/icons';
import {
  Popover,
  Card,
  Typography,
  Empty,
  Row,
  Col,
  Pagination,
  message,
  Space,
  Button,
  notification,
  Modal,
  Input
} from 'antd';
import { getNotificationsForMitra, getNotificationsContent } from '../../service/inboxComms';
import { AppContext } from '../../App';
import moment from 'moment';
import SideSheet from '../common/SideSheet';
import '../../css/notification.scss';
import GlobalSearchMobileAndDesktop from '../common/GlobalSearchMobileAndDesktop';
import CONSTANTS from '../../constants/constants';
import PageHeaderComponent from '../common/PageHeader';
import {useHistory} from 'react-router-dom';
import { loginForPayoutDashboard, sendPayoutDashboardPasswordResetUrl } from './../../service/commonService'

const { Title, Text } = Typography;

const Notification = ({ unreadInboxMessages }) => {
  const { mitraReducer, mitraDispatch, setAlert, setSpin } = useContext(AppContext);
  const [responselevel, setResponselevel1] = useState(null);
  const [responselevelEachElm, setResponselevelEachElm] = useState(null);
  const [breadcrumb] = useState([{ link: '/notification', label: 'Inbox' }]);
  const [showSideSheet, setShowSideSheet] = useState(false);
  const [pageLimit, setSpagelimit] = useState(20);
  const [currentPageCandidate, setCurrentPageCandidate] = useState(1);
  const [totalApplicationCountCandidates, setTotalApplicationCountCandidates] = useState(0);
  const [currentClickedCard, setCurrentClickedCard] = useState(null);
  const [visible, setVisible] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState('All Messages');
  const [searchText, setSearchText] = useState('');
  const [visibleAuth, setVisibleAuth] = useState(false);
  const [accessPasswordUsr, setAccessPasswordUsr] = useState('');
  const history = useHistory();

  const [filterData, setFilterData] = useState(CONSTANTS.INBOX_FILTER_TAGS);
  let isAdmin;

  const hide = (element) => {
    setSelectedFilter(element.name);
    setVisible(false);
  };

  const handleVisibleChange = (newVisible) => {
    setVisible(newVisible);
  };

  const filterContent = (
    <div
      className="d-flex flex-column justify-content-start align-items-start"
      style={{ gap: '10px', fontFamily: 'var(--primary-font-family)', fontSize: '14px', lineHeight: '24px',fontWeight: '700' }}
    >
      {filterData.map((item) => {
        return (
          <button
            key={item.id}
            onClick={() => hide(item)}
            style={{
              gap: '10px',
              fontFamily: 'var(--primary-font-family)',
              fontStyle: 'normal',
              fontWeight: '400',
              fontSize: '14px',
              lineHeight: '22px ',
            }}
          >
            {item.name}
          </button>
        );
      })}
    </div>
  );
  

  useEffect(() => {
    fetchInboxMessages();
  }, [mitraReducer?.mitraInfo, currentPageCandidate, searchText, selectedFilter]);

  const fetchInboxMessages = () => {
    isAdmin = mitraReducer?.mitraInfo?.role === 'admin';
    setSpin({
      loading: true, //false or true when actived
      delay: null,
      tip: 'Loading...',
    });
    getNotificationsForMitra({
      offset: currentPageCandidate * pageLimit - pageLimit,
      limit: pageLimit,
      searchText: searchText,
      filterBy: selectedFilter,
      isAdmin: isAdmin
    })
      .then((response) => {
        setResponselevel1(response.data.result);
        setTotalApplicationCountCandidates(Number(response.data.totalInboxMessageCount));
        setSpin({
          loading: false, //false or true when actived
          delay: null,
          tip: 'Loading...',
        });
        if (response.data.totalInboxMessageCount) fetchDetailedResponse(response);
      })
      .catch((e) => {
        message.error('No data');
        setTotalApplicationCountCandidates(0);
        console.error(e);
        setSpin({
          loading: false, //false or true when actived
          delay: null,
          tip: 'Loading...',
        });
        localStorage.removeItem('payoutAccessToken');
        setVisibleAuth(true);
      });
  };

  const fetchDetailedResponse = (response) => {
    setSpin({
      loading: true, //false or true when actived
      delay: null,
      tip: 'Loading...',
    });
    getNotificationsContent({
      commId: response.data.result[0].id,
      offset: currentPageCandidate * pageLimit - pageLimit,
      limit: pageLimit,
    }).then((res) => {
      setSpin({
        loading: false, //false or true when actived
        delay: null,
        tip: 'Loading...',
      });
      setResponselevelEachElm(res.data.data[0]);
    });
  };

  const onClickItem = (item) => {
    setSpin({
      loading: true, //false or true when actived
      delay: null,
      tip: 'Loading...',
    });
    getNotificationsContent({
      commId: item.id,
      offset: currentPageCandidate * pageLimit - pageLimit,
      limit: pageLimit,
    }).then((res) => {
      setSpin({
        loading: false, //false or true when actived
        delay: null,
        tip: 'Loading...',
      });
      setResponselevelEachElm(res.data.data[0]);
    });
    setShowSideSheet(true);
    setCurrentClickedCard(item);
  };

  const subjectContent = () => {
    return (
      <>
        <Card>
          <Space style={{ width: '100%' }} direction="vertical" size={16}>
            {/* <span
              className="tag-information"
              style={{ display: 'flex', alignItems: 'center', gap: '2px' }}
            >
              <FireOutlined />
              Tag Data
            </span>
            <Title level={3}>{responselevelEachElm?.subject}</Title>
            

            <br />
            <Space className="d-flex flex-column justify-content-between align-items-start">
              <Card style={{ width: '100%' }}>
                <div
                  className="d-flex flex-column justify-content-between align-items-start"
                  dangerouslySetInnerHTML={{ __html: responselevelEachElm?.content }}
                ></div>
              </Card>
            </Space> */}

            {responselevelEachElm?.messageTag && (
              <div className="inbox-detail-tag">
                <span className="tag-information">
                  <FireOutlined />
                  {responselevelEachElm?.messageTag || 'tag'}
                </span>
              </div>
            )}

            <div className="inbox-detail-title">
              <span>{responselevelEachElm?.subject}</span>
            </div>
            <div className="inbox-detail-content">
              <div
                className="d-flex flex-column justify-content-between align-items-start"
                dangerouslySetInnerHTML={{ __html: responselevelEachElm?.content }}
              ></div>
            </div>
          </Space>
        </Card>
      </>
    );
  };

  const formatTitleString = (title) => {
    // considering 42 characters for title in message list
    let titleString = title;
    if (!titleString || !titleString.length) return;
    if (titleString.length > 40) {
      titleString = titleString.substring(0, 39) + ' ..';
    } else {
      titleString = titleString + ' ..';
    }
    return titleString;
  };

  const setsearchElement = (e) => {
    setSearchText(e);
  };

  const onResetPasswordClick = () => {

    const key = `open${Date.now()}`;
    const btn = (
      <Button type="primary" size="small" onClick={() => history.push(`/notification}`)}>
        Go to Inbox
      </Button>
    );

    notification.open({
        message: `Notification`,
        description: `A password ${mitraReducer?.mitraInfo?.password ? 'reset' : 'creation'} SMS has been sent to your phone number ${mitraReducer?.mitraInfo?.phoneNumber} and email ${mitraReducer?.mitraInfo?.email}`,
        btn,
        key,
      });
    let isNewPassword = mitraReducer?.mitraInfo?.password ? false : true
    sendPayoutDashboardPasswordResetUrl(isNewPassword);
    history.push('/home')
  }

  const handleOkClick = () => {
    loginForPayoutDashboard({ password: accessPasswordUsr }).then(res => {
      if (res.data.success) {
        localStorage.setItem("payoutAccessToken", res.headers.payoutaccesstoken)
        setVisibleAuth(false);
      } else {
        message.error("Invalid credentials!")
      }
    }).catch((e) => {
      message.error("Invalid credentials!")
    })
  }

  const getButtons = () => {
    let buttonsArr = [];
    if (mitraReducer?.mitraInfo?.password) {
      buttonsArr.push(
        <Button
          onClick={onResetPasswordClick}
          type="secondary">
          <Text >Reset Password</Text>
        </Button>
      );
      buttonsArr.push(
        <Button
          onClick={handleOkClick}
          type="primary">
          <Text style={{ color: '#fff' }}> OK </Text>
        </Button>
      )
    }
    return buttonsArr;
  }

  return (
    <>
      <div className="Notification mainContentZ">
        <Modal
          title={<Text style={{ display: 'flex', alignItems: 'center' }} strong>Authentication</Text>}
          visible={visibleAuth && !localStorage.getItem("payoutAccessToken")}
          onOk={() => {
          }}
          onCancel={() => {
            history.goBack();
          }}
          footer={getButtons()}
        >
          {
            mitraReducer?.mitraInfo?.password ?
              <Input.Password
                placeholder="Input password to access Inbox"
                iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                onChange={(e) => {
                  setAccessPasswordUsr(e.target.value)
                }}
                defaultValue={accessPasswordUsr}
              /> :
              <Button
                onClick={onResetPasswordClick}
                type="secondary">
                <Text > Create New Password</Text>
              </Button>
          }
        </Modal>
        {/* Temporary Header and search until Notifications arrive*/}
        {
          !visibleAuth && (!isAdmin || localStorage.getItem('payoutAccessToken')) ? 
        <>
        <div
          className="header"
          style={{ marginBottom: 4, display: 'flex', justifyContent: 'space-between' }}
        >
          <span className="page-header-font">
            Inbox
            {unreadInboxMessages !== 0 &&
              ` (${
                unreadInboxMessages < 10 ? '0' + unreadInboxMessages : unreadInboxMessages
              } Unread)`}
          </span>
          <GlobalSearchMobileAndDesktop
            searchItem={setsearchElement}
            placeholder={'Search your inbox'}
          />
        </div>

        {/* Search Section Commented for now */}
        {/* <div style={{ padding: '10px' }}>
          <GlobalSearchMobileAndDesktop
            searchItem={setsearchElement}
            placeholder={'Search your inbox'}
          />
        </div> */}

        {/* Filter and Pagination section */}

        <div
          className="header"
          style={{
            marginBottom: '1.5em',
            display: 'flex',
            justifyContent: 'space-between',
            background: 'none',
          }}
        >
          <Popover
            overlayStyle={{ width: '200px' }}
            placement="bottom"
            content={filterContent}
            trigger="click"
            visible={visible}
            onVisibleChange={handleVisibleChange}
          >
            <Button className="d-flex align-items-center filter-content" icon={<FilterOutlined />}>
              {selectedFilter}
            </Button>
          </Popover>
          {totalApplicationCountCandidates > 0 ? (
            <Pagination
              key="candidate-pagination"
              total={totalApplicationCountCandidates > 0 && totalApplicationCountCandidates}
              showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
              defaultPageSize={pageLimit}
              defaultCurrent={currentPageCandidate}
              responsive={true}
              showLessItems={true}
              onChange={(page, pagesize) => {
                setCurrentPageCandidate(page);
              }}
              showSizeChanger={false}
            />
          ) : null}
        </div>

        {/* Message Container Section */}
        <Row>
          <Col className="level-1">
            {responselevel?.map((item, index) => {
              return (
                <Card
                  bodyStyle={{ padding: '10px', background: item.isRead ? '#F5F5F5' : '#fff' }}
                  onClick={() => onClickItem(item)}
                  key={`indox-level-1_${index}`}
                  hoverable
                  style={{ minWidth: '300px', marginBottom: 8 }}
                >
                  <Space style={{ width: '100%' }} direction="vertical" size={16}>
                    <Space
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: item.messageTag ? 'space-between' : 'flex-end',
                      }}
                      direction="horizontal"
                    >
                      {item?.messageTag && (
                        <span
                          className="tag-information"
                          style={{ display: 'flex', alignItems: 'center', gap: '2px' }}
                        >
                          <FireOutlined />
                          {item.messageTag}
                        </span>
                      )}
                      <Text type="secondary">{moment(item.createdAt).format('DD MMM YYYY')}</Text>
                    </Space>
                    <Space style={{ width: '100%' }} direction="vertical" size={2}>
                      <div className="d-flex custom-title">
                        <Title level={5}>{formatTitleString(item.subject)}</Title>
                      </div>
                      {/* <div className="d-flex justify-content-between align-items-center">
                        <Text type="secondary">
                          Brief messsage for the following matter is as follows...
                        </Text>
                      </div> */}
                      {/* <div className="inbox-detail-title">
                        <span>{item.subject}</span>
                      </div>
                      <div className="inbox-detail-content">
                        <span>Brief messsage for the following matter is as follows...</span>
                      </div> */}
                    </Space>
                  </Space>
                </Card>
              );
            })}
            {responselevel?.length === 0 && <Empty />}
          </Col>
          {responselevel?.length > 0 && (
            <Col className="level-2" flex="auto">
              {subjectContent()}
            </Col>
          )}
        </Row>
        </>
        : null
      }
      </div>
      <span className="show-only-mobile">
        {showSideSheet && (
          <SideSheet
            hideDismissIcon={true}
            sidername={responselevelEachElm?.subject}
            onDismiss={() => {
              setShowSideSheet(false);
            }}
          >
            <div style={{ padding: 10 }}>{subjectContent()}</div>
          </SideSheet>
        )}
      </span>
    </>
  );
};

export default Notification;
