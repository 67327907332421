import React, { useEffect, useState, useContext } from 'react';
import { logEvent } from '../../service/tracking';
import trackingConstants from '../../constants/trackingConstants';
import '../../css/lead.scss';
import useGAEventsTracker from '../../service/useGSEventsTracker';
import moment from 'moment';
import { AdditionalFormsURLEnum } from '../../utils/utility';
import { useHistory } from 'react-router-dom';
import { trackEvent } from '../../service/engagementMonitoringService';
import CONSTANTS from '../../constants/constants';
import { getViewDetailsData } from './../../service/leadService';
import {
  CloseCircleOutlined,
  PhoneOutlined,
  UploadOutlined,
  CheckCircleOutlined,
  WhatsAppOutlined,
  QuestionOutlined,
} from '@ant-design/icons';
import { Modal, Button, Timeline, Typography, Card } from 'antd';
import { AppContext } from '../../App';
import { getMitraPhoneNumberFromId } from '../../service/commonService';
import WhatsappTemplateModal from '../whatsappTemplateModal/WhatsappTemplateModal';

const { Text } = Typography;

const CandidateProfilePopup = ({ visible, setShowDetail, lead, selectedScheduledInterviewId }) => {
  const history = useHistory();
  const [leadDetails, setLeadDetails] = useState([]);
  const { mitraReducer } = useContext(AppContext);
  const [managerMitraPhoneNumber, setManagetMitraPhoneNumber] = useState();
  const [whatsAppMessageModal, setWhatsAppMessageModal] = useState(false);

  useEffect(() => {
    if (lead?.recommendationHistoriesId || lead?.scheduledInterviewId) {
      let data;
      if (selectedScheduledInterviewId?.length) {
        data = {
          recommendationHistoryIds: lead.recommendationHistoriesId,
          scheduledInterviewId: selectedScheduledInterviewId,
        };
      } else {
        data = {
          recommendationHistoryIds: lead.recommendationHistoriesId,
          scheduledInterviewId: lead?.scheduledInterviewId,
        };
      }
      getViewDetailsData(data).then((result) => {
        setLeadDetails(result.data);
      });
    }
  }, [lead]);

  useEffect(() => {
    if (mitraReducer?.mitraInfo?.managerMitraID) {
      getMitraPhoneNumberFromId(mitraReducer?.mitraInfo?.managerMitraID).then((res) => {
        setManagetMitraPhoneNumber(res.data.mitraPhoneNumber);
      });
    } else {
      setManagetMitraPhoneNumber(mitraReducer?.mitraInfo?.phoneNumber);
    }
  }, [mitraReducer?.mitraInfo?.phoneNumber]);

  const GAEventsTracker = useGAEventsTracker('Action buttons clicked');

  const editViewApplicationClick = (item, eventName) => {
    const paramObject = {
      name: item.lastName.includes('Unknown')
        ? item.firstName
        : item.firstName + ' ' + item.lastName,
      phoneNumber: item.phoneNumber,
      scheduledInterviewId: item.id,
      companyName: item.companyName,
      jobId: item.jobId,
      jobDemandId: item.jobDemandId,
      companyCity: item.companyCity,
      ...(item.companyName.toLowerCase().indexOf('rapido') !== -1
        ? {
            candidateId: item.siMetaData
              ? item.siMetaData.rapidoMilestone
                ? item.siMetaData.rapidoMilestone.captainId
                : null
              : null,
          }
        : {}),
      meta: JSON.stringify(item.siMetaData),
    };
    viewAdditionalForm(paramObject, eventName, { candidate_phone_number: lead.phoneNumber });
  };
  const viewAdditionalForm = (lead, event, obj) => {
    trackEvent(event, obj, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
    history.push({
      pathname: '/moreInfo',
      route: !lead.scheduledInterviewId ? 'jobRecommendationPage' : null,
      search: new URLSearchParams(lead).toString(),
    });
  };

  const nonuniqueCheck = (item) => {
    let nonUnique = false;
    item.timeline.map((ele) => {
      if (ele.label === 'Candidate Uniqueness Check' && ele.value === 'Not Unique') {
        nonUnique = true;
      }
    });
    return nonUnique;
  }
  const getCombinedErrorMsg = (milestone) => {
    let documentStatuses = milestone.value;
    let documentUploadCompleted = milestone.documentUploadCompleted;
    let documentUploadStarted = milestone.documentUploadStarted;
    let documentUploadErrorMsg = '';
    let erroredDocuments = [];
    documentStatuses.forEach(statusMsg => {
      if(!statusMsg.toLowerCase().includes('success')) {
        erroredDocuments.push(statusMsg.split('-')[0].trim());
      }
    });

    if(erroredDocuments && erroredDocuments.length > 0) {
      documentUploadErrorMsg = `Oh ho! There are some isues with – ${erroredDocuments.join(', ')}. Kindly reupload`;
    } else if (documentUploadStarted && documentUploadCompleted){
      documentUploadErrorMsg ='Documents Accepted';
    } else if (documentUploadStarted && !documentUploadCompleted) {
      documentUploadErrorMsg = 'Processing documents, please check again in some time.';
    }
    return documentUploadErrorMsg;
  }

  const openSupportForm = () => {
    window.FreshworksWidget('open');
    window.FreshworksWidget('identify', 'ticketForm', {
      name: mitraReducer?.mitraInfo?.name,
      email: mitraReducer?.mitraInfo?.email,
      group_id: 84000268346,
      custom_fields: {
        cf_your_registered_phone_number: mitraReducer?.mitraInfo?.phoneNumber,
        cf_manager_phone_number: managerMitraPhoneNumber ? managerMitraPhoneNumber : '',
        cf_candidate_phone_number: lead.phoneNumber,
        cf_candidate_city: lead.companyCity[0],
      },
    });
    window.FreshworksWidget('disable', 'ticketForm', [
      'name',
      'group_id',
      'custom_fields.cf_your_registered_phone_number',
      'custom_fields.cf_manager_phone_number',
      'custom_fields.cf_candidate_phone_number',
    ]);
    if (mitraReducer?.mitraInfo?.role === 'admin') {
      window.FreshworksWidget('hide', 'ticketForm', ['custom_fields.cf_manager_phone_number']);
    }
  };

  const onCloseDetailsPopup = () => {
    setShowDetail(false);
    window.FreshworksWidget('hide');
  };

  const whatsAppMessageModalCancel = () => {
    setWhatsAppMessageModal(false);
  };

  return (
    <>
      {/* <WhatsappTemplateModal
        lead={lead}
        leadDetails={leadDetails}
        whatsAppMessageModal={whatsAppMessageModal}
        whatsAppMessageModalCancel={whatsAppMessageModalCancel}
      ></WhatsappTemplateModal>
      <Modal
        title="Candidate Profile"
        centered
        visible={visible && lead}
        onCancel={() => onCloseDetailsPopup()}
        footer={null}
        bodyStyle={{ height: '600px', overflow: 'scroll' }}
        className="modal-candidate-profile"
      >
        <div className="details-component-container">
          <div className="Contact-details-header">
            <div className="Candidate-details">
              <div className="head-details">
                <div className="name-number-container">
                  <div className="lead-name">
                    {leadDetails.length > 0
                      ? leadDetails[0].lastName.includes('Unknown')
                        ? leadDetails[0].firstName
                        : leadDetails[0].firstName + ' ' + leadDetails[0].lastName
                      : ''}
                  </div>
                  <div className="lead-number">
                    {leadDetails.length > 0
                      ? leadDetails[0].parentPhoneNumber
                        ? `${leadDetails[0].parentPhoneNumber} (Edited)`
                        : leadDetails[0].phoneNumber
                      : ''}
                  </div>
                </div>
                <div className="contact-buttons">
                  <a
                    href={`tel:+91-${
                      leadDetails.length > 0
                        ? leadDetails[0].parentPhoneNumber
                          ? leadDetails[0].parentPhoneNumber
                          : leadDetails[0].phoneNumber
                        : ''
                    }`}
                    onClick={(e) => {
                      GAEventsTracker('CallCandidate');
                      logEvent(
                        'CallCandidate',
                        trackingConstants.EVENT_GROUP_VIEW_LEAD,
                        trackingConstants.EVENT_SCREEN_CANDIDATE
                      );
                      trackEvent(
                        'call_now_option_clicked',
                        {},
                        CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS
                      );
                    }}
                  >
                    <Button icon={<PhoneOutlined />} type="primary">
                      <Text style={{ color: '#fff' }}>Call Now</Text>
                    </Button>
                  </a>

                  <Button
                    onClick={(e) => {
                      GAEventsTracker('MessageCandidate');
                      setWhatsAppMessageModal(true);
                      logEvent(
                        'WhatsAppCandidate',
                        trackingConstants.EVENT_GROUP_VIEW_LEAD,
                        trackingConstants.EVENT_SCREEN_CANDIDATE
                      );
                      trackEvent(
                        'message_option_clicked',
                        {},
                        CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS
                      );
                    }}
                    icon={<WhatsAppOutlined style={{ color: '#fff' }} />}
                    style={{ backgroundColor: '#52C41A' }}
                    type="dashed"
                  >
                    <Text style={{ color: '#fff' }}>Message</Text>
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div style={{ overflow: 'scroll', height: '100%' }}>
            {leadDetails.length > 0
              ? leadDetails.map((item, index) => {
                  return (
                    <Card
                      key={'card_profile_' + index}
                      style={
                        nonuniqueCheck(item)
                          ? { backgroundColor: '#F5F5F5', margin: '20px 0px'}
                          : { margin: '20px 0px' }
                      }
                    >
                      <Timeline>
                        {item.timeline?.map((milestone, index) => {
                          let milestoneValue = milestone.value;
                          if (milestone.url) {
                            milestoneValue = (
                              <a href={milestone.url} target="_blank" rel="noreferrer">
                                {milestone.value}
                              </a>
                            );
                          }
                          return (
                            <Timeline.Item
                              key={'card_profile_' + milestone.label + index}
                              dot={
                                (milestone.completed) ? (
                                  <CheckCircleOutlined />
                                ) : (
                                  <CloseCircleOutlined />
                                )
                              }
                              color={milestone.date !== '' ? '#52C41A' : 'var(--primary-theme-color)'}
                            >
                              <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  {(milestone.date !== '' && milestone.date !== 'NO_DATE_UBER' &&  item.companyName.toLowerCase().indexOf('rapido') === -1) ? (
                                    <>
                                      <strong>{milestone.label}</strong>
                                      <div>{milestone.label == 'Document Upload Status' && Array.isArray(milestoneValue) ? <Text mark>{getCombinedErrorMsg(milestone)} <br /></Text> : milestoneValue} . {moment(milestone.date).format('DD MMMM YYYY')}</div>
                                    </>
                                  ) : 
                                  item.companyName.toLowerCase().indexOf('uber') !== -1 || item.companyName.toLowerCase().indexOf('rapido') !== -1? (
                                  <>
                                  <strong >{milestone.label}</strong>
                                  <div>{milestone.label == 'Document Upload Status' && Array.isArray(milestoneValue) ?  
                                  milestoneValue.map(ele => { return (ele.toLowerCase().includes('approved'))? <Text style={{ backgroundColor: '#90EE90' }}>{ele} <br /></Text> : (ele.toLowerCase().includes('review') || ele.toLowerCase().includes('verification'))? <Text style={{ backgroundColor: '#D3D3D3' }}>{ele} <br /></Text> : <Text style={{ backgroundColor: '#FFCCCB' }}>{ele} <br /></Text>}) : milestoneValue} {(milestone.date)? ". "+moment(milestone.date).format('DD MMMM YYYY'):''}</div>
                                  </> 
                                  ) :
                                  (
                                    <>
                                      <strong>{milestone.label}</strong>
                                      <div>{milestoneValue}</div>
                                    </>
                                  )}
                                </div>
                                {milestone.label === 'Application Started' ? (
                                  <div className="client-logo" style={{ marginLeft: 'auto' }}>
                                    <img className="imgResponse" src={item.logo} />
                                  </div>
                                ) : null}
                                {(milestone.label.includes('Lead Referred') ||
                                  milestone.label.includes('Application Completed') ||
                                  milestone.label.includes('Application Not Completed')) &&
                                AdditionalFormsURLEnum[item.companyName.toLowerCase()] ? (
                                  item.id ? (
                                    item.companyName.toLowerCase().indexOf('rapido') !== -1 &&
                                    (!item?.siMetaData?.rapidoMilestone?.captainId ||
                                      item?.siMetaData?.rapidoMilestone?.value
                                        ?.toString()
                                        .includes('Application Complete')) ? null : (
                                      <Button
                                        style={{ margin: '5px 0px 5px auto' }}
                                        icon={<UploadOutlined />}
                                        type="primary"
                                        onClick={() => {
                                          editViewApplicationClick(
                                            item,
                                            'view_application_clicked_on_application_status_page'
                                          );
                                        }}
                                      >
                                        Edit/View Application
                                      </Button>
                                    )
                                  ) : (
                                    <Button
                                      style={{ margin: '5px 0px 5px auto' }}
                                      icon={<UploadOutlined />}
                                      type="primary"
                                      onClick={() => {
                                        editViewApplicationClick(
                                          item,
                                          'complete_application_clicked_on_application_status_page'
                                        );
                                      }}
                                    >
                                      Complete Application
                                    </Button>
                                  )
                                ) : null}
                              </div>
                            </Timeline.Item>
                          );
                        })}
                      </Timeline>
                    </Card>
                  );
                })
              : null}
          </div>

          <div className="container-help-section">
            <div className="help-text">Need any help?</div>
            <div className="help-button">
              <Button onClick={() => openSupportForm()}>
                <QuestionOutlined /> Raise Issue
              </Button>
            </div>
          </div>
        </div>
      </Modal> */}
    </>
  );
};
export default CandidateProfilePopup;
