import * as React from "react";
const BookmarkIcon = ({fillColor='none',height=16,width=16,strokeColor="#1D5FFF"}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={width}
    viewBox="0 0 16 16"
    fill={fillColor}
  >
    <path
      d="M12 11.1234V4.79993V4.79773C12 4.05246 12 3.67959 11.8548 3.39465C11.727 3.14377 11.5227 2.93978 11.2719 2.81195C10.9866 2.66663 10.6135 2.66663 9.8668 2.66663H6.13346C5.38673 2.66663 5.01308 2.66663 4.72786 2.81195C4.47698 2.93978 4.27316 3.14377 4.14532 3.39465C4 3.67987 4 4.05319 4 4.79993V11.1234C4 12.0307 4 12.4845 4.13608 12.7621C4.3883 13.2767 4.94105 13.5725 5.50911 13.497C5.8156 13.4562 6.19309 13.2045 6.94807 12.7012L6.94954 12.7002C7.24901 12.5006 7.39886 12.4007 7.55545 12.3453C7.84296 12.2437 8.15651 12.2437 8.44401 12.3453C8.60086 12.4008 8.75109 12.5009 9.05155 12.7012C9.80652 13.2045 10.1845 13.4562 10.491 13.497C11.0591 13.5725 11.6117 13.2767 11.8639 12.7621C12 12.4845 12 12.0308 12 11.1236V11.1234Z"
      stroke={strokeColor}
      strokeWidth={1.33333}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default BookmarkIcon;
