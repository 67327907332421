import * as React from "react";
const DangerIcon = ({fillColor='none',height=16,width=16,strokeColor="#FFF"}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill={fillColor}
  >
    <path
      d="M8 6V9.33333"
      stroke={strokeColor}
      strokeWidth={1.33}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.99979 14.2733H3.95979C1.64646 14.2733 0.679792 12.6199 1.79979 10.5999L3.87979 6.85327L5.83979 3.33327C7.02646 1.19327 8.97313 1.19327 10.1598 3.33327L12.1198 6.85994L14.1998 10.6066C15.3198 12.6266 14.3465 14.2799 12.0398 14.2799H7.99979V14.2733Z"
      stroke={strokeColor}
      strokeWidth={1.33}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.99658 11.3333H8.00257"
      stroke={strokeColor}
      strokeWidth={1.33}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default DangerIcon;
