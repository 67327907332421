import * as React from "react";
const PeopleIcon = ({fillColor='none',height=16,width=16,strokeColor="#838383"}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill={fillColor}
  >
    <path
      d="M12.0002 4.77325C11.9602 4.76659 11.9135 4.76659 11.8735 4.77325C10.9535 4.73992 10.2202 3.98659 10.2202 3.05325C10.2202 2.09992 10.9869 1.33325 11.9402 1.33325C12.8935 1.33325 13.6602 2.10659 13.6602 3.05325C13.6535 3.98659 12.9202 4.73992 12.0002 4.77325Z"
      stroke={strokeColor}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.3135 9.62669C12.2268 9.78003 13.2335 9.62003 13.9401 9.14669C14.8801 8.52003 14.8801 7.49336 13.9401 6.86669C13.2268 6.39336 12.2068 6.23336 11.2935 6.39336"
      stroke={strokeColor}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.97983 4.77325C4.01983 4.76659 4.06649 4.76659 4.10649 4.77325C5.02649 4.73992 5.75982 3.98659 5.75982 3.05325C5.75982 2.09992 4.99316 1.33325 4.03983 1.33325C3.08649 1.33325 2.31982 2.10659 2.31982 3.05325C2.32649 3.98659 3.05983 4.73992 3.97983 4.77325Z"
      stroke={strokeColor}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.66663 9.62669C3.75329 9.78003 2.74663 9.62003 2.03996 9.14669C1.09996 8.52003 1.09996 7.49336 2.03996 6.86669C2.75329 6.39336 3.77329 6.23336 4.68663 6.39336"
      stroke={strokeColor}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.00021 9.75323C7.96021 9.74657 7.91355 9.74657 7.87355 9.75323C6.95355 9.7199 6.22021 8.96657 6.22021 8.03323C6.22021 7.0799 6.98688 6.31323 7.94021 6.31323C8.89355 6.31323 9.66021 7.08657 9.66021 8.03323C9.65355 8.96657 8.92021 9.72657 8.00021 9.75323Z"
      stroke={strokeColor}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.05998 11.8532C5.11998 12.4799 5.11998 13.5066 6.05998 14.1332C7.12665 14.8466 8.87331 14.8466 9.93998 14.1332C10.88 13.5066 10.88 12.4799 9.93998 11.8532C8.87998 11.1466 7.12665 11.1466 6.05998 11.8532Z"
      stroke={strokeColor}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default PeopleIcon;
