import { Empty, Select } from 'antd'
import React, { useContext, useEffect, useState } from 'react';

import HelpCard from './HelpCard';
import NewCustomPagination from '../../SamvaadiniV2/components/NewCustomPagination';
import { getSupportConversations, getSupportFilters } from '../../../service/SupportV2';
import { AppContext } from '../../../App';
import { useLocation } from 'react-router-dom';

const { Option } = Select;

function RaisedIssueListContainer() {
    const [selectedFilterTag, setSelectedFilterTag] = useState(null);
    const [filterTagTypes, setFilterTagTypes] = useState([]);
    const location = useLocation();

    let supportConversationApiPollingInterval;

    const {mitraReducer} = useContext(AppContext);

    const [tickets, setTickets] = useState([]);
    const [conversationsData, setConversationsData] = useState({});

    const [selectedSortBy, setSelectedSortBy] = useState(null);
    const [sortByOptions, setSortByOptions] = useState([]);

    const [filterToSortbyOptionsMap, setFilterToSortbyOptionsMap] = useState({});

    const [currentPage, setCurrentPage] = useState(1);

    const handleTagTypeFilterChange = (selectedTag) => {
        setSelectedFilterTag(selectedTag);
    }

    const handleSortBy = (val) => {
        setSelectedSortBy(val);
    };

    const getAllConversationFilters = () => {
        getSupportFilters({mitraId: mitraReducer?.mitraInfo?.id})
        .then(result=> {

            if(result?.data?.data?.statuses?.length) {
                setFilterTagTypes([...result?.data?.data?.statuses]);
                setSelectedFilterTag(result?.data?.data?.statuses?.[0]?.key);
            } else {
                setFilterTagTypes([]);
            }

            if(result?.data?.data?.sortBasisStatusMap) {
                setFilterToSortbyOptionsMap(result?.data?.data?.sortBasisStatusMap);
            } else {
                setFilterToSortbyOptionsMap({});
                setSortByOptions([]);
            }
        }).catch(err=> {
            console.log(err);
            setFilterTagTypes([]);
            setFilterToSortbyOptionsMap({});
            setSortByOptions([]);
        })
    }

    const getAllSupportConversations = () => {
        const payload = {
            mitraId: mitraReducer?.mitraInfo?.id, 
            offset: (currentPage-1) * 10, 
            limit: 10, 
            status: selectedFilterTag, 
            sortBy: selectedSortBy
        }
        getSupportConversations(payload)
        .then(res=> {
            if(res?.data?.status) {
                setTickets(res?.data?.data?.list);
                setConversationsData(res?.data?.data);
            } else {
                setTickets([]);
                setConversationsData({});
            }
        }).catch(err=> {
            console.log(err);
            setTickets([]);
            setConversationsData({});
        })
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    

    useEffect(() => {
      getAllConversationFilters();
    }, [mitraReducer?.mitraInfo?.id]);

    useEffect(() => {
        if(selectedFilterTag && Object.keys(filterToSortbyOptionsMap).length) {

            if(filterToSortbyOptionsMap[selectedFilterTag?.toLowerCase()].length) {
                setSortByOptions([...filterToSortbyOptionsMap[selectedFilterTag?.toLowerCase()]]);
                setSelectedSortBy(filterToSortbyOptionsMap[selectedFilterTag?.toLowerCase()]?.[0]?.key);
                setCurrentPage(1);
            } else {
                setSortByOptions([]);
                setSelectedSortBy(null);
            }
            
        }
    }, [selectedFilterTag, Object.keys(filterToSortbyOptionsMap).length])
    
    useEffect(() => {
        if(selectedSortBy) {
            getAllSupportConversations();
        }

        if(currentPage === 1) {
            supportConversationApiPollingInterval = setInterval(() => {
                if (location.pathname != '/help-lp-auto' && supportConversationApiPollingInterval) {
                  clearInterval(supportConversationApiPollingInterval);
                  return;
                }
          
                // func call to get the unread conversations
                getAllSupportConversations();
            }, 30000);
        }

        return () => {
            if (supportConversationApiPollingInterval) {
              clearInterval(supportConversationApiPollingInterval);
            }
          };
    }, [selectedSortBy, currentPage])
    
    

  return (
    <div className='raised-issue-list-container'>
        <div className="raised-issue-summary-container">
            <div className='d-flex align-items-center flex-column'>
                <span className='unbounded-regular-16 plain-black'>Issues</span>
                <span className='inter-semibold-16-24' style={{color: '#D7AF1D'}}>{conversationsData?.active || '0'} Active</span>
            </div>
            <div className='d-flex align-items-end flex-column'>
                <span className='inter-regular-16-24 plain-black'>{`(${conversationsData?.resolved || '0'} resolved, ${conversationsData?.raised || '0'} raised in total)`}</span>
                
                    {
                        conversationsData?.raised ? (
                            <div className='tickets-resolution-indicator-container'>
                                <div className='tickets-resolved-indicator' style={{
                                    width: conversationsData.resolved ? `${(conversationsData.resolved/conversationsData.raised)*100}%` : '1%'
                                    }}></div>
                            </div>
                        ): null
                    }
                    
            </div>
        </div>

        <div className='raised-issue-list-filter-container'>
            <div className='d-flex align-items-center flex-gap-16 flex-wrap'>

                {
                    filterTagTypes?.length ? (
                        <div className='d-flex align-items-center flex-gap-12'>
                            <span className='inter-regular-14-20 color-838383'>Show</span>
                            <Select
                                style={{ width: '94px' }}
                                onChange={(tagType) => { handleTagTypeFilterChange(tagType) }}
                                value={selectedFilterTag}
                            >
                                {filterTagTypes?.map((item, index) => {
                                    return <Option value={item.key} key={index}>{item.label}</Option>
                                })}
                            </Select>
                        </div>
                    ): null
                }
                

                {
                    sortByOptions?.length ? (
                        <div className='d-flex align-items-center flex-gap-12'>
                            <span className='inter-regular-14-20 color-838383'>Sort by</span>
                            <Select
                                style={{ width: '194px' }}
                                onChange={(sortType) => { handleSortBy(sortType) }}
                                value={selectedSortBy}
                            >
                                {sortByOptions?.map((item, index) => {
                                    return <Option value={item.key} key={index}>{item.label}</Option>
                                })}
                            </Select>
                        </div>
                    ): null
                }
                
            </div>

            {
                conversationsData?.raised ? (
                    <NewCustomPagination 
                        totalItems={
                            (
                                selectedFilterTag === 'all' ? conversationsData?.raised:
                                selectedFilterTag === 'active' ? conversationsData?.active: conversationsData?.resolved
                            )
                             || 0} 
                        totalItemsPerPage={10} 
                        currentPage={currentPage} 
                        onPageChange={handlePageChange}
                    /> 
                ): null
            }
                       
        </div>

        <div className='rilc-divider'></div>

        {
            tickets.length ?
            tickets.map(item=> (
                <HelpCard key={item?.conversation_id} ticketDetails={item} getAllSupportConversations={getAllSupportConversations}/>
            )): <div className='d-flex align-items-center justify-content-center w-100 flex-column'>
            <Empty description=''/>
            <span className='inter-medium-14-20'>No Tickets Found</span>
          </div>
        }
    </div>
  )
}

export default RaisedIssueListContainer