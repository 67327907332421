import * as React from "react";
const SuitcaseIcon = ({fillColor='none',height=16,width=16,strokeColor="#838383"}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill={fillColor}
  >
    <path
      d="M10.6667 5.33341C10.6667 3.86066 9.47276 2.66675 8 2.66675C6.52724 2.66675 5.33333 3.86066 5.33333 5.33341M14 7.46672V11.2C14 11.9468 14.0001 12.3202 13.8548 12.6054C13.727 12.8563 13.5227 13.0602 13.2719 13.1881C12.9866 13.3334 12.6135 13.3334 11.8668 13.3334H4.13346C3.38673 13.3334 3.01308 13.3334 2.72786 13.1881C2.47698 13.0602 2.27316 12.8563 2.14532 12.6054C2 12.3202 2 11.9468 2 11.2V7.46672C2 6.71998 2 6.34666 2.14532 6.06144C2.27316 5.81056 2.47698 5.60657 2.72786 5.47874C3.01308 5.33341 3.38673 5.33341 4.13346 5.33341H11.8668C12.6135 5.33341 12.9866 5.33341 13.2719 5.47874C13.5227 5.60657 13.727 5.81056 13.8548 6.06144C14.0001 6.34666 14 6.71998 14 7.46672Z"
      stroke={strokeColor}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SuitcaseIcon;
